/* eslint-disable react/jsx-no-literals */
import { useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { getInitiativeById } from '@/features/canvas/api';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

type Props = {
  color: string;
  isNodeOptionChecked: boolean;
  nodeHasActiveFilter: boolean;
  nodeId: number;
  progress?: number;
  title?: string;
};

const SwimlaneViewNode = ({
  nodeId,
  title,
  progress = 0,
  color,
  isNodeOptionChecked,
  nodeHasActiveFilter,
}: Props) => {
  const {
    activeFilterId,
    filteredNodes,
    isPublicView,
    optionCheckedId,
    setSelectedNodeId,
    setIsInitiativeDetailDialogOpen,
  } = useEditorContext();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const progressBarColor = activeFilterId
    ? color || transparentize(0.85, theme.palette.brand.progressBar)
    : theme.palette.brand.progressBar;

  const getOpacity = () => {
    if (nodeHasActiveFilter && optionCheckedId && !isNodeOptionChecked) {
      return 0.15;
    }
    if (!nodeHasActiveFilter && activeFilterId) {
      return 0.15;
    }
    return 1;
  };

  const onSwimlaneNodeClick = () => {
    if (isPublicView) {
      const initiative = filteredNodes.find(node => node.id === nodeId)?.initiatives;
      queryClient.setQueryData([getInitiativeById.name, nodeId], initiative);
    }

    setSelectedNodeId(nodeId);
    setIsInitiativeDetailDialogOpen(true);
  };

  return (
    <Node $opacity={getOpacity()} onClick={onSwimlaneNodeClick}>
      <NodeTitle>{title}</NodeTitle>
      <NodeProgress>
        <NodeProgressBackLayer>
          <NodeProgressBar $widthPercentage={progress} $color={progressBarColor}>
            <ProgressText>{progress}</ProgressText>
            <Percentage>%</Percentage>
          </NodeProgressBar>
        </NodeProgressBackLayer>
      </NodeProgress>
    </Node>
  );
};

const Node = styled.div<{ $opacity: number }>`
  cursor: pointer;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;
  height: 60px;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  opacity: ${({ $opacity }) => $opacity};
  border: 1px solid white;
  box-shadow: ${({ theme }) => theme.shadows[0]};
`;
const NodeTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  height: 30px;
  font-size: 16px;
  color: '#363649';
  text-align: center;
  padding: 0.125rem;
  margin-bottom: 0.25rem;
`;
const NodeProgress = styled.div`
  position: relative;
  padding: 0.125rem;
  width: 100%;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

const NodeProgressBackLayer = styled.div`
  width: 100%;
  height: 18px;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.palette.brand.white};
  display: flex;
  align-items: center;
  padding: 0 2px;
`;

const NodeProgressBar = styled.div<{ $color: string; $widthPercentage: number }>`
  color: ${({ theme }) => theme.palette.brand.white};
  padding: 0 4px;
  width: ${({ $widthPercentage }) => ($widthPercentage > 15 ? $widthPercentage : 'fit-content')}%;
  height: 16px;
  border-radius: 1rem;
  background-color: ${({ $color }) => $color};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  gap: 0.25rem;
`;

const ProgressText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Percentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { SwimlaneViewNode };
