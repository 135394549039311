import React from 'react';
import Popover from '@mui/material/Popover';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';

import { theme } from '@/features/ui/theme';

const DatePicker = ({
  anchorEl,
  handleClose,
  dateValue,
  handleChange,
  disabled,
}: {
  anchorEl: SVGElement | null;
  dateValue: Dayjs;
  handleChange: (value: Dayjs) => void;
  handleClose: () => void;
  disabled?: boolean;
}) => {
  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          disabled={disabled}
          value={dateValue}
          onChange={newValue => handleChange(newValue as Dayjs)}
          slotProps={{
            day: {
              sx: {
                '&.Mui-selected': {
                  backgroundColor: theme().palette.brand.editorTabPrimary,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Popover>
  );
};

export { DatePicker };
