import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Switch as MuiSwitch, Typography } from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';

type Props = {
  isChecked: boolean;
  onChange: () => void;
  value?: number;
};

const CustomSwitch = ({ onChange, isChecked, value }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center">
      <SwitchLabel>{t('editor.filter_sidebar.switch_off')}</SwitchLabel>
      <StyledSwitch
        onChange={onChange}
        checked={isChecked}
        value={value}
        inputProps={{ 'aria-label': 'ant design' }}
      />
      <SwitchLabel>{t('editor.filter_sidebar.switch_on')}</SwitchLabel>
    </Stack>
  );
};

const SwitchLabel = styled(Typography)`
  font-size: 1.5rem;
`;

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 34,
  height: 16,
  margin: '0 4px',
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: theme.palette.brand.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.brand.positiveStatus,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: transparentize(0.5, theme.palette.brand.black),
    boxSizing: 'border-box',
  },
}));

export { CustomSwitch };
