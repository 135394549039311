import {
  CustomFieldType,
  InitiativeFieldTypesConst,
} from '@/features/canvas/constants/initiative-field';
import { components } from '@/types/api';

import { NewFilterLinkingFilterOption } from './filter-option';

export interface LinkageColumn {
  id: string;
  name: string;
}

interface Cell {
  id: string;
  value: string;
}

export interface LinkageRow {
  cells: Cell[];
  id: string;
}

export const LinkedColumnType = {
  ...InitiativeFieldTypesConst,
  filter: 'filter',
  createNewField: 'createNewField',
  createNewFilter: 'createNewFilter',
  defaultFilter: 'defaultFilter',
} as const;

export type LinkedColumn = {
  importedColumnUuid: string;
  linkedTargetId: string;
  linkedTargetTitle: string;
  linkedTargetType: keyof typeof LinkedColumnType;
};

export type FieldLinkingFormState = {
  selectedLinkOptionId: string;
  fieldName?: string;
  fieldType?: keyof typeof CustomFieldType;
  filterName?: string;
  filterOptions?: NewFilterLinkingFilterOption[];
  filterShape?: components['schemas']['Filter']['shape'];
  filterType?: components['schemas']['Filter']['selection'];
  linkedColumnType?: keyof typeof LinkedColumnType;
};

export type LinkSelectionOption = {
  id: string;
  label: string;
  type: keyof typeof LinkedColumnType;
  sourceId?: string;
};
