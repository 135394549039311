import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import i18n from 'i18next';
import { defaultApi } from 'src/api';
import { AxiosError } from 'axios';
import { authCheck } from './auth';
import { useNavigate } from 'react-router';
import routes from '@/constants/routes';

const useLogout = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [, , removeCookie] = useCookies(['user', 'refreshToken']);
  return useMutation<void, AxiosError, void>(async () => {
    try {
      await defaultApi.post('/logout');

      removeCookie('user');
      removeCookie('refreshToken');
      queryClient.removeQueries(authCheck.name);
      navigate(routes.login);
    } catch (error) {
      throw new Error(i18n.t('error', { message: error }));
    }
  });
};

export default useLogout;
