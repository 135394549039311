/* eslint-disable react/no-this-in-sfc */
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, Button, Divider, IconButton, useTheme } from '@mui/material';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { createDependency, deleteDependency } from '@/features/canvas/api';
import { FilteredNodes, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';

import { HistoryActionType, useUndoRedo } from '../../../contexts/undo-redo-context';

const InitiativeAccordionContentDependencies = () => {
  const {
    filteredNodes,
    setFilteredNodes,
    selectedNodeId,
    setIsSelectingDependencies,
    isSelectingDependencies,
    setShowDependencies,
    filteredNodesRef,
    updateLastSavedTime,
  } = useEditorContext();
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const { addHistoryItem } = useUndoRedo();
  const theme = useTheme();

  const { mutateAsync: deleteNodeDependencyMutation } = useMutation(deleteDependency, {
    onSuccess: (_, { masterNodeId, slaveNodeId }) => {
      const newFilteredNodes = filteredNodesRef.current.map(n => {
        if (n.id === slaveNodeId) {
          return {
            ...n,
            dependencies: n.dependencies?.filter(
              d => d.masterNodeId !== masterNodeId || d.slaveNodeId !== slaveNodeId
            ),
          };
        }
        return n;
      });
      setFilteredNodes(newFilteredNodes as FilteredNodes[]);
    },
    onError: () => {
      showToast('error', t('editor.canvas.dependency_deletion_error'));
    },
  });

  const { mutateAsync: createNodeDependency } = useMutation(createDependency, {
    onSuccess: ({ masterNodeId, slaveNodeId }) => {
      const newFilteredNodes = filteredNodesRef.current.map(n => {
        if (n.id === slaveNodeId) {
          return {
            ...n,
            dependencies: [
              ...(n.dependencies || []),
              {
                masterNodeId,
                slaveNodeId,
              },
            ],
          };
        }
        return n;
      });

      updateLastSavedTime();

      setFilteredNodes(newFilteredNodes as FilteredNodes[]);
    },
    onError: () => {
      showToast('error', t('editor.canvas.dependency_creation_error'));
    },
  });

  const node = filteredNodes.find(n => n.id === selectedNodeId);

  const handleDeleteDependency = useCallback(
    async ({ masterNodeId, slaveNodeId }: { masterNodeId: number; slaveNodeId: number }) => {
      await deleteNodeDependencyMutation({ masterNodeId, slaveNodeId });
      addHistoryItem({
        type: HistoryActionType.DeleteDependency,
        async undo(resourceIds?: number[]) {
          const createdDependency = await createNodeDependency({
            masterNodeId: resourceIds?.[1] || masterNodeId!,
            slaveNodeId: resourceIds?.[0] || slaveNodeId,
          });
          this.redo = async (redoResourceIds?: number[]) => {
            await deleteNodeDependencyMutation({
              masterNodeId: redoResourceIds?.[1] || createdDependency.masterNodeId,
              slaveNodeId: redoResourceIds?.[0] || createdDependency.slaveNodeId,
            });
          };
          this.resourceIds = [
            resourceIds?.[0] || selectedNodeId! || slaveNodeId,
            resourceIds?.[1] || createdDependency.masterNodeId!,
          ];
        },
        redo: async (resourceIds?: number[]) => {
          await deleteNodeDependencyMutation({
            masterNodeId: resourceIds?.[1] || masterNodeId,
            slaveNodeId: resourceIds?.[0] || slaveNodeId || selectedNodeId!,
          });
        },
        resourceIds: [slaveNodeId || selectedNodeId!, masterNodeId],
      });
    },
    [deleteNodeDependencyMutation, addHistoryItem, selectedNodeId, createNodeDependency]
  );

  const dependenciesToNodesThatExist = useMemo(() => {
    return (
      node?.dependencies?.filter(dependency =>
        filteredNodes.some(fNode => fNode.id === dependency.masterNodeId)
      ) || []
    );
  }, [filteredNodes, node?.dependencies]);

  return (
    <Box sx={{ margin: '1rem 0' }}>
      <Title>{t('editor.sidebar.dependencies')}</Title>
      <Divider variant="fullWidth" sx={{ borderColor: theme.palette.brand.backgroundDialog }} />
      <Box sx={{ padding: '1rem 0' }}>
        <AddDependencyButton
          $isSelectingDependencies={isSelectingDependencies}
          onClick={() => {
            setIsSelectingDependencies(!isSelectingDependencies);
            setShowDependencies(false);
          }}
        >
          {t('editor.sidebar.select_dependencies')}
        </AddDependencyButton>
        {dependenciesToNodesThatExist.map(dependency => {
          const dependencyNode = filteredNodes.find(fNode => fNode.id === dependency.masterNodeId)!;

          return (
            <DependencyListItem key={`${dependency.masterNodeId}-${dependency.slaveNodeId}`}>
              <DependencyListItemTitle>
                {dependencyNode?.initiatives?.title || t('untitled')}
              </DependencyListItemTitle>
              <IconButton
                onClick={() =>
                  handleDeleteDependency({
                    masterNodeId: dependency.masterNodeId!,
                    slaveNodeId: dependency.slaveNodeId,
                  })
                }
                sx={{ padding: '0.5rem' }}
              >
                <CloseIcon />
              </IconButton>
            </DependencyListItem>
          );
        })}
      </Box>
    </Box>
  );
};

const Title = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

const DependencyListItem = styled.div`
  display: flex;
  margin: 0.5rem 0;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 2rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
`;

const AddDependencyButton = styled(Button)<{ $isSelectingDependencies: boolean }>`
  margin: 1rem 0 2rem;
  padding: 0.25rem 1.5rem;
  letter-spacing: 0.75px;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.palette.brand.white};
  text-transform: none;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  box-shadow: ${({ theme }) => theme.shadows[0]};
  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.white)};
  }
  ${({ theme, $isSelectingDependencies }) =>
    $isSelectingDependencies &&
    css`
      font-weight: 600;
      color: ${theme.palette.brand.white};
      background-color: ${theme.palette.brand.editorTabPrimary};

      &:hover {
        background-color: ${darken(0.1, theme.palette.brand.editorTabPrimary)};
      }
    `}
`;

const DependencyListItemTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export { InitiativeAccordionContentDependencies };
