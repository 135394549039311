import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function createDependency({
  masterNodeId,
  slaveNodeId,
}: {
  masterNodeId: number;
  slaveNodeId: number;
}) {
  const { data } = await defaultApi.post<components['schemas']['Dependencies']>(`/dependencies`, {
    masterNodeId,
    slaveNodeId,
  });
  return data;
}

export async function deleteDependency({
  masterNodeId,
  slaveNodeId,
}: {
  masterNodeId: number;
  slaveNodeId: number;
}) {
  return defaultApi.delete(`/dependencies`, {
    data: {
      masterNodeId,
      slaveNodeId,
    },
  });
}
