/* eslint-disable react/no-this-in-sfc */
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from 'react-konva';
import { Portal, useImage } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import BinIconSrc from '@/assets/icons/bin.svg';
import PencilIconSrc from '@/assets/icons/pencil.svg';
import ThinPlus from '@/assets/icons/thin-plus-primary.svg';
import {
  createSection,
  deleteSection,
  getRoadmapSections,
  updateSection,
} from '@/features/canvas/api';
import { TabActionMenuItem } from '@/features/canvas/components/TabActionMenuItem';
import {
  DEFAULT_NEW_TAB_WIDTH,
  MAX_VERTICAL_SECTIONS,
  MIN_VERTICAL_SECTIONS,
  TAB_ACTION_MENU_HEIGHT,
  TAB_ACTION_MENU_WIDTH,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  isHoverActive: boolean;
  rotation: number;
  sectionId: number;
  sectionIndex: number;
  sectionOrder: number;
  sectionOrientation: components['schemas']['RoadmapDetail']['sections'][0]['orientation'];
  setIsEditingTitle: Dispatch<SetStateAction<boolean>>;
  setIsHoveringAction: Dispatch<SetStateAction<boolean>>;
  shouldDisableActionMenuBecauseOfUsedTemporaryId: boolean;
  x: number;
  y: number;
};

const VerticalSectionActionMenu = ({
  x,
  y,
  rotation,
  sectionId,
  sectionOrder,
  sectionIndex,
  sectionOrientation,
  setIsEditingTitle,
  isHoverActive,
  shouldDisableActionMenuBecauseOfUsedTemporaryId,
  setIsHoveringAction,
}: Props) => {
  const theme = useTheme();
  const [pencilIcon] = useImage(PencilIconSrc);
  const [binIcon] = useImage(BinIconSrc);
  const [plusIcon] = useImage(ThinPlus);
  const { addHistoryItem, updateResourceId } = useUndoRedo();

  const {
    roadmap,
    sortedVerticalSections,

    generateUniqueIntTemporaryId,
    temporaryCreatedIds,
    setShouldRecalculateNodePositions,
    sortedVerticalSectionsRef,
    sortedHorizontalSectionsRef,
    updateLastSavedTime,
  } = useEditorContext();
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const { mutateAsync: createNewSection } = useMutation(
    ({ newSection }: { newSection: components['schemas']['Section']; newSectionTempId: number }) =>
      createSection({ newSection }),
    {
      onSuccess: ({ id }, { newSection, newSectionTempId }) => {
        const updatedSection = { ...newSection, id };
        queryClient.setQueryData(
          [getRoadmapSections.name, roadmap.id],
          (oldSections?: components['schemas']['Section'][]) => {
            if (oldSections) {
              return oldSections.map(s => {
                if (s.id === newSectionTempId) {
                  temporaryCreatedIds.delete(newSectionTempId);
                  return { ...updatedSection };
                }
                return s;
              });
            }
            return [];
          }
        );
        setShouldRecalculateNodePositions(true);
        updateLastSavedTime();
      },
      onError: () => {
        showToast('error', t('editor.canvas.add_section_error'));
        return queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
      },
    }
  );

  const { mutateAsync: updateSectionMutation } = useMutation(
    ({ newSectionData }: { newSectionData: components['schemas']['Section'] }) =>
      updateSection({ newSectionData, sectionId: Number(newSectionData.id) }),
    {
      onSuccess: () => updateLastSavedTime(),
      onError: () => {
        showToast('error', t('editor.canvas.edit_section_error'));
        return queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
      },
    }
  );

  const { mutateAsync: deleteSectionMutation } = useMutation(
    (id: number | string) => deleteSection({ sectionId: Number(id) }),
    {
      onMutate: verticalSectionId => {
        queryClient.setQueryData(
          [getRoadmapSections.name, roadmap.id],
          (oldSections?: components['schemas']['Section'][]) => {
            updateLastSavedTime();
            if (oldSections) {
              return oldSections.filter(s => Number(s.id) !== Number(verticalSectionId));
            }
            return [];
          }
        );
      },
      onSuccess: () => {
        setShouldRecalculateNodePositions(true);
        updateLastSavedTime();
      },
      onError: () => {
        showToast('error', t('editor.canvas.delete_section_error'));
        return queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
      },
    }
  );

  const deleteSectionHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (sectionId: number) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete this section?')) {
        await deleteSectionMutation(sectionId);
      }
    },
    [deleteSectionMutation]
  );

  const addVerticalSection = useCallback(
    async (newVerticalSection?: components['schemas']['Section']) => {
      const indexToIncrementFrom = sortedVerticalSections.findIndex(s => s.order === sectionOrder);

      let newSectionOrder = 0;
      let newOrder = 1;
      const verticalSectionsWithIncrementedOrder = sortedVerticalSections.map((s, index) => {
        let adjustedSection = { ...s, order: newOrder };
        if (index >= indexToIncrementFrom) {
          adjustedSection = { ...s, order: newOrder + 1 };
        }
        if (index === indexToIncrementFrom) {
          newSectionOrder = newOrder;
        }
        newOrder += 1;
        return adjustedSection;
      });

      const verticalSectionToUpdate = newVerticalSection
        ? sortedVerticalSectionsRef.current.filter(vs => vs.id !== newVerticalSection?.id)
        : verticalSectionsWithIncrementedOrder;

      const updatedSectionsPromises = verticalSectionToUpdate.map(s =>
        updateSectionMutation({ newSectionData: { ...s, roadmap_id: Number(roadmap.id) } })
      );

      const newSectionTempId = generateUniqueIntTemporaryId();

      const newSection = {
        id: newSectionTempId,
        title: `SECTION ${verticalSectionsWithIncrementedOrder.length + 1}`,
        tabWidth: DEFAULT_NEW_TAB_WIDTH,
        orientation: sectionOrientation,
        order: newSectionOrder,
        roadmap_id: Number(roadmap.id),
      };

      queryClient.setQueryData(
        [getRoadmapSections.name, roadmap.id],
        [
          ...sortedHorizontalSectionsRef.current,
          ...verticalSectionToUpdate,
          newVerticalSection || newSection,
        ]
      );

      await Promise.all(updatedSectionsPromises);

      const createdSection = await createNewSection({
        newSection: newVerticalSection || newSection,
        newSectionTempId: newVerticalSection?.id || newSectionTempId,
      });
      return createdSection;
    },
    [
      createNewSection,
      generateUniqueIntTemporaryId,
      queryClient,
      roadmap.id,
      sectionOrder,
      sectionOrientation,
      sortedHorizontalSectionsRef,
      sortedVerticalSections,
      sortedVerticalSectionsRef,
      updateSectionMutation,
    ]
  );

  const removeVerticalSection = useCallback(async () => {
    await deleteSectionHandler(sectionId);

    addHistoryItem({
      type: HistoryActionType.DeleteSection,
      async undo(resourceIds?: number[]) {
        const section = sortedVerticalSections.find(
          (vs, index) => vs.id === (resourceIds?.[0] || sectionId) || index === sectionIndex
        )!;
        const newCreatedSection = await addVerticalSection({
          ...section,
          roadmap_id: Number(roadmap.id),
        });

        updateResourceId(this.type, resourceIds?.[0] || sectionId, newCreatedSection.id!);
        this.redo = async (redoResourceIds?: number[]) => {
          await deleteSectionHandler(redoResourceIds?.[0] || newCreatedSection!.id!);
        };
      },
      redo: async (resourceIds?: number[]) => {
        await deleteSectionHandler(resourceIds?.[0] || sectionId);
      },
      resourceIds: [sectionId],
    });
  }, [
    deleteSectionHandler,
    sectionId,
    addHistoryItem,
    sortedVerticalSections,
    addVerticalSection,
    roadmap.id,
    updateResourceId,
    sectionIndex,
  ]);

  const actionMenuItems = useMemo(() => {
    const icons = [
      {
        id: 1,
        tooltipText: t('editor.canvas.add_tooltip'),
        icon: plusIcon,
        onClick: async () => {
          const createdSection = await addVerticalSection();
          addHistoryItem({
            type: HistoryActionType.AddSection,
            undo: async (resourceIds?: number[]) => {
              await deleteSectionHandler(resourceIds?.[0] || createdSection.id!);
            },
            async redo(redoResourceIds?: number[]) {
              const newCreatedSection = await addVerticalSection(createdSection);
              updateResourceId(
                this.type,
                redoResourceIds?.[0] || createdSection.id!,
                newCreatedSection.id!
              );
              this.undo = async (resourceIds?: number[]) => {
                await deleteSectionHandler(resourceIds?.[0] || newCreatedSection.id!);
              };
              this.resourceIds = [newCreatedSection.id!];
            },
            resourceIds: [createdSection.id!],
          });
        },
        disabled:
          sortedVerticalSections.length >= MAX_VERTICAL_SECTIONS ||
          shouldDisableActionMenuBecauseOfUsedTemporaryId,
      },
      {
        id: 2,
        tooltipText: t('editor.canvas.delete_tooltip'),
        icon: binIcon,
        onClick: removeVerticalSection,
        disabled:
          sortedVerticalSections.length <= MIN_VERTICAL_SECTIONS ||
          shouldDisableActionMenuBecauseOfUsedTemporaryId,
      },
      {
        id: 3,
        tooltipText: t('editor.canvas.edit_tooltip'),
        icon: pencilIcon,
        onClick: () => {
          setIsEditingTitle(true);
        },
        disabled: shouldDisableActionMenuBecauseOfUsedTemporaryId,
      },
    ].filter(actionMenuItem => Boolean(actionMenuItem.icon));
    return icons;
  }, [
    t,
    plusIcon,
    sortedVerticalSections.length,
    shouldDisableActionMenuBecauseOfUsedTemporaryId,
    binIcon,
    removeVerticalSection,
    pencilIcon,
    addVerticalSection,
    addHistoryItem,
    deleteSectionHandler,
    updateResourceId,
    setIsEditingTitle,
  ]);

  return (
    <Portal selector=".top-layer" enabled={!isHoverActive}>
      <Group
        id={sectionId.toString()}
        width={TAB_ACTION_MENU_WIDTH}
        height={TAB_ACTION_MENU_HEIGHT}
        x={x}
        y={y}
        fill={theme.palette.brand.white}
        onMouseEnter={() => setIsHoveringAction(true)}
        onMouseOver={() => setIsHoveringAction(true)}
        onMouseLeave={() => setIsHoveringAction(false)}
        visible={isHoverActive}
      >
        {actionMenuItems.map((actionMenuItem, index) => (
          <TabActionMenuItem
            key={`vertical-section-${sectionId}-${actionMenuItem.id}`}
            id={`vertical-section-${sectionId}-${actionMenuItem.id}`}
            itemIndex={index}
            icon={actionMenuItem.icon!}
            text={actionMenuItem.tooltipText}
            rotation={rotation}
            onClick={actionMenuItem.onClick}
            disabled={Boolean(actionMenuItem.disabled)}
          />
        ))}
      </Group>
    </Portal>
  );
};

export { VerticalSectionActionMenu };
