import { useCallback, useEffect, useRef, useState } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { ReactComponent as ManagePermissionsIcon } from 'src/assets/icons/permissions.svg';
import styled from 'styled-components';

import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { RoadmapPermissions } from '@/modules/RoadmapPermissions';

const EditorHeaderPermissionManager = () => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const tooltipButtonRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const { roadmap, roadmapPermissions } = useEditorContext();

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (
        isOpen &&
        tooltipRef.current &&
        !tooltipRef.current.contains(e.target as Node) &&
        !tooltipButtonRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  return (
    <Tooltip
      open={isOpen}
      ref={tooltipButtonRef}
      title={
        <PermissionWrapper ref={tooltipRef}>
          <RoadmapPermissions
            roadmapId={roadmap.id!}
            roadmapPermissions={roadmapPermissions}
            maxHeight="25rem"
            roadmapOwner={{ id: roadmap!.createdById!, userName: roadmap.createdBy! }}
          />
        </PermissionWrapper>
      }
      placement="bottom"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [50, 8],
              },
            },
          ],
          popperOptions: {
            placement: 'bottom',
          },
        },

        tooltip: {
          sx: {
            padding: 0,
            borderRadius: '2rem',
            maxWidth: '35rem',
            bgcolor: theme.palette.brand.white,
            '& .MuiTooltip-arrow': {
              fontSize: '2rem',
              '&:before': {
                color: theme.palette.brand.white,
                boxShadow: theme.shadows[0],
              },
            },
            boxShadow: theme.shadows[0],
          },
        },
      }}
    >
      <IconButtonWrapper onClick={() => setIsOpen(!isOpen)}>
        <ManagePermissionsIcon />
      </IconButtonWrapper>
    </Tooltip>
  );
};

const IconButtonWrapper = styled(IconButton)`
  padding: 1rem;
  width: calc(24px + 2rem);
  height: calc(24px + 2rem);

  svg {
    width: 100%;
    height: 100%;
    width: 24px;
    height: 24px;

    path,
    line,
    rect {
      stroke: ${({ theme }) => theme.palette.brand.primary};
    }
  }

  &:hover {
    svg {
      path,
      line,
      rect {
        stroke: ${({ theme }) => theme.palette.brand.editorTabPrimary};
      }
    }
  }
`;

const PermissionWrapper = styled.div`
  padding: 1rem;
`;

export { EditorHeaderPermissionManager };
