import { memo, useMemo } from 'react';
import { Group } from 'react-konva';

import { ArrowShape } from '@/features/canvas/components/ArrowShape';
import { NODE_RADIUS } from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { Point } from '@/features/canvas/types/point';
import { calculateAbsolutePositionOnCanvasFromPercentageToPx } from '@/features/canvas/utils/calculate-absolute-position-on-canvas-in-px';
import { components } from '@/types/api';

type ItemProps = {
  dependencyX: number;
  dependencyY: number;
  nodeX: number;
  nodeY: number;
};

const Item = memo<ItemProps>(({ nodeX, nodeY, dependencyX, dependencyY }) => {
  const selectedNode = useMemo(() => ({ x: nodeX, y: nodeY }), [nodeX, nodeY]);
  const dependency = useMemo(
    () => ({ x: dependencyX, y: dependencyY }),
    [dependencyX, dependencyY]
  );

  return <ArrowShape source={dependency} target={selectedNode} />;
});

const NodeDependencies = ({
  nodeDependencies,
  nodeId,
  nodePosition,
  canvasHeightToFit,
  canvasWidthToFit,
  offset,
}: {
  canvasHeightToFit: number;
  canvasWidthToFit: number;
  nodeDependencies: components['schemas']['Dependencies'][];
  nodeId: number;
  nodePosition: Point;
  offset: Point;
}) => {
  const { filteredNodes } = useEditorContext();

  const dependenciesToNodesThatExist = useMemo(() => {
    return nodeDependencies.filter(dependency =>
      filteredNodes.some(fNode => fNode.id === dependency.slaveNodeId && fNode.isVisible)
    );
  }, [filteredNodes, nodeDependencies]);

  return (
    <Group key={nodeId}>
      {dependenciesToNodesThatExist.map(dependency => {
        const dependencyNode = filteredNodes.find(fNode => fNode.id === dependency.masterNodeId)!;

        const { x: dependencyX, y: dependencyY } =
          calculateAbsolutePositionOnCanvasFromPercentageToPx({
            height: canvasHeightToFit,
            width: canvasWidthToFit,
            xPercentage: dependencyNode.x,
            yPercentage: dependencyNode.y,
            offsetX: offset.x,
            offsetY: offset.y,
            additionX: NODE_RADIUS,
            additionY: NODE_RADIUS,
          });

        return (
          <Item
            key={`${dependency.masterNodeId}-${dependency.slaveNodeId}`}
            dependencyX={dependencyX}
            dependencyY={dependencyY}
            nodeX={nodePosition.x}
            nodeY={nodePosition.y}
          />
        );
      })}
    </Group>
  );
};

export { NodeDependencies };
