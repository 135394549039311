import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { ReactComponent as ThinPlus } from '@/assets/icons/thin-plus.svg';

import * as S from './styles';

interface EmptyProjectItemProps {
  handleNewProject: () => void;
}

const EmptyProjectItem = memo<EmptyProjectItemProps>(({ handleNewProject }) => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.Title>{t('roadmaps.create')}</S.Title>
      <S.IconButton onClick={handleNewProject}>
        <StyledPlusIcon />
      </S.IconButton>
    </S.Wrapper>
  );
});

const StyledPlusIcon = styled(ThinPlus)`
  line {
    stroke: ${({ theme }) => transparentize(0.8, theme.palette.brand.textSecondary)};
  }
`;

export default EmptyProjectItem;
