/* eslint-disable no-alert */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import {
  getAllRoadmapNodes,
  getFiltersByRoadmapId,
  getRoadmapsDropdownVisible,
  getRoadmapSections,
  getRoadmapsPopupVisible,
  getRoadmapsRoadmapVisible,
  getRoadmapTimePeriods,
} from '@/features/canvas/api';
import { authenticatePublicView } from '@/features/canvas/api/public-view';
import { TimelineEditor as Editor } from '@/features/canvas/components/TimelineEditor';
import { EditorContextProvider, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { getRoadmapById } from '@/features/roadmaps/api';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

const PublicView = () => {
  const { roadmapPublicLinkUrl } = useParams<{ roadmapPublicLinkUrl: string }>();

  const [roadmapId, setRoadmapId] = useState<number | null>(null);
  const [defaultFilteredNodes, setDefaultFilteredNodes] = useState<components['schemas']['Node'][]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const { updateLastSavedTime } = useEditorContext();

  const hasMountedRef = useRef(false);

  const { mutateAsync: authenticatePublicViewMutation } = useMutation(authenticatePublicView, {
    onSuccess: roadmap => {
      setRoadmapId(roadmap.id!);

      const formattedNodes = roadmap.nodes.map(n => ({
        ...n,
        initiatives: n.initiative!,
      }));
      setDefaultFilteredNodes(formattedNodes);

      queryClient.setQueryData([getRoadmapById.name, roadmap.id], {
        ...roadmap,
        title: roadmap.titlePublic || roadmap.title,
      });
      queryClient.setQueryData(
        [getFiltersByRoadmapId.name, roadmap.id],
        roadmap.filters.map(f => ({ ...f, nodes: f.nodeOptions }))
      );
      queryClient.setQueryData([getAllRoadmapNodes.name, roadmap.id], formattedNodes);

      queryClient.setQueryData([getRoadmapTimePeriods.name, roadmap.id], roadmap.timePeriods);
      queryClient.setQueryData([getRoadmapSections.name, roadmap.id], roadmap.sections);
      queryClient.setQueryData(
        [getRoadmapsRoadmapVisible.name, roadmap.id],
        roadmap.roadmapVisibles
      );
      queryClient.setQueryData([getRoadmapsPopupVisible.name, roadmap.id], roadmap.popupVisibles);
      queryClient.setQueryData([getRoadmapsDropdownVisible.name, roadmap.id], []);

      setLoading(false);
      updateLastSavedTime();
    },
    onError: () => {
      showToast('error', t('public-view.invalid_password'));
      hasMountedRef.current = false;
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!hasMountedRef.current) {
      const password = window.prompt(t('public-view.enter_password'));
      setLoading(true);
      hasMountedRef.current = true;
      if (password && roadmapPublicLinkUrl) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        authenticatePublicViewMutation({ password, roadmapPublicLinkUrl });
      }
      if (!password || !roadmapPublicLinkUrl) {
        setLoading(false);
      }
      if (!password && password !== null) {
        showToast('error', t('public-view.invalid_password'));
      }
    }
  }, [authenticatePublicViewMutation, roadmapId, roadmapPublicLinkUrl, showToast, t]);

  return (
    <>
      {loading && (
        <LoaderWrapper>
          <StyledLoader />
        </LoaderWrapper>
      )}
      {!loading && roadmapId && (
        <EditorContextProvider
          isPublicView
          defaultFilteredNodes={defaultFilteredNodes}
          roadmapId={roadmapId}
        >
          <Editor />
        </EditorContextProvider>
      )}
    </>
  );
};

const LoaderWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { PublicView };
