/* eslint-disable react/no-array-index-key */
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { darken, transparentize } from 'polished';
import { ReactComponent as CircleCheckIcon } from 'src/assets/icons/linkage-circle-checked.svg';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import { ReactComponent as LinkingIcon } from '@/assets/icons/linking.svg';
import { LINKAGE_ROW_SIZE, LINKAGE_VISIBLE_COLUMNS } from '@/features/canvas/constants';
import { LinkageColumn, LinkageRow, LinkedColumn } from '@/features/canvas/types/linkage';

import { LinkingTableFooter } from './LinkingTableFooter';

interface LinkingTableProps {
  dataColumns: LinkageColumn[];
  dataRows: LinkageRow[];
  linkedColumns: LinkedColumn[];
  onSubmit: () => void;
  setIsFieldLinkingDialogOpen: Dispatch<SetStateAction<boolean>>;
  setLinkedColumns: Dispatch<SetStateAction<LinkedColumn[]>>;
  setLinkingColumnId: Dispatch<SetStateAction<string | null>>;
}

const LinkingTable = ({
  linkedColumns,
  setLinkedColumns,
  setIsFieldLinkingDialogOpen,
  setLinkingColumnId,
  dataRows,
  dataColumns,
  onSubmit,
}: LinkingTableProps) => {
  const [page, setPage] = useState(0);

  const [columnStartIndex, setColumnStartIndex] = useState(0);

  const visibleColumns = useMemo(() => {
    return dataColumns.slice(columnStartIndex, columnStartIndex + LINKAGE_VISIBLE_COLUMNS);
  }, [columnStartIndex, dataColumns]);

  const visibleRows = useMemo(
    () => dataRows.slice(page * LINKAGE_ROW_SIZE, page * LINKAGE_ROW_SIZE + LINKAGE_ROW_SIZE),
    [dataRows, page]
  );

  const openFieldLinkingDialog = (columnId: string) => {
    setIsFieldLinkingDialogOpen(true);
    setLinkingColumnId(columnId);
  };

  const handleColumnHeaderClick = (columnId: string) => {
    const isColumnLinked = linkedColumns.some(lk => lk.importedColumnUuid === columnId);
    if (isColumnLinked) {
      const newLinkedColumns = linkedColumns.filter(lk => lk.importedColumnUuid !== columnId);
      setLinkedColumns(newLinkedColumns);
      return;
    }
    openFieldLinkingDialog(columnId);
  };

  const displayGoToPreviousColumnButton = columnStartIndex > 0;
  const displayGoToNextColumnButton =
    columnStartIndex < dataColumns.length - LINKAGE_VISIBLE_COLUMNS;

  return (
    <>
      <TableContainer sx={{ display: 'flex', alignItems: 'stretch' }} component={Paper}>
        {displayGoToPreviousColumnButton && (
          <TableColumnMoveButton
            disabled={!displayGoToPreviousColumnButton}
            onClick={() => setColumnStartIndex(columnStartIndex - 1)}
            $borderRadius="1rem 0 0 1rem"
          >
            <RotatedArrowIcon rotate={90} />
          </TableColumnMoveButton>
        )}
        <StyledTable>
          <TableHead>
            <StyledRow>
              {visibleColumns.map(column => {
                const isColumnLinked = linkedColumns.some(
                  lk => lk.importedColumnUuid === column.id
                );
                return (
                  <StyledHeaderCell
                    key={column.id}
                    $isColumnLinked={isColumnLinked}
                    onClick={() => handleColumnHeaderClick(column.id)}
                  >
                    <TableCellContentWrapper>
                      <StyledHeaderCellText>{column.name}</StyledHeaderCellText>
                      <LinkingIconButton onClick={() => handleColumnHeaderClick(column.id)}>
                        {isColumnLinked && <StyledCircleCheckIcon />}
                        {!isColumnLinked && (
                          <LinkingIcon width="2rem" height="2rem" overflow="visible" />
                        )}
                      </LinkingIconButton>
                    </TableCellContentWrapper>
                  </StyledHeaderCell>
                );
              })}
            </StyledRow>
          </TableHead>
          <TableBody>
            {visibleRows.map(row => (
              <StyledRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {visibleColumns.map((column, index) => {
                  const isColumnLinked = linkedColumns.some(
                    lk => lk.importedColumnUuid === column.id
                  );
                  return (
                    <StyledBodyCell key={`${row.id}-${column.id}`} $isColumnLinked={isColumnLinked}>
                      {row.cells[columnStartIndex + index].value || ''}
                    </StyledBodyCell>
                  );
                })}
              </StyledRow>
            ))}
          </TableBody>
        </StyledTable>
        {displayGoToNextColumnButton && (
          <TableColumnMoveButton
            disabled={!displayGoToNextColumnButton}
            onClick={() => setColumnStartIndex(columnStartIndex + 1)}
            $borderRadius="0 1rem 1rem 0"
          >
            <RotatedArrowIcon rotate={-90} />
          </TableColumnMoveButton>
        )}
      </TableContainer>
      <LinkingTableFooter
        onSubmit={onSubmit}
        rowsLength={dataRows.length}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

const StyledRow = styled(TableRow)`
  display: flex;
  align-items: stretch;
  gap: 0.75rem;
`;

const StyledTable = styled(Table)`
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
`;

const StyledTableCell = styled(TableCell)<{ $isColumnLinked: boolean }>`
  flex: 1 0 0;
  padding: 1rem 2rem;
  text-align: left;
  line-height: unset;
  color: ${({ theme }) => theme.palette.brand.textPrimary};

  ${({ $isColumnLinked, theme }) =>
    $isColumnLinked &&
    css`
      color: ${theme.palette.brand.white};
      background-color: ${theme.palette.brand.secondary};
    `}
`;

const StyledHeaderCell = styled(StyledTableCell)<{ $isColumnLinked: boolean }>`
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  ${({ $isColumnLinked, theme }) =>
    !$isColumnLinked &&
    css`
      background-color: ${theme.palette.brand.backgroundDialog};
    `}
`;

const StyledBodyCell = styled(StyledTableCell)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5rem;

  ${({ $isColumnLinked, theme }) =>
    !$isColumnLinked &&
    css`
      background-color: ${theme.palette.brand.backgroundMain};
    `}
`;

const TableCellContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkingIconButton = styled(IconButton)`
  flex: 0 0 2rem;
`;
const TableColumnMoveButton = styled(IconButton)<{ $borderRadius: string }>`
  background-color: ${({ theme }) => theme.palette.brand.textSecondary};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  path {
    stroke: ${({ theme }) => theme.palette.brand.white};
  }
  &.Mui-disabled {
    opacity: 0.3;
    background-color: ${({ theme }) => theme.palette.brand.textSecondary};
    path {
      stroke: ${({ theme }) => transparentize(0.5, theme.palette.brand.white)};
    }
  }
  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.textSecondary)};
  }
`;
const RotatedArrowIcon = styled(ArrowDownIcon)<{ rotate: number }>`
  transform: rotate(${({ rotate }) => rotate}deg);
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledHeaderCellText = styled.div`
  flex: 1;
  min-width: 0;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5rem;
`;

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  width: 2rem;
  height: 2rem;
  path,
  polygon {
    fill: ${({ theme }) => theme.palette.brand.white};
  }
`;

export { LinkingTable };
