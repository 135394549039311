import { defaultApi } from '@/api';

export type ResourceContract = {
  id: number;
  display_name: string;
  logo_url: string;
  subdomain: string;
  palette_primary1: string;
  palette_primary2: string;
  palette_secondary1: string;
  palette_secondary2: string;
  description: string;
};

export function getMockedResourceByTenant(): ResourceContract {
  return {
    id: 1,
    display_name: 'Adastra Czech',
    logo_url: '',
    subdomain: 'adastra',
    palette_primary1: '#219c35',
    palette_primary2: '321fbd',
    palette_secondary1: '#219c35',
    palette_secondary2: 'ebedef',
    description: 'Some description that should be show on login page.',
  };
}

export async function getResourcesByTenant() {
  const { data } = await defaultApi.get<ResourceContract>(`/tenant`);
  return data;
}

export async function getLogo() {
  const { data } = await defaultApi.get('/tenant/logo', { responseType: 'blob' });
  return URL.createObjectURL(data);
}
