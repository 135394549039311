import React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import SwimlaneIcon from '@/assets/icons/active-swimlane-mode.svg';
import { CANVAS_MODE_BUTTON_HEIGHT, CANVAS_MODE_BUTTON_WIDTH } from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

const SwimlaneViewButton = () => {
  const { setIsSwimlaneView } = useEditorContext();

  return <StyledSvg src={SwimlaneIcon} onClick={() => setIsSwimlaneView(false)} />;
};

const StyledSvg = styled(SVG)`
  position: sticky;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.brand.white};
  width: ${CANVAS_MODE_BUTTON_WIDTH}px;
  height: ${CANVAS_MODE_BUTTON_HEIGHT}px;
  box-shadow: 2px 2px 4px -1px rgba(103, 103, 224, 1);
  rect {
    fill: ${({ theme }) => theme.palette.brand.editorTabPrimary};
  }
`;

export { SwimlaneViewButton };
