import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import {
  Box,
  Button,
  CircularProgress,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { darken } from 'polished';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as CloseDialogIcon } from '@/assets/icons/close-dialog-icon.svg';
import { createRoadmapFilter, getFiltersByRoadmapId } from '@/features/canvas/api';
import { CustomFieldType } from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useImportInitiativeContext } from '@/features/canvas/contexts/import-initiative-context';
import { FilterTypes } from '@/features/canvas/types/filter';
import {
  FieldLinkingFormState,
  LinkageRow,
  LinkedColumn,
  LinkedColumnType,
  LinkSelectionOption,
} from '@/features/canvas/types/linkage';
import { VALIDATION_ERROR_KEY } from '@/features/canvas/types/validation';
import { createDefaultFilterByType } from '@/features/canvas/utils/create-default-filter-by-type';
import {
  validateBigField,
  validateBudgetField,
  validateDate,
  validateDescription,
  validateFilter,
  validateMultiFilter,
  validateProgressField,
  validateSmallField,
  validateString,
  validateTitle,
} from '@/features/canvas/validators';
import { Form } from '@/features/ui/components/Form';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';
import { textEllipsis } from '@/utils/text-ellipsis';

import { FieldLinkingSelectionFormContent } from './FieldLinkingSelectionFormContent';

const ValidationErrorTranslationKeys = {
  [VALIDATION_ERROR_KEY.invalidNumber]: 'editor.sidebar.data_tab.invalid_number',
  [VALIDATION_ERROR_KEY.wrongDateFormat]: 'editor.sidebar.data_tab.invalid_date',
  [VALIDATION_ERROR_KEY.invalidProgressValue]: 'editor.sidebar.data_tab.invalid_progress_value',
  [VALIDATION_ERROR_KEY.wrongBudgetFormat]: 'editor.sidebar.data_tab.invalid_budget_value',
  [VALIDATION_ERROR_KEY.textIsEmpty]: 'editor.sidebar.data_tab.text_is_empty',
  [VALIDATION_ERROR_KEY.textTooLong]: 'editor.sidebar.data_tab.text_too_long',
};

type FieldLinkingSelectionDialogProps = DialogProps & {
  dataRows: LinkageRow[];
  indexOfSelectedOptionId: number;
  linkedColumns: LinkedColumn[];
  linkingColumnId: string | null;
  setLinkedColumns: Dispatch<SetStateAction<LinkedColumn[]>>;
  onClose?: () => void;
};

const FieldLinkingSelectionDialog = ({
  open,
  onClose,
  setLinkedColumns,
  linkingColumnId,
  linkedColumns,
  dataRows,
  indexOfSelectedOptionId,
  ...other
}: FieldLinkingSelectionDialogProps) => {
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { roadmapVisible, dropdownVisible, filters, roadmap, updateLastSavedTime } =
    useEditorContext();
  const { customFieldsToBeCreatedFromImport, setCustomFieldsToBeCreatedFromImport } =
    useImportInitiativeContext();

  const [dialogTitle, setDialogTitle] = useState<string>(
    t('editor.sidebar.data_tab.field_linking_selection_dialog_title')
  );

  const { mutateAsync: createFilter, isLoading: isCreatingFilter } = useMutation(
    createRoadmapFilter,
    {
      onSuccess: () => {
        updateLastSavedTime();
        return queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmap.id]);
      },
      onError: () => {
        showToast('error', t('editor.sidebar.data_tab.linking_selection_dialog_error'));
      },
    }
  );

  const defaultFiltersOptions = useMemo(() => {
    const defaultFilters = [
      { id: FilterTypes.PRIORITY, label: t('editor.sidebar.data_tab.priority_filter_label') },
      { id: FilterTypes.RAG, label: t('editor.sidebar.data_tab.rag_filter_label') },
      {
        id: FilterTypes.PROGRESS_STATUS,
        label: t('editor.sidebar.data_tab.progress_bar_filter_label'),
      },
    ];

    const removedAlreadyExistingFilters = defaultFilters.filter(
      df => !filters.find(f => f.title === df.id)
    );
    return removedAlreadyExistingFilters;
  }, [filters, t]);

  const linkSelectionOptions = useMemo(() => {
    const linkedColumnIds = linkedColumns.map(lc => lc.linkedTargetId);
    const options: LinkSelectionOption[] = [
      {
        id: uuidv4(),
        label: t('editor.sidebar.data_tab.create_new_field'),
        type: LinkedColumnType.createNewField,
      },
      {
        id: uuidv4(),
        label: t('editor.sidebar.data_tab.create_new_filter'),
        type: LinkedColumnType.createNewFilter,
      },
      ...(linkedColumnIds.includes(LinkedColumnType.title)
        ? []
        : [
            {
              id: `${LinkedColumnType.title}`,
              label: t('editor.sidebar.data_tab.title'),
              type: LinkedColumnType.title,
            },
          ]),
      ...roadmapVisible
        .filter(rv => !linkedColumnIds.includes(`${rv.id!.toString()}-${rv.type}-roadmap`))
        .map(r => ({
          id: `${r.id!.toString()}-${r.type}-roadmap`,
          label: r.title,
          type: r.type as keyof typeof LinkedColumnType,
        })),
      ...dropdownVisible
        .filter(dv => !linkedColumnIds.includes(`${dv.id!.toString()}-${dv.type}-dropdown`))
        .map(d => ({
          id: `${d.id!.toString()}-${d.type}-dropdown`,
          label: d.title,
          type: d.type as keyof typeof LinkedColumnType,
        })),
      ...filters
        .filter(f => {
          return !linkedColumnIds.includes(f.id!.toString());
        })
        .map(f => ({
          id: f.id!.toString(),
          label: t('editor.sidebar.data_tab.filter_label', { filterTitle: f.title }),
          type: LinkedColumnType.filter,
          sourceId: f.id!.toString(),
        })),
      ...defaultFiltersOptions.map(f => ({
        id: f.id,
        label: t('editor.sidebar.data_tab.filter_label', { filterTitle: f.label }),
        type: LinkedColumnType.defaultFilter,
      })),
    ];
    return options;
  }, [defaultFiltersOptions, dropdownVisible, filters, linkedColumns, roadmapVisible, t]);

  const submitForm = async (data: FieldLinkingFormState) => {
    if (!data.selectedLinkOptionId || !linkingColumnId || indexOfSelectedOptionId < 0) {
      showToast('error', t('editor.sidebar.data_tab.linking_selection_dialog_error'));
      return;
    }

    const option = linkSelectionOptions.find(o => o.id === data.selectedLinkOptionId);

    if (!option) {
      showToast('error', t('editor.sidebar.data_tab.linking_selection_dialog_error'));
      return;
    }

    const values = dataRows.map(r => r.cells[indexOfSelectedOptionId].value || '');

    const { areValuesValid, error } = validateValues(
      option.type,
      values,
      data.fieldType,
      data.filterType,
      option.id
    );

    if (!areValuesValid) {
      showToast(
        'error',
        t(
          `${
            ValidationErrorTranslationKeys[
              error.messageKey as keyof typeof ValidationErrorTranslationKeys
            ] || ValidationErrorTranslationKeys.textTooLong
          }`,
          { value: textEllipsis(error.value, 32) }
        )
      );
      return;
    }

    let { label: linkedTargetTitle, type: linkedTargetType } = option;

    if (option.type === LinkedColumnType.createNewField) {
      linkedTargetTitle = data.fieldName!;
      linkedTargetType = data.fieldType!;
    }

    const newLinkedColumn = {
      importedColumnUuid: linkingColumnId!,
      linkedTargetId: data.selectedLinkOptionId,
      linkedTargetType,
      linkedTargetTitle,
    };

    if (option.type === LinkedColumnType.createNewField) {
      // createNewField is static option, its custom Id shouldn't be added to following state
      // we assign new uuid to the added field, so its unique
      // even tho uuid changes for createNewField, because of rerender, and won't match with other new fields
      const newUuidForNewCustomField = uuidv4();
      newLinkedColumn.linkedTargetId = newUuidForNewCustomField;
      setCustomFieldsToBeCreatedFromImport([
        ...customFieldsToBeCreatedFromImport,
        {
          id: newUuidForNewCustomField,
          title: data.fieldName!,
          type: data.fieldType!,
        },
      ]);
    }
    if (option.type === LinkedColumnType.filter) {
      newLinkedColumn.linkedTargetId = option.sourceId!;
    }

    if (option.type === LinkedColumnType.createNewFilter) {
      const options = data.filterOptions
        ?.map(fo => ({
          color: fo.color,
          title: fo.title,
        }))
        .filter(x => x.title.length); // remove empty options

      const newFilter: components['schemas']['Filter'] = {
        title: data.filterName!,
        shape: data.filterShape || 'circle',
        options: options || [],
        selection: data.filterType || 'single',
        isVisible: true,
      };
      const createdFilter = await createFilter({
        roadmapId: roadmap.id!,
        payload: newFilter,
      });

      if (!createdFilter.id) return;

      newLinkedColumn.linkedTargetId = createdFilter.id.toString();
      newLinkedColumn.linkedTargetType = LinkedColumnType.filter;
      newLinkedColumn.linkedTargetTitle = createdFilter.title;
    }

    if (option.type === LinkedColumnType.defaultFilter) {
      const filter = createDefaultFilterByType(option.id as keyof typeof FilterTypes);
      const uniqueValues = new Set(values);

      const newOptions = Array.from(uniqueValues)
        .filter(uv => !filter.options.find(o => o.title === uv))
        .map(uv => ({ title: uv, color: theme.palette.brand.corePPTHeadlines }))
        .filter(x => x.title.length); // remove empty options

      const createdFilter = await createFilter({
        roadmapId: roadmap.id!,
        payload: { ...filter, options: [...filter.options, ...newOptions] },
      });

      if (!createdFilter.id) return;

      newLinkedColumn.linkedTargetId = createdFilter.id.toString();
      newLinkedColumn.linkedTargetType = LinkedColumnType.filter;
      newLinkedColumn.linkedTargetTitle = createdFilter.title;
    }
    setLinkedColumns([...linkedColumns, newLinkedColumn]);
    handleDialogTitle();
    onClose?.();
  };

  const validateValues = (
    type: keyof typeof LinkedColumnType,
    values: string[],
    fieldType?: keyof typeof CustomFieldType,
    filterType?: 'single' | 'multiple',
    optionId?: string
  ) => {
    let areValuesValid = true;
    const error = { messageKey: '', value: '' };

    switch (type) {
      case LinkedColumnType.createNewField:
        if (fieldType === CustomFieldType.small) {
          areValuesValid = values.every(v => {
            const { isValid, errorKey, value } = validateSmallField(v);
            if (!isValid && errorKey) {
              error.messageKey = errorKey;
              error.value = value || error.value;
            }
            return isValid;
          });
        }
        if (fieldType === CustomFieldType.big) {
          areValuesValid = values.every(v => {
            const { isValid, errorKey, value } = validateBigField(v);
            if (!isValid && errorKey) {
              error.messageKey = errorKey;
              error.value = value || error.value;
            }
            return isValid;
          });
        }
        break;
      case LinkedColumnType.big:
        areValuesValid = values.every(v => {
          const { isValid, errorKey, value } = validateBigField(v);
          if (!isValid && errorKey) {
            error.messageKey = errorKey;
            error.value = value || error.value;
          }
          return isValid;
        });
        break;
      case LinkedColumnType.date:
        areValuesValid = values.every(v => {
          const { isValid, errorKey, value } = validateDate(v);
          if (!isValid && errorKey) {
            error.messageKey = errorKey;
            error.value = value || error.value;
          }
          return isValid;
        });
        break;
      case LinkedColumnType.progressBar:
        areValuesValid = values.every(v => {
          const { isValid, errorKey, value } = validateProgressField(v);
          if (!isValid && errorKey) {
            error.messageKey = errorKey;
            error.value = value || error.value;
          }
          return isValid;
        });
        break;
      case LinkedColumnType.description:
        areValuesValid = values.every(v => {
          const { isValid, errorKey, value } = validateDescription(v);
          if (!isValid && errorKey) {
            error.messageKey = errorKey;
            error.value = value || error.value;
          }
          return isValid;
        });
        break;
      case LinkedColumnType.title:
        areValuesValid = values.every(v => {
          const { isValid, errorKey, value } = validateTitle(v);
          if (!isValid && errorKey) {
            error.messageKey = errorKey;
            error.value = value || error.value;
          }
          return isValid;
        });
        break;
      case LinkedColumnType.budget:
        areValuesValid = values.every(v => {
          const { isValid, errorKey, value } = validateBudgetField(v);
          if (!isValid && errorKey) {
            error.messageKey = errorKey;
            error.value = value || error.value;
          }
          return isValid;
        });
        break;
      case LinkedColumnType.createNewFilter:
        if (!filterType || filterType === 'single') {
          areValuesValid = values.every(v => {
            const { isValid, errorKey, value } = validateFilter(v);
            if (!isValid && errorKey) {
              error.messageKey = errorKey;
              error.value = value || error.value;
            }
            return isValid;
          });
        }
        if (filterType === 'multiple') {
          areValuesValid = values.every(v => {
            const { isValid, errorKey, value } = validateMultiFilter(v);
            if (!isValid && errorKey) {
              error.messageKey = errorKey;
              error.value = value || error.value;
            }
            return isValid;
          });
        }
        break;
      case LinkedColumnType.filter:
        {
          const filter = filters.find(f => f.id?.toString() === optionId);
          if (!filter?.selection || filter.selection === 'single') {
            areValuesValid = values.every(v => {
              const { isValid, errorKey, value } = validateFilter(v);
              if (!isValid && errorKey) {
                error.messageKey = errorKey;
                error.value = value || error.value;
              }
              return isValid;
            });
          }
          if (filter?.selection === 'multiple') {
            areValuesValid = values.every(v => {
              const { isValid, errorKey, value } = validateMultiFilter(v);
              if (!isValid && errorKey) {
                error.messageKey = errorKey;
                error.value = value || error.value;
              }
              return isValid;
            });
          }
        }
        break;
      default:
        areValuesValid = values.every(v => {
          const { isValid, errorKey, value } = validateString(v);
          if (!isValid && errorKey) {
            error.messageKey = errorKey;
            error.value = value || error.value;
          }
          return isValid;
        });
        break;
    }

    return { areValuesValid, error };
  };

  const handleDialogTitle = (isCreatingNewField?: boolean) => {
    const newTitle = isCreatingNewField
      ? t('editor.sidebar.data_tab.new_field_linking_selection_dialog_title')
      : t('editor.sidebar.data_tab.field_linking_selection_dialog_title');
    setDialogTitle(newTitle);
  };

  const selectedColumnData = useMemo(() => {
    if (indexOfSelectedOptionId < 0) return new Set<string>();
    const columnData = dataRows.map(r => {
      return r.cells[indexOfSelectedOptionId].value || '';
    });
    return new Set(columnData);
  }, [dataRows, indexOfSelectedOptionId]);

  return (
    <MuiDialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          padding: '2rem',
          borderRadius: '2rem',
          minWidth: 700,
          overflowY: 'unset',
        },
      }}
      {...other}
    >
      <StyledForm
        mode="onSubmit"
        reValidateMode="onSubmit"
        defaultValues={{
          selectedLinkOptionId: '',
        }}
        onSubmit={formData => submitForm(formData as FieldLinkingFormState)}
      >
        <StyledDialogTitle>
          {dialogTitle}
          {onClose && (
            <StyledCloseWrapper
              onClick={() => {
                handleDialogTitle();
                onClose();
              }}
            >
              <CloseDialogIcon />
            </StyledCloseWrapper>
          )}
        </StyledDialogTitle>

        <DialogContent sx={{ padding: 0 }}>
          <FieldLinkingSelectionFormContent
            defaultValues={{
              selectedLinkOptionId: '',
            }}
            linkSelectionOptions={linkSelectionOptions}
            selectedColumnData={selectedColumnData}
            handleDialogTitle={handleDialogTitle}
          />
        </DialogContent>
        <DialogActions sx={{ padding: '1rem 0 0' }}>
          <SubmitButton type="submit" disabled={isCreatingFilter}>
            {isCreatingFilter && <StyledLoader color="inherit" size={16} />}
            <>{t('confirm')}</>
          </SubmitButton>
        </DialogActions>
      </StyledForm>
    </MuiDialog>
  );
};

const StyledForm = styled(Form)`
  padding: 0.5rem;
  overflow: auto;
` as typeof Form<FieldLinkingFormState>;

const StyledCloseWrapper = styled(Box)`
  cursor: pointer;
  top: -2rem;
  right: -2rem;
  position: absolute;
  border-radius: 2rem;
  width: 5rem;
  height: 5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  background-color: ${({ theme }) => theme.palette.brand.white};

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.palette.brand.primary};
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding: 0;
`;

const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.brand.secondary};
  text-transform: none;
  font-size: 1.5rem;
  padding: 0 2rem;
  color: ${({ theme }) => theme.palette.brand.white};
  font-weight: 600;
  border-radius: 2rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.secondary)};
  }

  &.Mui-disabled {
    opacity: 0.5;
    color: ${({ theme }) => theme.palette.brand.white};
  }
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { FieldLinkingSelectionDialog };
