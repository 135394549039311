import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  useTheme,
} from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { ReactComponent as CloseDialogIcon } from '@/assets/icons/close-dialog-icon.svg';

type DualChoiceDialogProps = DialogProps & {
  dialogDescription: string;
  firstChoiceButtonText: string;
  firstChoiceDescription: string;
  firstChoiceIsDisabled: boolean;
  firstChoiceIsLoading: boolean;
  firstChoiceOnClick: () => void;
  onClose: () => void;
  open: boolean;
  secondChoiceButtonText: string;
  secondChoiceDescription: string;
  secondChoiceIsDisabled: boolean;
  secondChoiceIsLoading: boolean;
  secondChoiceOnClick: () => void;
};

const DualChoiceDialog = ({
  open,
  onClose,
  dialogDescription,
  firstChoiceButtonText,
  firstChoiceDescription,
  firstChoiceIsDisabled,
  firstChoiceIsLoading,
  firstChoiceOnClick,
  secondChoiceButtonText,
  secondChoiceDescription,
  secondChoiceIsDisabled,
  secondChoiceIsLoading,
  secondChoiceOnClick,
  ...other
}: DualChoiceDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          padding: '3rem',
          borderRadius: '2rem',
          maxWidth: '550px',
          overflowY: 'unset',
        },
      }}
      {...other}
    >
      <StyledCloseWrapper onClick={() => onClose()}>
        <CloseDialogIcon />
      </StyledCloseWrapper>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'space-center',
          alignItems: 'center',
          fontSize: '1.75rem',
          textAlign: 'center',
          padding: '2rem',
          marginBottom: '1rem',
          fontWeight: '600',
          lineHeight: '2.5rem',
          color: theme.palette.brand.textPrimary,
        }}
      >
        {dialogDescription}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          fontSize: '2rem',
          padding: '0 2rem',
          gap: '1rem',
        }}
      >
        <ActionRow>
          <DeleteInCurrentRoadmapButton
            onClick={firstChoiceOnClick}
            disabled={firstChoiceIsDisabled}
          >
            {firstChoiceIsLoading && <StyledLoader color="inherit" size={20} />}

            {firstChoiceButtonText}
          </DeleteInCurrentRoadmapButton>
          <ActionDescription>{firstChoiceDescription}</ActionDescription>
        </ActionRow>
        <ActionRow>
          <OrText>{t('or')}</OrText>
          <PlaceholderComponent />
        </ActionRow>
        <ActionRow>
          <DeleteInAllRoadmapsButton
            onClick={secondChoiceOnClick}
            disabled={secondChoiceIsDisabled}
          >
            {secondChoiceIsLoading && <StyledLoader color="inherit" size={20} />}
            {secondChoiceButtonText}
          </DeleteInAllRoadmapsButton>
          <ActionDescription>{secondChoiceDescription}</ActionDescription>
        </ActionRow>
      </DialogActions>
    </Dialog>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.brand.white};
  padding: 1rem;
  border-radius: 2rem;
  font-size: 1.5rem;
  text-transform: none;
  font-weight: 600;
  gap: 1rem;
  white-space: nowrap;
  flex: 4;
  letter-spacing: 0.1rem;
  & .MuiButton-startIcon {
    margin: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
  }
  &:focus {
    background-color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
    color: ${({ theme }) => theme.palette.brand.white};
  }
  &.Mui-disabled {
    color: ${({ theme }) => transparentize(0.3, theme.palette.brand.white)};
  }
`;

const DeleteInCurrentRoadmapButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.palette.brand.primary};
`;
const DeleteInAllRoadmapsButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.palette.brand.negativeStatus};

  &:hover {
    background-color: ${({ theme }) => transparentize(0.2, theme.palette.brand.negativeStatus)};
  }
`;
const ActionRow = styled.div`
  && {
    margin-left: 0;
  }
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
`;

const StyledCloseWrapper = styled(Box)`
  cursor: pointer;
  top: -2rem;
  right: -2rem;
  position: absolute;
  border-radius: 2rem;
  width: 5rem;
  height: 5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  background-color: ${({ theme }) => theme.palette.brand.white};

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.palette.brand.primary};
    }
  }
`;

const ActionDescription = styled.div`
  flex: 5;
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

const OrText = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const PlaceholderComponent = styled.div`
  flex: 5;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { DualChoiceDialog };
