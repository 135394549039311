import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function createInitiativeField({
  roadmapId,
  initiativeId,
  newInitiativeField,
}: {
  roadmapId: number;
  initiativeId: number;
  newInitiativeField: components['schemas']['InitiativeField'];
}) {
  const initiativeFieldInArray = [newInitiativeField];

  const { data } = await defaultApi.post<components['schemas']['InitiativeField']>(
    `/initiatives/${initiativeId}/custom-fields/${roadmapId}`,
    initiativeFieldInArray
  );
  return data;
}

export async function updateInitiativeFieldById({
  initiativeId,
  fieldId,
  payload,
}: {
  initiativeId: number;
  fieldId: number;
  payload: components['schemas']['InitiativeField'];
}) {
  return defaultApi.put(`/initiatives/${initiativeId}/fields/${fieldId}`, [payload]);
}

export async function permanentlyDeleteInitiativeCustomFieldById({
  fieldId,
  roadmapId,
}: {
  fieldId: number;
  roadmapId: number;
}) {
  return defaultApi.delete(`/initiatives/fields/${fieldId}/roadmap/${roadmapId}`);
}
