import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Link,
  useTheme,
} from '@mui/material';
import { darken, transparentize } from 'polished';
import styled from 'styled-components';

import { createRoadmapPublicLink } from '@/features/canvas/api';
import { PublishDialogFormContent } from '@/features/canvas/components/PublishDialogFormContent';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { createRoadmapPublicLinkUrl } from '@/features/canvas/utils/create-roadmap-public-link-url';
import { Form } from '@/features/ui/components/Form';
import { useHandleCopyUrl } from '@/hooks/useHandleCopyUrl';
import { components } from '@/types/api';

interface PublishDialogFormState {
  password: string;
  title: string;
}

const PublishDialogForm = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { handleCopyUrl } = useHandleCopyUrl();
  const { roadmap, updateLastSavedTime } = useEditorContext();

  const [roadmapPublicLinkUrl, setRoadmapPublicLinkUrl] = useState('');

  const { mutateAsync: createRoadmapPublicLinkMutation, isLoading: isCreatingPublicLink } =
    useMutation(
      ({ newLink }: { newLink: components['schemas']['PublicLinkRequest'] }) =>
        createRoadmapPublicLink({
          roadmapId: Number(roadmap.id),
          newLink,
        }),
      { onSuccess: () => updateLastSavedTime() }
    );

  const onSubmit = async (formData: PublishDialogFormState) => {
    const { url: publicLink } = await createRoadmapPublicLinkMutation({
      newLink: {
        ...formData,
        isLocked: true,
      },
    });
    setRoadmapPublicLinkUrl(publicLink);
  };

  const roadmapPublicLink = createRoadmapPublicLinkUrl(roadmapPublicLinkUrl);

  return (
    <Form<PublishDialogFormState>
      defaultValues={{ title: roadmap.title }}
      onSubmit={formData => onSubmit(formData as unknown as PublishDialogFormState)}
    >
      <DialogContent
        sx={{
          color: theme.palette.brand.textPrimary,
          padding: '0 2rem',
        }}
      >
        <PublishDialogFormContent />

        {roadmapPublicLinkUrl && (
          <Box
            sx={{
              padding: '2rem',
              backgroundColor: theme.palette.brand.backgroundMain,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1rem',
              marginBottom: '3rem',
              borderRadius: '2rem',
              wordBreak: 'break-word',
            }}
          >
            <PublicLink>{roadmapPublicLink}</PublicLink>
            <ViewLinkButton type="button" href={roadmapPublicLink} rel="noopener" target="_blank">
              {t('editor.header.publish_dialog.view_link')}
            </ViewLinkButton>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          fontSize: '2rem',
          padding: '0 2rem',
          gap: '2rem',
        }}
      >
        <>
          {!roadmapPublicLinkUrl && (
            <StyledPrimaryButton
              type="submit"
              disabled={isCreatingPublicLink}
              {...(isCreatingPublicLink && {
                startIcon: <StyledLoader color="inherit" size={20} />,
              })}
            >
              {t('editor.header.publish_dialog.main_button_publish')}
            </StyledPrimaryButton>
          )}
          {roadmapPublicLinkUrl && (
            <StyledPrimaryButton
              type="button"
              onClick={() => {
                handleCopyUrl({ url: roadmapPublicLink });
              }}
            >
              {t('editor.header.publish_dialog.main_button_copy_link')}
            </StyledPrimaryButton>
          )}
        </>
      </DialogActions>
    </Form>
  );
};

const ViewLinkButton = styled(Link)`
  font-size: 1.5rem;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  background-color: ${({ theme }) => theme.palette.brand.white};
  padding: 0.5rem 2rem;
  font-weight: 600;
  border-radius: 2rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  text-transform: none;
  white-space: nowrap;
  :hover {
    background-color: ${({ theme }) => darken(0.05, theme.palette.brand.white)};
  }
`;
const PublicLink = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

const StyledPrimaryButton = styled(Button)`
  border-radius: 2rem;
  padding: 0.25rem 2rem;
  min-width: 180px;
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: none;
  color: ${({ theme }) => theme.palette.brand.white};
  background-color: ${({ theme }) => theme.palette.brand.secondary};
  border: 1px solid ${({ theme }) => theme.palette.brand.secondary};
  :hover {
    background-color: ${({ theme }) => theme.palette.brand.backgroundInputFocus};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }

  &.Mui-disabled {
    color: ${({ theme }) => transparentize(0.3, theme.palette.brand.white)};
  }
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { PublishDialogForm };
