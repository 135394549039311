import React, { forwardRef, TextareaHTMLAttributes } from 'react';

import StyledTextarea from './Textarea.styled';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  children?: React.ReactNode;
};

const Textarea: React.FC<TextareaProps> = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { children, ...rest } = props;
    return (
      <StyledTextarea ref={ref} {...rest}>
        {children}
      </StyledTextarea>
    );
  }
);

Textarea.defaultProps = {
  children: undefined,
};

export default Textarea;
