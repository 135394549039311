import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { CircularProgress } from '@mui/material';
import { AxiosError } from 'axios';
import styled from 'styled-components';

import { getServiceStatus } from '@/api/shared/service-status';
import { ServiceStatusCodes } from '@/constants/common';
import { maintenanceRoutes } from '@/constants/routes';
import { GlobalStyle } from '@/features/ui/theme';
import { MaintenanceMode } from '@/pages/MaintenanceMode';

export const MaintenanceModeWrapper = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const [isMaintenance, setIsMaintenance] = useState(false);

  const { isLoading } = useQuery(getServiceStatus.name, getServiceStatus, {
    refetchOnWindowFocus: false,
    onError: err => {
      if (
        (err as AxiosError).response?.status === ServiceStatusCodes.MAINTENANCE ||
        (err as AxiosError).response?.status === ServiceStatusCodes.GATEWAY_TIMEOUT
      ) {
        setIsMaintenance(true);
      }
    },
  });

  useEffect(() => {
    if (isMaintenance) {
      navigate(maintenanceRoutes.maintenance);
    }
  }, [isMaintenance, navigate]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <StyledLoader />
      </LoaderWrapper>
    );
  }

  return (
    <>
      {isMaintenance && (
        <>
          <GlobalStyle />
          <Routes>
            <Route path={maintenanceRoutes.maintenance} element={<MaintenanceMode />} />
            <Route path="*" element={<Navigate to={maintenanceRoutes.maintenance} replace />} />
          </Routes>
        </>
      )}
      {!isMaintenance && children}
    </>
  );
};

const LoaderWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled(CircularProgress)`
  color: '#6767e0';
`;
