import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import { theme as mainTheme } from '@/features/ui/theme';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;

const StyledSelectCheckmarks = ({
  onChange,
  options,
  placeholder,
}: {
  onChange: (value: null | number[]) => void;
  options: { id: number; title: string }[];
  placeholder: string;
}) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  return (
    <SSelect
      value={selectedValues}
      onChange={e => {
        setSelectedValues(e.target.value as number[]);
        onChange(e.target.value as number[]);
      }}
      SelectDisplayProps={{
        style: {
          backgroundColor: 'none',
          padding: '0.5rem 1rem',
        },
      }}
      MenuProps={{
        style: {
          left: -6,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {
          sx: {
            '& .MuiMenuItem-root.Mui-selected': {
              backgroundColor: mainTheme().palette.brand.sunray,
            },
            '& .MuiMenuItem-root:hover': {
              backgroundColor: mainTheme().palette.brand.sunray,
            },
          },
        },
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      }}
      multiple
      variant="standard"
      disableUnderline
      displayEmpty
      labelId="new-initiative-label"
      IconComponent={() => <ArrowDownIcon />}
      renderValue={() => {
        return <div>{placeholder}</div>;
      }}
    >
      {options.map(o => (
        <StyledMenuItem key={o.id} value={o.id}>
          <Title>{o.title}</Title>

          <Checkbox
            sx={{
              '&.Mui-checked': {
                color: mainTheme().palette.brand.editorTabPrimary,
              },
            }}
            checked={selectedValues.some(id => id === o.id)}
          />
        </StyledMenuItem>
      ))}

      {!options.length && (
        <StyledMenuItem disabled>{t('editor.sidebar.no_options')}</StyledMenuItem>
      )}
    </SSelect>
  );
};

export { StyledSelectCheckmarks };

const SSelect = styled(Select)`
  width: 100%;
  border-radius: 1rem;
  font-size: 1.25rem;
  border: 0;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  svg {
    width: 1rem;
    height: 1rem;
    position: relative;
    right: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  & .MuiInput-input:focus {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  padding: 0.25rem 1rem;
`;

const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
