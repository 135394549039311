/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
import {
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Group as GroupType } from 'konva/lib/Group';
import { Stage as StageType } from 'konva/lib/Stage';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { getRoadmapPermissionList } from '@/api/shared/roadmap';
import routes from '@/constants/routes';
import {
  getAllRoadmapNodes,
  getFiltersByRoadmapId,
  getRoadmapsDropdownVisible,
  getRoadmapSections,
  getRoadmapsPopupVisible,
  getRoadmapsRoadmapVisible,
  getRoadmapTimePeriods,
  updateNodesSectionAndTimePeriod,
  updateSection,
} from '@/features/canvas/api';
import {
  HORIZONTAL_OFFSET,
  SIDEBAR_ACCORDION_KEY_MAP,
  STAGE_OFFSET,
  VERTICAL_OFFSET,
} from '@/features/canvas/constants';
import { Point } from '@/features/canvas/types/point';
import { HorizontalSection, type VerticalSection } from '@/features/canvas/types/section';
import { generateTemporaryNumberId } from '@/features/canvas/utils/generate-temporary-number-id';
import { getRoadmapById } from '@/features/roadmaps/api';
import { components } from '@/types/api';

import { calculateAbsolutePositionOnCanvasFromPercentageToPx } from '../utils/calculate-absolute-position-on-canvas-in-px';
import { getIntersectionSectionAndTimePeriodId } from '../utils/get-intersection-section-and-time-period-id';

export type Node = components['schemas']['Node'];

export interface FilteredNodes extends Node {
  color: string;
  shape: 'circle' | 'square' | 'triangle';
}

const EditorContext = createContext({
  roadmap: {} as components['schemas']['RoadmapDetail'],
  filters: {} as components['schemas']['Filter'][],
  filteredNodes: [] as FilteredNodes[],
  isDraggingTab: false,
  setIsDraggingTab: (() => {}) as Dispatch<SetStateAction<boolean>>,
  draggedTabPositionChange: 0,
  filterNodes: (() => {}) as ({
    filter,
    optionColor,
    optionId,
    multiFilterColor,
  }: {
    filter: components['schemas']['Filter'];
    optionColor: string;
    multiFilterColor?: string;
    optionId?: number;
  }) => void,
  setDraggedTabPositionChange: (() => {}) as Dispatch<SetStateAction<number>>,
  setIsSidebarContentLoading: (() => {}) as Dispatch<SetStateAction<boolean>>,
  isSidebarContentLoading: false as boolean,
  setSelectedNodeId: (() => {}) as Dispatch<SetStateAction<number | null>>,
  selectedNodeId: null as number | null,
  isCreatingNewInitiative: false,
  setIsCreatingNewInitiative: (() => {}) as Dispatch<SetStateAction<boolean>>,
  sortedTimePeriods: [] as components['schemas']['TimePeriod'][],
  sortedVerticalSections: [] as VerticalSection[],
  sortedHorizontalSections: [] as HorizontalSection[],
  roadmapNodes: [] as components['schemas']['Node'][],
  setFilteredNodes: (() => {}) as Dispatch<SetStateAction<FilteredNodes[] | []>>,
  activeFilterId: null as number | null,
  setActiveFilterId: (() => {}) as Dispatch<SetStateAction<number | null>>,
  isPreview: false,
  setIsPreview: (() => {}) as Dispatch<SetStateAction<boolean>>,
  roadmapVisible: [] as components['schemas']['RoadmapVisible'][],
  popupVisible: [] as components['schemas']['PopupVisible'][],
  dropdownVisible: [] as components['schemas']['DropdownVisible'][],
  optionCheckedId: null as number | null,
  setOptionCheckedId: (() => {}) as Dispatch<SetStateAction<number | null>>,
  isPublicView: false,
  isInitiativeDetailDialogOpen: false,
  setIsInitiativeDetailDialogOpen: (() => {}) as Dispatch<SetStateAction<boolean>>,
  expandedSidebarAccordionKey: null as keyof typeof SIDEBAR_ACCORDION_KEY_MAP | null,
  setExpandedSidebarAccordionKey: (() => {}) as Dispatch<
    SetStateAction<keyof typeof SIDEBAR_ACCORDION_KEY_MAP | null>
  >,
  generateUniqueIntTemporaryId: (() => {}) as () => number,
  temporaryCreatedIds: new Set<number>(),
  isSwimlaneView: false,
  setIsSwimlaneView: (() => {}) as Dispatch<SetStateAction<boolean>>,
  setShowDependencies: (() => {}) as Dispatch<SetStateAction<boolean>>,
  showDependencies: false,
  stageRef: null as unknown as MutableRefObject<StageType | null>,
  canvasAreaRef: null as unknown as MutableRefObject<GroupType | null>,
  canvasAreaOffset: { x: 0, y: 0 } as Point,
  height: 0 as number | undefined,
  width: 0 as number | undefined,
  setHeight: (() => {}) as Dispatch<SetStateAction<number | undefined>>,
  setWidth: (() => {}) as Dispatch<SetStateAction<number | undefined>>,
  widthToFitContent: 0,
  heightToFitContent: 0,
  detectNodeTimePeriodAndSectionIdChanges: (() => {}) as () => void,
  setShouldRecalculateNodePositions: (() => {}) as Dispatch<SetStateAction<boolean>>,
  isSelectingDependencies: false,
  setIsSelectingDependencies: (() => {}) as Dispatch<SetStateAction<boolean>>,
  sortedTimePeriodsRef: null as unknown as MutableRefObject<components['schemas']['TimePeriod'][]>,
  sortedVerticalSectionsRef: null as unknown as MutableRefObject<VerticalSection[]>,
  sortedHorizontalSectionsRef: null as unknown as MutableRefObject<HorizontalSection[]>,
  filteredNodesRef: null as unknown as MutableRefObject<FilteredNodes[]>,
  lastSavedTime: new Date(),
  updateLastSavedTime: (() => {}) as () => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roadmapPermissions: [] as unknown[] as any[],
});

export const EditorContextProvider = ({
  children,
  isPublicView,
  roadmapId: id,
  defaultFilteredNodes,
}: {
  children: ReactNode;
  defaultFilteredNodes?: Node[];
  isPublicView?: boolean;
  roadmapId?: number | null;
}) => {
  const stageRef = useRef<StageType | null>(null);
  const canvasAreaRef = useRef<GroupType | null>(null);

  const prevTimePeriodsWidthRef = useRef<number[]>([]);
  const prevTimePeriodsHeightRef = useRef<number[]>([]);
  const prevSectionsWidthRef = useRef<number[]>([]);

  const navigate = useNavigate();

  const { id: roadmapIdParam } = useParams<{ id: string }>();

  const [isDraggingTab, setIsDraggingTab] = useState(false);
  const [draggedTabPositionChange, setDraggedTabPositionChange] = useState(0);
  const [filteredNodes, setFilteredNodes] = useState<FilteredNodes[] | []>(
    (defaultFilteredNodes as FilteredNodes[]) || []
  );

  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();

  const [isSidebarContentLoading, setIsSidebarContentLoading] = useState<boolean>(false);
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);
  const [isCreatingNewInitiative, setIsCreatingNewInitiative] = useState(false);
  const [activeFilterId, setActiveFilterId] = useState<number | null>(null);
  const [optionCheckedId, setOptionCheckedId] = useState<number | null>(null);
  const [isPreview, setIsPreview] = useState<boolean>(Boolean(isPublicView));
  const [isInitiativeDetailDialogOpen, setIsInitiativeDetailDialogOpen] = useState<boolean>(false);
  const [expandedSidebarAccordionKey, setExpandedSidebarAccordionKey] = useState<
    keyof typeof SIDEBAR_ACCORDION_KEY_MAP | null
  >(null);
  const [isSwimlaneView, setIsSwimlaneView] = useState<boolean>(false);
  const [showDependencies, setShowDependencies] = useState<boolean>(false);
  const [isSelectingDependencies, setIsSelectingDependencies] = useState<boolean>(false);
  const [shouldRecalculateNodePositions, setShouldRecalculateNodePositions] =
    useState<boolean>(false);

  const [lastSavedTime, setLastSavedTime] = useState<Date>(new Date());

  const widthToFitContent = (width || 0) - HORIZONTAL_OFFSET - 2 * STAGE_OFFSET;
  const heightToFitContent = (height || 0) - 2 * VERTICAL_OFFSET - 2 * STAGE_OFFSET;

  const canvasAreaOffset = canvasAreaRef.current?.getAbsolutePosition() || {
    x: STAGE_OFFSET + HORIZONTAL_OFFSET,
    y: STAGE_OFFSET + VERTICAL_OFFSET,
  };

  const roadmapId = id || Number(roadmapIdParam);

  const { data: roadmap, isLoading: isRoadmapLoading } = useQuery(
    [getRoadmapById.name, Number(roadmapId)],
    async () => {
      const data = await getRoadmapById(Number(roadmapId));
      return data;
    },
    {
      enabled: Boolean(roadmapId),
    }
  );

  const { data: roadmapPermissions = [] } = useQuery(
    [getRoadmapPermissionList.name, roadmapId],
    () => getRoadmapPermissionList({ roadmapId: Number(roadmapId) }),
    { enabled: Boolean(roadmapId && !isPublicView) }
  );

  const { data: filters } = useQuery<components['schemas']['Filter'][]>(
    [getFiltersByRoadmapId.name, Number(roadmapId)],
    () => {
      return getFiltersByRoadmapId(roadmap!.id!);
    },
    {
      enabled: Boolean(roadmap?.id),
    }
  );

  const { data: roadmapNodes = [], isLoading: areRoadmapNodesLoading } = useQuery(
    [getAllRoadmapNodes.name, Number(roadmapId)],
    () => getAllRoadmapNodes({ roadmapId: Number(roadmapId) }),
    {
      enabled: Boolean(roadmapId),
      cacheTime: 0,
      onSuccess: data => {
        setFilteredNodes(data as FilteredNodes[]);
      },

      refetchOnWindowFocus: false,
    }
  );

  const { data: timePeriods = [], isLoading: areTimePeriodsLoading } = useQuery(
    [getRoadmapTimePeriods.name, Number(roadmapId)],
    () => getRoadmapTimePeriods({ roadmapId: Number(roadmapId) }),
    {
      enabled: Boolean(roadmapId),
    }
  );

  const { data: sections = [], isLoading: areSectionsLoading } = useQuery(
    [getRoadmapSections.name, Number(roadmapId)],
    () => getRoadmapSections({ roadmapId: Number(roadmapId) }),
    {
      enabled: Boolean(roadmapId),
    }
  );

  const { data: roadmapVisible = [], isLoading: isLoadingRoadmapVisible } = useQuery(
    [getRoadmapsRoadmapVisible.name, Number(roadmapId)],
    () => getRoadmapsRoadmapVisible({ roadmapId: Number(roadmapId) }),
    { enabled: Boolean(roadmapId) }
  );

  const { data: popupVisible = [], isLoading: isLoadingPopupVisible } = useQuery(
    [getRoadmapsPopupVisible.name, Number(roadmapId)],
    () => getRoadmapsPopupVisible({ roadmapId: Number(roadmapId) }),
    { enabled: Boolean(roadmapId) }
  );

  const { data: dropdownVisible = [], isLoading: isLoadingDropdownVisible } = useQuery(
    [getRoadmapsDropdownVisible.name, Number(roadmapId)],
    () => getRoadmapsDropdownVisible({ roadmapId: Number(roadmapId) }),
    { enabled: Boolean(roadmapId) }
  );

  const sortedTimePeriods = useMemo(
    () =>
      [...timePeriods].sort(
        ({ order: firstTimePeriodOrder }, { order: secondTimePeriodOrder }) =>
          firstTimePeriodOrder - secondTimePeriodOrder
      ),
    [timePeriods]
  );

  const { mutateAsync: updateSectionMutation } = useMutation(
    ({ sectionData }: { sectionData: components['schemas']['Section'] }) =>
      updateSection({ sectionId: Number(sectionData.id), newSectionData: sectionData }),
    {}
  );

  // checks if two sorted sections have the same order attribute value
  const isResortingNeeded = (sortedSections: VerticalSection[] | HorizontalSection[]) => {
    return sortedSections.some(({ order }, index) => {
      const nextIndex = index + 1;

      if (sortedSections[nextIndex]) {
        return sortedSections[nextIndex].order === order;
      }

      return false;
    });
  };

  // eslint-disable-next-line no-warning-comments
  // TODO fix return type
  // This fixes bug when there were two sections with the same order attribute value,
  // which then reordered sections after mutation.
  const resortSections = useCallback(
    (sortedSections: VerticalSection[] | HorizontalSection[]) => {
      const fixedSortedSections = sortedSections.map((section, index) => ({
        ...section,
        order: index + 1,
      }));

      // update the order in DB as well
      fixedSortedSections.map(section => updateSectionMutation({ sectionData: section }));

      return fixedSortedSections;
    },
    [updateSectionMutation]
  );

  const sortedVerticalSections = useMemo(() => {
    let sortedSections = (
      sections.filter(({ orientation }) => orientation === 'vertical') as VerticalSection[]
    ).sort(
      ({ order: firstTimePeriodOrder }, { order: secondTimePeriodOrder }) =>
        firstTimePeriodOrder - secondTimePeriodOrder
    );

    if (isResortingNeeded(sortedSections)) {
      sortedSections = resortSections(sortedSections) as VerticalSection[];
    }

    return sortedSections;
  }, [resortSections, sections]);

  const sortedHorizontalSections = useMemo(() => {
    let sortedSections = (
      sections.filter(({ orientation }) => orientation === 'horizontal') as HorizontalSection[]
    ).sort(
      ({ order: firstTimePeriodOrder }, { order: secondTimePeriodOrder }) =>
        firstTimePeriodOrder - secondTimePeriodOrder
    );

    if (isResortingNeeded(sortedSections)) {
      sortedSections = resortSections(sortedSections) as HorizontalSection[];
    }

    return sortedSections;
  }, [resortSections, sections]);

  const sortedTimePeriodsRef = useRef<components['schemas']['TimePeriod'][]>(sortedTimePeriods);
  const sortedVerticalSectionsRef = useRef<VerticalSection[]>(sortedVerticalSections);
  const sortedHorizontalSectionsRef = useRef<HorizontalSection[]>(sortedHorizontalSections);
  const filteredNodesRef = useRef<FilteredNodes[]>(filteredNodes);

  const temporaryCreatedIds = useMemo(() => new Set<number>(), []);

  const generateUniqueIntTemporaryId = useCallback((): number => {
    const newId = generateTemporaryNumberId({ createdIds: temporaryCreatedIds });
    temporaryCreatedIds.add(newId);
    return newId;
  }, [temporaryCreatedIds]);

  const { mutateAsync: updateRoadmapNodesSectionAndTimePeriod } = useMutation(
    ({ nodes, roadmapIds }: { nodes: components['schemas']['NodeUpdate'][]; roadmapIds: number }) =>
      updateNodesSectionAndTimePeriod({ roadmapId: roadmapIds, nodes }),
    { onSuccess: () => updateLastSavedTime() }
  );

  const detectNodeTimePeriodAndSectionIdChanges = useCallback(() => {
    const nodesToUpdate: components['schemas']['NodeUpdate'][] = [];

    filteredNodes.forEach(node => {
      const nodePositionRelativeToCanvasInPx = calculateAbsolutePositionOnCanvasFromPercentageToPx({
        height: heightToFitContent,
        width: widthToFitContent,
        xPercentage: node.x,
        yPercentage: node.y,
        offsetX: canvasAreaOffset.x,
        offsetY: canvasAreaOffset.y,
      });

      const intersections = stageRef.current?.getAllIntersections(nodePositionRelativeToCanvasInPx);

      if (!intersections) {
        return;
      }

      const { sectionId, timePeriodId } = getIntersectionSectionAndTimePeriodId(intersections);

      const shouldUpdateNode =
        node.sectionId !== Number(sectionId) || node.timePeriodId !== Number(timePeriodId);

      if (shouldUpdateNode) {
        nodesToUpdate.push({
          nodeId: node.id,
          timePeriodId: Number(timePeriodId),
          sectionId: Number(sectionId),
        });
      }
    });
    return nodesToUpdate;
  }, [
    canvasAreaOffset.x,
    canvasAreaOffset.y,
    filteredNodes,
    heightToFitContent,
    widthToFitContent,
  ]);

  const isLoading =
    isRoadmapLoading ||
    areRoadmapNodesLoading ||
    areTimePeriodsLoading ||
    areSectionsLoading ||
    isLoadingRoadmapVisible ||
    isLoadingPopupVisible ||
    isLoadingDropdownVisible;

  const updateNodes = useCallback(
    async (nodes: components['schemas']['NodeUpdate'][]) => {
      const updatedNodes = await updateRoadmapNodesSectionAndTimePeriod({
        roadmapIds: roadmapId,
        nodes,
      });

      const updatedNodeIds = updatedNodes.map(un => un.nodeId);

      const newFilteredNodes = filteredNodes.map(node => {
        if (updatedNodeIds.includes(node.id)) {
          const updatedNode = updatedNodes.find(un => un.nodeId === node.id);
          return {
            ...node,
            sectionId: updatedNode?.sectionId || node.sectionId,
            timePeriodId: updatedNode?.timePeriodId || node.timePeriodId,
          };
        }
        return node;
      });

      setFilteredNodes(newFilteredNodes);
    },
    [filteredNodes, setFilteredNodes, roadmapId, updateRoadmapNodesSectionAndTimePeriod]
  );

  useEffect(() => {
    sortedTimePeriodsRef.current = sortedTimePeriods;
  }, [sortedTimePeriods]);
  useEffect(() => {
    sortedVerticalSectionsRef.current = sortedVerticalSections;
  }, [sortedVerticalSections]);
  useEffect(() => {
    sortedHorizontalSectionsRef.current = sortedHorizontalSections;
  }, [sortedHorizontalSections]);
  useEffect(() => {
    filteredNodesRef.current = filteredNodes;
  }, [filteredNodes]);

  useEffect(() => {
    if (shouldRecalculateNodePositions) {
      const sortedTimePeriodWidths = sortedTimePeriods.map(({ tabWidth }) => tabWidth);
      const sortedTimePeriodHeights = sortedTimePeriods.map(({ areaHeight }) => areaHeight);

      const sortedSectionWidths = [...sortedVerticalSections, ...sortedHorizontalSections].map(
        ({ tabWidth }) => tabWidth
      );

      const didAnyTimePeriodWidthChange = sortedTimePeriodWidths.some(
        (tpWidth, index) => tpWidth !== prevTimePeriodsWidthRef.current[index]
      );
      const didAnyTimePeriodHeightChange = sortedTimePeriodHeights.some(
        (tpHeight, index) => tpHeight !== prevTimePeriodsHeightRef.current[index]
      );

      const didAnySectionWidthChange = sortedSectionWidths.some(
        (sWidth, index) => sWidth !== prevSectionsWidthRef.current[index]
      );

      const didWidthsLengthChange =
        prevTimePeriodsWidthRef.current.length !== 0 &&
        sortedTimePeriodWidths.length !== prevTimePeriodsWidthRef.current.length;

      const didHeightsLengthChange =
        prevTimePeriodsHeightRef.current.length !== 0 &&
        sortedTimePeriodHeights.length !== prevTimePeriodsHeightRef.current.length;

      const didSectionsLengthChange =
        prevSectionsWidthRef.current.length !== 0 &&
        sortedSectionWidths.length !== prevSectionsWidthRef.current.length;

      const didTimePeriodWidthChange = didAnyTimePeriodWidthChange || didWidthsLengthChange;

      const didTimePeriodHeightChange = didAnyTimePeriodHeightChange || didHeightsLengthChange;

      const didSectionWidthChange = didAnySectionWidthChange || didSectionsLengthChange;

      if (didTimePeriodWidthChange || didTimePeriodHeightChange || didSectionWidthChange) {
        const nodesToUpdate = detectNodeTimePeriodAndSectionIdChanges();
        updateNodes(nodesToUpdate);

        if (didTimePeriodWidthChange) {
          prevTimePeriodsWidthRef.current = sortedTimePeriodWidths;
        }
        if (didTimePeriodHeightChange) {
          prevTimePeriodsHeightRef.current = sortedTimePeriodHeights;
        }
        if (didSectionWidthChange) {
          prevSectionsWidthRef.current = sortedSectionWidths;
        }
      }
    }
    setShouldRecalculateNodePositions(false);
  }, [
    updateNodes,
    setShouldRecalculateNodePositions,
    detectNodeTimePeriodAndSectionIdChanges,
    sortedTimePeriods,
    shouldRecalculateNodePositions,
    sortedVerticalSections,
    sortedHorizontalSections,
  ]);

  useEffect(() => {
    if (!roadmap?.id && !isLoading) {
      navigate(routes.dashboardTest);
    }

    if (roadmap?.id) {
      setLastSavedTime(new Date(roadmap!.updatedAt!));
    }
  }, [roadmap?.id, navigate, isLoading, roadmap]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <StyledLoader />
      </LoaderWrapper>
    );
  }

  const filterNodes = ({
    filter,
    optionColor,
    optionId,
    multiFilterColor,
  }: {
    filter: components['schemas']['Filter'];
    optionColor: string;
    multiFilterColor?: string;
    optionId?: number;
  }) => {
    if (filter.nodes) {
      const optionColorMap = new Map<number, string>();

      filter.options.forEach(option => {
        optionColorMap.set(Number(option.id), option.color);
      });

      const nodes = filteredNodes.map(fn => {
        if (filter.selection === 'multiple') {
          const filteredFilterNodes = filter.nodes?.filter(n => n.nodeId === fn.id);
          const isOptionMatching = filteredFilterNodes?.some(n => n.optionId === optionId);

          if (filteredFilterNodes?.length && isOptionMatching) {
            return {
              ...fn,
              shape: filter.shape,
              color: multiFilterColor,
            };
          }
          if (filteredFilterNodes?.length && !isOptionMatching) {
            return {
              ...fn,
              shape: filter.shape,
              color: '',
            };
          }
          return {
            ...fn,
            shape: '',
            color: '',
          };
        }
        const filteredNode = filter.nodes?.find(n => n.nodeId === fn.id);
        const isOptionMatching = filteredNode?.optionId === optionId;

        if (filteredNode && isOptionMatching) {
          return {
            ...fn,
            shape: filter.shape,
            color: optionColor,
          };
        }
        if (filteredNode && !isOptionMatching) {
          const color = optionColorMap.get(Number(filteredNode.optionId));

          return {
            ...fn,
            shape: filter.shape,
            color: color ? transparentize(0.85, color) : '',
          };
        }
        return {
          ...fn,
          shape: '',
          color: '',
        };
      });
      setFilteredNodes(nodes as FilteredNodes[]);
    }
  };

  const updateLastSavedTime = () => {
    const currentTime = new Date();
    setLastSavedTime(currentTime);
  };

  return (
    <EditorContext.Provider
      value={{
        stageRef,
        canvasAreaRef,
        roadmap: roadmap!,
        filters: filters!,
        filteredNodes: filteredNodes!,
        isSidebarContentLoading: isSidebarContentLoading!,
        setIsSidebarContentLoading: setIsSidebarContentLoading!,
        setIsDraggingTab,
        filterNodes,
        isDraggingTab,
        setDraggedTabPositionChange,
        draggedTabPositionChange,
        setSelectedNodeId,
        selectedNodeId,
        isCreatingNewInitiative,
        setIsCreatingNewInitiative,
        sortedTimePeriods,
        sortedVerticalSections,
        sortedHorizontalSections,
        roadmapNodes,
        setFilteredNodes,
        activeFilterId,
        setActiveFilterId,
        roadmapVisible,
        popupVisible,
        dropdownVisible,
        setIsPreview,
        isPreview: Boolean(isPreview),
        optionCheckedId,
        setOptionCheckedId,
        isPublicView: Boolean(isPublicView),
        isInitiativeDetailDialogOpen,
        setIsInitiativeDetailDialogOpen,
        expandedSidebarAccordionKey,
        setExpandedSidebarAccordionKey,
        generateUniqueIntTemporaryId,
        temporaryCreatedIds,
        isSwimlaneView,
        setIsSwimlaneView,
        setShowDependencies,
        showDependencies,
        canvasAreaOffset,
        height,
        width,
        setHeight,
        setWidth,
        widthToFitContent,
        heightToFitContent,
        detectNodeTimePeriodAndSectionIdChanges,
        setShouldRecalculateNodePositions,
        isSelectingDependencies,
        setIsSelectingDependencies,
        sortedTimePeriodsRef,
        sortedHorizontalSectionsRef,
        sortedVerticalSectionsRef,
        filteredNodesRef,
        lastSavedTime,
        updateLastSavedTime,
        roadmapPermissions,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => {
  const context = useContext(EditorContext);

  if (!context) {
    throw Error('EditorContext hook can be used only inside EditorContextProvider');
  }

  return context;
};

const LoaderWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;
