import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { SUPPORTED_DATE_FORMATS } from '@/constants/common';

import {
  CUSTOM_FIELD_MAX_MULTI_LINE_LENGTH,
  CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH,
} from '../constants';
import { VALIDATION_ERROR_KEY, ValidationResponse } from '../types/validation';
import { transformPercentageToInteger } from '../utils/transform-percentage-to-integer';

dayjs.extend(customParseFormat);

const budgetRegex =
  /(^([$€£]|USD|AUD|EUR|CAD|GBP)?\s*([\d,.]+)\s*([MK]?)$)|(^([\d,.]+)\s*([MK]?)\s*([$€£]|USD|AUD|EUR|CAD|GBP)?)$/i;

const validateIfEmpty = (value: string): ValidationResponse => {
  return {
    isValid: !value,
  };
};

export const validateSmallField = (value: string): ValidationResponse => {
  const isValid = value.length <= CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH;
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.textTooLong,
    };
  }
  return { isValid: value.length <= CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH };
};

export const validateBigField = (value: string): ValidationResponse => {
  const isValid =
    validateIfEmpty(value).isValid || value.length <= CUSTOM_FIELD_MAX_MULTI_LINE_LENGTH;
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.textTooLong,
    };
  }
  return { isValid };
};

export const validateDate = (value: string): ValidationResponse => {
  const isValid =
    validateIfEmpty(value).isValid ||
    SUPPORTED_DATE_FORMATS.some(format => dayjs(value, format, true).isValid());
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.wrongDateFormat,
    };
  }
  return { isValid };
};

export const validateNumber = (value: string): ValidationResponse => {
  const isValid = validateIfEmpty(value).isValid || !Number.isNaN(Number(value));
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.invalidNumber,
    };
  }
  return { isValid };
};

export const validateTitle = (value: string): ValidationResponse => {
  const isValid =
    validateIfEmpty(value).isValid || value.length <= CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH;
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.textTooLong,
    };
  }
  return { isValid };
};

export const validateDescription = (value: string): ValidationResponse => {
  const isValid =
    validateIfEmpty(value).isValid || value.length <= CUSTOM_FIELD_MAX_MULTI_LINE_LENGTH;
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.textTooLong,
    };
  }
  return { isValid };
};

export const validateString = (value: string): ValidationResponse => {
  const isValid =
    validateIfEmpty(value).isValid || value.length <= CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH;

  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.textTooLong,
    };
  }
  return { isValid };
};

export const validateProgressField = (value: string): ValidationResponse => {
  const integer = value ? transformPercentageToInteger(value) : 0;
  const isValidInteger = Number.isInteger(integer);
  const isNumberInRange = integer >= 0 && integer <= 100;
  const isValid = isValidInteger && isNumberInRange;

  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.invalidProgressValue,
    };
  }
  return { isValid };
};

export const validateBudgetField = (value: string): ValidationResponse => {
  const match = value.trim().match(budgetRegex);
  const isValid = Boolean(match) || validateIfEmpty(value).isValid;
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.wrongBudgetFormat,
    };
  }
  return { isValid };
};

export const validateMultiFilter = (value: string): ValidationResponse => {
  const isValid = value.split(',').every(item => validateFilter(item).isValid);
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.textTooLong,
    };
  }
  return { isValid };
};

export const validateFilter = (value: string): ValidationResponse => {
  const isValid =
    validateIfEmpty(value).isValid || value.length <= CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH;
  if (!isValid) {
    return {
      isValid,
      value,
      errorKey: VALIDATION_ERROR_KEY.textTooLong,
    };
  }
  return { isValid };
};
