/* eslint-disable max-len */
const AccountIcon = ({
  width = 48,
  height = 48,
}: {
  height?: number | string;
  width?: number | string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <defs>
        <clipPath id="account-clip-path">
          <rect
            id="account-Rectangle_957"
            data-name="Rectangle 957"
            width="30"
            height="30"
            transform="translate(0.102 -1)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g id="account-Group_13820" data-name="Group 13820" transform="translate(-667.191 -439)">
        <rect
          id="account-Rectangle_1022"
          data-name="Rectangle 1022"
          width="48"
          height="48"
          rx="24"
          transform="translate(667.191 439)"
        />
        <g id="account-Group_1331" data-name="Group 1331" transform="translate(676.091 449)">
          <g
            id="account-Group_1330"
            data-name="Group 1330"
            transform="translate(0 0)"
            clipPath="url(#account-clip-path)"
          >
            <path
              id="account-Path_219"
              data-name="Path 219"
              d="M22.073,23.143a12.045,12.045,0,0,1-7.831,2.967A12.045,12.045,0,0,1,6.41,23.143a1.153,1.153,0,0,1-.117-1.663,10.637,10.637,0,0,1,4.982-3.2,6.55,6.55,0,0,0,5.934,0,10.134,10.134,0,0,1,4.982,3.2,1.068,1.068,0,0,1-.117,1.663m-7.831-18.4c2.608,0,4.747,2.608,4.747,5.934s-2.139,5.934-4.747,5.934-4.747-2.608-4.747-5.934,2.139-5.934,4.747-5.934m0-4.747A14.242,14.242,0,1,0,28.484,14.242,14.286,14.286,0,0,0,14.242,0"
              transform="translate(0.835 0.058)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export { AccountIcon };
