import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import { getRoadmapSections, updateSection } from '@/features/canvas/api';
import { HorizontalSectionTabShape } from '@/features/canvas/components/HorizontalSectionTabShape';
import { SectionAreaShape } from '@/features/canvas/components/SectionAreaShape';
import {
  AREA_SHAPE_CLIP_OFFSET,
  HORIZONTAL_OFFSET,
  VERTICAL_OFFSET,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  canvasHeight: number;
  canvasWidth: number;
  cornerRadius: number[];
  horizontalSectionTabScaleProportion: number;
  scaledHorizontalSectionTabWidths: number[];
  sectionId: number;
  sectionIndex: number;
  sectionOrder: number;
  sectionOrientation: components['schemas']['RoadmapDetail']['sections'][0]['orientation'];
  sectionTitle: string;
};

const HorizontalSection = ({
  sectionIndex,
  horizontalSectionTabScaleProportion,
  sectionId,
  sectionTitle,
  canvasHeight,
  canvasWidth,
  cornerRadius,
  scaledHorizontalSectionTabWidths,
  sectionOrientation,
  sectionOrder,
}: Props) => {
  const { addHistoryItem } = useUndoRedo();
  const theme = useTheme();
  const {
    draggedTabPositionChange,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    sortedHorizontalSections,
    sortedVerticalSections,
    roadmap,
    temporaryCreatedIds,
    setShouldRecalculateNodePositions,
    updateLastSavedTime,
  } = useEditorContext();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const y = canvasHeight + VERTICAL_OFFSET;

  const { mutateAsync: updateSectionMutation } = useMutation(
    ({ sectionData }: { sectionData: components['schemas']['Section'] }) =>
      updateSection({ sectionId: Number(sectionData.id), newSectionData: sectionData }),
    { onSuccess: () => updateLastSavedTime() }
  );

  const shouldDisableActionMenuBecauseOfUsedTemporaryId =
    sectionId < 0 || temporaryCreatedIds.has(sectionId);

  const calculatedRectPrimaryAxisPosition = scaledHorizontalSectionTabWidths.reduce(
    (axisPosition, tabWidthValue, currentIndex) => {
      if (currentIndex >= sectionIndex) {
        return axisPosition;
      }
      return axisPosition + tabWidthValue;
    },
    HORIZONTAL_OFFSET
  );
  const scaledTabWidth = scaledHorizontalSectionTabWidths[sectionIndex];

  const areVerticalSectionsEven = Boolean(sortedVerticalSections.length % 2);
  const isRemainderPositive = Boolean(sectionIndex % 2);
  const shouldSectionBeFilled = areVerticalSectionsEven
    ? !isRemainderPositive
    : isRemainderPositive;

  const onTabResizeDragEndHandler = useCallback(async () => {
    try {
      const newScaledTimePeriodWidths = scaledHorizontalSectionTabWidths.map(
        (width, currentIndex) => {
          if (sectionIndex === currentIndex) {
            return width + draggedTabPositionChange;
          }
          if (sectionIndex + 1 === currentIndex) {
            return width - draggedTabPositionChange;
          }
          return width;
        }
      );

      const sectionsToUpdate = [
        sortedHorizontalSections[sectionIndex],
        sortedHorizontalSections[sectionIndex + 1],
      ].map(s => ({ ...s, roadmap_id: Number(roadmap.id) }));

      const newSectionsToUpdate = sectionsToUpdate.map((s, index) => {
        return {
          ...s,
          tabWidth: Math.round(
            newScaledTimePeriodWidths[sectionIndex + index] / horizontalSectionTabScaleProportion
          ),
        };
      });

      queryClient.setQueryData(
        [getRoadmapSections.name, roadmap.id],
        (oldSections?: components['schemas']['Section'][]) => {
          const newSections = (oldSections || []).map(s => {
            if (s.id === newSectionsToUpdate[0].id) {
              return newSectionsToUpdate[0];
            }
            if (s.id === newSectionsToUpdate[1].id) {
              return newSectionsToUpdate[1];
            }
            return s;
          });
          return newSections;
        }
      );

      const sectionUpdatePromises = newSectionsToUpdate.map(s =>
        updateSectionMutation({ sectionData: s })
      );

      await Promise.all(sectionUpdatePromises);

      addHistoryItem({
        type: HistoryActionType.MoveSection,
        resourceIds: [
          sortedHorizontalSections[sectionIndex].id!,
          sortedHorizontalSections[sectionIndex + 1].id!,
        ],
        undo: async (resourceIds?: number[]) => {
          const sectionsWithUpdatedResourceIds = sectionsToUpdate.map(s => {
            if (resourceIds && s.id === newSectionsToUpdate[0].id! && resourceIds[0]) {
              return { ...s, id: resourceIds[0] };
            }
            if (resourceIds && s.id === newSectionsToUpdate[1].id! && resourceIds[1]) {
              return { ...s, id: resourceIds[1] };
            }
            return s;
          });
          queryClient.setQueryData(
            [getRoadmapSections.name, roadmap.id],
            (oldSections?: components['schemas']['Section'][]) => {
              const newSections = (oldSections || []).map(s => {
                if (s.id === sectionsWithUpdatedResourceIds[0].id) {
                  return sectionsWithUpdatedResourceIds[0];
                }
                if (s.id === sectionsWithUpdatedResourceIds[1].id) {
                  return sectionsWithUpdatedResourceIds[1];
                }
                return s;
              });
              return newSections;
            }
          );

          await Promise.all(
            sectionsWithUpdatedResourceIds.map(s => updateSectionMutation({ sectionData: s }))
          );
          setShouldRecalculateNodePositions(true);
        },
        redo: async (resourceIds?: number[]) => {
          const sectionsWithUpdatedResourceIds = sectionsToUpdate.map(s => {
            if (resourceIds && s.id === newSectionsToUpdate[0].id! && resourceIds[0]) {
              return { ...s, id: resourceIds[0] };
            }
            if (resourceIds && s.id === newSectionsToUpdate[1].id! && resourceIds[1]) {
              return { ...s, id: resourceIds[1] };
            }
            return s;
          });
          queryClient.setQueryData(
            [getRoadmapSections.name, roadmap.id],
            (oldSections?: components['schemas']['Section'][]) => {
              const newSections = (oldSections || []).map(s => {
                if (s.id === sectionsWithUpdatedResourceIds[0].id) {
                  return sectionsWithUpdatedResourceIds[0];
                }
                if (s.id === sectionsWithUpdatedResourceIds[1].id) {
                  return sectionsWithUpdatedResourceIds[1];
                }
                return s;
              });
              return newSections;
            }
          );

          const newSectionUpdatePromises = sectionsWithUpdatedResourceIds.map(s =>
            updateSectionMutation({ sectionData: s })
          );

          await Promise.all(newSectionUpdatePromises);
          setShouldRecalculateNodePositions(true);
        },
      });

      setIsDraggingTab(false);
      setDraggedTabPositionChange(0);
      setShouldRecalculateNodePositions(true);
    } catch (error) {
      showToast('error', t('editor.canvas.edit_section_error'));
      await queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
      setIsDraggingTab(false);
    }
  }, [
    scaledHorizontalSectionTabWidths,
    sortedHorizontalSections,
    sectionIndex,
    queryClient,
    roadmap.id,
    addHistoryItem,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    setShouldRecalculateNodePositions,
    draggedTabPositionChange,
    horizontalSectionTabScaleProportion,
    updateSectionMutation,
    showToast,
    t,
  ]);

  const isLast = sectionIndex === scaledHorizontalSectionTabWidths.length - 1;
  const successorIdWithWidth =
    sectionIndex < scaledHorizontalSectionTabWidths.length - 1
      ? {
          id: sortedHorizontalSections[sectionIndex + 1].id,
          width: scaledHorizontalSectionTabWidths[sectionIndex + 1],
        }
      : {
          id: undefined,
          width: 0,
        };

  return (
    <HorizontalSectionTabShape
      key={sectionId}
      x={calculatedRectPrimaryAxisPosition}
      y={y}
      sectionId={sectionId}
      sectionIndex={sectionIndex}
      sectionTitle={sectionTitle}
      sectionOrder={sectionOrder}
      sectionOrientation={sectionOrientation}
      horizontalSectionTabWidth={scaledTabWidth}
      textColor={theme.palette.brand.white}
      cornerRadius={cornerRadius}
      fill={
        sectionIndex % 2
          ? theme.palette.brand.editorTabSecondary
          : theme.palette.brand.editorTabPrimary
      }
      onDragEnd={onTabResizeDragEndHandler}
      shouldDisableActionMenuBecauseOfUsedTemporaryId={
        shouldDisableActionMenuBecauseOfUsedTemporaryId
      }
      successor={successorIdWithWidth}
    >
      <SectionAreaShape
        id={sectionId.toString()}
        startPointARelativeToGroup={{ x: 0, y: 0 }}
        startPointBRelativeToGroup={{
          x: scaledTabWidth,
          y: 0,
        }}
        endPointARelativeToGroup={{
          x: canvasWidth - calculatedRectPrimaryAxisPosition + HORIZONTAL_OFFSET,
          y: -canvasHeight,
        }}
        clipOffset={{
          a: { x: -AREA_SHAPE_CLIP_OFFSET, y: 0 },
          b: { x: AREA_SHAPE_CLIP_OFFSET, y: 0 },
        }}
        {...(shouldSectionBeFilled && {
          fill: theme.palette.brand.sunray,
          strokeWidth: 2,
        })}
        isLast={isLast}
      />
    </HorizontalSectionTabShape>
  );
};

export { HorizontalSection };
