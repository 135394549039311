import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { useResources } from '@/contexts/resource-context';
import { components } from '@/types/api';

import * as S from './styles';

type Props = {
  account: components['schemas']['User'] | undefined;
};

const Header = memo<Props>(({ account }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { logoUrl } = useResources();

  return (
    <div>
      <S.HeaderWrapper>
        {logoUrl && <S.LogoWrapper src={logoUrl} />}

        {account && (
          <S.WelcomeText
            text={t('header.welcome', { name: `${account?.firstName} ${account?.lastName}` })}
            size="large"
            color={theme.palette.brand.textPrimary}
          />
        )}
      </S.HeaderWrapper>
    </div>
  );
});

export default Header;
