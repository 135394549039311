export const getTabSizeProportion = ({
  sizeToFit,
  tabSizes,
}: {
  sizeToFit: number;
  tabSizes: number[];
}) => {
  const tabsSumSize = tabSizes.reduce((partialSum, value) => partialSum + value, 0);
  const proportion = sizeToFit / tabsSumSize;
  return proportion;
};
