import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function createSection({
  newSection,
}: {
  newSection: components['schemas']['Section'];
}) {
  // temp
  const newSectionInArray = [newSection];
  const { data } = await defaultApi.post<components['schemas']['Section']>(
    '/sections',
    newSectionInArray
  );
  return data;
}
export async function deleteSection({ sectionId }: { sectionId: number }) {
  await defaultApi.delete(`/sections/${sectionId}`);
}

export async function updateSection({
  sectionId,
  newSectionData,
}: {
  sectionId: number;
  newSectionData: components['schemas']['Section'];
}) {
  const sectionDataInArray = [newSectionData];
  await defaultApi.put(`/sections/${sectionId}`, sectionDataInArray);
}

export async function getRoadmapSections({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get<components['schemas']['Section'][]>(
    `/sections/roadmap/${roadmapId}`
  );
  return data;
}
