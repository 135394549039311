import React, { useMemo } from 'react';
import { Arrow } from 'react-konva';
import { useTheme } from '@mui/material/styles';

import { ARROW_DASH, ARROW_RADIUS } from '@/features/canvas/constants';

type Point = {
  x: number;
  y: number;
};

type ArrowShapeProps = {
  source: Point;
  target: Point;
  fill?: string;
};

const ArrowShape = React.memo(({ source, target, fill: strokeFill }: ArrowShapeProps) => {
  const theme = useTheme();

  const dx = target.x - source.x;
  const dy = target.y - source.y;
  const angle = Math.atan2(-dy, dx);

  const arrowStart = useMemo(
    () => ({
      x: source.x + -ARROW_RADIUS * Math.cos(angle + Math.PI),
      y: source.y + ARROW_RADIUS * Math.sin(angle + Math.PI),
    }),
    [angle, source.x, source.y]
  );

  const arrowEnd = useMemo(
    () => ({
      x: target.x + -ARROW_RADIUS * Math.cos(angle),
      y: target.y + ARROW_RADIUS * Math.sin(angle),
    }),
    [angle, target.x, target.y]
  );

  const points = useMemo(
    () => [arrowStart.x, arrowStart.y, arrowEnd.x, arrowEnd.y],
    [arrowEnd.x, arrowEnd.y, arrowStart.x, arrowStart.y]
  );

  return (
    <Arrow
      dash={ARROW_DASH}
      fill={theme.palette.brand.textSecondary}
      stroke={strokeFill || theme.palette.brand.textSecondary}
      strokeWidth={2}
      points={points}
    />
  );
});

export { ArrowShape };
