import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import {
  createRoadmapsDropdownVisible,
  deleteRoadmapsPopupVisible,
  deleteRoadmapsRoadmapVisible,
  getInitiativeById,
  getRoadmapsDropdownVisible,
  getRoadmapsPopupVisible,
  getRoadmapsRoadmapVisible,
  permanentlyDeleteInitiativeCustomFieldById,
} from '@/features/canvas/api';
import { DualChoiceDialog } from '@/features/canvas/components/DualChoiceDialog';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { ConfirmationDialog } from '@/features/ui/components/ConfirmationDialog';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type InitiativeAccordionContentDeleteFieldDialogsProps = {
  customFieldId: number | null;
  initiative: components['schemas']['Initiative'];
  isDeleteCustomFieldDialogOpen: boolean;
  isDeleteFieldDialogOpen: boolean;
  isDeletingFromDropdown: boolean;
  onDualChoiceClose: () => void;
  onFieldDeleteClose: () => void;
  selectedFieldId: number | null;
};

export const InitiativeAccordionContentDeleteFieldDialogs = ({
  isDeleteFieldDialogOpen,
  isDeletingFromDropdown,
  onFieldDeleteClose,
  onDualChoiceClose,
  initiative,
  isDeleteCustomFieldDialogOpen,
  customFieldId,
  selectedFieldId,
}: InitiativeAccordionContentDeleteFieldDialogsProps) => {
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const { popupVisible, selectedNodeId, roadmapVisible, roadmap, updateLastSavedTime } =
    useEditorContext();

  const queryClient = useQueryClient();

  const { mutateAsync: deleteField, isLoading: isDeletingField } = useMutation(
    async (fieldId: number) => {
      try {
        const field = initiative!.fields?.find(f => f.id === fieldId);
        if (!field) {
          showToast('error', t('editor.sidebar.initiative.delete_field_error'));
          return;
        }

        const actions: Promise<unknown>[] = [];
        const popupToDelete = popupVisible?.find(
          p => p.title === field.title! && p.type === field.type
        );
        const roadMapToDelete = roadmapVisible?.find(
          r => r.title === field.title! && r.type === field.type
        );

        if (popupToDelete) {
          actions.push(deleteRoadmapsPopupVisible({ roadmapId: popupToDelete.id! }));
        }

        if (roadMapToDelete) {
          actions.push(deleteRoadmapsRoadmapVisible({ roadmapId: roadMapToDelete.id! }));
        }

        actions.push(
          createRoadmapsDropdownVisible({
            roadmapId: roadmap.id!,
            newDropdownVisible: { id: field.id, title: field.title!, type: field.type },
          })
        );

        await Promise.all(actions);
        await Promise.all([
          await queryClient.invalidateQueries([getRoadmapsPopupVisible.name, roadmap.id]),
          await queryClient.invalidateQueries([getRoadmapsRoadmapVisible.name, roadmap.id]),
          await queryClient.invalidateQueries([getRoadmapsDropdownVisible.name, roadmap.id]),
        ]);

        updateLastSavedTime();
        showToast('success', t('editor.sidebar.initiative.delete_field_success'));
      } catch (e) {
        showToast('error', t('editor.sidebar.initiative.delete_field_error'));
      }
    }
  );

  const {
    mutateAsync: permanentlyDeleteCustomField,
    isLoading: isPermanentlyDeletingFieldInCurrentRoadmap,
  } = useMutation(permanentlyDeleteInitiativeCustomFieldById, {
    onError: () => {
      showToast('error', t('editor.sidebar.initiative.delete_field_error'));
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries([getRoadmapsPopupVisible.name, roadmap.id]);
      await queryClient.invalidateQueries([getRoadmapsRoadmapVisible.name, roadmap.id]);
      await queryClient.invalidateQueries([getRoadmapsDropdownVisible.name, roadmap.id]);
      updateLastSavedTime();
    },
  });

  const handleDeleteInAllRoadmaps = async () => {
    await permanentlyDeleteCustomField({ fieldId: customFieldId!, roadmapId: roadmap.id! });
    onDualChoiceClose();
  };
  const handleDeleteInCurrentRoadmap = async () => {
    await deleteField(customFieldId!);
    onDualChoiceClose();
  };

  const handleDelete = async (fieldId: number) => {
    if (isDeletingFromDropdown) {
      await permanentlyDeleteCustomField({ fieldId, roadmapId: roadmap.id! });
    } else {
      await deleteField(fieldId);
    }
    onFieldDeleteClose();
    await queryClient.invalidateQueries([getInitiativeById.name, selectedNodeId]);
  };

  const isActionLoading = isDeletingField || isPermanentlyDeletingFieldInCurrentRoadmap;
  return (
    <>
      <DualChoiceDialog
        id="delete-custom-field-confirm-dialog"
        open={isDeleteCustomFieldDialogOpen && Boolean(customFieldId)}
        onClose={onDualChoiceClose}
        dialogDescription={t('editor.delete_custom_field_dialog.description')}
        firstChoiceOnClick={handleDeleteInCurrentRoadmap}
        firstChoiceIsDisabled={isActionLoading}
        firstChoiceIsLoading={isDeletingField}
        firstChoiceButtonText={t('editor.delete_custom_field_dialog.this_roadmap_only')}
        firstChoiceDescription={t(
          'editor.delete_custom_field_dialog.this_roadmap_only_description'
        )}
        secondChoiceOnClick={handleDeleteInAllRoadmaps}
        secondChoiceIsDisabled={isActionLoading}
        secondChoiceIsLoading={isPermanentlyDeletingFieldInCurrentRoadmap}
        secondChoiceButtonText={t('editor.delete_custom_field_dialog.all_roadmaps')}
        secondChoiceDescription={t('editor.delete_custom_field_dialog.all_roadmaps_description')}
        keepMounted={false}
      />
      <ConfirmationDialog
        open={isDeleteFieldDialogOpen}
        okButtonText={t('yes_sure')}
        keepMounted={false}
        description={t('editor.sidebar.initiative.delete_dialog_description')}
        handleOk={() => handleDelete(selectedFieldId!)}
        id="delete-field-confirm-dialog"
        onClose={onFieldDeleteClose}
        isLoading={isActionLoading}
      />
    </>
  );
};
