import { Group, Image, Rect } from 'react-konva';
import { useImage } from 'react-konva-utils';
import { useTheme } from '@mui/material';

import DeactivatedMode from '@/assets/icons/home-button.svg';
import ActiveMode from '@/assets/icons/home-button-active.svg';
import {
  CANVAS_HOME_BG_X_OFFSET,
  CANVAS_HOME_BG_Y_OFFSET,
  CANVAS_HOME_BUTTON_HEIGHT,
  CANVAS_HOME_BUTTON_WIDTH,
  CANVAS_HOME_BUTTON_X_OFFSET,
  CANVAS_HOME_BUTTON_Y_OFFSET,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

const CanvasViewHomeButton = ({ canvasHeightToFit }: { canvasHeightToFit: number }) => {
  const theme = useTheme();
  const { setActiveFilterId, activeFilterId, setShowDependencies, showDependencies } =
    useEditorContext();

  const [activeMode] = useImage(ActiveMode);
  const [deactivatedMode] = useImage(DeactivatedMode);

  const isActive = activeFilterId || showDependencies;

  return (
    <Group
      width={CANVAS_HOME_BUTTON_WIDTH}
      height={CANVAS_HOME_BUTTON_HEIGHT}
      x={CANVAS_HOME_BG_X_OFFSET}
      y={canvasHeightToFit + CANVAS_HOME_BG_Y_OFFSET}
      onMouseEnter={e => {
        const stage = e.target.getStage();
        if (stage) {
          const container = stage.container();
          container.style.cursor = 'pointer';
        }
      }}
      onMouseLeave={e => {
        const stage = e.target.getStage();
        if (stage) {
          const container = stage.container();
          container.style.cursor = 'default';
        }
      }}
      onClick={() => {
        setActiveFilterId(null);
        setShowDependencies(false);
      }}
      onTap={() => {
        setActiveFilterId(null);
        setShowDependencies(false);
      }}
    >
      <Rect
        cornerRadius={[5, 5, 5, 5]}
        fill={theme.palette.brand.white}
        width={CANVAS_HOME_BUTTON_WIDTH}
        height={CANVAS_HOME_BUTTON_HEIGHT}
        shadowColor={theme.palette.brand.black}
        shadowBlur={5}
        shadowOpacity={0.3}
      />
      <Image
        image={isActive ? activeMode : deactivatedMode}
        x={CANVAS_HOME_BUTTON_X_OFFSET}
        y={CANVAS_HOME_BUTTON_Y_OFFSET}
        width={CANVAS_HOME_BUTTON_WIDTH - 2 * CANVAS_HOME_BUTTON_X_OFFSET}
        height={CANVAS_HOME_BUTTON_HEIGHT - 2 * CANVAS_HOME_BUTTON_Y_OFFSET}
      />
    </Group>
  );
};

export { CanvasViewHomeButton };
