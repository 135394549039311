/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-statements */
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from 'react-konva';
import { Portal, useImage } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import BinIconSrc from '@/assets/icons/bin.svg';
import PencilIconSrc from '@/assets/icons/pencil.svg';
import ThinPlus from '@/assets/icons/thin-plus-primary.svg';
import {
  createSection,
  deleteSection,
  getRoadmapSections,
  updateSection,
} from '@/features/canvas/api';
import { TabActionMenuItem } from '@/features/canvas/components/TabActionMenuItem';
import {
  DEFAULT_NEW_TAB_WIDTH,
  MAX_HORIZONTAL_SECTIONS,
  MIN_HORIZONTAL_SECTIONS,
  TAB_ACTION_MENU_WIDTH,
  TAB_HEIGHT,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  horizontalSectionTabWidth: number;
  isHoverActive: boolean;
  sectionId: number;
  sectionIndex: number;
  sectionOrder: number;
  sectionOrientation: components['schemas']['RoadmapDetail']['sections'][0]['orientation'];
  setIsEditingTitle: Dispatch<SetStateAction<boolean>>;
  setIsHoveringAction: Dispatch<SetStateAction<boolean>>;
  shouldDisableActionMenuBecauseOfUsedTemporaryId: boolean;
  x: number;
  y: number;
};
const HorizontalSectionActionMenu = ({
  x,
  y,
  sectionId,
  sectionOrder,
  sectionIndex,
  sectionOrientation,
  setIsEditingTitle,
  horizontalSectionTabWidth,
  isHoverActive,
  setIsHoveringAction,
  shouldDisableActionMenuBecauseOfUsedTemporaryId,
}: Props) => {
  const theme = useTheme();
  const [pencilIcon] = useImage(PencilIconSrc);
  const [binIcon] = useImage(BinIconSrc);
  const [plusIcon] = useImage(ThinPlus);

  const { t } = useTranslation();
  const { showToast } = useShowToast();

  const {
    sortedHorizontalSections,

    roadmap,
    generateUniqueIntTemporaryId,
    temporaryCreatedIds,
    setShouldRecalculateNodePositions,
    sortedHorizontalSectionsRef,
    sortedVerticalSectionsRef,
    updateLastSavedTime,
  } = useEditorContext();
  const { addHistoryItem, updateResourceId } = useUndoRedo();
  const queryClient = useQueryClient();

  const { mutateAsync: createNewSection } = useMutation(
    ({ newSection }: { newSection: components['schemas']['Section']; newSectionTempId: number }) =>
      createSection({ newSection }),
    {
      onSuccess: ({ id }, { newSection, newSectionTempId }) => {
        const updatedSection = { ...newSection, id };
        queryClient.setQueryData(
          [getRoadmapSections.name, roadmap.id],
          (oldSections?: components['schemas']['Section'][]) => {
            if (oldSections) {
              return oldSections.map(s => {
                if (s.id === newSectionTempId) {
                  temporaryCreatedIds.delete(newSectionTempId);
                  return { ...updatedSection };
                }
                return s;
              });
            }
            return [];
          }
        );
        setShouldRecalculateNodePositions(true);
        updateLastSavedTime();
      },
    }
  );

  const { mutateAsync: updateSectionMutation } = useMutation(
    ({ newSectionData }: { newSectionData: components['schemas']['Section'] }) =>
      updateSection({ newSectionData, sectionId: Number(newSectionData.id) }),
    { onSuccess: () => updateLastSavedTime() }
  );

  const { mutateAsync: deleteSectionMutation } = useMutation(
    (currentSectionId: number | string | undefined) =>
      deleteSection({ sectionId: Number(currentSectionId) }),
    {
      onMutate: horizontalSectionId => {
        queryClient.setQueryData(
          [getRoadmapSections.name, roadmap.id],
          (oldSections?: components['schemas']['Section'][]) => {
            updateLastSavedTime();
            if (oldSections) {
              return oldSections.filter(s => Number(s.id) !== Number(horizontalSectionId));
            }
            return [];
          }
        );
      },
      onSuccess: () => {
        setShouldRecalculateNodePositions(true);
        updateLastSavedTime();
      },
      onError: () => {
        showToast('error', t('editor.canvas.delete_section_error'));
        return queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
      },
    }
  );

  const deleteSectionHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (sectionId: number) => {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete this section?')) {
        await deleteSectionMutation(sectionId);
      }
    },
    [deleteSectionMutation]
  );

  const addHorizontalSection = useCallback(
    async (newHorizontalSection?: components['schemas']['Section']) => {
      try {
        const indexToIncrementFrom = sortedHorizontalSections.findIndex(
          s => s.order === sectionOrder
        );

        let newSectionOrder = 0;
        let newOrder = 1;
        const horizontalSectionsWithIncrementedOrder = sortedHorizontalSections.map((s, index) => {
          let timePeriod = { ...s, order: newOrder };
          if (index > indexToIncrementFrom) {
            timePeriod = { ...s, order: newOrder + 1 };
          }
          if (index === indexToIncrementFrom) {
            newSectionOrder = newOrder + 1;
          }
          newOrder += 1;
          return timePeriod;
        });

        const horizontalSectionsToUpdate = newHorizontalSection
          ? sortedHorizontalSectionsRef.current.filter(hs => hs.id !== newHorizontalSection?.id)
          : horizontalSectionsWithIncrementedOrder;

        const updatedSectionsPromises = horizontalSectionsToUpdate.map(s =>
          updateSectionMutation({
            newSectionData: { ...s, roadmap_id: Number(roadmap.id) },
          })
        );

        const newSectionTempId = generateUniqueIntTemporaryId();

        const newSection = {
          id: newSectionTempId,
          title: `SECTION ${horizontalSectionsWithIncrementedOrder.length + 1}`,
          tabWidth: DEFAULT_NEW_TAB_WIDTH,
          orientation: sectionOrientation,
          order: newSectionOrder,
          roadmap_id: Number(roadmap.id),
        };
        queryClient.setQueryData(
          [getRoadmapSections.name, roadmap.id],
          [
            ...sortedVerticalSectionsRef.current,
            ...horizontalSectionsToUpdate,
            newHorizontalSection || newSection,
          ]
        );

        await Promise.all(updatedSectionsPromises);

        const createdSection = await createNewSection({
          newSection: newHorizontalSection || newSection,
          newSectionTempId: newHorizontalSection?.id || newSectionTempId,
        });

        return createdSection;
      } catch (error) {
        showToast('error', t('editor.canvas.edit_section_error'));
        await queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
      }
    },
    [
      createNewSection,
      generateUniqueIntTemporaryId,
      queryClient,
      roadmap.id,
      sectionOrder,
      sectionOrientation,
      showToast,
      sortedHorizontalSections,
      sortedHorizontalSectionsRef,
      sortedVerticalSectionsRef,
      t,
      updateSectionMutation,
    ]
  );

  const removeHorizontalSection = useCallback(async () => {
    await deleteSectionHandler(sectionId);

    addHistoryItem({
      type: HistoryActionType.DeleteSection,
      async undo(resourceIds?: number[]) {
        const section = sortedHorizontalSections.find(
          (vs, index) => vs.id === (resourceIds?.[0] || sectionId) || index === sectionIndex
        )!;
        const newCreatedSection = await addHorizontalSection({
          ...section,
          roadmap_id: Number(roadmap.id),
        });

        updateResourceId(this.type, resourceIds?.[0] || sectionId, newCreatedSection!.id!);
        this.redo = async (redoResourceIds?: number[]) => {
          await deleteSectionHandler(redoResourceIds![0]);
        };
      },
      redo: async (redoResourceIds?: number[]) => {
        await deleteSectionHandler(redoResourceIds![0]);
      },
      resourceIds: [sectionId],
    });
  }, [
    sectionId,
    sortedHorizontalSections,
    deleteSectionHandler,
    addHistoryItem,
    addHorizontalSection,
    roadmap.id,
    updateResourceId,
    sectionIndex,
  ]);

  const actionMenuItems = useMemo(() => {
    const icons = [
      {
        id: 1,
        tooltipText: t('editor.canvas.edit_tooltip'),
        icon: pencilIcon,
        onClick: () => {
          setIsEditingTitle(true);
        },
        disabled: shouldDisableActionMenuBecauseOfUsedTemporaryId,
      },
      {
        id: 2,
        tooltipText: t('editor.canvas.delete_tooltip'),
        icon: binIcon,
        onClick: removeHorizontalSection,
        disabled:
          shouldDisableActionMenuBecauseOfUsedTemporaryId ||
          sortedHorizontalSections.length <= MIN_HORIZONTAL_SECTIONS,
      },
      {
        id: 3,
        icon: plusIcon,
        tooltipText: t('editor.canvas.add_tooltip'),
        onClick: async () => {
          const createdSection = await addHorizontalSection();

          addHistoryItem({
            type: HistoryActionType.AddSection,
            undo: async (resourceIds?: number[]) => {
              await deleteSectionHandler(resourceIds?.[0] || createdSection!.id!);
            },
            async redo(redoResourceIds?: number[]) {
              const newCreatedSection = (await addHorizontalSection(createdSection))!;

              updateResourceId(
                this.type,
                redoResourceIds?.[0] || newCreatedSection.id!,
                newCreatedSection.id!
              );

              this.resourceIds = [newCreatedSection.id!];
              this.undo = async (resourceIds?: number[]) => {
                await deleteSectionHandler(resourceIds?.[0] || newCreatedSection.id!);
              };
            },
            resourceIds: [createdSection!.id!],
          });
        },
        disabled:
          shouldDisableActionMenuBecauseOfUsedTemporaryId ||
          sortedHorizontalSections.length >= MAX_HORIZONTAL_SECTIONS,
      },
    ].filter(actionMenuItem => Boolean(actionMenuItem.icon));
    return icons;
  }, [
    t,
    pencilIcon,
    binIcon,
    removeHorizontalSection,
    sortedHorizontalSections.length,
    shouldDisableActionMenuBecauseOfUsedTemporaryId,
    plusIcon,
    setIsEditingTitle,
    addHorizontalSection,
    addHistoryItem,
    deleteSectionHandler,
    updateResourceId,
  ]);

  const draggableRectXPosition = x + horizontalSectionTabWidth - TAB_ACTION_MENU_WIDTH;
  const draggableRectYPosition = y;

  return (
    <Portal selector=".top-layer" enabled={isHoverActive}>
      <Group
        id={sectionId.toString()}
        width={TAB_ACTION_MENU_WIDTH}
        height={TAB_HEIGHT / 2}
        x={draggableRectXPosition}
        y={draggableRectYPosition}
        fill={theme.palette.brand.white}
        onMouseEnter={() => setIsHoveringAction(true)}
        onMouseOver={() => setIsHoveringAction(true)}
        onMouseLeave={() => setIsHoveringAction(false)}
        visible={isHoverActive}
      >
        {actionMenuItems.map((actionMenuItem, index) => (
          <TabActionMenuItem
            key={`horizontal-section-${sectionId}-${actionMenuItem.id}`}
            id={`horizontal-section-${sectionId}-${actionMenuItem.id}`}
            itemIndex={index}
            icon={actionMenuItem.icon!}
            text={actionMenuItem.tooltipText}
            onClick={actionMenuItem.onClick}
            disabled={Boolean(actionMenuItem.disabled)}
          />
        ))}
      </Group>
    </Portal>
  );
};

export { HorizontalSectionActionMenu };
