import { Group } from 'react-konva';

import { TimePeriod } from '@/features/canvas/components/TimePeriod';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { getTabSizeProportion } from '@/features/canvas/utils/get-tab-size-proportion';

type Props = {
  canvasHeightToFit: number;
  canvasWidthToFit: number;
};

const TimePeriods = ({ canvasWidthToFit, canvasHeightToFit }: Props) => {
  const { sortedTimePeriods } = useEditorContext();

  const timePeriodTabWidths = sortedTimePeriods.map(({ tabWidth }) => tabWidth);
  const timePeriodTabAreaHeights = sortedTimePeriods.map(({ areaHeight }) => areaHeight);

  const timePeriodTabWidthScaleProportion = getTabSizeProportion({
    sizeToFit: canvasWidthToFit,
    tabSizes: timePeriodTabWidths,
  });
  const timePeriodTabHeightScaleProportion = getTabSizeProportion({
    sizeToFit: canvasHeightToFit,
    tabSizes: timePeriodTabAreaHeights,
  });

  const scaleAdjustedTimePeriodTabWidths = timePeriodTabWidths.map(
    tabWidth => tabWidth * timePeriodTabWidthScaleProportion
  );

  const scaleAdjustedTimePeriodTabAreaHeights = timePeriodTabAreaHeights.map(
    tabWidth => tabWidth * timePeriodTabHeightScaleProportion
  );

  return (
    <Group id="time-periods">
      {sortedTimePeriods.map(({ id, title, order }, index) => (
        <TimePeriod
          canvasHeightToFit={canvasHeightToFit}
          canvasWidthToFit={canvasWidthToFit}
          timePeriodId={id!}
          timePeriodIndex={index}
          timePeriodTitle={title}
          timePeriodOrder={order}
          key={id}
          scaleAdjustedTimePeriodTabWidths={scaleAdjustedTimePeriodTabWidths}
          scaleAdjustedTimePeriodTabAreaHeights={scaleAdjustedTimePeriodTabAreaHeights}
          isLast={index === scaleAdjustedTimePeriodTabWidths.length - 1}
          timePeriodTabWidthScaleProportion={timePeriodTabWidthScaleProportion}
          timePeriodTabHeightScaleProportion={timePeriodTabHeightScaleProportion}
        />
      ))}
    </Group>
  );
};

export { TimePeriods };
