/* eslint react/jsx-no-literals: 0 */
/* eslint react-perf/jsx-no-new-object-as-prop: 0 */

import PlusIconSrc from 'src/assets/icons/plus.svg';
import Button from 'src/features/ui/components/Button/Button';
import { Card } from 'src/features/ui/components/Card/Card';
import Icon from 'src/features/ui/components/Icon/Icon';
import Input from 'src/features/ui/components/Input/Input';
import InputLabel from 'src/features/ui/components/InputLabel/InputLabel';
import Text from 'src/features/ui/components/Text/Text';
import Textarea from 'src/features/ui/components/Textarea/Textarea';

const UiPage = () => {
  return (
    <main
      style={{
        fontSize: '1.6rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '4rem',
        padding: '6rem 12rem',
      }}
    >
      <h1 style={{ fontSize: '4rem', fontWeight: 'bold', margin: 'auto' }}>UI Lib</h1>
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Text</h2>

      {/* TEXT */}
      <div style={{ display: 'flex' }}>
        <Text text="Text small" size="small" color="red" /> &nbsp;
        {'<Text text="Text small" size="small" color="red" />'}
      </div>

      <div style={{ display: 'flex' }}>
        <Text text="Text medium" size="medium" color="green" /> &nbsp;
        {'<Text text="Text medium" size="medium" color="green" />'}
      </div>

      <div style={{ display: 'flex' }}>
        <Text text="Text large" size="large" color="blue" /> &nbsp;
        {'<Text text="Text large" size="large" color="blue" />'}
      </div>

      {/* BUTTONS */}
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Buttons</h2>

      <div>
        <Button label="Button Primary" variant="primary" /> &nbsp;
        {'<Button label="Button Primary" variant="primary" />'}
      </div>

      <div>
        <Button label="Button Secondary" variant="secondary" /> &nbsp;
        {'<Button label="Button Secondary" variant="secondary" />'}
      </div>

      <div>
        <Button label="Button Tertiary" variant="tertiary" /> &nbsp;
        {'<Button label="Button Tertiary" variant="tertiary" />'}
      </div>

      <div>
        <Button icon={<Icon src={PlusIconSrc} />} label="Button Action" variant="action" /> &nbsp;
        {' <Button icon={<Icon src={PlusIconSrc} />} label="Button Action" variant="action" />'}
      </div>

      {/* Form inputs */}
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Form inputs</h2>
      <div>
        <Input defaultValue="<Input />" /> &nbsp;
      </div>
      <div>
        <Input icon="person" defaultValue="<Input icon='person' />" /> &nbsp;
      </div>
      <div>
        <Input
          icon="key"
          type="password"
          hidable
          defaultValue="<Input icon='key' type='password' hidable />"
        />{' '}
        &nbsp;
      </div>

      {/* Input label */}
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Input label</h2>
      <div style={{ height: 'fit-content' }}>
        <InputLabel htmlFor="inputId">
          {'<InputLabel htmlFor="inputId" placeholder="Input placeholder">Input label</InputLabel>'}
        </InputLabel>
        <Input id="inputId" placeholder="Input placeholder" defaultValue="<Input id='inputId' />" />
      </div>

      {/* Card */}
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Card</h2>
      <Card>{'<Card>Content of the card</Card>'}</Card>

      {/* Textarea */}
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Textarea</h2>
      <Textarea rows={7}>{'<Textarea>Content of the textarea</Textarea>'}</Textarea>

      {/* Search field */}
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Search field (TBD)</h2>

      {/* Modal */}
      <h2 style={{ fontSize: '2.8rem', margin: 'auto' }}>Modal (TBD)</h2>
    </main>
  );
};

export default UiPage;
