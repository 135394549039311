import { useEffect, useRef } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, Input, InputProps } from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { useFormContext } from '@/features/ui/components/Form';
import { theme } from '@/features/ui/theme';

type FormTextInputProps = InputProps & {
  name: string;
  textColor: string;
  defaultValue?: string | number;
  placeholder?: string;
  required?: boolean;
  rules?: RegisterOptions;
};

const CanvasFormInput = ({
  name,
  placeholder,
  required,
  defaultValue,
  textColor,
  ...otherProps
}: FormTextInputProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      rules={{
        required: required ? t('form.field_required_validation') : false,
        ...(otherProps.rules ? otherProps.rules : {}),
      }}
      render={({ field, fieldState: { error }, formState: { isSubmitting } }) => {
        return (
          <>
            <FormControl sx={{ width: '100%' }}>
              <Input
                inputRef={inputRef}
                inputProps={{
                  sx: {
                    textAlign: 'center',
                    padding: 0,
                    paddingBottom: '3px',
                    textShadow: `0px 0px 1px ${textColor}`,
                  },
                }}
                disableUnderline
                placeholder={placeholder}
                {...otherProps}
                {...field}
                onChange={v => {
                  otherProps.onChange?.(v);
                  field.onChange(v);
                }}
                error={!!error}
                value={otherProps.value?.toString() || field.value}
                disabled={otherProps.disabled || isSubmitting}
                onBlur={e => {
                  otherProps.onBlur?.(e);
                  field.onBlur();
                }}
                sx={{
                  '&.MuiInput-root:before': {
                    content: 'unset',
                  },
                  '&.MuiInput-root:after': {
                    border: 'none',
                  },
                  '&.Mui-disabled': {
                    content: 'unset',
                  },
                  fontSize: '16px',
                  fontWeight: '900',
                  fontFamily: 'Open Sans',
                  color: textColor,
                  backgroundColor: transparentize(0.5, theme().palette.brand.white),
                  borderRadius: '0.5rem',
                  textAlign: 'center',
                }}
              />
            </FormControl>
            {!!error && !isSubmitting && <StyledError>{error.message}</StyledError>}
          </>
        );
      }}
    />
  );
};

const StyledError = styled.div`
  color: ${theme().palette.brand.negativeStatus};
  font-size: 1rem;
  font-weight: 600;
  margin: 0.25rem 0;
  position: absolute;
`;

export { CanvasFormInput };
