/* eslint-disable max-lines */

/* eslint-disable react/jsx-no-literals */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';
import CircleIconSrc from 'src/assets/icons/circle.svg';
import CircleCheckIconSrc from 'src/assets/icons/circle-check.svg';
import TriangleIconSrc from 'src/assets/icons/triangle.svg';
import TriangleCheckIconSrc from 'src/assets/icons/triangle-check.svg';
import styled, { css } from 'styled-components';

import AddNewCircleSVG from '@/assets/icons/add-new-circle.svg';
import AddNewSquareSVG from '@/assets/icons/add-new-square.svg';
import AddNewTriangleSVG from '@/assets/icons/add-new-triangle.svg';
import SquareIconSrc from '@/assets/icons/square.svg';
import SquareCheckIconSrc from '@/assets/icons/square-check.svg';
import {
  createFilterOption,
  getFiltersByRoadmapId,
  getOptionsByFilterId,
} from '@/features/canvas/api';
import { FilterMultiOption } from '@/features/canvas/components/FilterMultiOption';
import { FilterSingleOption } from '@/features/canvas/components/FilterSingleOption';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { FilterTypes } from '@/features/canvas/types/filter';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

// eslint-disable-next-line max-statements
const FiltersAccordionSubContent = ({
  filter,
  handleEdit,
  roadmapId,
}: {
  filter: components['schemas']['Filter'];
  handleEdit: (filter: components['schemas']['Filter']) => Promise<void>;
  roadmapId: number;
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(filter.title);
  const theme = useTheme();
  const { showToast } = useShowToast();
  const queryClient = useQueryClient();
  const { setIsSidebarContentLoading, updateLastSavedTime } = useEditorContext();

  const TITLE_MAX_NUMBER_CHARS = 32;

  const { mutateAsync: createOption } = useMutation(
    async (option: components['schemas']['FilterOption']) => {
      try {
        await createFilterOption({ filterId: filter.id!, payload: option });
        updateLastSavedTime();
        showToast('success', t('editor.sidebar.filter.create_option_success'));
      } catch (e) {
        showToast('error', t('editor.sidebar.filter.create_option_success'));
      }
    }
  );

  const { data: options } = useQuery<components['schemas']['FilterOption'][]>(
    [getOptionsByFilterId.name, filter.id],
    () => {
      return getOptionsByFilterId(filter.id!);
    },
    {
      enabled: Boolean(filter.id),
    }
  );

  const handleShapeEdit = async (shape: 'circle' | 'square' | 'triangle') => {
    setIsSidebarContentLoading(true);

    const updatedFilter = { ...filter, shape };
    await handleEdit(updatedFilter);

    setIsSidebarContentLoading(false);
  };

  const isCustomFilter = (value: string) => {
    return (
      value !== FilterTypes.PRIORITY &&
      value !== FilterTypes.RAG &&
      value !== FilterTypes.PROGRESS_STATUS
    );
  };

  const handleTitleEdit = (event: { target: { value: string } }) => {
    const { value } = event.target;
    if (value.length > TITLE_MAX_NUMBER_CHARS) {
      return;
    }
    setInputValue(value);
  };

  const handleTitleInputBlur = async () => {
    if (!inputValue) {
      setInputValue(filter.title);
      showToast('error', t('editor.sidebar.filter.filter_title_empty_error'));
      return;
    }

    if (!isCustomFilter(inputValue)) {
      setInputValue(filter.title);
      showToast('error', t('editor.sidebar.filter.filter_title_in_used'));
      return;
    }

    if (inputValue === filter.title) {
      return;
    }

    setIsSidebarContentLoading(true);
    await handleEdit({ ...filter, title: inputValue });
    setIsSidebarContentLoading(false);
  };

  const handleTitleInputKeyDown = async (event: { key: string }) => {
    if (event.key === 'Enter') {
      await handleTitleInputBlur();
    }
  };

  const handleOptionAdd = async () => {
    const newOption: components['schemas']['FilterOption'] = {
      title: t('editor.sidebar.filter.new_option_title'),
      color: theme.palette.brand.corePPTHeadlines,
    };
    setIsSidebarContentLoading(true);

    await createOption(newOption);
    await queryClient.invalidateQueries([getOptionsByFilterId.name, filter.id]);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmapId]);

    setIsSidebarContentLoading(false);
  };

  const getAddIcon = () => {
    let addIcon = AddNewCircleSVG;
    if (filter.shape === 'triangle') {
      addIcon = AddNewTriangleSVG;
    } else if (filter.shape === 'square') {
      addIcon = AddNewSquareSVG;
    }

    return (
      <StyledAddNewSVG
        src={addIcon}
        onClick={handleOptionAdd}
        $isTriangle={filter.shape === 'triangle'}
      />
    );
  };

  return (
    <div>
      <SelectTypeText>
        {filter.selection === 'single'
          ? t('editor.sidebar.filter.selection_type_single', { filterTitle: filter.title })
          : t('editor.sidebar.filter.selection_type_multi', { filterTitle: filter.title })}
      </SelectTypeText>
      {isCustomFilter(filter.title) && (
        <TitleWrapper>
          <AccordionSubContentText>{t('editor.sidebar.filter.title')}</AccordionSubContentText>
          <TitleInput
            type="text"
            value={inputValue}
            onChange={handleTitleEdit}
            onBlur={handleTitleInputBlur}
            onKeyDown={handleTitleInputKeyDown}
          />
        </TitleWrapper>
      )}
      <OptionWrapper>
        {filter.selection === 'single' &&
          options?.map(option => {
            return (
              <FilterSingleOption
                key={option.id}
                option={option}
                filterId={filter.id!}
                roadmapId={roadmapId}
                filterShape={filter.shape!}
              />
            );
          })}
        {filter.selection === 'multiple' &&
          options?.map(option => {
            return (
              <FilterMultiOption
                key={option.id}
                option={option}
                filterId={filter.id!}
                roadmapId={roadmapId}
                filterShape={filter.shape!}
              />
            );
          })}
      </OptionWrapper>
      {getAddIcon()}
      <ShapeSelectWrapper>
        <AccordionSubContentText>
          {t('editor.sidebar.filter.selection_shape')}
        </AccordionSubContentText>
        <ShapeImagesWrapper>
          {filter.shape === 'circle' ? (
            <StyledShapeSVGCheck src={CircleCheckIconSrc} />
          ) : (
            <StyledShapeSVG src={CircleIconSrc} onClick={() => handleShapeEdit('circle')} />
          )}
          {filter.shape === 'square' ? (
            <StyledShapeSVGCheck src={SquareCheckIconSrc} />
          ) : (
            <StyledShapeSVG src={SquareIconSrc} onClick={() => handleShapeEdit('square')} />
          )}
          {filter.shape === 'triangle' ? (
            <StyledShapeSVGCheck src={TriangleCheckIconSrc} />
          ) : (
            <StyledShapeSVG src={TriangleIconSrc} onClick={() => handleShapeEdit('triangle')} />
          )}
        </ShapeImagesWrapper>
      </ShapeSelectWrapper>
    </div>
  );
};

const SelectTypeText = styled.div`
  font-size: 1.2rem;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

const TitleWrapper = styled.div`
  margin: 0 1.5rem;
  margin-bottom: 2rem;
`;

const TitleInput = styled.input`
  outline: none;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1.5rem;
`;

const StyledAddNewSVG = styled(SVG)<{ $isTriangle: boolean }>`
  width: 10rem;
  height: 2.5rem;
  cursor: pointer;
  margin: 1rem 0.5rem;

  ${({ $isTriangle }) =>
    $isTriangle &&
    css`
      .st0 path {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      polygon {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      #Polygon_102 .st2 {
        fill-opacity: 0;
      }

      #Polygon_102 .st1 {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }
    `}

  ${({ $isTriangle }) =>
    !$isTriangle &&
    css`
      path {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      polygon {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      circle {
        fill-opacity: 0 !important;
      }
    `}
`;

const ShapeSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  align-items: center;
`;

const ShapeImagesWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const StyledShapeSVG = styled(SVG)`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  margin: 0 0.5rem;
`;

const StyledShapeSVGCheck = styled(SVG)`
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;

  g,
  polygon,
  rect,
  path {
    fill: ${({ theme }) => theme.palette.brand.editorTabPrimary}!important;
  }
`;

const AccordionSubContentText = styled.div`
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
`;

export { FiltersAccordionSubContent };
