import styled, { css } from 'styled-components';

import { components } from '@/types/api';

import { ImportListItem } from './ImportListItem';

const ImportList = ({
  title,
  onUpdateList,
  list,
  disabled,
}: {
  list: components['schemas']['Initiative'][];
  onUpdateList: (id: number, e?: React.DragEvent<HTMLDivElement>) => void;
  title: string;
  disabled?: boolean;
}) => {
  return (
    <ListContainer>
      <ListTitle>{title}</ListTitle>
      <ListWrapper $disabled={disabled}>
        {list.map(initiative => {
          return (
            <ImportListItem
              key={initiative.id}
              initiative={initiative}
              onUpdateList={onUpdateList}
            />
          );
        })}
      </ListWrapper>
    </ListContainer>
  );
};

export { ImportList };

const ListContainer = styled.div`
  width: 100%;
`;

const ListWrapper = styled.div<{ $disabled?: boolean }>`
  max-height: 200px;
  overflow-y: auto;
  padding: 1rem;
  background: ${({ theme }) => theme.palette.brand.backgroundSidebar};
  border-radius: 1rem;

  ${({ $disabled }) => css`
    pointer-events: ${$disabled ? 'none' : 'auto'};
    opacity: ${$disabled ? 0.5 : 1};
  `}
`;

const ListTitle = styled.div`
  font-size: 1.25rem;
  padding: 1rem;
`;
