import { Group, Rect, Text } from 'react-konva';
import { useTheme } from '@mui/material';

type Props = {
  height: number;
  isVisible: boolean;
  text: string;
  width: number;
  x: number;
  y: number;
  rotation?: number;
};

const Tooltip = ({ x, y, text, width, height, isVisible, rotation }: Props) => {
  const theme = useTheme();
  return (
    <Group visible={isVisible} x={x} y={y} rotation={360 - (rotation || 0)}>
      <Rect
        cornerRadius={[2, 2, 2, 2]}
        width={width}
        height={height}
        fill={theme.palette.brand.backgroundDialog}
        shadowColor="black"
        shadowBlur={5}
        shadowOpacity={0.3}
      />
      <Text
        text={text}
        align="center"
        verticalAlign="middle"
        width={width}
        padding={5}
        height={height}
        fontStyle="500"
        fontSize={8}
      />
    </Group>
  );
};

export { Tooltip };
