import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import { getRoadmapSections, updateSection } from '@/features/canvas/api';
import { SectionAreaShape } from '@/features/canvas/components/SectionAreaShape';
import { VerticalSectionTabShape } from '@/features/canvas/components/VerticalSectionTabShape';
import {
  AREA_SHAPE_CLIP_OFFSET,
  HORIZONTAL_OFFSET,
  TAB_HEIGHT,
  VERTICAL_OFFSET,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  canvasWidth: number;
  cornerRadius: number[];
  rotation: number;
  scaledVerticalSectionTabWidths: number[];
  sectionId: number;
  sectionIndex: number;
  sectionOrder: number;
  sectionOrientation: components['schemas']['RoadmapDetail']['sections'][0]['orientation'];
  sectionTabScaleProportion: number;
  sectionTitle: string;
  areaRotation?: number;
  y?: number;
};

const VerticalSection = ({
  sectionIndex,
  sectionTabScaleProportion,
  sectionId,
  rotation,
  areaRotation,
  sectionTitle,
  canvasWidth,
  cornerRadius,
  scaledVerticalSectionTabWidths,
  sectionOrientation,
  sectionOrder,
}: Props) => {
  const { addHistoryItem } = useUndoRedo();
  const theme = useTheme();
  const {
    draggedTabPositionChange,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    sortedVerticalSections,
    roadmap,
    setShouldRecalculateNodePositions,
    temporaryCreatedIds,
    updateLastSavedTime,
  } = useEditorContext();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const calcRectStartPrimaryAxisPosition =
    VERTICAL_OFFSET + scaledVerticalSectionTabWidths[sectionIndex];

  const { mutateAsync: updateSectionMutation } = useMutation(
    ({ sectionData }: { sectionData: components['schemas']['Section'] }) =>
      updateSection({ sectionId: Number(sectionData.id), newSectionData: sectionData }),
    { onSuccess: () => updateLastSavedTime() }
  );

  const shouldDisableActionMenuBecauseOfUsedTemporaryId =
    sectionId < 0 || temporaryCreatedIds.has(sectionId);

  const calculatedRectPrimaryAxisPosition = scaledVerticalSectionTabWidths.reduce(
    (axisPosition, tabWidthValue, currentIndex) => {
      if (currentIndex >= sectionIndex) {
        return axisPosition;
      }
      return axisPosition + tabWidthValue;
    },
    calcRectStartPrimaryAxisPosition
  );

  const isRemainderPositive = Boolean(sectionIndex % 2);
  const scaledTabWidth = scaledVerticalSectionTabWidths[sectionIndex];

  const onTabResizeDragEndHandler = useCallback(async () => {
    try {
      const newScaledTimePeriodWidths = scaledVerticalSectionTabWidths.map(
        (width, currentIndex) => {
          if (sectionIndex === currentIndex) {
            return width + draggedTabPositionChange;
          }
          if (sectionIndex + 1 === currentIndex) {
            return width - draggedTabPositionChange;
          }
          return width;
        }
      );

      const sectionsToUpdate = [
        sortedVerticalSections[sectionIndex],
        sortedVerticalSections[sectionIndex + 1],
      ].map(s => ({ ...s, roadmap_id: Number(roadmap.id) }));

      const newSectionsToUpdate = sectionsToUpdate.map((s, index) => {
        return {
          ...s,
          tabWidth: Math.round(
            newScaledTimePeriodWidths[sectionIndex + index] / sectionTabScaleProportion
          ),
        };
      });

      queryClient.setQueryData(
        [getRoadmapSections.name, roadmap.id],
        (oldSections?: components['schemas']['Section'][]) => {
          const newSections = (oldSections || []).map(s => {
            if (s.id === newSectionsToUpdate[0].id) {
              return newSectionsToUpdate[0];
            }
            if (s.id === newSectionsToUpdate[1].id) {
              return newSectionsToUpdate[1];
            }
            return s;
          });
          return newSections;
        }
      );

      const sectionUpdatePromises = newSectionsToUpdate.map(s =>
        updateSectionMutation({ sectionData: s })
      );

      await Promise.all(sectionUpdatePromises);

      addHistoryItem({
        type: HistoryActionType.MoveSection,
        resourceIds: [
          sortedVerticalSections[sectionIndex].id!,
          sortedVerticalSections[sectionIndex + 1].id!,
        ],
        undo: async (resourceIds?: number[]) => {
          const sectionsWithUpdatedResourceIds = sectionsToUpdate.map(s => {
            if (resourceIds && s.id === newSectionsToUpdate[0].id! && resourceIds[0]) {
              return { ...s, id: resourceIds[0] };
            }
            if (resourceIds && s.id === newSectionsToUpdate[1].id! && resourceIds[1]) {
              return { ...s, id: resourceIds[1] };
            }
            return s;
          });
          queryClient.setQueryData(
            [getRoadmapSections.name, roadmap.id],
            (oldSections?: components['schemas']['Section'][]) => {
              const newSections = (oldSections || []).map(s => {
                if (s.id === sectionsWithUpdatedResourceIds[0].id) {
                  return sectionsWithUpdatedResourceIds[0];
                }
                if (s.id === sectionsWithUpdatedResourceIds[1].id) {
                  return sectionsWithUpdatedResourceIds[1];
                }
                return s;
              });
              return newSections;
            }
          );

          await Promise.all(
            sectionsWithUpdatedResourceIds.map(s => updateSectionMutation({ sectionData: s }))
          );
          setShouldRecalculateNodePositions(true);
        },
        redo: async (resourceIds?: number[]) => {
          const sectionsWithUpdatedResourceIds = sectionsToUpdate.map(s => {
            if (resourceIds && s.id === newSectionsToUpdate[0].id! && resourceIds[0]) {
              return { ...s, id: resourceIds[0] };
            }
            if (resourceIds && s.id === newSectionsToUpdate[1].id! && resourceIds[1]) {
              return { ...s, id: resourceIds[1] };
            }
            return s;
          });
          queryClient.setQueryData(
            [getRoadmapSections.name, roadmap.id],
            (oldSections?: components['schemas']['Section'][]) => {
              const newSections = (oldSections || []).map(s => {
                if (s.id === sectionsWithUpdatedResourceIds[0].id) {
                  return sectionsWithUpdatedResourceIds[0];
                }
                if (s.id === sectionsWithUpdatedResourceIds[1].id) {
                  return sectionsWithUpdatedResourceIds[1];
                }
                return s;
              });
              return newSections;
            }
          );

          const newSectionUpdatePromises = sectionsWithUpdatedResourceIds.map(s =>
            updateSectionMutation({ sectionData: s })
          );

          await Promise.all(newSectionUpdatePromises);
          setShouldRecalculateNodePositions(true);
        },
      });

      setIsDraggingTab(false);
      setDraggedTabPositionChange(0);
      setShouldRecalculateNodePositions(true);
    } catch (error) {
      showToast('error', t('editor.canvas.edit_section_error'));
      await queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
      setIsDraggingTab(false);
    }
  }, [
    scaledVerticalSectionTabWidths,
    sortedVerticalSections,
    sectionIndex,
    queryClient,
    roadmap.id,
    addHistoryItem,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    setShouldRecalculateNodePositions,
    draggedTabPositionChange,
    sectionTabScaleProportion,
    updateSectionMutation,
    showToast,
    t,
  ]);

  const successorIdWithWidth =
    sectionIndex < scaledVerticalSectionTabWidths.length - 1
      ? {
          id: sortedVerticalSections[sectionIndex + 1].id,
          width: scaledVerticalSectionTabWidths[sectionIndex + 1],
        }
      : {
          id: undefined,
          width: 0,
        };

  return (
    <VerticalSectionTabShape
      key={sectionId}
      y={calculatedRectPrimaryAxisPosition}
      sectionId={sectionId}
      rotation={rotation}
      textColor={theme.palette.brand.white}
      sectionIndex={sectionIndex}
      sectionTitle={sectionTitle}
      sectionOrientation={sectionOrientation}
      sectionOrder={sectionOrder}
      verticalSectionTabWidth={scaledTabWidth}
      cornerRadius={cornerRadius}
      fill={
        sectionIndex % 2
          ? theme.palette.brand.editorTabSecondary
          : theme.palette.brand.editorTabPrimary
      }
      onDragEnd={onTabResizeDragEndHandler}
      shouldDisableActionMenuBecauseOfUsedTemporaryId={
        shouldDisableActionMenuBecauseOfUsedTemporaryId
      }
      successor={successorIdWithWidth}
    >
      <SectionAreaShape
        id={sectionId.toString()}
        rotation={areaRotation}
        startPointARelativeToGroup={{
          x: TAB_HEIGHT,
          y: 0 - scaledTabWidth,
        }}
        startPointBRelativeToGroup={{
          x: TAB_HEIGHT,
          y: 0,
        }}
        endPointARelativeToGroup={{
          x: canvasWidth + HORIZONTAL_OFFSET,
          y: -calculatedRectPrimaryAxisPosition + TAB_HEIGHT,
        }}
        {...(isRemainderPositive && {
          fill: theme.palette.brand.sunray,
          strokeWidth: 2,
        })}
        clipOffset={{
          a: { x: 0, y: -AREA_SHAPE_CLIP_OFFSET },
          b: { x: 0, y: AREA_SHAPE_CLIP_OFFSET },
        }}
      />
    </VerticalSectionTabShape>
  );
};

export { VerticalSection };
