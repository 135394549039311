import React, { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH } from '@/features/canvas/constants';
import { CustomFieldType } from '@/features/canvas/constants/initiative-field';
import { useReservedProperty } from '@/features/canvas/hooks/useReservedProperty';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

import { InitiativeCustom } from '../InitiativeCustom';

const InitiativeSmallCustom = ({
  readOnly,
  onDeleteField,
  onUpdateField,
  onClick,
  isLoading,
  field,
  isInCreationMode = false,
  isInitiativeLockedAndIsNotOwner,
}: {
  field: components['schemas']['InitiativeField'];
  isInitiativeLockedAndIsNotOwner: boolean;
  isLoading: boolean;
  onClick: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onUpdateField: (field: components['schemas']['InitiativeField']) => Promise<void>;
  readOnly: boolean;
  isInCreationMode?: boolean;
  onDeleteField?: (fieldId: number, e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const { reservedProperties } = useReservedProperty();

  const [inputValue, setInputValue] = useState<string>(field.value || '');

  const handleInputValueChange = (event: { target: { value: SetStateAction<string> } }) => {
    setInputValue(event.target.value);
  };

  const handleOnSubmit = async (title: string, type: keyof typeof CustomFieldType) => {
    const trimmedTitle = title.trim();

    if (
      isInCreationMode &&
      reservedProperties.some(property => property.title === trimmedTitle && property.type === type)
    ) {
      showToast('error', t('editor.sidebar.initiative.reserved_title_used'));
      return;
    }

    const updatedField = {
      ...field,
      value: inputValue,
      title: trimmedTitle,
      type: CustomFieldType.small,
    };

    await onUpdateField(updatedField);
  };

  return (
    <InitiativeCustom
      field={field}
      handleSubmit={handleOnSubmit}
      isLoading={isLoading}
      onDeleteField={onDeleteField}
      readOnly={readOnly}
      isInCreationMode={isInCreationMode}
      isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
    >
      <StyledInput
        readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
        type="text"
        value={inputValue || ''}
        onChange={handleInputValueChange}
        placeholder={t('editor.sidebar.initiative.max_char_count', {
          count: CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH,
        })}
        maxLength={CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH}
        onClick={onClick}
      />
    </InitiativeCustom>
  );
};

export { InitiativeSmallCustom };

const StyledInput = styled.input`
  outline: none;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;
