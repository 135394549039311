import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getNodesByFilterId(filterId: number) {
  const { data } = await defaultApi.get<components['schemas']['FilterNode'][]>(
    `/filternodes/filter/${filterId}`
  );
  return data;
}

export async function deleteNodeById(id: number) {
  return defaultApi.delete(`/filternodes/${id}`);
}

export async function createFilterNode({
  filterId,
  payload,
}: {
  filterId: number;
  payload: components['schemas']['FilterNode'];
}) {
  const { data } = await defaultApi.post<components['schemas']['FilterNode']>(
    `/filternodes/${filterId}`,
    [payload]
  );
  return data;
}
