import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export const FormInputError = ({
  children,
  fontSize,
  padding,
}: {
  children: ReactNode;
  fontSize?: string;
  padding?: number | string;
}) => {
  return (
    <StyledError padding={padding} fontSize={fontSize}>
      {children}
    </StyledError>
  );
};

const StyledError = styled.div<{ fontSize?: string; padding?: number | string }>`
  color: ${({ theme }) => theme.palette.brand.negativeStatus};
  font-size: ${({ fontSize }) => css`
    ${fontSize || '2rem'}
  `};
  padding: ${({ padding }) => css`
    ${padding || 0}
  `};
  margin-bottom: 0.5rem;
`;
