import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { ReactComponent as Plus } from '@/assets/icons/plus.svg';
import routes from '@/constants/routes';
import useLogout from '@/features/authentication/api/useLogout';
import { createNewRoadmap, getRoadmapList } from '@/features/roadmaps/api';
import { AccountIcon } from '@/features/ui/components/AccountIcon';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';
import { isRouteActive } from '@/utils/isRouteActive';

export const defaultRoadmap: components['schemas']['RoadmapDetail'] = {
  title: 'Untitled',
  updatedAt: dayjs().toString(),
  nodes: [],
  timePeriods: [
    {
      title: dayjs().year().toString(),
      tabWidth: 20,
      areaHeight: 10,
      order: 1,
    },
    {
      title: (dayjs().year() + 1).toString(),
      tabWidth: 30,
      areaHeight: 10,
      order: 2,
    },
    {
      title: (dayjs().year() + 2).toString(),
      tabWidth: 50,
      areaHeight: 10,
      order: 3,
    },
  ],
  sections: [
    {
      title: 'ENTER SECTION 1',
      tabWidth: 50,
      orientation: 'horizontal',
      order: 1,
    },
    {
      title: 'ENTER SECTION 2',
      tabWidth: 50,
      orientation: 'horizontal',
      order: 2,
    },
    {
      title: 'ENTER SECTION 3',
      tabWidth: 50,
      orientation: 'horizontal',
      order: 3,
    },

    {
      title: 'ENTER SECTION 4',
      tabWidth: 50,
      orientation: 'vertical',
      order: 2,
    },
    {
      title: 'ENTER SECTION 5',
      tabWidth: 50,
      orientation: 'vertical',
      order: 1,
    },
  ],
};

const Sidebar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showToast } = useShowToast();
  const { mutateAsync: logOut } = useLogout();
  const queryClient = useQueryClient();

  const { mutateAsync: createRoadmapMutation } = useMutation(
    () => createNewRoadmap(defaultRoadmap),
    {
      onSuccess: async roadmap => {
        showToast('success', t('roadmaps.create_success'));
        await queryClient.invalidateQueries(getRoadmapList.name);
        navigate(`${routes.roadmap}/${roadmap.id}`);
      },
      onError: () => {
        showToast('error', t('roadmaps.create_error'));
      },
    }
  );

  return (
    <SidebarContainer>
      <SidebarContent>
        <SidebarLink
          to={routes.account}
          $shouldHighlightLink={isRouteActive({
            currentRoute: pathname,
            targetRoute: routes.account,
          })}
        >
          <AccountContainer>
            <IconWrapper
              $shouldHighlightLink={isRouteActive({
                currentRoute: pathname,
                targetRoute: routes.account,
              })}
            >
              <AccountIcon />
            </IconWrapper>

            <SidebarLinkText>{t('account')}</SidebarLinkText>
          </AccountContainer>
        </SidebarLink>

        <StyledButton onClick={() => createRoadmapMutation()} startIcon={<Plus />}>
          <div>{t('new_project')}</div>
        </StyledButton>
        <SidebarLink
          to={routes.roadmaps}
          $shouldHighlightLink={isRouteActive({
            currentRoute: pathname,
            targetRoute: routes.roadmaps,
          })}
        >
          <SidebarLinkText>{t('your_documents')}</SidebarLinkText>
        </SidebarLink>
        <SidebarLink
          to={routes.publicLinks}
          $shouldHighlightLink={isRouteActive({
            currentRoute: pathname,
            targetRoute: routes.publicLinks,
          })}
        >
          <SidebarLinkText>{t('manage_links')}</SidebarLinkText>
        </SidebarLink>
        <SidebarLink
          to={routes.support}
          $shouldHighlightLink={isRouteActive({
            currentRoute: pathname,
            targetRoute: routes.support,
          })}
        >
          <SidebarLinkText>{t('support.title')}</SidebarLinkText>
        </SidebarLink>
        <SidebarLink to="#" onClick={() => logOut()}>
          <SidebarLinkText>{t('log_out')}</SidebarLinkText>
        </SidebarLink>
      </SidebarContent>
      <Copyright>
        {t('copyright', {
          currentYear: dayjs().year(),
        })}
      </Copyright>
    </SidebarContainer>
  );
};

const StyledButton = styled(Button)`
  border-radius: 4rem;
  padding: 0.2rem 3rem 0.2rem 3rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-weight: 500;
  font-size: 2rem;
  transition: background-color 0.2s ease-in-out;
  text-transform: none;

  background-color: ${({ theme }) => theme.palette.brand.primary};
  color: ${({ theme }) => theme.palette.brand.white};

  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.secondary)};
  }

  svg {
    path {
      fill: ${({ theme }) => darken(0.1, theme.palette.brand.white)};
    }
  }
`;

const SidebarContent = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 4rem;
`;

const SidebarContainer = styled.div`
  margin-top: 60px;
  /* 60 margin 60 header height = 180px */
  height: calc(100vh - 120px);
  height: calc(100dvh - 120px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
  width: calc(280px - 4rem); // since I decreased the padding as well for mobile version

  /* 100px was added so RoadmapsHomepage won't make weird jumps on width resize */
  @media (min-width: calc(${({ theme }) => theme.breakpoints.values.xl}px + 100px)) {
    padding: 0 4rem;
    width: 280px;

    margin-top: 120px;
    /* 120 margin 60 header height = 180px */
    height: calc(100vh - 180px);
    height: calc(100dvh - 180px);
  }
`;

const SidebarLink = styled(Link)<{ $shouldHighlightLink?: boolean }>`
  color: ${({ theme }) => theme.palette.brand.textPrimary};

  ${({ $shouldHighlightLink }) =>
    $shouldHighlightLink &&
    css`
      color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
    `}
`;

const SidebarLinkText = styled.div`
  font-weight: 600;
  font-size: 2rem;
`;

const IconWrapper = styled.div<{ $shouldHighlightLink: boolean }>`
  margin-right: 0.75rem;
  svg {
    fill: ${({ theme }) => transparentize(0.4, theme.palette.brand.textPrimary)};
  }
  ${({ $shouldHighlightLink }) =>
    $shouldHighlightLink &&
    css`
      svg {
        fill: ${({ theme }) => theme.palette.brand.secondary};
      }
    `}
`;

const Copyright = styled.div`
  bottom: 4rem;
  position: fixed;
  font-size: 1.25rem;

  color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

export const AccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default Sidebar;
