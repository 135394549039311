import axios from 'axios';

// https://www.w3schools.com/js/js_cookies.asp#:%7E:text=a%20cookie%20value-,A%20Function%20to%20Set%20a%20Cookie,-First%2C%20we%20create
const getCookie = (cname: string) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const createApi = () => {
  const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  const api = axios.create({
    baseURL:
      process.env.REACT_APP_ENVIRONMENT === 'dev'
        ? 'https://konvergent-api-dev.azurewebsites.net/api'
        : 'https://konvergent-api-prod.azurewebsites.net/api',
    withCredentials: true,
    ...(development
      ? {
          headers: {
            OriginOverride: 'https://ambitious-pebble-087fedc03.3.azurestaticapps.net',
          },
        }
      : {}),
  });

  api.interceptors.request.use(
    ({ ...config }) => {
      // if (development) console.log(config.method, config.url);
      return {
        ...config,
      };
    },
    error => Promise.reject(error)
  );

  api.interceptors.response.use(response => {
    if (development && response?.request?.hasError && response.status === 200) {
      const errorString = `special error detected for ${response?.config?.url}, throwing error: ${response?.data}`;
      // console.warn(errorString, '_hasError', response.request.hasError);
    }
    return response;
  });

  api.interceptors.request.use(function (config) {
    const token = getCookie('user');
    if (config.headers) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }

    return config;
  });

  return api;
};

export const defaultApi = createApi();
