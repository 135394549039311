import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { IconButton, Tooltip } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as BinIcon } from '@/assets/icons/bin.svg';
import { ReactComponent as EyePasswordIcon } from '@/assets/icons/eye.svg';
import { ReactComponent as EyePasswordCrossedIcon } from '@/assets/icons/eye-crossed.svg';
import {
  getAllRoadmapNodes,
  getInitiativeById,
  handleLockingInitiative,
  handleNodeVisibility,
  updateInitiative,
} from '@/features/canvas/api';
import { LockElement } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeTitle/LockElement';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

const InitiativeTitle = ({
  initiative,
  setIsBasicDeleteInitiativeDialogOpen,
  setIsDualChoiceDeleteInitiativeDialogOpen,
  isInitiativeOwner,
  isRoadmapOwner,
  isRoadmapEditor,
  isInitiativeLockedAndIsNotOwner,
}: {
  initiative: components['schemas']['Initiative'];
  isInitiativeLockedAndIsNotOwner: boolean;
  isInitiativeOwner: boolean;
  isRoadmapEditor: boolean;
  isRoadmapOwner: boolean;
  setIsBasicDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDualChoiceDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [inputValue, setInputValue] = useState<string>(initiative.title || '');
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();
  const { setFilteredNodes, filteredNodes, roadmap, selectedNodeId, updateLastSavedTime } =
    useEditorContext();
  const [visible, setVisible] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const isNotOwnerNorEditor = !isRoadmapOwner && !isRoadmapEditor;
  const isReadonly = isNotOwnerNorEditor || isInitiativeLockedAndIsNotOwner;

  const isInitiativeLocked = Boolean(initiative?.isLocked);

  const canDeleteLockedInitiative = isInitiativeLocked && isInitiativeOwner;
  const canDeleteUnlockedInitiative =
    !isInitiativeLocked && (isInitiativeOwner || isRoadmapOwner || isRoadmapEditor);

  const { mutateAsync: updateInitiativeMutation } = useMutation(updateInitiative, {
    onError: async () => {
      await queryClient.invalidateQueries([getAllRoadmapNodes.name, roadmap.id]);
      showToast('error', t('editor.sidebar.initiative.edit_field_error'));
    },
    onMutate: () => {
      const updatedField = { ...initiative, title: inputValue };
      queryClient.setQueryData([getAllRoadmapNodes.name, roadmap.id], () => {
        const updatedNodes = filteredNodes.map(node => {
          if (node.initiatives?.id === initiative.id) {
            return { ...node, initiatives: updatedField };
          }
          return node;
        });
        return updatedNodes;
      });

      updateLastSavedTime();
    },
  });

  const { mutateAsync: updateNodeVisibility } = useMutation(handleNodeVisibility, {
    onSuccess: ({ isTimelineVisible }) => {
      const newFilteredNodes = filteredNodes.map(node => {
        if (node.initiatives?.id === initiative.id) {
          setVisible(isTimelineVisible);
          return { ...node, isVisible: isTimelineVisible };
        }
        return node;
      });
      setFilteredNodes(newFilteredNodes);
      updateLastSavedTime();

      if (isTimelineVisible) {
        showToast('success', t('editor.sidebar.initiative.initiative_reveal_success'));
      } else {
        showToast('success', t('editor.sidebar.initiative.initiative_hide_success'));
      }
    },
    onError: () => {
      showToast('error', t('editor.sidebar.initiative.edit_field_error'));
    },
  });

  const { mutateAsync: handleLockingInitiativeMutation, isLoading: isHandlingLockingInitiative } =
    useMutation(handleLockingInitiative, {
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries([getInitiativeById.name, selectedNodeId]);
        if (variables.isLocked) {
          showToast('success', t('editor.sidebar.initiative.lock_initiative_success'));
        } else {
          showToast('success', t('editor.sidebar.initiative.unlock_initiative_success'));
        }
      },
      onError: () => {
        showToast('error', t('editor.sidebar.initiative.edit_initiative_error'));
      },
    });

  const handleInputChange = (event: { target: { value: SetStateAction<string> } }) => {
    setInputValue(event.target.value);
  };

  const handleBlur = async () => {
    if (!inputValue || isReadonly) {
      return;
    }
    const updatedField = { ...initiative, title: inputValue };
    await updateInitiativeMutation({
      initiativeBody: updatedField,
      initiativeId: Number(initiative.id),
    });
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      inputRef.current?.blur();
    }
  };

  const handleInitiativeDelete = () => {
    const isInitiativeUsedInOtherRoadmaps = initiative.isUsedInOtherRoadmaps;
    if (isInitiativeOwner && isInitiativeUsedInOtherRoadmaps) {
      setIsDualChoiceDeleteInitiativeDialogOpen(true);
      return;
    }
    setIsBasicDeleteInitiativeDialogOpen(true);
  };

  useEffect(() => {
    const node = filteredNodes.find(n => n.initiatives?.id === initiative.id);
    setVisible(node?.isVisible ?? false);
  }, [initiative.id, filteredNodes]);

  const getLockTooltipTitle = () => {
    if (!isInitiativeOwner && isInitiativeLocked) {
      return t('editor.sidebar.initiative.initiative_locked_status');
    }

    if (isInitiativeOwner && isInitiativeLocked) {
      return t('editor.sidebar.initiative.unlock_initiative_tooltip');
    }
    if (isInitiativeOwner && !isInitiativeLocked) {
      return t('editor.sidebar.initiative.lock_initiative_tooltip');
    }
    return null;
  };

  const tooltipTitle = getLockTooltipTitle();

  return (
    <TitleWrapper>
      <TitleField>
        <div>{t('editor.sidebar.initiative.title')}</div>
        <MainActions>
          {(isInitiativeOwner || isInitiativeLocked) && (
            <LockIconButton
              $isInitiativeOwner={isInitiativeOwner}
              onClick={() => {
                if (isInitiativeOwner) {
                  return handleLockingInitiativeMutation({
                    id: initiative.id!,
                    isLocked: !isInitiativeLocked,
                  });
                }
              }}
              aria-label="lock"
              size="small"
            >
              <Tooltip title={tooltipTitle} placement="top">
                <LockElement
                  isHandlingLockingInitiative={isHandlingLockingInitiative}
                  isInitiativeLocked={isInitiativeLocked}
                />
              </Tooltip>
            </LockIconButton>
          )}
          <EyeIconButton
            aria-label="toggle-hide"
            size="small"
            onClick={() =>
              updateNodeVisibility({ nodeId: selectedNodeId!, isTimelineVisible: !visible })
            }
          >
            <Tooltip
              title={
                visible
                  ? t('editor.sidebar.initiative.hide_filter_tooltip')
                  : t('editor.sidebar.initiative.show_filter_tooltip')
              }
              placement="top"
            >
              {visible ? <EyePasswordIcon /> : <EyePasswordCrossedIcon />}
            </Tooltip>
          </EyeIconButton>
          {(canDeleteLockedInitiative || canDeleteUnlockedInitiative) && (
            <BinIconButton onClick={handleInitiativeDelete} aria-label="delete" size="small">
              <Tooltip
                title={t('editor.sidebar.initiative.delete_initiative_tooltip')}
                placement="top"
              >
                <BinIcon />
              </Tooltip>
            </BinIconButton>
          )}
        </MainActions>
      </TitleField>
      <InputWrapper>
        <TitleInput
          readOnly={isReadonly}
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          maxLength={32}
          minLength={1}
        />
      </InputWrapper>
    </TitleWrapper>
  );
};

export { InitiativeTitle };

const TitleWrapper = styled.div`
  padding: 1rem 0;
`;

const TitleField = styled.div`
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
`;

const MainActions = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
`;

const InputWrapper = styled.div`
  padding: 0 1rem;
`;

const TitleInput = styled.input`
  outline: none;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const LockIconButton = styled(IconButton)<{ $isInitiativeOwner: boolean }>`
  cursor: ${({ $isInitiativeOwner }) => ($isInitiativeOwner ? 'pointer' : 'default')};
  svg {
    overflow: visible;
    path {
      fill: ${({ theme }) => theme.palette.brand.textPrimary};
    }
  }
  :hover {
    svg {
      overflow: visible;
      path {
        fill: ${({ theme }) => theme.palette.brand.editorTabSecondary};
      }
    }
  }
`;

const EyeIconButton = styled(IconButton)`
  svg {
    overflow: visible;
    path {
      stroke: ${({ theme }) => theme.palette.brand.textPrimary};
    }
    circle {
      stroke: ${({ theme }) => theme.palette.brand.textPrimary};
    }
  }
  :hover {
    svg {
      overflow: visible;
      path {
        stroke: ${({ theme }) => theme.palette.brand.editorTabSecondary};
      }
      circle {
        stroke: ${({ theme }) => theme.palette.brand.editorTabSecondary};
      }
    }
  }
`;

const BinIconButton = styled(IconButton)`
  padding-bottom: 0.75rem;
  svg {
    overflow: visible;
    path {
      stroke: ${({ theme }) => theme.palette.brand.textPrimary};
    }
  }
  :hover {
    svg {
      overflow: visible;
      path {
        stroke: ${({ theme }) => theme.palette.brand.editorTabSecondary};
      }
    }
  }
`;
