import React, { Dispatch, forwardRef, memo, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { ReactComponent as BinIconSrc } from 'src/assets/icons/bin.svg';
import { ReactComponent as DuplicateIconSrc } from 'src/assets/icons/duplicate.svg';
import { ReactComponent as LinkIconSrc } from 'src/assets/icons/link.svg';
import { ReactComponent as ManagePermissionsIcon } from 'src/assets/icons/permissions.svg';
import { ReactComponent as ProjectThumbnailIconSrc } from 'src/assets/images/roadmapIcon.svg';
import styled from 'styled-components';

import { DATE_FORMAT } from '@/constants/common';
import routes from '@/constants/routes';
import { useAccount } from '@/contexts/account-context';
import { createRoadmapPublicLinkUrl } from '@/features/canvas/utils/create-roadmap-public-link-url';
import { duplicateRoadmap, getRoadmapList } from '@/features/roadmaps/api';
import { useHandleCopyUrl } from '@/hooks/useHandleCopyUrl';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

interface ProjectItemProps {
  roadmap: components['schemas']['Roadmap'];
  setIsDeleteProjectDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsManageRoadmapPermissionsDialogOpened: Dispatch<SetStateAction<boolean>>;
  setRoadmapOwner: Dispatch<SetStateAction<null | { id: number; userName: string }>>;
  setSelectedRoadMapId: Dispatch<SetStateAction<number | null>>;
}

const ProjectItem = forwardRef<HTMLDivElement, ProjectItemProps>((props, ref) => {
  const {
    roadmap,
    setIsDeleteProjectDialogOpen,
    setSelectedRoadMapId,
    setIsManageRoadmapPermissionsDialogOpened,
    setRoadmapOwner,
  } = props;
  const queryClient = useQueryClient();
  const { handleCopyUrl } = useHandleCopyUrl();
  const { showToast } = useShowToast();
  const navigate = useNavigate();
  const account = useAccount();

  const { t } = useTranslation();

  const isRoadmapOwner =
    roadmap.ownerUserId === account?.id && roadmap.grantedPermissionType === 'roadmap_owner';
  const isRoadmapEditor = roadmap.grantedPermissionType === 'roadmap_edit';

  const { mutateAsync: handleDuplicateProject } = useMutation(duplicateRoadmap, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getRoadmapList.name);
      showToast('success', t('roadmaps.duplicate_success'));
    },
    onError: () => {
      showToast('error', t('roadmaps.duplicate_error'));
    },
  });

  const handleCopyURL = useCallback(
    (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      if (!roadmap?.publicLinkUrl) return;
      const publicLinkUrl = createRoadmapPublicLinkUrl(roadmap.publicLinkUrl);
      void handleCopyUrl({ url: publicLinkUrl });
    },
    [handleCopyUrl, roadmap?.publicLinkUrl]
  );

  const handleDuplicate = useCallback(
    async (event: React.MouseEvent<SVGSVGElement>, roadmapId: number) => {
      event.stopPropagation();
      await handleDuplicateProject(roadmapId);
    },
    [handleDuplicateProject]
  );

  const handleDelete = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      event.stopPropagation();
      setSelectedRoadMapId(Number(roadmap.id));
      setIsDeleteProjectDialogOpen(true);
    },
    [setIsDeleteProjectDialogOpen, roadmap.id, setSelectedRoadMapId]
  );

  return (
    <ProjectItemWrapper
      ref={ref}
      onClick={() => {
        navigate(`${routes.roadmap}/${roadmap.id}`);
      }}
    >
      <LeftSideWrapper>
        <TextContent>
          <Title>{roadmap.title}</Title>
          <SubContentWrapper>
            <SubTitle>
              {t('roadmaps.last_edited_at', {
                date: dayjs(roadmap.updatedAt).format(DATE_FORMAT),
              })}
            </SubTitle>
            {roadmap.OwnerFullName && (
              <SubTextWrapper>
                {t('roadmaps.created_by', {
                  name: roadmap.OwnerFullName,
                })}
              </SubTextWrapper>
            )}
          </SubContentWrapper>
        </TextContent>
        <Actions>
          {roadmap.publicLinkUrl && (
            <Tooltip title={t('roadmaps.tooltip.copy_link')} placement="top">
              <IconWrapper>
                <LinkIconSrc onClick={e => handleCopyURL(e)} />
              </IconWrapper>
            </Tooltip>
          )}
          <Tooltip title={t('roadmaps.tooltip.duplicate_project')} placement="top">
            <IconWrapper>
              <DuplicateIconSrc onClick={e => handleDuplicate(e, Number(roadmap.id))} />
            </IconWrapper>
          </Tooltip>
          {(isRoadmapOwner || isRoadmapEditor) && (
            <Tooltip title={t('roadmaps.tooltip.manage_roadmap_permissions')} placement="top">
              <IconWrapper
                onClick={e => {
                  e.stopPropagation();
                  setSelectedRoadMapId(Number(roadmap.id));
                  setRoadmapOwner({ id: roadmap!.ownerUserId!, userName: roadmap.OwnerFullName! });
                  setIsManageRoadmapPermissionsDialogOpened(true);
                }}
              >
                <ManagePermissionsIcon />
              </IconWrapper>
            </Tooltip>
          )}
          {isRoadmapOwner && (
            <Tooltip title={t('roadmaps.tooltip.delete_link')} placement="top">
              <IconWrapper>
                <BinIconSrc onClick={e => handleDelete(e)} />
              </IconWrapper>
            </Tooltip>
          )}
        </Actions>
      </LeftSideWrapper>
      <ImageWrapper>
        <ProjectThumbnailIconSrc />
      </ImageWrapper>
    </ProjectItemWrapper>
  );
});

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;

  width: 2rem;
  height: 2rem;

  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    path,
    line,
    rect {
      stroke: ${({ theme }) => theme.palette.brand.primary};
    }
  }

  &:hover {
    svg {
      path,
      line,
      rect {
        stroke: ${({ theme }) => theme.palette.brand.editorTabPrimary};
      }
    }
  }
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.palette.brand.secondary};
  font-weight: 600;
  font-size: 1.25rem;
`;

const SubTextWrapper = styled.div`
  color: ${({ theme }) => theme.palette.brand.secondary};
  font-weight: 600;
  font-size: 1rem;
`;

const SubContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.palette.brand.primary};
  font-weight: 600;
  font-size: 2rem;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
`;

const ImageWrapper = styled.div`
  flex: 1;
  height: 100%;
  max-width: 200px;
`;

const ProjectItemWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.brand.white};
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  max-height: 250px;

  cursor: pointer;
  &:hover {
    transition: 0.2s;
    svg {
      rect {
        fill: ${({ theme }) => theme.palette.brand.sunray};
      }

      #Group_19791 * {
        fill: ${({ theme }) => theme.palette.brand.editorTabPrimary};
      }
    }
  }
`;

const LeftSideWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export default memo(ProjectItem);
