export type FilterToBeCreatedByImport = {
  id: string;
  title: string;
};

export const FilterTypes = {
  PRIORITY: 'Priority',
  RAG: 'RAG',
  PROGRESS_STATUS: 'Progress status',
  CUSTOM_SINGLE: 'Custom single',
  CUSTOM_MULTI: 'Custom multi',
};
