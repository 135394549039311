import StyledText, { StyledTextProps } from './Text.styled';

export type TextProps = StyledTextProps & {
  text: string;
  style?: React.CSSProperties;
};

const Text: React.FC<TextProps> = props => {
  const { text, style, ...rest } = props;
  return (
    <StyledText {...rest} style={style}>
      {text}
    </StyledText>
  );
};
export default Text;
