import { SUPPORTED_CURRENCIES } from '@/constants/common';

export const extractCurrencyAndValue = ({
  budgetWithCurrency,
}: {
  budgetWithCurrency?: string;
}) => {
  const budgetWithCurrencyValue = budgetWithCurrency || '';
  const defaultCurrency = SUPPORTED_CURRENCIES[0].id;
  let currencyId = defaultCurrency;

  const currencyCode = budgetWithCurrencyValue.match(/(USD|AUD|EUR|CAD|GBP)?/i)?.[0];
  const currencyInfo = SUPPORTED_CURRENCIES.find(c => c.currency === currencyCode);
  if (currencyInfo) {
    currencyId = currencyInfo.id;
  }

  const numberValue = budgetWithCurrencyValue.replace(currencyCode || '', '');

  return {
    value: numberValue,
    currencyId,
  };
};
