export const variables = {
  device: {
    mobile: `(min-width: 425px)`,
    tablet: `(min-width: 768px)`,
    laptop: `(min-width: 1024px)`,
    desktop: `(min-width: 1440px)`,
  },
};

export const BRAND_COLORS = {
  backgroundMain: '#F4F4F4',
  backgroundHeader: '#FAFAFA',
  backgroundSidebar: '#FFFFFF',
  backgroundDialog: '#DCDCDC',
  backgroundIcon: '#205499',
  backgroundInputFocus: '#F2F8FF',
  buttonPrimaryHover: '#DFE6EC',
  buttonPrimaryFilterHover:
    'sepia(100%) brightness(70%) hue-rotate(251deg) saturate(801%) contrast(101%) invert(33%);',
  buttonSecondaryHover: '#00386B',
  buttonTertiaryHover: '#9E0600',
  primary: '#363649',
  secondary: '#6E6E72',
  tertiary: '#E52922',
  textPrimary: '#142E38',
  textSecondary: '#6B6B6B',
  textSecondaryFiltered: 'rgba(107,107,107,0.15)',
  textSecondaryFilter:
    'invert(43%) sepia(27%) saturate(10%) hue-rotate(343deg) brightness(88%) contrast(77%);',
  corePPTHeadlines: '#00539F',
  corePPTCopy: '#6e6767',
  corePPTAction: '#EE1C2D',
  corePPTWhite: '#F6F6F6',
  white: '#FFFFFF',
  black: '#000000',
  positiveStatus: '#4CC86D',
  negativeStatus: '#EE1C2D',
  inProgressStatus: '#FFA626',
  otherStatus: '#00B0F0',
  editorTabPrimary: '#00539F',
  editorTabSecondary: '#1075D2',
  progressBar: '#6E6E72',
  sunray: '#F2F8FF',
};

export const BRAND_SHADOWS = [`rgba(99, 99, 99, 0.2) 0 0.2rem 0.75rem 0`];
