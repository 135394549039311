/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-statements */
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from 'react-konva';
import { Portal, useImage } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import BinIconSrc from '@/assets/icons/bin.svg';
import PencilIconSrc from '@/assets/icons/pencil.svg';
import ThinPlus from '@/assets/icons/thin-plus-primary.svg';
import {
  createNewTimePeriod,
  deleteTimePeriod,
  getRoadmapTimePeriods,
  updateTimePeriod,
} from '@/features/canvas/api';
import { TabActionMenuItem } from '@/features/canvas/components/TabActionMenuItem';
import {
  DEFAULT_NEW_TAB_HEIGHT_INCREMENT,
  DEFAULT_NEW_TAB_WIDTH,
  MAX_TIME_PERIODS,
  MIN_TIME_PERIODS,
  TAB_ACTION_MENU_HEIGHT,
  TAB_ACTION_MENU_ICON_SIZE,
  // TAB_ACTION_MENU_ITEM_MARGIN,
  // TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT,
  // TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH,
  TAB_ACTION_MENU_WIDTH,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  id: number;
  isHoverActive: boolean;
  setIsEditingTitle: Dispatch<SetStateAction<boolean>>;
  setIsHoveringAction: Dispatch<SetStateAction<boolean>>;
  shouldDisableActionMenuBecauseOfUsedTemporaryId: boolean;
  timePeriodAreaHeight: number;
  timePeriodIndex: number;
  timePeriodOrder: number;
  timePeriodTabWidth: number;
  x: number;
  y: number;
};

const TimePeriodActionMenu = ({
  setIsEditingTitle,
  setIsHoveringAction,
  timePeriodTabWidth,
  timePeriodAreaHeight,
  x,
  y,
  id,
  timePeriodOrder,
  timePeriodIndex,
  isHoverActive,
  shouldDisableActionMenuBecauseOfUsedTemporaryId,
}: Props) => {
  const theme = useTheme();
  const [pencilIcon] = useImage(PencilIconSrc);
  const [binIcon] = useImage(BinIconSrc);
  const [plusIcon] = useImage(ThinPlus);
  const { addHistoryItem, updateResourceId } = useUndoRedo();

  const {
    roadmap,
    sortedTimePeriods,
    generateUniqueIntTemporaryId,
    temporaryCreatedIds,
    setShouldRecalculateNodePositions,
    sortedTimePeriodsRef,
    updateLastSavedTime,
  } = useEditorContext();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { showToast } = useShowToast();

  const { mutateAsync: createTimePeriod } = useMutation(
    ({
      newTimePeriod,
    }: {
      newTimePeriod: components['schemas']['TimePeriod'];
      newTimePeriodTempId: number;
    }) => createNewTimePeriod({ newTimePeriod }),
    {
      onSuccess: ({ id: tpId }, { newTimePeriod, newTimePeriodTempId }) => {
        const updatedTimePeriod = { ...newTimePeriod, id: tpId };
        queryClient.setQueryData(
          [getRoadmapTimePeriods.name, roadmap.id],
          (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
            if (oldTimePeriods) {
              return oldTimePeriods.map(tp => {
                if (tp.id === newTimePeriodTempId) {
                  temporaryCreatedIds.delete(newTimePeriodTempId);
                  return { ...updatedTimePeriod };
                }
                return tp;
              });
            }
            return [];
          }
        );
        setShouldRecalculateNodePositions(true);
        updateLastSavedTime();
      },
      onError: () => {
        showToast('error', t('editor.canvas.add_time_period_error'));
        return queryClient.invalidateQueries([getRoadmapTimePeriods.name, roadmap.id]);
      },
    }
  );

  const { mutateAsync: updateTimePeriodMutation } = useMutation(
    ({ newTimePeriodData }: { newTimePeriodData: components['schemas']['TimePeriod'] }) =>
      updateTimePeriod({ newTimePeriodData, timePeriodId: Number(newTimePeriodData.id) }),
    {
      onSuccess: () => updateLastSavedTime(),
      onError: () => {
        showToast('error', t('editor.canvas.edit_time_period_error'));
        return queryClient.invalidateQueries([getRoadmapTimePeriods.name, roadmap.id]);
      },
    }
  );

  const { mutateAsync: deleteTimePeriodMutation } = useMutation(
    (timePeriodId: number) => deleteTimePeriod({ timePeriodId }),
    {
      onMutate: timePeriodId => {
        queryClient.setQueryData(
          [getRoadmapTimePeriods.name, roadmap.id],
          (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
            updateLastSavedTime();
            if (oldTimePeriods) {
              return oldTimePeriods.filter(tp => Number(tp.id) !== Number(timePeriodId));
            }
            return [];
          }
        );
      },
      onSuccess: () => {
        setShouldRecalculateNodePositions(true);
        updateLastSavedTime();
      },
      onError: () => {
        showToast('error', t('editor.canvas.delete_time_period_error'));
        return queryClient.invalidateQueries([getRoadmapTimePeriods.name, roadmap.id]);
      },
    }
  );

  const addTimePeriod = useCallback(
    async (currentTimePeriod?: components['schemas']['TimePeriod']) => {
      const indexToIncrementFrom = sortedTimePeriods.findIndex(s => s.order === timePeriodOrder);

      let newTimePeriodOrder = 0;
      let newOrder = 1;
      const timePeriodsWithIncrementedOrder = sortedTimePeriods.map((s, index) => {
        let timePeriod = { ...s, order: newOrder };
        if (index > indexToIncrementFrom) {
          timePeriod = { ...s, order: newOrder + 1 };
        }
        if (index === indexToIncrementFrom) {
          newTimePeriodOrder = newOrder + 1;
        }
        newOrder += 1;
        return timePeriod;
      });

      const timePeriodsToUpdate = currentTimePeriod
        ? sortedTimePeriodsRef.current.filter(tp => tp.id !== currentTimePeriod.id)
        : timePeriodsWithIncrementedOrder;

      const newTimePeriodTempId = generateUniqueIntTemporaryId();

      const newTimePeriod = {
        id: newTimePeriodTempId,
        title: `ENTER TIME PERIOD ${sortedTimePeriods.length + 1}`,
        tabWidth: DEFAULT_NEW_TAB_WIDTH,
        areaHeight: Math.round(timePeriodAreaHeight + DEFAULT_NEW_TAB_HEIGHT_INCREMENT),
        order: newTimePeriodOrder,
        roadmap_id: Number(roadmap.id),
      };

      const updatedTimePeriodPromises = timePeriodsToUpdate.map(tp =>
        updateTimePeriodMutation({ newTimePeriodData: { ...tp, roadmap_id: Number(roadmap.id) } })
      );

      queryClient.setQueryData(
        [getRoadmapTimePeriods.name, roadmap.id],
        [...timePeriodsToUpdate, currentTimePeriod || newTimePeriod]
      );

      await Promise.all(updatedTimePeriodPromises);
      const createdTimePeriod = await createTimePeriod({
        newTimePeriod: currentTimePeriod || newTimePeriod,
        newTimePeriodTempId: currentTimePeriod?.id || newTimePeriodTempId,
      });

      return createdTimePeriod;
    },
    [
      createTimePeriod,
      generateUniqueIntTemporaryId,
      queryClient,
      roadmap.id,
      sortedTimePeriods,
      sortedTimePeriodsRef,
      timePeriodAreaHeight,
      timePeriodOrder,
      updateTimePeriodMutation,
    ]
  );

  const removeTimePeriod = useCallback(async () => {
    await deleteTimePeriodMutation(id);

    addHistoryItem({
      type: HistoryActionType.DeleteTimePeriod,
      async undo(resourceIds?: number[]) {
        const currentTimePeriod = sortedTimePeriods.find(
          (tp, index) => tp.id === Number(resourceIds?.[0] || id) || index === timePeriodIndex
        )!;

        const newCreatedTimePeriod = await addTimePeriod({
          ...currentTimePeriod,
          roadmap_id: roadmap.id!,
        });

        updateResourceId(this.type, resourceIds?.[0] || id, newCreatedTimePeriod.id!);
        this.redo = async (redoResourceIds?: number[]) => {
          await deleteTimePeriodMutation(redoResourceIds?.[0] || newCreatedTimePeriod.id!);
        };
      },
      redo: async (resourceIds?: number[]) => {
        await deleteTimePeriodMutation(resourceIds?.[0] || id);
      },
      resourceIds: [id],
    });
  }, [
    deleteTimePeriodMutation,
    id,
    addHistoryItem,
    sortedTimePeriods,
    addTimePeriod,
    roadmap.id,
    updateResourceId,
    timePeriodIndex,
  ]);

  const actionMenuItems = useMemo(
    () =>
      [
        {
          id: 1,
          tooltipText: t('editor.canvas.edit_tooltip'),
          icon: pencilIcon,
          onClick: () => {
            setIsEditingTitle(true);
          },
          disabled: shouldDisableActionMenuBecauseOfUsedTemporaryId,
        },
        {
          id: 2,
          tooltipText: t('editor.canvas.delete_tooltip'),
          icon: binIcon,
          onClick: removeTimePeriod,
          disabled:
            sortedTimePeriods.length <= MIN_TIME_PERIODS ||
            shouldDisableActionMenuBecauseOfUsedTemporaryId,
        },
        {
          id: 3,
          tooltipText: t('editor.canvas.add_tooltip'),
          icon: plusIcon,
          onClick: async () => {
            const createdTimePeriod = await addTimePeriod();

            addHistoryItem({
              type: HistoryActionType.AddTimePeriod,
              undo: async (resourceIds?: number[]) => {
                await deleteTimePeriodMutation(resourceIds?.[0] || createdTimePeriod.id!);
              },
              async redo(redoResourceIds?: number[]) {
                const newCreatedTimePeriod = await addTimePeriod(createdTimePeriod);

                updateResourceId(this.type, redoResourceIds![0], newCreatedTimePeriod.id!);
                this.resourceIds = [newCreatedTimePeriod.id!];
                this.undo = async (resourceIds?: number[]) => {
                  await deleteTimePeriodMutation(resourceIds?.[0] || newCreatedTimePeriod.id!);
                };
              },
              resourceIds: [createdTimePeriod.id!],
            });
          },
          disabled:
            sortedTimePeriods.length >= MAX_TIME_PERIODS ||
            shouldDisableActionMenuBecauseOfUsedTemporaryId,
        },
      ].filter(actionMenuItem => Boolean(actionMenuItem.icon)),
    [
      t,
      pencilIcon,
      shouldDisableActionMenuBecauseOfUsedTemporaryId,
      binIcon,
      removeTimePeriod,
      sortedTimePeriods.length,
      plusIcon,
      setIsEditingTitle,
      addTimePeriod,
      addHistoryItem,
      deleteTimePeriodMutation,
      updateResourceId,
    ]
  );

  const rectXPosition = x + timePeriodTabWidth - TAB_ACTION_MENU_WIDTH;
  const rectYPosition = y - TAB_ACTION_MENU_ICON_SIZE;

  return (
    <Portal selector=".top-layer" enabled={isHoverActive}>
      <Group
        id={id.toString()}
        width={TAB_ACTION_MENU_WIDTH}
        height={TAB_ACTION_MENU_HEIGHT}
        x={rectXPosition}
        y={rectYPosition}
        fill={theme.palette.brand.white}
        visible={isHoverActive}
        onMouseEnter={() => setIsHoveringAction(true)}
        onMouseOver={() => setIsHoveringAction(true)}
        onMouseLeave={() => setIsHoveringAction(false)}
      >
        {actionMenuItems.map((actionMenuItem, index) => (
          <TabActionMenuItem
            itemIndex={index}
            icon={actionMenuItem.icon!}
            key={`timePeriod-${id}-${actionMenuItem.id}`}
            id={`timePeriod-${id}-${actionMenuItem.id}`}
            text={actionMenuItem.tooltipText}
            onClick={actionMenuItem.onClick}
            disabled={Boolean(actionMenuItem.disabled)}
          />
        ))}
      </Group>
    </Portal>
  );
};

export { TimePeriodActionMenu };
