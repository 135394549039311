import { authCheck } from '@/features/authentication/api/auth';
import { useCookies } from 'react-cookie';
import { useMutation, useQueryClient } from 'react-query';
import i18n from 'i18next';
import { defaultApi } from 'src/api';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import routes from '@/constants/routes';
import { useShowToast } from '@/hooks/useShowToast';
import { useTranslation } from 'react-i18next';
import { components } from '@/types/api';

const useLogin = () => {
  const [, setCookie] = useCookies(['user']);
  const [, setRefreshTokenToCookies] = useCookies(['refreshToken']);
  const navigate = useNavigate();
  const { showToast } = useShowToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation<void, AxiosError, components['schemas']['User']>(async formData => {
    try {
      const response = await defaultApi.post<{
        token: components['schemas']['Token'];
        user: components['schemas']['User'];
      }>('/login', formData);

      setCookie('user', response.data.token.accessToken);
      setRefreshTokenToCookies('refreshToken', response.data.token.refreshToken);
      await queryClient.invalidateQueries(authCheck.name);
      navigate(routes.roadmaps);
    } catch (error) {
      showToast('error', t('authentication.login_error'));
      throw new Error(i18n.t('error', { message: error }));
    }
  });
};

export default useLogin;
