import SVG from 'react-inlinesvg';
import CircleIconSrc from 'src/assets/icons/circle.svg';
import TriangleIconSrc from 'src/assets/icons/triangle.svg';
import styled from 'styled-components';

import RectangleIconSrc from '@/assets/icons/square.svg';
import { FilterOption } from '@/features/canvas/components/FilterOption';
import { components } from '@/types/api';

const FilterMultiOption = ({
  option,
  filterShape,
  filterId,
  roadmapId,
}: {
  filterId: number;
  filterShape: 'circle' | 'square' | 'triangle';
  option: components['schemas']['FilterOption'];
  roadmapId: number;
}) => {
  const getShapeIcon = () => {
    switch (filterShape) {
      case 'triangle':
        return <StyledSVG src={TriangleIconSrc} />;
      case 'square':
        return <StyledSVG src={RectangleIconSrc} />;
      default:
        return <StyledSVG src={CircleIconSrc} />;
    }
  };

  const Icon = getShapeIcon();

  return (
    <FilterOption
      key={option.id}
      option={option}
      filterId={filterId}
      roadmapId={roadmapId}
      Icon={Icon}
    />
  );
};

const StyledSVG = styled(SVG)`
  width: 1.4rem;
  height: 1.4rem;
  overflow: visible;
`;

export { FilterMultiOption };
