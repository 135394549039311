import { ConcreteField } from '@/features/canvas/components/InitiativesAccordionContent/Fields/ConcreteField';
import {
  InitiativeFieldTitles,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';

class FieldFactory {
  static createTitleField(): ConcreteField {
    return new ConcreteField(InitiativeFieldTitles.title, InitiativeFieldTypes.title, '');
  }

  static createDescriptionField(): ConcreteField {
    return new ConcreteField(
      InitiativeFieldTitles.description,
      InitiativeFieldTypes.description,
      ''
    );
  }

  static createStartDateField(): ConcreteField {
    return new ConcreteField(InitiativeFieldTitles.startDate, InitiativeFieldTypes.date, '');
  }

  static createEndDateField(): ConcreteField {
    return new ConcreteField(InitiativeFieldTitles.endDate, InitiativeFieldTypes.date, '');
  }

  static createBudgetField(): ConcreteField {
    return new ConcreteField(InitiativeFieldTitles.budget, InitiativeFieldTypes.budget, '');
  }

  static createProgressBarField(): ConcreteField {
    return new ConcreteField(
      InitiativeFieldTitles.progressBar,
      InitiativeFieldTypes.progressBar,
      ''
    );
  }

  static createSmallCustomField(): ConcreteField {
    return new ConcreteField(InitiativeFieldTitles.customSmall, InitiativeFieldTypes.small, '');
  }

  static createLargeCustomField(): ConcreteField {
    return new ConcreteField(InitiativeFieldTitles.customBig, InitiativeFieldTypes.big, '');
  }
}

export { FieldFactory };
