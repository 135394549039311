import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useResources } from '@/contexts/resource-context';

const Logo = () => {
  const { t } = useTranslation();
  const { logoUrl } = useResources();
  return (
    <LogoContainer>
      <StyledLogoImage src={logoUrl} alt={t('logo')} />
    </LogoContainer>
  );
};

const StyledLogoImage = styled.img`
  width: 100%;
  height: 100%;
`;
const LogoContainer = styled.div`
  width: 200px;
`;

export { Logo };
