import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getPublicLinks() {
  const { data } = await defaultApi.get<components['schemas']['PublicLinkResponse'][]>(
    '/public-links'
  );
  return data;
}

export async function deletePublicLink(linkId: number) {
  await defaultApi.delete(`/public-links/${linkId}`);
}

export async function editPublicLink(publicLink: components['schemas']['PublicLink']) {
  const { data } = await defaultApi.put(`/public-links/${publicLink.id}`, [publicLink]);
  return data;
}
