import styled from 'styled-components';

const borderRadius = 1.8;

const StyledTextarea = styled.textarea`
  padding: 2rem;
  border-radius: ${`${borderRadius}rem ${borderRadius}rem ${
    borderRadius - 1.3
  }rem ${borderRadius}rem`};
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
  border: 2px solid ${({ theme }) => theme.palette.brand.textSecondary};

  &:focus {
    border: 2px solid ${({ theme }) => theme.palette.brand.primary};
    background: ${({ theme }) => theme.palette.brand.sunray};
  }

  &::placeholder {
    font-style: italic;
  }
`;

export default StyledTextarea;
