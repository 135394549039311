import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import KonexisLogo from '@/assets/images/KonexisLogo.svg';
import { getLogo, getResourcesByTenant, ResourceContract } from '@/features/canvas/api/theme';
import { theme } from '@/features/ui/theme';

const ResourceContext = React.createContext({
  resources: {} as ResourceContract,
  logoUrl: '' as string | undefined,
});

export const ResourceContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [initThemeRequired, setInitThemeRequired] = useState(true);
  const [initLogoRequired, setInitLogoRequired] = useState(true);
  const { data: resources, isLoading } = useQuery(getResourcesByTenant.name, getResourcesByTenant, {
    enabled: initThemeRequired,
    onSuccess: res => {
      setInitThemeRequired(false);
      theme(res);
    },
  });

  const { data: logoUrl, isLoading: isLogoLoading } = useQuery(getLogo.name, getLogo, {
    enabled: initLogoRequired,
    onSuccess: () => setInitLogoRequired(false),
  });

  if (isLoading || isLogoLoading) {
    return (
      <LoaderWrapper>
        <StyledLoader />
      </LoaderWrapper>
    );
  }

  return (
    <ResourceContext.Provider
      value={{
        resources: resources!,
        logoUrl: logoUrl || KonexisLogo,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};

export const useResources = () => {
  const context = React.useContext(ResourceContext);

  if (!context) {
    throw Error('ResourceContext hook can be used only inside ResourceContextProvider');
  }

  return context;
};

const LoaderWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled(CircularProgress)`
  color: '#6767e0';
`;
