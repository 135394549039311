import { useTranslation } from 'react-i18next';

import { DualChoiceDialog } from '@/features/canvas/components/DualChoiceDialog';

type Props = {
  firstChoiceIsDisabled: boolean;
  firstChoiceIsLoading: boolean;
  firstChoiceOnClick: () => Promise<void>;
  isDualChoiceDeleteInitiativeDialogOpen: boolean;
  onClose: () => void;
  secondChoiceIsDisabled: boolean;
  secondChoiceIsLoading: boolean;
  secondChoiceOnClick: () => Promise<void>;
};

const TimelineEditorDualChoiceDeleteDialog = ({
  isDualChoiceDeleteInitiativeDialogOpen,
  onClose,
  firstChoiceOnClick,
  secondChoiceOnClick,
  firstChoiceIsDisabled,
  firstChoiceIsLoading,
  secondChoiceIsDisabled,
  secondChoiceIsLoading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <DualChoiceDialog
      open={isDualChoiceDeleteInitiativeDialogOpen}
      onClose={onClose}
      dialogDescription={t('editor.canvas.delete_initiative_dialog_description')}
      firstChoiceButtonText={t('editor.canvas.dual_choice_first_button_text')}
      firstChoiceDescription={t('editor.canvas.dual_choice_first_choice_description')}
      firstChoiceIsDisabled={firstChoiceIsDisabled}
      firstChoiceIsLoading={firstChoiceIsLoading}
      firstChoiceOnClick={firstChoiceOnClick}
      secondChoiceButtonText={t('editor.canvas.dual_choice_second_button_text')}
      secondChoiceDescription={t('editor.canvas.dual_choice_second_choice_description')}
      secondChoiceIsDisabled={secondChoiceIsDisabled}
      secondChoiceIsLoading={secondChoiceIsLoading}
      secondChoiceOnClick={secondChoiceOnClick}
    />
  );
};

export { TimelineEditorDualChoiceDeleteDialog };
