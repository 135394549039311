import { useCallback } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

import { FilteredNodes, useEditorContext } from '@/features/canvas/contexts/editor-context';

export const useNodeFocusOut = () => {
  const {
    filters,
    filteredNodes,
    setFilteredNodes,
    activeFilterId,
    optionCheckedId,
    setOptionCheckedId,
  } = useEditorContext();

  const onNodeFocusOut = useCallback(
    (event: KonvaEventObject<MouseEvent>) => {
      const stage = event.target.getStage();
      if (stage) {
        const pos = stage.getPointerPosition();
        if (pos) {
          const nodeIds = filteredNodes.map(n => n.id);
          const target = stage.getIntersection(pos);
          if (
            !nodeIds.includes(Number(target?.parent?.id())) &&
            optionCheckedId &&
            activeFilterId
          ) {
            const optionColorMap = new Map<number, string>();
            const activeFilter = filters.find(f => f.id === activeFilterId);

            activeFilter?.options.forEach(option => {
              optionColorMap.set(option.id!, option.color);
            });

            const nodes = filteredNodes.map(fn => {
              const filteredNode = activeFilter?.nodes?.find(n => n.nodeId === fn.id);
              if (filteredNode) {
                return {
                  ...fn,
                  shape: activeFilter?.shape || '',
                  color: optionColorMap.get(filteredNode.optionId) || '',
                };
              }

              return {
                ...fn,
                shape: '',
                color: '',
              };
            });
            setFilteredNodes(nodes as FilteredNodes[]);
            setOptionCheckedId(null);
          }
        }
      }
    },
    [activeFilterId, filteredNodes, filters, optionCheckedId, setFilteredNodes, setOptionCheckedId]
  );

  return { onNodeFocusOut };
};
