import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { TableCell } from '@mui/material';
import styled from 'styled-components';

import { editPublicLink, getPublicLinks } from '@/features/publicLinks/api';
import { EditLinkPasswordFormContent } from '@/features/publicLinks/components/Table/EditLinkPasswordFormContent';
import { Form } from '@/features/ui/components/Form';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

interface EditLinkPasswordFormState {
  password: string;
}

const PasswordCell = ({ row }: { row: components['schemas']['PublicLinkResponse'] }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();

  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  const [isPasswordEditable, setIsPasswordEditable] = useState(false);

  const { mutateAsync: updatePublicLink } = useMutation(editPublicLink, {
    onSuccess: async () => {
      setIsPasswordEditable(false);
      setShouldShowPassword(false);
      showToast('success', t('public_links.edit_success'));
      await queryClient.invalidateQueries(getPublicLinks.name);
    },
    onError: () => {
      showToast('error', t('public_links.edit_error'));
    },
  });

  return (
    <TableCell align="center" sx={{ fontSize: '2rem' }}>
      <CellContent>
        <StyledForm
          onSubmit={async formData => {
            const { publishDate, ...otherRowValues } = row;
            await updatePublicLink({ ...otherRowValues, ...formData });
          }}
        >
          <Wrapper>
            {!shouldShowPassword && !isPasswordEditable && (
              <VerticalCenterWrapper>
                {t('form.password_asterisks_placeholder')}
              </VerticalCenterWrapper>
            )}

            <EditLinkPasswordFormContent
              isPasswordEditable={isPasswordEditable}
              setIsPasswordEditable={setIsPasswordEditable}
              publicLink={row}
            />
          </Wrapper>
        </StyledForm>
      </CellContent>
    </TableCell>
  );
};

const CellContent = styled.div`
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const VerticalCenterWrapper = styled.div`
  height: fit-content;
  padding-top: 0.5rem;
`;

const StyledForm = styled(Form)`` as typeof Form<EditLinkPasswordFormState>;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;
export { PasswordCell };
