import { SetStateAction, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styled, { css } from 'styled-components';

import {
  deleteOptionById,
  editOption,
  getFiltersByRoadmapId,
  getOptionsByFilterId,
} from '@/features/canvas/api';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

const FilterOption = ({
  option,
  filterId,
  roadmapId,
  Icon,
  iconCursor = false,
}: {
  Icon: JSX.Element;
  filterId: number;
  option: components['schemas']['FilterOption'];
  roadmapId: number;
  iconCursor?: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>(option.title);
  const [editMode, setEditMode] = useState<boolean>(false);

  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const queryClient = useQueryClient();
  const { setIsSidebarContentLoading, updateLastSavedTime } = useEditorContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const INPUT_MAX_NUMBER_CHARS = 65;

  const { mutateAsync: deleteOption } = useMutation(async () => {
    try {
      await deleteOptionById(option.id!);
      updateLastSavedTime();
      showToast('success', t('editor.sidebar.filter.delete_option_success'));
    } catch (e) {
      showToast('error', t('editor.sidebar.filter.delete_option_error'));
    }
  });

  const { mutateAsync: updateOption } = useMutation(
    async (optionData: components['schemas']['FilterOption']) => {
      try {
        await editOption(optionData.id!, optionData);
        updateLastSavedTime();
        showToast('success', t('editor.sidebar.filter.edit_option_success'));
      } catch (e) {
        showToast('success', t('editor.sidebar.filter.edit_option_error'));
      }
    }
  );

  const handleFocus = () => {
    setEditMode(true);
  };

  const handleBlur = async () => {
    inputRef.current?.blur();
    setEditMode(false);
    if (inputValue === option.title) {
      return;
    }

    const updatedOption = { ...option, title: inputValue };
    await updateOption(updatedOption);
    await queryClient.invalidateQueries([getOptionsByFilterId.name, filterId]);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmapId]);
  };

  const handleInputChange = (event: { target: { value: SetStateAction<string> } }) => {
    if (event.target.value.length > INPUT_MAX_NUMBER_CHARS) {
      return;
    }
    setInputValue(event.target.value);
  };

  const handleKeyDown = async (event: { key: string }) => {
    if (event.key === 'Enter') {
      await handleBlur();
    }
  };

  const handleDelete = async () => {
    setIsSidebarContentLoading(true);

    await deleteOption();
    await queryClient.invalidateQueries([getOptionsByFilterId.name, filterId]);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmapId]);

    setIsSidebarContentLoading(false);
  };

  return (
    <OptionWrapper $editMode={editMode}>
      <StartOptionWrapper $pointerCursor={iconCursor}>
        {Icon}
        <TitleInput
          $editMode={editMode}
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
        />
      </StartOptionWrapper>
      <DeleteWrapper onClick={handleDelete}>
        <StyledDelete />
      </DeleteWrapper>
    </OptionWrapper>
  );
};

const OptionWrapper = styled.div<{ $editMode: boolean }>`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  border-radius: 1rem;
  padding: 0 0.5rem;

  ${({ $editMode, theme }) =>
    $editMode &&
    css`
      background-color: ${theme.palette.brand.white};
      border: 1px solid ${theme.palette.brand.backgroundDialog};
    `}
`;

const TitleInput = styled.input<{ $editMode: boolean }>`
  width: 100%;

  ${({ $editMode }) =>
    $editMode &&
    css`
      outline: none;
      border: none;
    `}

  ${({ $editMode, theme }) =>
    !$editMode &&
    css`
      background-color: ${theme.palette.brand.backgroundDialog};
      border: none;
    `}
`;

const StartOptionWrapper = styled.div<{ $pointerCursor: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  ${({ $pointerCursor }) =>
    $pointerCursor &&
    css`
      cursor: pointer;
    `}
`;

const DeleteWrapper = styled.div`
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledDelete = styled.div`
  width: 8px;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

export { FilterOption };
