import { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextareaAutosize } from '@mui/material';
import styled from 'styled-components';

import { CUSTOM_FIELD_MAX_MULTI_LINE_LENGTH } from '@/features/canvas/constants';
import { SmallActionButton } from '@/features/ui/components/SmallActionButton/SmallActionButton';
import { components } from '@/types/api';

const InitiativeDescription = ({
  field,
  onUpdateField,
  onClick,
  readOnly,
}: {
  field: components['schemas']['InitiativeField'];
  onClick: (e: React.MouseEvent<HTMLTextAreaElement>) => void;
  onUpdateField: (field: components['schemas']['InitiativeField']) => void;
  readOnly: boolean;
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>(field.value || '');

  const handleOnSubmit = () => {
    const updatedField = { ...field, value: inputValue };
    onUpdateField(updatedField);
  };

  const handleInputValueChange = (event: { target: { value: SetStateAction<string> } }) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <DescriptionArea
        readOnly={readOnly}
        onClick={onClick}
        value={inputValue}
        minRows={3}
        maxLength={CUSTOM_FIELD_MAX_MULTI_LINE_LENGTH}
        placeholder={t('editor.sidebar.initiative.description_placeholder')}
        onChange={handleInputValueChange}
      />
      {!readOnly && (
        <ActionWrapper>
          <SmallActionButton
            text={t('editor.sidebar.initiative.save_button_title')}
            onClick={handleOnSubmit}
          />
        </ActionWrapper>
      )}
    </>
  );
};

export { InitiativeDescription };

const DescriptionArea = styled(TextareaAutosize)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};
  border-radius: 0.4rem;
  resize: vertical;

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.palette.brand.textSecondary};
    outline: none;
  }
`;

const ActionWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;
