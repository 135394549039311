import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Rect, Text } from 'react-konva';
import { Html, Portal } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import { getRoadmapTimePeriods, updateTimePeriod } from '@/features/canvas/api';
import { TabEditFormContent } from '@/features/canvas/components/TabEditFormContent';
import { TimePeriodActionMenu } from '@/features/canvas/components/TimePeriodActionMenu';
import {
  SIZE_HANDLER_WIDTH,
  TAB_ACTION_MENU_END_OFFSET,
  TAB_ACTION_MENU_WIDTH,
  TAB_HEIGHT,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { TabEditFormValues } from '@/features/canvas/types/tabEditFormValues';
import { checkXTabBoundaries } from '@/features/canvas/utils/check-x-tab-boundaries';
import { Form } from '@/features/ui/components/Form';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  cornerRadius: number[];
  fill: string;
  handlerHeight: number;
  onDragEnd: () => void;
  shouldDisableActionMenuBecauseOfUsedTemporaryId: boolean;
  shouldShowResizeHandler: boolean;
  successorWidth: number;
  textColor: string;
  timePeriodId: number;
  timePeriodIndex: number;
  timePeriodOrder: number;
  timePeriodTabHeight: number;
  timePeriodTabWidth: number;
  timePeriodTitle: string;
  x: number;
  children?: ReactNode;
};

const TimePeriodTabShape = ({
  x,
  timePeriodId,
  timePeriodIndex,
  timePeriodTitle,
  timePeriodTabWidth,
  timePeriodTabHeight,
  timePeriodOrder,
  shouldShowResizeHandler,
  fill,
  cornerRadius,
  textColor,
  children,
  handlerHeight,
  shouldDisableActionMenuBecauseOfUsedTemporaryId,
  successorWidth,
  onDragEnd,
}: Props) => {
  const {
    isPreview,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    sortedTimePeriods,
    roadmap,
    updateLastSavedTime,
  } = useEditorContext();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isHoveringTab, setIsHoveringTab] = useState(false);
  const [isHoveringAction, setIsHoveringAction] = useState(false);
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const actionMenuX =
    x -
    (timePeriodTabWidth > TAB_ACTION_MENU_WIDTH + TAB_ACTION_MENU_END_OFFSET
      ? TAB_ACTION_MENU_END_OFFSET
      : 0);
  const draggableRectXPosition = x + timePeriodTabWidth - SIZE_HANDLER_WIDTH / 2;

  const { mutateAsync: updateTimePeriodsMutation } = useMutation(
    ({ newTimePeriodData }: { newTimePeriodData: components['schemas']['TimePeriod'] }) =>
      updateTimePeriod({ timePeriodId, newTimePeriodData }),
    {
      onMutate: async ({ newTimePeriodData }) => {
        await queryClient.cancelQueries([getRoadmapTimePeriods.name, roadmap.id]);
        queryClient.setQueryData([getRoadmapTimePeriods.name, roadmap.id], () => {
          setIsEditingTitle(false);
          updateLastSavedTime();
          const timePeriodsToUpdate = [...sortedTimePeriods];
          const timePeriodIndexToReplace = timePeriodsToUpdate.findIndex(
            ({ id }) => Number(id) === Number(timePeriodId)
          );

          timePeriodsToUpdate[timePeriodIndexToReplace] = newTimePeriodData;
          return timePeriodsToUpdate;
        });
      },
      onSuccess: () => updateLastSavedTime(),
      onError: async () => {
        showToast('error', t('editor.canvas.edit_time_period_error'));
        await queryClient.invalidateQueries([getRoadmapTimePeriods.name, roadmap.id]);
        setIsEditingTitle(false);
      },
    }
  );

  const handleUpdateTimePeriod = useCallback(
    async (formData: TabEditFormValues) => {
      const timePeriodToReplace = sortedTimePeriods[timePeriodIndex];

      await updateTimePeriodsMutation({
        newTimePeriodData: {
          ...timePeriodToReplace,
          roadmap_id: Number(roadmap.id),
          title: formData.title,
        },
      });

      setIsEditingTitle(false);
    },
    [roadmap.id, sortedTimePeriods, timePeriodIndex, updateTimePeriodsMutation]
  );

  return (
    <>
      <Group id={timePeriodId.toString()} key={timePeriodId} x={x} y={0}>
        <Group
          id={`tab-${timePeriodId}`}
          key={`tab-${timePeriodId}`}
          onMouseEnter={() => setIsHoveringTab(true)}
          onMouseOver={() => setIsHoveringTab(true)}
          onMouseOut={() => setIsHoveringTab(false)}
          onTap={() => setIsHoveringTab(!isHoveringTab)}
        >
          <Rect
            width={timePeriodTabWidth}
            height={TAB_HEIGHT}
            fill={fill}
            cornerRadius={cornerRadius}
          />
          {isEditingTitle && !isPreview && (
            <Html
              divProps={{
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: `${timePeriodTabWidth}px`,
                  height: `${TAB_HEIGHT / 3}px`,
                  top: `${TAB_HEIGHT / 2 - TAB_HEIGHT / 3 / 2}px`,
                },
              }}
            >
              <Form
                defaultValues={{ title: timePeriodTitle }}
                onSubmit={formData => handleUpdateTimePeriod(formData as TabEditFormValues)}
              >
                <TabEditFormContent
                  textColor={theme.palette.brand.textPrimary}
                  onSubmit={handleUpdateTimePeriod}
                />
              </Form>
            </Html>
          )}
          {!isEditingTitle && (
            <Text
              fill={textColor}
              text={timePeriodTitle}
              align="center"
              verticalAlign="middle"
              width={timePeriodTabWidth}
              height={TAB_HEIGHT}
              fontStyle="900"
              fontSize={16}
              letterSpacing={0.15}
              fontFamily="Open Sans"
              shadowColor={textColor}
              shadowBlur={1}
            />
          )}
          {!isPreview && (
            <TimePeriodActionMenu
              id={timePeriodId}
              timePeriodTabWidth={timePeriodTabWidth}
              timePeriodAreaHeight={timePeriodTabHeight}
              timePeriodOrder={timePeriodOrder}
              timePeriodIndex={timePeriodIndex}
              x={actionMenuX}
              y={0}
              setIsEditingTitle={setIsEditingTitle}
              setIsHoveringAction={setIsHoveringAction}
              isHoverActive={isHoveringTab || isHoveringAction}
              shouldDisableActionMenuBecauseOfUsedTemporaryId={
                shouldDisableActionMenuBecauseOfUsedTemporaryId
              }
            />
          )}
        </Group>
        {children}
      </Group>

      {shouldShowResizeHandler && (
        <Portal selector=".top-layer" enabled>
          <Rect
            width={SIZE_HANDLER_WIDTH}
            height={handlerHeight < TAB_HEIGHT ? TAB_HEIGHT : handlerHeight}
            x={draggableRectXPosition}
            y={0}
            draggable
            onMouseEnter={e => {
              const container = e.target.getStage()!.container();
              container.style.cursor = `col-resize`;
            }}
            onMouseLeave={e => {
              const stage = e.target.getStage();
              if (stage) {
                const container = stage.container();
                container.style.cursor = 'default';
              }
            }}
            onDragStart={() => {
              setIsDraggingTab(true);
            }}
            onDragMove={evt => {
              const rect = evt.target;
              const currentX = rect.x();

              const deltaX = currentX - draggableRectXPosition;

              setDraggedTabPositionChange(deltaX);
            }}
            onDragEnd={onDragEnd}
            dragBoundFunc={pos =>
              checkXTabBoundaries({
                rectXPosition: draggableRectXPosition,
                posX: pos.x,
                tabWidth: timePeriodTabWidth,
                neighborTabWidth: successorWidth,
              })
            }
          />
        </Portal>
      )}
    </>
  );
};

export { TimePeriodTabShape };
