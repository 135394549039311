interface Field {
  title: string;
  type: string;
  value: string;
}

class ConcreteField implements Field {
  title: string;

  type: string;

  value: string;

  constructor(title: string, type: string, value: string) {
    this.title = title;
    this.type = type;
    this.value = value;
  }

  getField(): Field {
    return {
      title: this.title,
      type: this.type,
      value: this.value,
    };
  }
}

export { ConcreteField };
