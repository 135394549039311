import { Group, Line } from 'react-konva';
import { useTheme } from '@mui/material';
import { transparentize } from 'polished';

import { Point } from '@/features/canvas/types/point';

const SectionAreaShape = ({
  id,
  startPointARelativeToGroup,
  startPointBRelativeToGroup,
  endPointARelativeToGroup,
  rotation = 0,
  fill,
  strokeWidth = 0,
  clipOffset,
  isLast,
}: {
  clipOffset: { a: Point; b: Point };
  endPointARelativeToGroup: Point;
  startPointARelativeToGroup: Point;
  startPointBRelativeToGroup: Point;
  fill?: string;
  id?: string;
  isLast?: boolean;
  rotation?: number;
  strokeWidth?: number;
}) => {
  const theme = useTheme();

  return (
    <Group
      id={`${id}-section-area-shape`}
      key={id}
      clipFunc={ctx => {
        ctx.beginPath();
        ctx.moveTo(
          startPointARelativeToGroup.x + clipOffset.a.x,
          startPointARelativeToGroup.y + clipOffset.a.y
        );
        ctx.lineTo(endPointARelativeToGroup.x, endPointARelativeToGroup.y);
        ctx.lineTo(
          startPointBRelativeToGroup.x + clipOffset.b.x,
          startPointBRelativeToGroup.y + clipOffset.b.y
        );
        ctx.closePath();
      }}
      rotation={rotation}
    >
      <Line
        points={[
          startPointARelativeToGroup.x,
          startPointARelativeToGroup.y,
          endPointARelativeToGroup.x,
          endPointARelativeToGroup.y,
          startPointBRelativeToGroup.x,
          startPointBRelativeToGroup.y,
        ]}
        fill={fill}
        closed
      />
      {!isLast && (
        <Line
          points={[
            startPointARelativeToGroup.x,
            startPointARelativeToGroup.y,
            endPointARelativeToGroup.x,
            endPointARelativeToGroup.y,
            startPointBRelativeToGroup.x,
            startPointBRelativeToGroup.y,
          ]}
          dash={[3, 6]}
          stroke={transparentize(0.5, theme.palette.brand.textSecondary)}
          strokeWidth={strokeWidth}
        />
      )}
      {isLast && (
        <Line
          points={[
            startPointARelativeToGroup.x,
            startPointARelativeToGroup.y,
            endPointARelativeToGroup.x,
            endPointARelativeToGroup.y,
          ]}
          dash={[3, 6]}
          stroke={transparentize(0.5, theme.palette.brand.textSecondary)}
          strokeWidth={strokeWidth}
        />
      )}
    </Group>
  );
};

export { SectionAreaShape };
