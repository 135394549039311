import styled from 'styled-components';

import { SwimlaneViewGrid } from '@/features/canvas/components/SwimlaneViewGrid';
import { STAGE_OFFSET } from '@/features/canvas/constants';

const SwimlaneView = () => {
  return (
    <Swimlane>
      <SwimlaneViewGrid />
    </Swimlane>
  );
};

const Swimlane = styled.div`
  height: 100%;
  padding: ${STAGE_OFFSET}px;
  overflow: hidden;
`;

export { SwimlaneView };
