import { Dispatch, ReactNode, SetStateAction } from 'react';
import styled from 'styled-components';

import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { EditorHeader } from '@/features/canvas/modules/EditorHeader';
import { EditorSidebar } from '@/features/canvas/modules/EditorSidebar';

const Editor: React.FC<{
  children: ReactNode;
  setIsBasicDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDualChoiceDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
}> = ({
  children,
  setIsBasicDeleteInitiativeDialogOpen,
  setIsDualChoiceDeleteInitiativeDialogOpen,
}) => {
  const { isPreview, isSwimlaneView } = useEditorContext();
  return (
    <Main>
      {!isPreview && (
        <EditorSidebar
          setIsBasicDeleteInitiativeDialogOpen={setIsBasicDeleteInitiativeDialogOpen}
          setIsDualChoiceDeleteInitiativeDialogOpen={setIsDualChoiceDeleteInitiativeDialogOpen}
        />
      )}
      <EditorContent>
        <EditorHeader />
        <CanvasContent $isSwimlaneView={isSwimlaneView}>{children}</CanvasContent>
      </EditorContent>
    </Main>
  );
};

const Main = styled.main`
  display: flex;
  min-height: 100vh;
  min-height: 100dvh;
  width: 100vw;
`;
const EditorContent = styled.div`
  flex: 1 0;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 100%;
`;

const CanvasContent = styled.div<{ $isSwimlaneView: boolean }>`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  max-height: calc(100vh - 60px);
  max-height: calc(100dvh - 60px);
`;

export { Editor };
