import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stage } from 'konva/lib/Stage';

import { useInitiative } from '@/features/canvas/api/hooks/useInitiative';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useImportInitiativeContext } from '@/features/canvas/contexts/import-initiative-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useImportedInitiativeDrop } from '@/features/canvas/hooks/useImportedInitiativeDrop';
import { useInitiativeDrop } from '@/features/canvas/hooks/useInitiativeDrop';
import { InitiativeToBeImported } from '@/features/canvas/types/initiative';
import { getIntersectionSectionAndTimePeriodId } from '@/features/canvas/utils/get-intersection-section-and-time-period-id';
import { useShowToast } from '@/hooks/useShowToast';

export const useOnDrop = () => {
  const { showToast } = useShowToast();
  const { t } = useTranslation();
  const { initiativeToImport } = useImportInitiativeContext();
  const { addHistoryItem, updateResourceId } = useUndoRedo();

  const {
    roadmap,
    stageRef,
    canvasAreaRef,
    heightToFitContent,
    widthToFitContent,
    updateLastSavedTime,
    setSelectedNodeId,
  } = useEditorContext();

  const { onNewInitiativeDrop } = useInitiativeDrop({
    roadmapId: roadmap.id!,
  });
  const { deleteInitiativeMutation } = useInitiative({
    roadmapId: roadmap.id!,
    setSelectedNodeId,
    updateLastSavedTime,
  });
  const { onImportInitiativeDrop } = useImportedInitiativeDrop({
    roadmapId: roadmap.id!,
  });

  const calculatePosition = useCallback(() => {
    const position = stageRef.current?.getPointerPosition();
    if (!position) {
      return;
    }
    const canvasDropArea = canvasAreaRef?.current;
    const offset = canvasDropArea?.getAbsolutePosition();

    const correctedPosition = {
      x: position.x - (offset?.x || 0),
      y: position.y - (offset?.y || 0),
    };

    const newCanvasXPercentage = Number(
      ((correctedPosition.x / widthToFitContent) * 100).toFixed(2)
    );
    const newCanvasYPercentage = Number(
      ((correctedPosition.y / heightToFitContent) * 100).toFixed(2)
    );

    return { position, newCanvasXPercentage, newCanvasYPercentage };
  }, [canvasAreaRef, heightToFitContent, stageRef, widthToFitContent]);

  const dropImportedInitiative = useCallback(
    async ({
      stageRefCurrent,
      event,
      newInitiativeToImport,
    }: {
      event: DragEvent;
      newInitiativeToImport: InitiativeToBeImported;
      stageRefCurrent: Stage;
    }) => {
      stageRefCurrent.setPointersPositions(event);
      const calculationResult = calculatePosition();
      if (!calculationResult?.position) {
        return showToast('error', t('editor.sidebar.initiative_position_error'));
      }
      const { position, newCanvasXPercentage, newCanvasYPercentage } = calculationResult;
      const intersections = stageRefCurrent?.getAllIntersections(position);

      if (!intersections?.length || intersections.length < 2) {
        return showToast('error', t('editor.sidebar.initiative_position_error'));
      }
      const { timePeriodId, sectionId } = getIntersectionSectionAndTimePeriodId(intersections);
      if (!timePeriodId || !sectionId) {
        return showToast('error', t('editor.sidebar.initiative_position_error'));
      }

      await onImportInitiativeDrop({
        initiative: newInitiativeToImport,
        newNodesCanvasXPercentage: newCanvasXPercentage,
        newNodesCanvasYPercentage: newCanvasYPercentage,
        timePeriodId: Number(timePeriodId),
        sectionId: Number(sectionId),
        newInitiativeFieldValues: newInitiativeToImport.fieldValues,
        newInitiativeFilterValues: newInitiativeToImport.filterOptionValues,
      });
    },
    [calculatePosition, onImportInitiativeDrop, showToast, t]
  );

  const dropManuallyCreatedInitiative = useCallback(
    async ({ stageRefCurrent, event }: { event: DragEvent; stageRefCurrent: Stage }) => {
      stageRefCurrent.setPointersPositions(event);

      const titleElement = document.getElementById('new-initiative-title');
      const calculationResult = calculatePosition();
      if (!calculationResult?.position) {
        return showToast('error', t('editor.sidebar.initiative_position_error'));
      }
      const { position, newCanvasXPercentage, newCanvasYPercentage } = calculationResult;
      const intersections = stageRefCurrent?.getAllIntersections(position);

      if (!intersections?.length || intersections.length < 2) {
        return showToast('error', t('editor.sidebar.initiative_position_error'));
      }
      const { timePeriodId, sectionId } = getIntersectionSectionAndTimePeriodId(intersections);
      if (!timePeriodId || !sectionId) {
        return showToast('error', t('editor.sidebar.initiative_position_error'));
      }

      const { initiative: createdInitiative, nodeId } = (await onNewInitiativeDrop({
        newNodesCanvasXPercentage: newCanvasXPercentage,
        newNodesCanvasYPercentage: newCanvasYPercentage,
        title: titleElement?.innerText || t('editor.header.untitled'),
        timePeriodId: Number(timePeriodId),
        sectionId: Number(sectionId),
      }))!;

      addHistoryItem({
        type: HistoryActionType.AddInitiative,
        resourceIds: [createdInitiative!.id!],
        undo: async (resourceIds?: number[]) => {
          await deleteInitiativeMutation(resourceIds?.[0] || createdInitiative!.id!);
        },
        async redo(redoResourceIds?: number[]) {
          const { initiative: newCreatedInitiative, nodeId: redoNodeId } =
            (await onNewInitiativeDrop({
              newNodesCanvasXPercentage: newCanvasXPercentage,
              newNodesCanvasYPercentage: newCanvasYPercentage,
              title: titleElement?.innerText || t('editor.header.untitled'),
              timePeriodId: Number(timePeriodId),
              sectionId: Number(sectionId),
            }))!;

          updateResourceId(
            this.type,
            redoResourceIds?.[0] || createdInitiative!.id!,
            newCreatedInitiative!.id!,
            {
              newResourceIds: [redoNodeId, newCreatedInitiative.id!],
              resourceIds: [nodeId, redoResourceIds?.[0] || createdInitiative!.id!],
            }
          );
          updateResourceId(HistoryActionType.UpdateNode, nodeId, redoNodeId);
        },
      });
    },
    [
      addHistoryItem,
      calculatePosition,
      deleteInitiativeMutation,
      onNewInitiativeDrop,
      showToast,
      t,
      updateResourceId,
    ]
  );

  useEffect(() => {
    const container = stageRef?.current?.container();

    const onDragOver = (e: DragEvent) => {
      e.preventDefault();
    };

    const onDrop = async (e: DragEvent) => {
      e.preventDefault();
      if (stageRef.current && initiativeToImport) {
        await dropImportedInitiative({
          stageRefCurrent: stageRef.current,
          event: e,
          newInitiativeToImport: initiativeToImport,
        });
      }
      if (stageRef.current && !initiativeToImport) {
        await dropManuallyCreatedInitiative({
          stageRefCurrent: stageRef.current,
          event: e,
        });
      }
    };

    if (container) {
      container.addEventListener('dragover', onDragOver);
      container.addEventListener('drop', onDrop);
    }

    return () => {
      if (container) {
        container.removeEventListener('dragover', onDragOver);
        container.removeEventListener('drop', onDrop);
      }
    };
  }, [dropImportedInitiative, dropManuallyCreatedInitiative, initiativeToImport, stageRef]);
};
