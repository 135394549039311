import SVG from 'react-inlinesvg';
import { useTheme } from '@mui/material';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import CircleSVG from '@/assets/icons/circle.svg';
import SquareSVG from '@/assets/icons/square.svg';
import TriangleSVG from '@/assets/icons/triangle.svg';
import { INITIATIVE_DETAIL_FILTER_OPTION_TITLE_MAX_LENGTH } from '@/features/canvas/constants';
import { components } from '@/types/api';
import { textEllipsis } from '@/utils/text-ellipsis';

type Props = {
  filterShape: string;
  options: components['schemas']['FilterOption'][];
  title: string;
  filterSelection?: 'single' | 'multiple';
};

const InitiativeDetailFilterTag = ({ title, filterShape, options, filterSelection }: Props) => {
  const theme = useTheme();
  const getFilterIcon = (color: string) => {
    let addIcon = CircleSVG;
    if (filterShape === 'triangle') {
      addIcon = TriangleSVG;
    } else if (filterShape === 'square') {
      addIcon = SquareSVG;
    }

    return <StyledSVG src={addIcon} $color={color} />;
  };
  return (
    <InitiativeDetailFilter>
      <InitiativeDetailFilterTagTitle>{title}</InitiativeDetailFilterTagTitle>
      <InitiativeDetailFilterTagContent>
        {options.map(option => {
          return (
            <InitiativeDetailFilterTagOption key={option.id}>
              {getFilterIcon(
                filterSelection === 'multiple' ? theme.palette.brand.textSecondary : option.color
              )}
              <InitiativeDetailFilterTagOptionTitle>
                {textEllipsis(option.title, INITIATIVE_DETAIL_FILTER_OPTION_TITLE_MAX_LENGTH)}
              </InitiativeDetailFilterTagOptionTitle>
            </InitiativeDetailFilterTagOption>
          );
        })}
      </InitiativeDetailFilterTagContent>
    </InitiativeDetailFilter>
  );
};

const InitiativeDetailFilterTagTitle = styled.div`
  font-size: 1.25rem;

  color: ${({ theme }) => transparentize(0.3, theme.palette.brand.textSecondary)};
`;

const InitiativeDetailFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const InitiativeDetailFilterTagContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;
const InitiativeDetailFilterTagOption = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.palette.brand.white};
  border-radius: 2rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
`;

const InitiativeDetailFilterTagOptionTitle = styled.div`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

const StyledSVG = styled(SVG)<{ $color: string }>`
  width: 1.5rem;
  height: 1.5rem;
  overflow: visible;
  ${({ $color }) =>
    css`
      & path {
        stroke: ${$color};
        fill: ${$color};
      }
    `}
`;

export { InitiativeDetailFilterTag };
