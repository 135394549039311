import { useQuery } from 'react-query';
import {
  Box,
  CircularProgress,
  Dialog as MuiDialog,
  DialogContent,
  DialogProps,
} from '@mui/material';
import styled from 'styled-components';

import { getRoadmapPermissionList } from '@/api/shared/roadmap';
import { ReactComponent as CloseDialogIcon } from '@/assets/icons/close-dialog-icon.svg';
import { RoadmapPermissions } from '@/modules/RoadmapPermissions';

type ManageRoadmapPermissionsDialogProps = DialogProps & {
  onClose: () => void;
  open: boolean;
  roadmapOwner: { id: number; userName: string } | null;
  selectedRoadmapId: number;
};

const ManageRoadmapPermissionsDialog = ({
  open,
  onClose,
  selectedRoadmapId,
  roadmapOwner,
  ...other
}: ManageRoadmapPermissionsDialogProps) => {
  const { data: roadmapPermissions, isLoading } = useQuery(
    [getRoadmapPermissionList.name, selectedRoadmapId],
    () => getRoadmapPermissionList({ roadmapId: Number(selectedRoadmapId) }),
    { enabled: Boolean(selectedRoadmapId && open) }
  );

  return (
    <MuiDialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '2rem',
          minWidth: 800,
          overflowY: 'unset',
        },
      }}
      {...other}
    >
      <StyledCloseWrapper onClick={() => onClose()}>
        <CloseDialogIcon />
      </StyledCloseWrapper>
      <DialogContent>
        {isLoading && (
          <LoaderWrapper>
            <StyledLoader />
          </LoaderWrapper>
        )}
        {!isLoading && (
          <RoadmapPermissions
            roadmapOwner={roadmapOwner}
            roadmapPermissions={roadmapPermissions}
            roadmapId={selectedRoadmapId!}
          />
        )}
      </DialogContent>
    </MuiDialog>
  );
};

const StyledCloseWrapper = styled(Box)`
  cursor: pointer;
  top: -2rem;
  right: -2rem;
  position: absolute;
  border-radius: 2rem;
  width: 5rem;
  height: 5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  background-color: ${({ theme }) => theme.palette.brand.white};

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.palette.brand.primary};
    }
  }
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { ManageRoadmapPermissionsDialog };
