import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button, CircularProgress } from '@mui/material';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import { ReactComponent as Import } from '@/assets/icons/import.svg';
import { getRoadmapInitiatives } from '@/features/canvas/api';
import { ImportList } from '@/features/canvas/components/ImportAccordionContent/List/ImportList';
import { StyledSelect } from '@/features/canvas/components/StyledSelect';
import { useImportInitiativeContext } from '@/features/canvas/contexts/import-initiative-context';
import { getRoadmapList } from '@/features/roadmaps/api';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

import { StyledSelectCheckmarks } from '../../StyledSelect/StyledSelectCheckmarks';

const Linkage = () => {
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const { isInitiativeImportRunning, setSelectedRoadmapId, selectedRoadmapId } =
    useImportInitiativeContext();

  const [isActive, setActive] = useState<boolean>(false);

  const [initiativeList, setInitiativeList] = useState<components['schemas']['Initiative'][]>([]);
  const [initiativeListView, setInitiativeListView] = useState<
    components['schemas']['Initiative'][]
  >([]);
  const [initiativeOptions, setInitiativeOptions] = useState<{ id: number; title: string }[]>([]);
  const [isLoadingInitiatives, setIsLoadingInitiatives] = useState<boolean>(false);

  const { data: roadmaps } = useQuery(getRoadmapList.name, getRoadmapList, {
    onError: () => {
      showToast('error', t('roadmaps.fetch_error'));
    },
  });

  const getFieldOptions = useMemo(() => {
    if (!roadmaps) {
      return [];
    }

    return roadmaps
      .map(roadmap => ({ id: roadmap.id!, title: roadmap.title }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [roadmaps]);

  const handleChange = async (value: number | null) => {
    setIsLoadingInitiatives(true);
    setSelectedRoadmapId(value);

    const initiatives = await getRoadmapInitiatives({ roadmapId: value! });
    const sortedInitiatives = initiatives.sort((a, b) => a.title.localeCompare(b.title));
    setInitiativeList(sortedInitiatives);
    setInitiativeListView([]);

    const initiativesOptions = sortedInitiatives.map(initiative => {
      return {
        id: initiative.id!,
        title: initiative.title,
      };
    });
    setInitiativeOptions(initiativesOptions);
    setIsLoadingInitiatives(false);
  };

  const handleInitiativeChange = (values: number[] | null) => {
    const initiatives = initiativeList.filter(initiative =>
      values?.some(id => initiative.id === id)
    );
    setInitiativeListView(initiatives);
  };

  const handleUpdateList = (id: number) => {
    const initiatives = initiativeList.filter(initiative => initiative.id !== id);
    setInitiativeList(initiatives);

    const initiativesView = initiativeListView.filter(initiative => initiative.id !== id);
    setInitiativeListView(initiativesView);

    const newInitiativeOptions = initiatives.map(initiative => {
      return {
        id: initiative.id!,
        title: initiative.title,
      };
    });
    setInitiativeOptions(newInitiativeOptions);
  };

  return (
    <ContentWrapper>
      <ImportButton
        $isActive={isActive}
        type="button"
        startIcon={<ButtonIcon />}
        onClick={() => {
          setActive(!isActive);
        }}
        fullWidth
      >
        {t('editor.sidebar.data_tab.linkage_button_text')}
      </ImportButton>
      {isActive && (
        <>
          <StyledSelect
            placeholder={t('editor.sidebar.data_tab.select_roadmap_placeholder')}
            selectedId={selectedRoadmapId}
            options={getFieldOptions}
            onChange={handleChange}
          />
          {isLoadingInitiatives && (
            <LoaderWrapper>
              <StyledLoader />
            </LoaderWrapper>
          )}
          {selectedRoadmapId && !isLoadingInitiatives && (
            <>
              <StyledSelectCheckmarks
                placeholder={t('editor.sidebar.data_tab.checklist_title_edit')}
                options={initiativeOptions}
                onChange={handleInitiativeChange}
              />
              {!!initiativeListView.length && (
                <ImportList
                  title={t('editor.sidebar.data_tab.checklist_title')}
                  onUpdateList={handleUpdateList}
                  list={initiativeListView}
                  disabled={isInitiativeImportRunning}
                />
              )}
            </>
          )}
        </>
      )}
    </ContentWrapper>
  );
};

export { Linkage };

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
`;

const ImportButton = styled(Button)<{ $isActive?: boolean }>`
  display: flex;
  border-radius: 2rem;
  padding: 0.25rem 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  background-color: ${({ theme }) => theme.palette.brand.white};
  gap: 0rem !important;
  justify-content: center !important;
  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.white)};
  }
  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      color: ${theme.palette.brand.white};
      background-color: ${theme.palette.brand.editorTabPrimary};
      svg {
        path {
          fill: ${theme.palette.brand.white};
        }
      }
      :hover {
        background-color: ${theme.palette.brand.editorTabSecondary};
      }
    `}
`;

const ButtonIcon = styled(Import)`
  width: 1.5rem;
  height: 1.5rem;
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;
