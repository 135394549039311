import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

const StyledIcon = styled(SVG)<{ onClick: unknown }>`
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
  & path {
    fill: ${props => props.fill ?? props.theme.palette.brand.textPrimary};
  }
`;

export default StyledIcon;
