import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getFiltersByRoadmapId(roadmapId: number) {
  const { data } = await defaultApi.get(`/filters/roadmap/${roadmapId}`);
  return data;
}

export async function deleteFilterById(id: number) {
  return defaultApi.delete(`/filters/${id}`);
}

export async function createRoadmapFilter({
  roadmapId,
  payload,
}: {
  roadmapId: number;
  payload: components['schemas']['Filter'];
}) {
  const { data } = await defaultApi.post<components['schemas']['Filter']>(`/filters/${roadmapId}`, [
    payload,
  ]);
  return data;
}

export async function editFilter(filterId: number, payload: components['schemas']['Filter']) {
  return defaultApi.put(`/filters/${filterId}`, [payload]);
}
