import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import {
  createInitiativeField,
  createRoadmapsPopupVisible,
  createRoadmapsRoadmapVisible,
  deleteRoadmapsDropdownVisible,
  getRoadmapsDropdownVisible,
  getRoadmapsPopupVisible,
  getRoadmapsRoadmapVisible,
} from '@/features/canvas/api';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

interface Props {
  initiativeId?: number;
}

export const useInitiativeFields = ({ initiativeId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();
  const { roadmap, updateLastSavedTime } = useEditorContext();

  const { mutateAsync: createRoadmapsPopupVisibleMutation } = useMutation(
    createRoadmapsPopupVisible
  );

  const { mutateAsync: createRoadmapsRoadmapVisibleMutation } = useMutation(
    createRoadmapsRoadmapVisible
  );

  const { mutateAsync: deleteRoadmapsDropdownVisibleMutation } = useMutation(
    deleteRoadmapsDropdownVisible
  );

  const { mutateAsync: createInitiativeFieldMutation } = useMutation(createInitiativeField);

  const { mutateAsync: createField, isLoading: isCreatingField } = useMutation(
    async (values: {
      dropdownField: { title: string; type: string; id?: number | undefined } | undefined;
      field: components['schemas']['InitiativeField'];
    }) => {
      try {
        const { dropdownField, field } = values;

        if (dropdownField) {
          const newField = {
            id: field.id,
            title: field.title!,
            type: field.type,
          };

          const actions: Promise<unknown>[] = [];
          actions.push(
            createRoadmapsPopupVisibleMutation({
              roadmapId: roadmap.id!,
              newPopupVisible: newField,
            })
          );

          actions.push(
            createRoadmapsRoadmapVisibleMutation({
              roadmapId: roadmap.id!,
              newRoadmapVisible: newField,
            })
          );

          actions.push(
            deleteRoadmapsDropdownVisibleMutation({
              roadmapId: dropdownField.id!,
            })
          );

          await Promise.all(actions);
        } else {
          await createInitiativeFieldMutation({
            roadmapId: roadmap.id!,
            initiativeId: initiativeId!,
            newInitiativeField: field,
          });
        }

        await Promise.all([
          queryClient.invalidateQueries([getRoadmapsPopupVisible.name, roadmap.id]),
          queryClient.invalidateQueries([getRoadmapsRoadmapVisible.name, roadmap.id]),
          queryClient.invalidateQueries([getRoadmapsDropdownVisible.name, roadmap.id]),
        ]);

        updateLastSavedTime();
        showToast('success', t('editor.sidebar.initiative.create_field_success'));
      } catch (e) {
        showToast('error', t('editor.sidebar.initiative.create_field_error'));
      }
    }
  );

  return { createField, isCreatingField };
};
