import Button from 'src/features/ui/components/Button/Button';
import { Card } from 'src/features/ui/components/Card/Card';
import Input from 'src/features/ui/components/Input/Input';
import Text from 'src/features/ui/components/Text/Text';
import Textarea from 'src/features/ui/components/Textarea/Textarea';
import styled from 'styled-components';

export const Wrapper = styled(Card)`
  background-color: ${({ theme }) => theme.palette.brand.white};
  flex: 1;
  display: grid;
  padding: 3rem 4rem;
  width: 100%;
`;

export const GridWrapper = styled.div`
  gap: 2rem;
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
`;

export const Title = styled(Text)`
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;
  margin: 0 0 2.7rem 0;
  letter-spacing: 0.1rem;
`;

export const InputRow = styled.div<{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: ${props => props.marginLeft ?? '0rem'};
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

export const Label = styled(Text)<{ marginTop?: string }>`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.1rem;
  line-height: 2.7rem;
  margin-bottom: 0.7rem;
  margin-top: ${props => props?.marginTop ?? '0'};
`;

export const NameInput = styled(Input)`
  height: 6rem;
`;

export const EmailInput = styled(Input)`
  height: 6rem;
`;

export const TextAreaInput = styled(Textarea)`
  height: 19rem;
`;
export const CentralizedButtonDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const Submit = styled(Button)`
  height: 4rem;
  width: 13.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7.1rem 0 3.7rem 0;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.1rem;
  letter-spacing: 0.1rem;
`;

export const ErrorMessage = styled(Text)`
  margin-top: 1rem;
`;
