import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';

import BinIconSrc from '@/assets/icons/bin.svg';
import CalendarIconSrc from '@/assets/icons/calendar.svg';
import { DATE_FORMAT, SUPPORTED_DATE_FORMATS } from '@/constants/common';
import { DatePicker } from '@/features/canvas/components/DatePicker';
import { ListStyledImage } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeCustom/InitiativeCustom';
import { HideFieldIcon } from '@/features/canvas/components/InitiativesAccordionContent/HideFieldIcon';
import { components } from '@/types/api';

const InitiativeDate = ({
  onDeleteField,
  field,
  onUpdateField,
  onClick,
  readOnly,
  isInitiativeLockedAndIsNotOwner,
}: {
  field: components['schemas']['InitiativeField'];
  isInitiativeLockedAndIsNotOwner: boolean;
  onClick: (e: React.MouseEvent<SVGElement | HTMLInputElement>) => void;
  onDeleteField: (fieldId: number, e: React.MouseEvent<HTMLButtonElement>) => void;
  onUpdateField: (field: components['schemas']['InitiativeField']) => void;
  readOnly: boolean;
}) => {
  const { t } = useTranslation();
  const currentDay = field.value ? dayjs(field.value, SUPPORTED_DATE_FORMATS) : dayjs();

  const [date, setDate] = useState<{ day: string; month: string; year: string }>({
    day: '',
    month: '',
    year: '',
  });
  const [pickerAnchorEl, setPickerAnchorEl] = useState<SVGElement | null>(null);

  useEffect(() => {
    if (field.value) {
      const newDate = dayjs(field.value, SUPPORTED_DATE_FORMATS);
      if (newDate.isValid()) {
        setDate({
          day: newDate.date().toString(),
          month: (newDate.month() + 1).toString(),
          year: newDate.year().toString(),
        });
      }
    }
  }, [field.value]);

  const onClickDateIcon = (event: React.MouseEvent<SVGElement>) => {
    setPickerAnchorEl(event.currentTarget);
    onClick(event);
  };

  const handleCloseDatePicker = () => {
    setPickerAnchorEl(null);
  };

  const handleDatePickerChanged = (value: Dayjs) => {
    handleDateChanged(value);
    handleCloseDatePicker();
  };

  const handleDateInputChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const regex = id === 'year' ? /^(?:[0-9]{0,4})$/ : /^(?:[0-9]{0,2})$/;

    if (regex.test(value)) {
      const changedDate = { ...date, [id]: value };
      setDate(changedDate);
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (readOnly || isInitiativeLockedAndIsNotOwner) return;
    const { id, value } = event.target;
    const changedDate = { ...date, [id]: value };
    const isDateValid = dayjs(
      `${changedDate.year}-${changedDate.month}-${changedDate.day}`
    ).isValid();

    if (!isDateValid) {
      return;
    }
    setDate(changedDate);
    handleDateChanged(dayjs(`${changedDate.year}-${changedDate.month}-${changedDate.day}`));
  };

  const handleDateChanged = (value: Dayjs) => {
    const updatedField = { ...field, value: value.format(DATE_FORMAT) };
    onUpdateField(updatedField);
  };

  return (
    <>
      <DateWrapper>
        <StyledSVG
          src={CalendarIconSrc}
          onClick={e => {
            if (readOnly || isInitiativeLockedAndIsNotOwner) return;
            onClickDateIcon(e);
          }}
        />

        <DatePicker
          disabled={readOnly || isInitiativeLockedAndIsNotOwner}
          anchorEl={pickerAnchorEl}
          handleClose={handleCloseDatePicker}
          dateValue={currentDay}
          handleChange={handleDatePickerChanged}
        />
        <TitleInput
          readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
          type="text"
          id="day"
          value={date.day}
          onChange={handleDateInputChanged}
          onBlur={handleBlur}
          placeholder={t('editor.sidebar.initiative.day_placeholder')}
          onClick={onClick}
        />
        <TitleInput
          readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
          type="text"
          id="month"
          value={date.month}
          onChange={handleDateInputChanged}
          onBlur={handleBlur}
          placeholder={t('editor.sidebar.initiative.month_placeholder')}
          onClick={onClick}
        />
        <TitleInput
          readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
          type="text"
          id="year"
          value={date.year}
          onChange={handleDateInputChanged}
          onBlur={handleBlur}
          placeholder={t('editor.sidebar.initiative.year_placeholder')}
          onClick={onClick}
        />
      </DateWrapper>
      {!readOnly && (
        <MainActions>
          <HideFieldIcon initiativeField={field} />
          {!isInitiativeLockedAndIsNotOwner && (
            <IconButton aria-label="delete" size="small" onClick={e => onDeleteField(field.id!, e)}>
              <Tooltip title={t('editor.sidebar.initiative.delete_field')} placement="top">
                <ListStyledImage src={BinIconSrc} />
              </Tooltip>
            </IconButton>
          )}
        </MainActions>
      )}
    </>
  );
};

export { InitiativeDate };

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledSVG = styled(SVG)`
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
`;

const TitleInput = styled.input`
  outline: none;
  width: 25%;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const MainActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
