import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { FilterToBeCreatedByImport } from '@/features/canvas/types/filter';
import { InitiativeToBeImported } from '@/features/canvas/types/initiative';
import { CustomField } from '@/features/canvas/types/initiative-field';

const ImportInitiativeContext = createContext({
  initiativeToImport: null as InitiativeToBeImported | null,
  setInitiativeToImport: (() => {}) as Dispatch<InitiativeToBeImported | null>,
  isInitiativeImportRunning: false,
  setIsInitiativeImportRunning: (() => {}) as Dispatch<SetStateAction<boolean>>,
  customFieldsToBeCreatedFromImport: [] as CustomField[],
  setCustomFieldsToBeCreatedFromImport: (() => {}) as Dispatch<CustomField[]>,
  filtersToBeCreatedFromImport: [] as FilterToBeCreatedByImport[],
  setFiltersToBeCreatedFromImport: (() => {}) as Dispatch<FilterToBeCreatedByImport[]>,
  selectedRoadmapId: null as number | null,
  setSelectedRoadmapId: (() => {}) as Dispatch<SetStateAction<number | null>>,
});

export const ImportInitiativeContextProvider = ({ children }: { children: ReactNode }) => {
  const [initiativeToImport, setInitiativeToImport] = useState<InitiativeToBeImported | null>(null);
  const [isInitiativeImportRunning, setIsInitiativeImportRunning] = useState<boolean>(false);
  const [selectedRoadmapId, setSelectedRoadmapId] = useState<number | null>(null);
  const [customFieldsToBeCreatedFromImport, setCustomFieldsToBeCreatedFromImport] = useState<
    CustomField[]
  >([]);
  const [filtersToBeCreatedFromImport, setFiltersToBeCreatedFromImport] = useState<
    FilterToBeCreatedByImport[]
  >([]);

  return (
    <ImportInitiativeContext.Provider
      value={{
        initiativeToImport,
        setInitiativeToImport,
        isInitiativeImportRunning,
        setIsInitiativeImportRunning,
        customFieldsToBeCreatedFromImport,
        setCustomFieldsToBeCreatedFromImport,
        filtersToBeCreatedFromImport,
        setFiltersToBeCreatedFromImport,
        selectedRoadmapId,
        setSelectedRoadmapId,
      }}
    >
      {children}
    </ImportInitiativeContext.Provider>
  );
};

export const useImportInitiativeContext = () => {
  const context = useContext(ImportInitiativeContext);

  if (!context) {
    throw Error(
      'ImportInitiativeContext hook can be used only inside ImportInitiativeContextProvider'
    );
  }

  return context;
};
