import { ButtonHTMLAttributes, ReactNode } from 'react';

import StyledButton, { StyledButtonProps } from './Button.styled';

export type ButtonProps = StyledButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string;
    icon?: ReactNode;
  };

const Button: React.FC<ButtonProps> = props => {
  const { icon, label, ...rest } = props;
  return (
    <StyledButton type="button" {...rest}>
      {icon}
      <span>{label}</span>
    </StyledButton>
  );
};

export default Button;
