import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';

import { useResources } from '@/contexts/resource-context';
import { InitiativeDetailsDialogContent } from '@/features/canvas/components/InitiativeDetailsDialogContent';
import { SwimlaneView } from '@/features/canvas/components/SwimlaneView';
import { TimelineEditorDeleteInitiativeDialogs } from '@/features/canvas/components/TimelineEditorDeleteInitiativeDialogs';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { Canvas } from '@/features/canvas/modules/Canvas';
import { EditorFilterSidebar } from '@/features/canvas/modules/EditorFilterSidebar';
import { Dialog } from '@/features/ui/components/Dialog';
import { Editor } from '@/layouts/Editor';

const TimelineEditor: React.FC = () => {
  const stageContainerRef = useRef<HTMLDivElement>(null);
  const {
    filteredNodes,
    setIsInitiativeDetailDialogOpen,
    isInitiativeDetailDialogOpen,
    isPreview,
    isPublicView,
    isSwimlaneView,
    width,
    setWidth,
    selectedNodeId,
    height,
    setHeight,
    lastSavedTime,
  } = useEditorContext();

  const { logoUrl } = useResources();

  const delay = 60 * 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(lastSavedTime);
    }, delay);

    return () => clearInterval(interval);
  }, [delay, lastSavedTime]);

  const { t } = useTranslation();

  const [isBasicDeleteInitiativeDialogOpen, setIsBasicDeleteInitiativeDialogOpen] = useState(false);
  const [isDualChoiceDeleteInitiativeDialogOpen, setIsDualChoiceDeleteInitiativeDialogOpen] =
    useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(event => {
      setWidth(event[0].contentBoxSize[0].inlineSize);
      setHeight(event[0].contentBoxSize[0].blockSize);
    });
    const stageContainer = stageContainerRef.current;
    if (stageContainer) {
      resizeObserver.observe(stageContainer);
    }

    return () => {
      if (stageContainer) {
        resizeObserver.unobserve(stageContainer);
      }
    };
  });

  const isCanvasContainerSizeInitialized = width && height;

  const dialogTitle = filteredNodes.find(node => Number(node.id) === Number(selectedNodeId))
    ?.initiatives?.title;

  return (
    <Editor
      setIsBasicDeleteInitiativeDialogOpen={setIsBasicDeleteInitiativeDialogOpen}
      setIsDualChoiceDeleteInitiativeDialogOpen={setIsDualChoiceDeleteInitiativeDialogOpen}
    >
      <CanvasContainer $isSwimlaneView={isSwimlaneView} ref={stageContainerRef}>
        {!isCanvasContainerSizeInitialized && (
          <CanvasLoaderWrapper>
            <StyledLoader />
          </CanvasLoaderWrapper>
        )}

        {isCanvasContainerSizeInitialized && !isSwimlaneView && (
          <Canvas key={`canvas-${isPreview}-${isPublicView}`} />
        )}
        {isCanvasContainerSizeInitialized && isSwimlaneView && <SwimlaneView />}
        {selectedNodeId && (
          <Dialog
            onClose={() => {
              setIsInitiativeDetailDialogOpen(false);
            }}
            open={isInitiativeDetailDialogOpen}
            title={dialogTitle}
            disablePortal
            keepMounted={false}
            fullScreen={false}
            disableRestoreFocus
            disableEnforceFocus
            disableAutoFocus
            sx={{
              '&.MuiDialog-root': {
                position: 'absolute',
              },
              '& .MuiBackdrop-root': {
                position: 'absolute',
              },
              '& .MuiDialog-paper': {
                padding: '2rem 2rem 4rem 2rem',
                borderRadius: '2rem',
                minWidth: '900px',
                overflowY: 'unset',
              },
            }}
          >
            <InitiativeDetailsDialogContent />
          </Dialog>
        )}
      </CanvasContainer>
      <ContentWrapper>
        <LogoWrapper>
          <StyledImage src={logoUrl} />
        </LogoWrapper>
        <EditorFilterSidebar />
      </ContentWrapper>
      <Copyright>
        {t('copyright', {
          currentYear: dayjs().year(),
        })}
      </Copyright>
      <TimelineEditorDeleteInitiativeDialogs
        isDualChoiceDeleteInitiativeDialogOpen={isDualChoiceDeleteInitiativeDialogOpen}
        setIsBasicDeleteInitiativeDialogOpen={setIsBasicDeleteInitiativeDialogOpen}
        setIsDualChoiceDeleteInitiativeDialogOpen={setIsDualChoiceDeleteInitiativeDialogOpen}
        isBasicDeleteInitiativeDialogOpen={isBasicDeleteInitiativeDialogOpen}
      />
    </Editor>
  );
};

const ContentWrapper = styled.div`
  padding: 2rem 2rem 0 0;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  margin: 2rem 0 4rem;
  padding: 1rem 3rem;
  width: 250px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const CanvasLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CanvasContainer = styled.div<{ $isSwimlaneView: boolean }>`
  flex: 5 1 calc(100% - 280px);

  ${({ $isSwimlaneView }) =>
    !$isSwimlaneView &&
    css`
      overflow: hidden;
    `}
`;

const Copyright = styled.div`
  position: absolute;
  width: 250px;
  bottom: 0;
  right: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0 1.5rem 0;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { TimelineEditor };
