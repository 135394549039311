import { useMutation, UseMutationOptions } from 'react-query';
import { defaultApi } from 'src/api';

import { CreatePasswordFormData } from '../types/User';

const useCreatePasswordMutation = (
  options?: UseMutationOptions<boolean, unknown, CreatePasswordFormData>
) => {
  return useMutation<boolean, unknown, CreatePasswordFormData>(async formData => {
    try {
      await defaultApi.post('/create-new-password', formData);
      return true;
    } catch {
      return false;
    }
  }, options);
};

export default useCreatePasswordMutation;
