import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { ReactComponent as BinIconSrc } from 'src/assets/icons/bin.svg';
import { ReactComponent as LinkIconSrc } from 'src/assets/icons/link.svg';
import ProjectThumbnailIconSrc from 'src/assets/images/roadmapIcon.svg';
import styled from 'styled-components';

import { DATE_FORMAT } from '@/constants/common';
import { createRoadmapPublicLinkUrl } from '@/features/canvas/utils/create-roadmap-public-link-url';
import { deletePublicLink, getPublicLinks } from '@/features/publicLinks/api';
import { PasswordCell } from '@/features/publicLinks/components/Table/PasswordCell';
import { ConfirmationDialog } from '@/features/ui/components/ConfirmationDialog';
import { useHandleCopyUrl } from '@/hooks/useHandleCopyUrl';
import { useShowToast } from '@/hooks/useShowToast';
import { PageWithSidebar } from '@/layouts/PageWithSidebar';

const PublicLinks = () => {
  const { t } = useTranslation();
  const { handleCopyUrl } = useHandleCopyUrl();
  const { showToast } = useShowToast();

  const queryClient = useQueryClient();

  const [isDeletePublicLinkDialogOpen, setIsDeletePublicLinkDialogOpen] = useState(false);
  const [selectedPublicLinkId, setSelectedPublicLinkId] = useState<null | number>(null);

  const {
    data: publicLinks,
    isLoading,
    isError,
  } = useQuery(getPublicLinks.name, getPublicLinks, {
    onError: () => {
      showToast('error', t('public_links.fetch_error'));
    },
  });

  const { mutateAsync: deletePublicLinkMutation } = useMutation(deletePublicLink, {
    onSuccess: async () => {
      showToast('success', t('public_links.delete_success'));
      setIsDeletePublicLinkDialogOpen(false);
      await queryClient.invalidateQueries(getPublicLinks.name);
    },
    onError: () => {
      showToast('error', t('public_links.delete_error'));
    },
  });

  const sortedLinksByDate = useMemo(() => {
    return publicLinks?.sort((a, b) => {
      const time1 = new Date(a.publishDate).getTime();
      const time2 = new Date(b.publishDate).getTime();
      return time2 - time1;
    });
  }, [publicLinks]);

  return (
    <PageWithSidebar gridColumnStart={1} gridColumnEnd={4}>
      {isLoading && !publicLinks && (
        <LoaderWrapper>
          <StyledLoader />
        </LoaderWrapper>
      )}
      {!isLoading && publicLinks && !!publicLinks.length && !isError && (
        <TableContainer>
          <Table sx={{ minWidth: 500 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCellHeader align="center">
                  {t('public_links.table.name')}
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center">
                  {t('public_links.table.link')}
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center">
                  {t('public_links.table.password')}
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center">
                  {t('public_links.table.publish_date')}
                </StyledTableCellHeader>
                <StyledTableCellHeader align="center">{t('actions')}</StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedLinksByDate?.map(({ title, password, publishDate, url, id, isLocked }) => {
                const roadmapPublicLink = createRoadmapPublicLinkUrl(url);
                return (
                  <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableCell component="th" scope="row">
                      <Thumbnail>
                        <StyledImage src={ProjectThumbnailIconSrc} />
                      </Thumbnail>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <TitleWrapper>{title}</TitleWrapper>
                    </StyledTableCell>
                    <StyledTableCell align="center" sx={{ maxWidth: 300 }}>
                      <UrlCellContent>
                        <UrlText>{roadmapPublicLink}</UrlText>
                        <Tooltip title={t('public_links.tooltip.copy_link')} placement="top">
                          <IconWrapper>
                            <LinkIconSrc
                              onClick={() => handleCopyUrl({ url: roadmapPublicLink })}
                            />
                          </IconWrapper>
                        </Tooltip>
                      </UrlCellContent>
                    </StyledTableCell>
                    <PasswordCell row={{ title, password, publishDate, url, id, isLocked }} />
                    <StyledTableCell align="center">
                      {dayjs(publishDate).format(DATE_FORMAT)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Actions>
                        <Tooltip title={t('public_links.tooltip.delete_link')} placement="top">
                          <BinWrapper>
                            <BinIconSrc
                              onClick={() => {
                                setSelectedPublicLinkId(Number(id));
                                setIsDeletePublicLinkDialogOpen(true);
                              }}
                            />
                          </BinWrapper>
                        </Tooltip>
                      </Actions>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!isLoading && (isError || !publicLinks?.length) && (
        <EmptyView>{t('public_links.no_public_links_to_show')}</EmptyView>
      )}
      <ConfirmationDialog
        open={isDeletePublicLinkDialogOpen}
        okButtonText={t('yes_sure')}
        keepMounted={false}
        description={t('delete_link.dialog_description')}
        handleOk={() => deletePublicLinkMutation(Number(selectedPublicLinkId))}
        id="delete-public-link-confirm-dialog"
        onClose={() => {
          setSelectedPublicLinkId(null);
          setIsDeletePublicLinkDialogOpen(false);
        }}
      />
    </PageWithSidebar>
  );
};

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const UrlCellContent = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const StyledTableCell = styled(TableCell)`
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

const StyledTableCellHeader = styled(TableCell)`
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.brand.primary};
  font-weight: bold;
`;

const UrlText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.palette.brand.primary};
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 2.2rem;
  height: 2.2rem;

  svg {
    width: 100%;
    height: 100%;
    path,
    rect {
      fill: ${({ theme }) => theme.palette.brand.primary};
    }
  }

  &:hover {
    svg {
      path,
      rect {
        fill: ${({ theme }) => theme.palette.brand.editorTabPrimary};
      }
    }
  }
`;

const BinWrapper = styled.div`
  cursor: pointer;
  width: 2.2rem;
  height: 2.2rem;

  svg {
    width: 100%;
    height: 100%;
    path,
    rect {
      stroke: ${({ theme }) => theme.palette.brand.primary};
    }
  }

  &:hover {
    svg {
      path,
      rect {
        stroke: ${({ theme }) => theme.palette.brand.editorTabPrimary};
      }
    }
  }
`;

const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    width: 80px;
    height: 80px;
  }
`;

const EmptyView = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PublicLinks;
