import 'src/features/i18n/i18n';

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { styled } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import WebFont from 'webfontloader';

import { MaintenanceModeWrapper } from '@/components/MaintenanceModeWrapper';
import { AccountContextProvider } from '@/contexts/account-context';
import { ResourceContextProvider } from '@/contexts/resource-context';

import { QueryProvider } from './api';
import App from './App';

WebFont.load({
  google: {
    families: ['Open Sans'],
  },
});

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    fontSize: '1.3rem',
  },
}));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <SnackbarProvider
      Components={{ success: StyledMaterialDesignContent, error: StyledMaterialDesignContent }}
    >
      <BrowserRouter>
        <CookiesProvider>
          <QueryProvider>
            <StyledEngineProvider injectFirst>
              <MaintenanceModeWrapper>
                <ResourceContextProvider>
                  <AccountContextProvider>
                    <App />
                  </AccountContextProvider>
                </ResourceContextProvider>
              </MaintenanceModeWrapper>
            </StyledEngineProvider>
          </QueryProvider>
        </CookiesProvider>
      </BrowserRouter>
    </SnackbarProvider>
  </React.StrictMode>
);
