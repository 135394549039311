import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog as MuiDialog, DialogContent, DialogProps, DialogTitle } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as CloseDialogIcon } from '@/assets/icons/close-dialog-icon.svg';
import { LinkageColumn, LinkageRow, LinkedColumn } from '@/features/canvas/types/linkage';

import { FieldLinkingSelectionDialog } from './FieldLinkingSelectionDialog';
import { LinkingTable } from './LinkingTable';

type LinkingDialogProps = DialogProps & {
  columns: LinkageColumn[];
  linkedColumns: LinkedColumn[];
  onSubmit: () => void;
  rows: LinkageRow[];
  setLinkedColumns: Dispatch<SetStateAction<LinkedColumn[]>>;
  onClose?: () => void;
};

const LinkingDialog = ({
  open,
  onClose,
  rows,
  columns,
  linkedColumns,
  setLinkedColumns,
  onSubmit,
  ...other
}: LinkingDialogProps) => {
  const { t } = useTranslation();

  const [isFieldLinkingDialogOpen, setIsFieldLinkingDialogOpen] = useState(false);

  const [linkingColumnId, setLinkingColumnId] = useState<string | null>(null);

  return (
    <MuiDialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          padding: '5rem',
          borderRadius: '2rem',
          minWidth: 800,
          overflowY: 'unset',
        },
      }}
      {...other}
    >
      <StyledDialogTitle>
        {t('editor.sidebar.data_tab.linking_dialog_title')}
        {onClose && (
          <StyledCloseWrapper onClick={() => onClose()}>
            <CloseDialogIcon />
          </StyledCloseWrapper>
        )}
      </StyledDialogTitle>
      <DialogSubtitle>{t('editor.sidebar.data_tab.linking_dialog_subtitle')}</DialogSubtitle>
      <DialogContent sx={{ padding: 0 }}>
        <LinkingTable
          dataColumns={columns}
          dataRows={rows}
          linkedColumns={linkedColumns}
          setIsFieldLinkingDialogOpen={setIsFieldLinkingDialogOpen}
          setLinkingColumnId={setLinkingColumnId}
          setLinkedColumns={setLinkedColumns}
          onSubmit={onSubmit}
        />
      </DialogContent>
      <FieldLinkingSelectionDialog
        open={isFieldLinkingDialogOpen}
        onClose={() => {
          setLinkingColumnId(null);
          setIsFieldLinkingDialogOpen(false);
        }}
        linkingColumnId={linkingColumnId}
        linkedColumns={linkedColumns}
        setLinkedColumns={setLinkedColumns}
        dataRows={rows}
        indexOfSelectedOptionId={columns.findIndex(c => c.id === linkingColumnId)}
      />
    </MuiDialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  font-weight: 600;
  margin-bottom: 0.5rem;
  padding: 0;
`;

const DialogSubtitle = styled.div`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  margin-bottom: 2rem;
`;

const StyledCloseWrapper = styled(Box)`
  cursor: pointer;
  top: -2rem;
  right: -2rem;
  position: absolute;
  border-radius: 2rem;
  width: 5rem;
  height: 5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  background-color: ${({ theme }) => theme.palette.brand.white};

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: ${({ theme }) => theme.palette.brand.primary};
    }
  }
`;

export { LinkingDialog };
