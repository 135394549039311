import { Dispatch, SetStateAction } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@mui/material';
import styled, { css } from 'styled-components';

import { type AccountSettingsFormState } from '@/features/accountSettings/types/accountSettings';
import { useFormContext } from '@/features/ui/components/Form';
import { FormTextInput } from '@/features/ui/components/FormTextInput';

const AccountSettingsFormContent = ({
  isLoading,
  setShouldChangePassword,
  shouldChangePassword,
}: {
  isLoading: boolean;
  setShouldChangePassword: Dispatch<SetStateAction<boolean>>;
  shouldChangePassword: boolean;
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();
  const newPassword = useWatch<AccountSettingsFormState>({
    control: control as unknown as Control<AccountSettingsFormState>,
    name: 'newPassword',
  });

  return (
    <FormContent>
      <FormTextInput
        readOnly
        $shouldHideBorder
        name="email"
        label={`${t('form.email')}:`}
        placeholder={t('form.email_placeholder')}
        required
        size="medium"
      />

      <GridOverride gridColumnStart={1} gridRowStart={2}>
        <FormTextInput
          name="firstName"
          label={`${t('form.first_name')}:`}
          placeholder={t('form.first_name_placeholder')}
          required
          rules={{
            maxLength: {
              value: 32,
              message: t('form.field_too_long'),
            },
          }}
          size="medium"
        />
      </GridOverride>
      <GridOverride gridColumnStart={2} gridRowStart={2}>
        <FormTextInput
          name="lastName"
          label={`${t('form.last_name')}:`}
          placeholder={t('form.last_name_placeholder')}
          required
          rules={{
            maxLength: {
              value: 32,
              message: t('form.field_too_long'),
            },
          }}
          size="medium"
        />
      </GridOverride>
      <GridOverride gridColumnStart={1} gridRowStart={3}>
        <FormTextInput
          autoComplete="password"
          readOnly={!shouldChangePassword}
          name="oldPassword"
          label={`${t('form.password')}:`}
          type="password"
          size="medium"
          $shouldHideBorder={!shouldChangePassword}
          secondaryLabel={shouldChangePassword ? t('form.enter_current_password') : undefined}
          placeholder={t('form.password_asterisks_placeholder')}
          rules={
            shouldChangePassword
              ? {
                  required: { value: true, message: t('authentication.password_required') },
                  pattern: {
                    value: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{10,}$/g,
                    message: t('authentication.wrong_password_format'),
                  },
                  minLength: {
                    value: 10,
                    message: t('authentication.wrong_password_length', { maxCharCount: 10 }),
                  },
                }
              : {}
          }
        />
        {!shouldChangePassword && (
          <StyledSecondaryButton onClick={() => setShouldChangePassword(true)}>
            {t('change_password')}
          </StyledSecondaryButton>
        )}
      </GridOverride>
      {shouldChangePassword && (
        <>
          <GridOverride gridColumnStart={2} gridRowStart={3}>
            <FormTextInput
              autoComplete="new-password"
              name="newPassword"
              label={`${t('form.new_password')}:`}
              type="password"
              size="medium"
              icon="key"
              rules={{
                required: { value: true, message: t('authentication.password_required') },
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{10,}$/g,
                  message: t('authentication.wrong_password_format'),
                },
                minLength: {
                  value: 10,
                  message: t('authentication.wrong_password_length', { maxCharCount: 10 }),
                },
              }}
            />
          </GridOverride>
          <GridOverride gridColumnStart={2} gridRowStart={4}>
            <FormTextInput
              autoComplete="new-password"
              name="newPasswordAgain"
              label={`${t('form.re_enter_password')}:`}
              icon="key"
              type="password"
              size="medium"
              rules={{
                required: { value: true, message: t('authentication.password_required') },
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{10,}$/g,
                  message: t('authentication.wrong_password_format'),
                },
                minLength: {
                  value: 10,
                  message: t('authentication.wrong_password_length', { maxCharCount: 10 }),
                },
                validate: {
                  passwordMatch: value => {
                    if (value !== newPassword) {
                      return t('authentication.new_password_again_not_matching');
                    }
                    return true;
                  },
                },
              }}
            />
          </GridOverride>
        </>
      )}
      <GridOverride gridColumnStart={1} gridRowStart={4}>
        <ButtonWrapper>
          <StyledSecondaryButton type="submit">
            {isLoading && <StyledLoader color="inherit" size={20} sx={{ marginRight: '1rem' }} />}
            {t('save')}
          </StyledSecondaryButton>
        </ButtonWrapper>
      </GridOverride>
    </FormContent>
  );
};

const FormContent = styled.div`
  gap: 1rem 4rem;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  margin: 4rem 0;
`;

const GridOverride = styled.div<{ gridColumnStart: number; gridRowStart: number }>`
  ${({ gridRowStart, gridColumnStart }) => css`
    grid-row-start: ${gridRowStart};
    grid-column-start: ${gridColumnStart};
  `}
`;

const StyledSecondaryButton = styled(Button)`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.brand.primary};
  color: ${({ theme }) => theme.palette.brand.white};
  padding: 0.2rem 3rem;
  border-radius: 2rem;
  font-size: 2rem;
  text-transform: none;
  font-weight: 600;
  min-width: 150px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.brand.secondary};
  }
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: end;
  padding-bottom: 1rem;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { AccountSettingsFormContent };
