import { ReactNode } from 'react';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

type Props = {
  colEnd?: number | string;
  colSize?: number;
  content?: ReactNode;
  rowEnd?: number | string;
  rowSize?: number;
  title?: string;
  value?: string;
};

const InitiativeDetailField = ({
  colSize = 1,
  rowSize = 1,
  rowEnd = 'auto',
  colEnd = 'auto',
  title,
  value,
  content,
}: Props) => {
  return (
    <InitiativeDetailWrapper
      $colSize={colSize}
      $rowSize={rowSize}
      $rowEnd={rowEnd}
      $colEnd={colEnd}
    >
      {title && <InitiativeDetailFieldTitle>{title}</InitiativeDetailFieldTitle>}
      {!content && value && <InitiativeDetailFieldValue>{value}</InitiativeDetailFieldValue>}
      {content}
    </InitiativeDetailWrapper>
  );
};

const InitiativeDetailFieldTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.brand.primary};
`;

const InitiativeDetailWrapper = styled.div<{
  $colEnd: number | string;
  $colSize: number;
  $rowEnd: number | string;
  $rowSize: number;
}>`
  display: flex;
  border-radius: 1rem;
  flex-direction: column;
  gap: 0.5rem;

  ${({ $colSize, $rowSize, $colEnd, $rowEnd }) => css`
    grid-area: span ${$rowSize} / span ${$colSize} / ${$rowEnd} / ${$colEnd}};
  `}

  padding: 1.5rem;
  background-color: ${({ theme }) => theme.palette.brand.corePPTWhite};
  -webkit-box-shadow: inset 0px 0px 3px -1px ${({ theme }) => transparentize(0.5, theme.palette.brand.black)};
  -moz-box-shadow: inset 0px 0px 3px -1px ${({ theme }) => transparentize(0.5, theme.palette.brand.black)};
  box-shadow: inset 0px 0px 3px -1px ${({ theme }) => transparentize(0.5, theme.palette.brand.black)};
`;

const InitiativeDetailFieldValue = styled.div`
  color: ${({ theme }) => theme.palette.brand.textSecondary};
  letter-spacing: 0.1px;
  line-height: 1.5;
  font-size: 1.4rem;
  padding: 0.5rem 0;
  word-break: break-word;
  overflow: hidden;
  width: 100%;
`;
export { InitiativeDetailField };
