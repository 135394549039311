import { ROADMAP_ITEM_HEIGHT, ROADMAP_ITEM_WIDTH } from 'src/constants/common';
import styled from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  border-radius: 1rem;
  border: 2px dashed ${({ theme }) => theme.palette.brand.backgroundDialog};
  padding: 2rem;
  display: flex;
  flex-direction: row;
  max-height: 250px;
`;
export const Title = styled.div`
  color: ${({ theme }) => theme.palette.brand.textSecondary};
  font-weight: 600;
  font-size: 2rem;
`;

export const IconButton = styled.button`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: ${ROADMAP_ITEM_WIDTH * 0.9}px;
  height: ${ROADMAP_ITEM_HEIGHT * 0.9}px;
  border: 2px dashed ${({ theme }) => theme.palette.brand.backgroundDialog};
  border-radius: 14px;
  box-sizing: border-box;
  margin-right: 8px;
`;
