import { Box, Dialog as MuiDialog, DialogProps, DialogTitle } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as CloseDialogIcon } from '@/assets/icons/close-dialog-icon.svg';

type ConfirmationDialogProps = DialogProps & {
  onClose?: () => void;
  title?: string;
};

const Dialog = ({ title, open, onClose, children, ...other }: ConfirmationDialogProps) => {
  return (
    <MuiDialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          padding: '2rem 2rem 4rem 2rem',
          borderRadius: '2rem',
          minWidth: '800px',
          overflowY: 'unset',
        },
      }}
      {...other}
    >
      <StyledDialogTitle>
        {title}
        {onClose && (
          <StyledCloseWrapper onClick={() => onClose()}>
            <CloseDialogIcon />
          </StyledCloseWrapper>
        )}
      </StyledDialogTitle>
      {children}
    </MuiDialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.brand.primary};
  font-weight: 700;
  padding: 0 2rem 2rem;
`;

const StyledCloseWrapper = styled(Box)`
  cursor: pointer;
  top: -2rem;
  right: -2rem;
  position: absolute;
  border-radius: 2rem;
  width: 5rem;
  height: 5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  background-color: ${({ theme }) => theme.palette.brand.white};

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${({ theme }) => theme.palette.brand.primary};
    }
  }
`;

export { Dialog };
