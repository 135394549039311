import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { SwimlaneViewNode } from '@/features/canvas/components/SwimlaneViewNode';
import { SwimlaneNode } from '@/features/canvas/types/swimlane-node';

type Props = {
  isLast: boolean;
  isSectionOdd: boolean;
  nodes?: SwimlaneNode[];
};

const SwimlaneViewSectionGridCell = ({ nodes, isSectionOdd, isLast }: Props) => {
  return (
    <GridCell $isSectionOdd={isSectionOdd} $isLast={isLast}>
      <NodesWrapper>
        {nodes?.map(node => {
          return (
            <SwimlaneViewNode
              nodeId={node.nodeId!}
              key={node.nodeId}
              title={node.title}
              progress={node.progress}
              color={node.color}
              isNodeOptionChecked={node.isNodeOptionChecked}
              nodeHasActiveFilter={node.nodeHasActiveFilter}
            />
          );
        })}
      </NodesWrapper>
    </GridCell>
  );
};

const GridCell = styled.div<{ $isLast: boolean; $isSectionOdd: boolean }>`
  background-color: ${({ theme, $isSectionOdd }) =>
    $isSectionOdd ? theme.palette.brand.sunray : theme.palette.brand.white};

  ${({ $isLast, theme }) =>
    !$isLast &&
    css`
      background-image: linear-gradient(
          to right,
          ${transparentize(0.5, theme.palette.brand.textSecondary)} 33%,
          rgba(255, 255, 255, 0) 0%
        ),
        linear-gradient(
          ${transparentize(0.5, theme.palette.brand.textSecondary)} 33%,
          rgba(255, 255, 255, 0) 0%
        );
      background-position: bottom, right;
      background-size: 12px 2.25px, 2.25px 12px;
      background-repeat: repeat-x, repeat-y;
    `}

  ${({ $isLast, theme }) =>
    $isLast &&
    css`
      background-image: linear-gradient(
        ${transparentize(0.5, theme.palette.brand.textSecondary)} 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: right;
      background-size: 2.25px 12px;
      background-repeat: repeat-y;
    `}
`;

const NodesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0.5rem;
`;

export { SwimlaneViewSectionGridCell };
