import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useShowToast } from '@/hooks/useShowToast';

export const useHandleCopyUrl = (): {
  handleCopyUrl: ({ url }: { url: string }) => void;
} => {
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const handleCopyUrl = useCallback(
    async ({ url }: { url: string }) => {
      try {
        await navigator.clipboard.writeText(url);
        showToast('success', t('roadmaps.copy_url'));
      } catch (err) {
        showToast('success', t('roadmaps.copy_fail'));
      }
    },
    [showToast, t]
  );

  return { handleCopyUrl };
};
