import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Rect, Text } from 'react-konva';
import { Html, Portal } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import { getRoadmapSections, updateSection } from '@/features/canvas/api';
import { TabEditFormContent } from '@/features/canvas/components/TabEditFormContent';
import { VerticalSectionActionMenu } from '@/features/canvas/components/VerticalSectionActionMenu';
import {
  MIN_TAB_WIDTH,
  SIZE_HANDLER_WIDTH,
  STAGE_OFFSET,
  TAB_ACTION_MENU_END_OFFSET,
  TAB_ACTION_MENU_HEIGHT,
  TAB_ACTION_MENU_WIDTH,
  TAB_HEIGHT,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { VerticalSection } from '@/features/canvas/types/section';
import { TabEditFormValues } from '@/features/canvas/types/tabEditFormValues';
import { Form } from '@/features/ui/components/Form';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  cornerRadius: number[];
  fill: string;
  onDragEnd: () => void;
  rotation: number;
  sectionId: number;
  sectionIndex: number;
  sectionOrder: number;
  sectionOrientation: components['schemas']['RoadmapDetail']['sections'][0]['orientation'];
  sectionTitle: string;
  shouldDisableActionMenuBecauseOfUsedTemporaryId: boolean;
  successor: { id: number | undefined; width: number };
  textColor: string;
  verticalSectionTabWidth: number;
  y: number;
  children?: ReactNode;
  x?: number;
};

const VerticalSectionTabShape = ({
  y,
  x = 0,
  fill,
  rotation,
  sectionId,
  sectionIndex,
  sectionTitle,
  sectionOrientation,
  successor,
  sectionOrder,
  cornerRadius,
  verticalSectionTabWidth,

  shouldDisableActionMenuBecauseOfUsedTemporaryId,
  textColor,
  children,
  onDragEnd,
}: Props) => {
  const {
    isPreview,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    sortedVerticalSections,
    roadmap,
    temporaryCreatedIds,
    updateLastSavedTime,
  } = useEditorContext();

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isHoveringTab, setIsHoveringTab] = useState(false);
  const [isHoveringAction, setIsHoveringAction] = useState(false);
  const queryClient = useQueryClient();
  const theme = useTheme();
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const { mutateAsync: updateSectionMutation } = useMutation(
    ({ sectionData }: { sectionData: VerticalSection }) =>
      updateSection({ sectionId: Number(sectionData.id), newSectionData: sectionData }),
    {
      onMutate: ({ sectionData }) => {
        queryClient.setQueryData(
          [getRoadmapSections.name, roadmap.id],
          (oldSections?: components['schemas']['Section'][]) => {
            // update editor GUI
            setIsEditingTitle(false);
            updateLastSavedTime();

            if (oldSections) {
              const indexToReplace = oldSections.findIndex(s => Number(s.id) === Number(sectionId));
              const sectionsToUpdate = [...oldSections];
              sectionsToUpdate[indexToReplace] = sectionData;
              return sectionsToUpdate;
            }
            return [];
          }
        );
      },
      onSuccess: () => updateLastSavedTime(),
      onError: async () => {
        showToast('error', t('editor.canvas.edit_section_error'));
        await queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
        setIsEditingTitle(false);
      },
    }
  );

  const actionMenuX =
    x +
    (verticalSectionTabWidth > TAB_ACTION_MENU_WIDTH + TAB_ACTION_MENU_END_OFFSET
      ? TAB_ACTION_MENU_END_OFFSET
      : 0);

  const draggableRectXPosition = x;
  const draggableRectYPosition = y + SIZE_HANDLER_WIDTH / 2;

  const submitNewSections = useCallback(
    async (formData: TabEditFormValues) => {
      const sectionToReplace = sortedVerticalSections[sectionIndex];

      await updateSectionMutation({
        sectionData: {
          ...sectionToReplace,
          roadmap_id: Number(roadmap.id),
          title: formData.title,
        },
      });
    },
    [sortedVerticalSections, sectionIndex, updateSectionMutation, roadmap.id]
  );

  const hasSuccessorWithValidId = Boolean(
    successor.id && successor.id > 0 && !temporaryCreatedIds.has(successor.id)
  );
  const shouldShowResizeHandler =
    !shouldDisableActionMenuBecauseOfUsedTemporaryId &&
    Boolean(successor.width) &&
    !isPreview &&
    hasSuccessorWithValidId;

  return (
    <>
      <Group id={sectionId.toString()} key={sectionId} x={x} y={y} rotation={rotation}>
        <Group
          id={`tab-${sectionId}`}
          key={`tab-${sectionId}`}
          onMouseEnter={() => setIsHoveringTab(true)}
          onMouseOver={() => setIsHoveringTab(true)}
          onMouseOut={() => setIsHoveringTab(false)}
          onTap={() => setIsHoveringTab(!isHoveringTab)}
        >
          <Rect
            width={verticalSectionTabWidth}
            height={TAB_HEIGHT}
            fill={fill}
            cornerRadius={cornerRadius}
          />
          {isEditingTitle && !isPreview && (
            <Html
              divProps={{
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: `${verticalSectionTabWidth}px`,
                  height: `${TAB_HEIGHT / 3}px`,
                  top: '0px',
                  left: `${TAB_HEIGHT / 2 - TAB_HEIGHT / 3 / 2}px`,
                },
              }}
            >
              <Form
                defaultValues={{ title: sectionTitle }}
                onSubmit={formData => submitNewSections(formData as TabEditFormValues)}
              >
                <TabEditFormContent
                  key="verticalSections"
                  textColor={theme.palette.brand.white}
                  onSubmit={submitNewSections}
                />
              </Form>
            </Html>
          )}
          {!isEditingTitle && (
            <Text
              fill={textColor}
              text={sectionTitle}
              align="center"
              verticalAlign="middle"
              width={verticalSectionTabWidth}
              height={TAB_HEIGHT}
              fontStyle="900"
              fontSize={16}
              fontFamily="Open Sans"
              letterSpacing={0.15}
              shadowColor={textColor}
              shadowBlur={1}
            />
          )}
          {!isPreview && (
            <VerticalSectionActionMenu
              x={actionMenuX}
              y={-TAB_ACTION_MENU_HEIGHT / 2}
              sectionOrder={sectionOrder}
              sectionIndex={sectionIndex}
              sectionOrientation={sectionOrientation}
              rotation={rotation}
              sectionId={sectionId}
              isHoverActive={isHoveringTab || isHoveringAction}
              setIsEditingTitle={setIsEditingTitle}
              setIsHoveringAction={setIsHoveringAction}
              shouldDisableActionMenuBecauseOfUsedTemporaryId={
                shouldDisableActionMenuBecauseOfUsedTemporaryId
              }
            />
          )}
        </Group>
        {children}
      </Group>

      {shouldShowResizeHandler && (
        <Portal selector=".top-layer" enabled>
          <Rect
            width={SIZE_HANDLER_WIDTH}
            height={TAB_HEIGHT}
            x={draggableRectXPosition}
            y={draggableRectYPosition}
            draggable
            rotation={rotation}
            onMouseEnter={e => {
              const container = e.target.getStage()!.container();
              container.style.cursor = 'row-resize';
            }}
            onMouseLeave={e => {
              const stage = e.target.getStage();
              if (stage) {
                const container = stage.container();
                container.style.cursor = 'default';
              }
            }}
            onDragStart={() => {
              setIsDraggingTab(true);
            }}
            onDragMove={evt => {
              const rect = evt.target;
              const currentY = rect.y();
              const deltaY = currentY - draggableRectYPosition;
              setDraggedTabPositionChange(deltaY);
            }}
            onDragEnd={onDragEnd}
            dragBoundFunc={pos =>
              checkBoundaries({
                rectYPosition: draggableRectYPosition,
                posY: pos.y,
                tabWidth: verticalSectionTabWidth,
                neighborTabWidth: successor.width,
              })
            }
          />
        </Portal>
      )}
    </>
  );
};

const checkBoundaries = ({
  rectYPosition,
  posY,
  tabWidth,
  neighborTabWidth,
}: {
  neighborTabWidth: number;
  posY: number;
  rectYPosition: number;
  tabWidth: number;
}) => {
  let newY = posY;

  if (newY < rectYPosition - tabWidth + SIZE_HANDLER_WIDTH / 2 + MIN_TAB_WIDTH + STAGE_OFFSET) {
    newY = rectYPosition - tabWidth + SIZE_HANDLER_WIDTH / 2 + MIN_TAB_WIDTH + STAGE_OFFSET;
  } else if (
    newY >
    rectYPosition + neighborTabWidth + SIZE_HANDLER_WIDTH / 2 - MIN_TAB_WIDTH + STAGE_OFFSET
  ) {
    newY = rectYPosition + neighborTabWidth + SIZE_HANDLER_WIDTH / 2 - MIN_TAB_WIDTH + STAGE_OFFSET;
  }

  return { x: STAGE_OFFSET, y: newY };
};

export { VerticalSectionTabShape };
