import { MIN_TAB_WIDTH, SIZE_HANDLER_WIDTH, STAGE_OFFSET } from '@/features/canvas/constants';

export const checkXTabBoundaries = ({
  rectXPosition,
  posX,
  posY = 0,
  tabWidth,
  neighborTabWidth,
}: {
  neighborTabWidth: number;
  posX: number;
  rectXPosition: number;
  tabWidth: number;
  posY?: number;
}) => {
  let newX = posX;

  if (newX < STAGE_OFFSET + rectXPosition - tabWidth + SIZE_HANDLER_WIDTH / 2 + MIN_TAB_WIDTH) {
    newX = STAGE_OFFSET + rectXPosition - tabWidth + SIZE_HANDLER_WIDTH / 2 + MIN_TAB_WIDTH;
  } else if (
    newX >
    rectXPosition + neighborTabWidth - SIZE_HANDLER_WIDTH / 2 - MIN_TAB_WIDTH + STAGE_OFFSET
  ) {
    newX = rectXPosition + neighborTabWidth - SIZE_HANDLER_WIDTH / 2 - MIN_TAB_WIDTH + STAGE_OFFSET;
  }

  return { x: newX, y: posY + STAGE_OFFSET };
};
