// import theme from '../../theme/theme';

import StyledIcon from './Icon.styled';

export type IconProps = {
  src: string;
  alt?: string;
  fill?: string;
  onClick?: ((event: React.MouseEvent<SVGElement>) => void) | (() => void);
  size?: 'small' | 'medium' | 'large';
  stroke?: string;
  style?: React.CSSProperties;
};

const Icon: React.FC<IconProps> = props => {
  const { alt, src, fill, stroke, onClick, style } = props;
  return (
    <div title={alt}>
      <StyledIcon src={src} fill={fill} stroke={stroke} onClick={onClick} style={style} />
    </div>
  );
};

export default Icon;
