import { defaultApi } from '@/api';

// todo type
export async function getRoadmapPermissionList({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get(`/roadmaps/${roadmapId}/permissions`);
  return data;
}

export async function createRoadmapPermission({
  roadmapId,
  userId,
  permissionType,
}: {
  roadmapId: number;
  userId: number;
  permissionType: 'roadmap_edit' | 'roadmap_owner';
}) {
  const { data } = await defaultApi.post(`/roadmaps/${roadmapId}/permissions/${userId}`, {
    permissionType,
  });
  return data;
}

export async function removeRoadmapPermission({
  roadmapId,
  userId,
}: {
  roadmapId: number;
  userId: number;
}) {
  await defaultApi.delete(`/roadmaps/${roadmapId}/permissions/${userId}`);
}
