import { ReactNode } from 'react';
import styled from 'styled-components';

import { Card } from '@/features/ui/components/Card';

const PanelTitle = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  font-weight: 600;
`;

const PanelDescription = styled.div`
  color: ${({ theme }) => theme.palette.brand.textSecondary};
  font-size: 2rem;
  font-weight: 600;
`;

const Panel = styled(Card)`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 3fr 1fr;
`;

const PanelGridContainer = styled.div`
  grid-column: 2 / 3;
  padding: 2rem;
`;

const PanelHeader = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
`;

const UserManagementPanel: React.FC<{
  children: ReactNode;
  title: string;
  description?: string;
}> = ({ children, title, description }) => {
  return (
    <Panel>
      <PanelGridContainer>
        <PanelHeader>
          <PanelTitle>{title}</PanelTitle>
          {description && <PanelDescription>{description}</PanelDescription>}
        </PanelHeader>
        {children}
      </PanelGridContainer>
    </Panel>
  );
};

export { UserManagementPanel };
