import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

import { PublishDialogFormInput } from '@/features/canvas/components/PublishDialogFormInput';

const PublishDialogFormContent = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        justifyContent: 'space-between',
        marginBottom: '3rem',
      }}
    >
      <PublishDialogFormInput
        name="title"
        label={t('editor.header.publish_dialog.roadmap_title')}
        placeholder={t('editor.header.publish_dialog.roadmap_title_placeholder')}
        rules={{
          required: { value: true, message: t('form.field_required_validation') },
          maxLength: {
            value: 32,
            message: t('form.field_too_long'),
          },
        }}
        size="small"
      />
      <PublishDialogFormInput
        name="password"
        label={t('editor.header.publish_dialog.set_password')}
        placeholder={t('editor.header.publish_dialog.password_placeholder')}
        rules={{
          required: { value: true, message: t('form.field_required_validation') },
          maxLength: {
            value: 16,
            message: t('form.field_too_long'),
          },
        }}
        size="small"
      />
    </Box>
  );
};

export { PublishDialogFormContent };
