import React, { SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styled, { css } from 'styled-components';

import BinIconSrc from '@/assets/icons/bin.svg';
import TriangleIconSrc from '@/assets/icons/triangle.svg';
import { ListStyledImage } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeCustom/InitiativeCustom';
import { HideFieldIcon } from '@/features/canvas/components/InitiativesAccordionContent/HideFieldIcon';
import {
  PROGRESS_BAR_INCREMENT,
  PROGRESS_BAR_MAX,
  PROGRESS_BAR_MIN,
} from '@/features/canvas/constants';
import { components } from '@/types/api';

const InitiativeProgressBar = ({
  field,
  onDeleteField,
  onUpdateField,
  onClick,
  readOnly,
  isInitiativeLockedAndIsNotOwner,
}: {
  field: components['schemas']['InitiativeField'];
  isInitiativeLockedAndIsNotOwner: boolean;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  onDeleteField: (fieldId: number, e: React.MouseEvent<HTMLButtonElement>) => void;
  onUpdateField: (field: components['schemas']['InitiativeField']) => void;
  readOnly: boolean;
}) => {
  const [inputValue, setInputValue] = useState<string>(field.value || '0');

  const { t } = useTranslation();
  const handleInputChange = (event: { target: { value: SetStateAction<string> } }) => {
    const regexValidation = /^(?:100|[1-9][0-9]?|0)?$/;
    const { value } = event.target;
    if (!regexValidation.test(value as string)) {
      return;
    }
    setInputValue(value);
  };

  const handleBlur = () => {
    if (readOnly || isInitiativeLockedAndIsNotOwner) return;
    const updatedField = { ...field, value: inputValue };
    onUpdateField(updatedField);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  const incrementValue = () => {
    if (readOnly || isInitiativeLockedAndIsNotOwner) return;
    const valueAsNumber = Number(inputValue);
    if (valueAsNumber + PROGRESS_BAR_INCREMENT < PROGRESS_BAR_MAX) {
      const incrementedValue = (valueAsNumber + PROGRESS_BAR_INCREMENT).toString();
      setInputValue(incrementedValue);
      const updatedField = { ...field, value: incrementedValue };
      return onUpdateField(updatedField);
    }

    const newValue = PROGRESS_BAR_MAX.toString();
    setInputValue(newValue);
    const updatedField = { ...field, value: newValue };
    return onUpdateField(updatedField);
  };

  const decrementValue = () => {
    if (readOnly || isInitiativeLockedAndIsNotOwner) return;
    const valueAsNumber = Number(inputValue);
    if (valueAsNumber - PROGRESS_BAR_INCREMENT > PROGRESS_BAR_MIN) {
      const incrementedValue = (valueAsNumber - PROGRESS_BAR_INCREMENT).toString();
      setInputValue(incrementedValue);
      const updatedField = { ...field, value: incrementedValue };
      return onUpdateField(updatedField);
    }
    const newValue = PROGRESS_BAR_MIN.toString();
    setInputValue(newValue);
    const updatedField = { ...field, value: newValue };
    return onUpdateField(updatedField);
  };

  return (
    <>
      <FieldWrapper>
        <TitleInput
          readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onClick={onClick}
        />
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <Percentage>%</Percentage>
        <IncrementButtonWrapper>
          <IncrementButton onClick={incrementValue}>
            <StyledSVG src={TriangleIconSrc} />
          </IncrementButton>
          <DecrementButton onClick={decrementValue}>
            <StyledSVG src={TriangleIconSrc} $rotate="60deg" />
          </DecrementButton>
        </IncrementButtonWrapper>
      </FieldWrapper>
      {!readOnly && (
        <MainActions>
          <HideFieldIcon initiativeField={field} />
          {!isInitiativeLockedAndIsNotOwner && (
            <IconButton aria-label="delete" size="small" onClick={e => onDeleteField(field.id!, e)}>
              <Tooltip title={t('editor.sidebar.initiative.delete_field')} placement="top">
                <ListStyledImage src={BinIconSrc} />
              </Tooltip>
            </IconButton>
          )}
        </MainActions>
      )}
    </>
  );
};

export { InitiativeProgressBar };

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleInput = styled.input`
  outline: none;
  border-radius: 0.4rem 0 0 0.4rem;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};
  border-right: none;
  height: 25px;
  width: 32px;
  padding-left: 0.5rem;
  text-align: right;
`;

const Percentage = styled.div`
  font-size: 1.5rem;
  background-color: ${({ theme }) => theme.palette.brand.white};
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};
  border-left: none;
  height: 25px;
  width: 20px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
`;

const IncrementButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 25px;
  gap: 0.2rem;
  margin-left: 0.2rem;
`;

const IncrementButton = styled.div`
  display: flex;
  border-radius: 0 0.4rem 0 0;
  flex-column: row;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  width: 20px;
  height: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const DecrementButton = styled.div`
  display: flex;
  border-radius: 0 0 0.4rem 0;
  flex-column: row;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  width: 20px;
  height: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const StyledSVG = styled(SVG)<{ $rotate?: string }>`
  width: 0.5rem;
  height: 0.5rem;
  ${({ $rotate }) =>
    $rotate &&
    css`
      rotate: ${$rotate};
    `}

  & path {
    fill: ${({ theme }) => theme.palette.brand.textSecondary};
    stroke: ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const MainActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
