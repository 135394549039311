import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function authCheck() {
  return (await defaultApi.get(`/user/token`)).data;
}

export async function refreshAccessToken({
  refreshToken,
}: components['schemas']['refreshtoken_body']) {
  return (await defaultApi.post<components['schemas']['Token']>(`/refresh-token`, { refreshToken }))
    .data;
}
