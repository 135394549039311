import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function createNodesInitiative({
  nodeId,
  initiativeBody,
}: {
  nodeId: number;
  initiativeBody: components['schemas']['Initiative'];
}) {
  const initiativeInArray = [initiativeBody];
  const { data } = await defaultApi.post<components['schemas']['Initiative']>(
    `/initiatives/${nodeId}`,
    initiativeInArray
  );
  return data;
}

export async function createNewInitiative({
  initiativeBody,
}: {
  initiativeBody: components['schemas']['Initiative'];
}) {
  const initiativeInArray = [initiativeBody];
  const { data } = await defaultApi.post<components['schemas']['Initiative']>(
    '/initiatives',
    initiativeInArray
  );
  return data;
}

export async function getInitiativeById({ id }: { id: number }) {
  const { data } = await defaultApi.get<components['schemas']['Initiative']>(`/initiatives/${id}`);
  return data;
}

export async function deleteInitiative({ id }: { id: number }) {
  await defaultApi.delete(`/initiatives/${id}`);
}

export async function updateInitiative({
  initiativeId,
  initiativeBody,
}: {
  initiativeId: number;
  initiativeBody: components['schemas']['Initiative'];
}) {
  const initiativeInArray = [initiativeBody];
  await defaultApi.put<components['schemas']['Initiative']>(
    `/initiatives/${initiativeId}`,
    initiativeInArray
  );
}

export async function getRoadmapInitiatives({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get<components['schemas']['Initiative'][]>(
    `/initiatives/roadmap/${roadmapId}`
  );
  return data;
}

export async function handleLockingInitiative({ id, isLocked }: { id: number; isLocked: boolean }) {
  await defaultApi.put<{ isLocked: boolean; initiativeId: number }>(`/initiatives/${id}/lock`, {
    isLocked,
  });
}
