import { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import {
  CustomFieldType,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { FilterTypes } from '@/features/canvas/types/filter';
import { NewFilterLinkingFilterOption } from '@/features/canvas/types/filter-option';
import {
  FieldLinkingFormState,
  LinkedColumnType,
  LinkSelectionOption,
} from '@/features/canvas/types/linkage';
import { useFormContext } from '@/features/ui/components/Form';
import { FormSelectInput } from '@/features/ui/components/FormSelectInput';

import { FieldLinkingFilter } from './FieldLinkingFilter';
import { FormFieldLinkingTextInput } from './FormFieldLinkingTextInput';

type FieldLinkingSelectionFormContentProps = {
  defaultValues: FieldLinkingFormState;
  handleDialogTitle: (isNewFieldTitle: boolean) => void;
  linkSelectionOptions: LinkSelectionOption[];
  selectedColumnData: Set<string>;
};

const FieldLinkingSelectionFormContent = ({
  defaultValues,
  linkSelectionOptions,
  selectedColumnData,
  handleDialogTitle,
}: FieldLinkingSelectionFormContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { reset, control, getValues } = useFormContext();
  const { roadmapVisible, dropdownVisible } = useEditorContext();

  const [filterOptions, setFilterOptions] = useState<NewFilterLinkingFilterOption[]>([]);

  const [selectedLinkOptionId, filterType] = useWatch<FieldLinkingFormState>({
    control: control as unknown as Control<FieldLinkingFormState>,
    name: ['selectedLinkOptionId', 'filterType'],
  });

  const selectedOption = linkSelectionOptions.find(o => o.id === selectedLinkOptionId);

  const isSelectedOptionNewField = selectedOption?.type === LinkedColumnType.createNewField;
  const isSelectedOptionNewFilter = selectedOption?.type === LinkedColumnType.createNewFilter;

  const onSelectedOptionChange = (value: string) => {
    const linkSelectedOption = linkSelectionOptions.find(o => o.id === value);

    handleDialogTitle(
      linkSelectedOption?.type === LinkedColumnType.createNewField ||
        linkSelectedOption?.type === LinkedColumnType.createNewFilter
    );

    if (linkSelectedOption?.type === LinkedColumnType.createNewField) {
      reset({
        ...defaultValues,
        selectedLinkOptionId: value,
        fieldType: CustomFieldType.small,
      });
    }

    if (linkSelectedOption?.type === LinkedColumnType.createNewFilter) {
      reset({
        ...defaultValues,
        selectedLinkOptionId: value,
        filterType: 'single',
      });
    }
  };

  const reservedFieldProperties = useMemo(() => {
    const roadmapVisibilityValidationProperties = roadmapVisible.map(rv => ({
      title: rv.title,
      type: rv.type,
    }));
    const dropdownVisibilityValidationProperties = dropdownVisible.map(dv => ({
      title: dv.title,
      type: dv.type,
    }));

    const reservedValidationProperties = [
      ...roadmapVisibilityValidationProperties,
      ...dropdownVisibilityValidationProperties,
    ];

    const filteredProperties = reservedValidationProperties.filter(
      (property, index, array) =>
        !array.slice(0, index).some(p => p.title === property.title && p.type === property.type)
    );
    return filteredProperties as { title: string; type: keyof typeof InitiativeFieldTypes }[];
  }, [roadmapVisible, dropdownVisible]);

  const setNewSingleOptions = useCallback(() => {
    const newFilterOptions: NewFilterLinkingFilterOption[] = Array.from(selectedColumnData)
      .filter(Boolean)
      .map(value => ({
        id: uuidv4(),
        title: value,
        color: theme.palette.brand.corePPTHeadlines,
        isNotEditable: true,
      }));
    setFilterOptions(newFilterOptions);
  }, [selectedColumnData, theme.palette.brand.corePPTHeadlines]);

  const setMultipleOptions = useCallback(() => {
    const newMultipleOptions: NewFilterLinkingFilterOption[] = [];
    Array.from(selectedColumnData)
      .filter(Boolean)
      .forEach(item => {
        const splitItems = item.split(',').map(i => i.trim());
        splitItems.forEach(splitItem => {
          const isSplitItemAlreadyAnOption = newMultipleOptions.some(
            nwo => nwo.title === splitItem
          );
          if (!isSplitItemAlreadyAnOption) {
            const newOption = {
              id: uuidv4(),
              title: splitItem,
              color: theme.palette.brand.corePPTHeadlines,
              isNotEditable: true,
            };
            newMultipleOptions.push(newOption);
          }
        });
      });
    setFilterOptions(newMultipleOptions);
  }, [selectedColumnData, theme.palette.brand.corePPTHeadlines]);

  useEffect(() => {
    setNewSingleOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const values = getValues() as FieldLinkingFormState;
    if (!isEqual(values.filterOptions, filterOptions)) {
      reset({
        ...getValues(),
        filterOptions,
      });
    }
  }, [filterOptions, getValues, reset]);

  useEffect(() => {
    if (filterType === 'multiple') {
      setMultipleOptions();
    }
    if (filterType === 'single') {
      setNewSingleOptions();
    }
  }, [filterType, setMultipleOptions, setNewSingleOptions]);

  return (
    <ContentWrapper>
      <FormSelectInput
        name="selectedLinkOptionId"
        options={linkSelectionOptions}
        onChange={e => {
          onSelectedOptionChange(e.target.value as string);
        }}
        placeholder={t('editor.sidebar.data_tab.field_linking_select_placeholder')}
        variant="standard"
        fullWidth
        required
      />
      {isSelectedOptionNewField && (
        <NewFieldFormContent>
          <FormFieldLinkingTextInput
            label={t('editor.sidebar.data_tab.create_field_label')}
            name="fieldName"
            placeholder={t('editor.sidebar.data_tab.create_field_label_placeholder')}
            fullWidth
            required
            rules={{
              maxLength: {
                value: 32,
                message: t('form.field_too_long'),
              },
              validate: (value, formData) => {
                const isMatchingReservedProperty = reservedFieldProperties.some(
                  rfp => rfp.title === value && rfp.type === formData.fieldType
                );

                return isMatchingReservedProperty
                  ? t('editor.sidebar.data_tab.reserved_title_used')
                  : true;
              },
            }}
            errorInputProps={{ padding: '0 0.5rem' }}
          />
          <FormSelectInput
            name="fieldType"
            label={t('editor.sidebar.data_tab.select_field_type')}
            options={[
              {
                id: CustomFieldType.small,
                label: t('editor.sidebar.data_tab.single_line_label'),
              },
              {
                id: CustomFieldType.big,
                label: t('editor.sidebar.data_tab.multi_line_label'),
              },
            ]}
            variant="standard"
            fullWidth
          />
        </NewFieldFormContent>
      )}
      {isSelectedOptionNewFilter && (
        <NewFilterFormContent>
          <FormFieldLinkingTextInput
            label={t('editor.sidebar.data_tab.create_filter_title')}
            name="filterName"
            placeholder={t('editor.sidebar.data_tab.create_filter_title_placeholder')}
            fullWidth
            required
            rules={{
              maxLength: {
                value: 32,
                message: t('form.field_too_long'),
              },
              validate: value => {
                const reservedFilterNames = [
                  FilterTypes.PRIORITY,
                  FilterTypes.RAG,
                  FilterTypes.PROGRESS_STATUS,
                ];
                const isMatchingReservedFilterNames = reservedFilterNames.some(
                  filterName => filterName === value
                );

                return isMatchingReservedFilterNames
                  ? t('editor.sidebar.filter.filter_title_in_used')
                  : true;
              },
            }}
            errorInputProps={{ padding: '0 0.5rem' }}
          />
          <FormSelectInput
            name="filterType"
            label={t('editor.sidebar.data_tab.select_filter_type')}
            options={[
              {
                id: 'single',
                label: t('editor.sidebar.data_tab.single_filter_type'),
              },
              {
                id: 'multiple',
                label: t('editor.sidebar.data_tab.multiple_filter_type'),
              },
            ]}
            variant="standard"
            fullWidth
          />
          <FieldLinkingFilter
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            filterSelection={filterType as 'single' | 'multiple'}
          />
        </NewFilterFormContent>
      )}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const NewFieldFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 4rem;
`;
const NewFilterFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export { FieldLinkingSelectionFormContent };
