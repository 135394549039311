import { publicApi } from '@/api';
import { components } from '@/types/api';

export async function authenticatePublicView({
  roadmapPublicLinkUrl,
  password,
}: {
  roadmapPublicLinkUrl: string;
  password: string;
}) {
  const { data } = await publicApi.post<components['schemas']['PublicViewRoadmap']>(
    `/public-views/${roadmapPublicLinkUrl}`,
    {
      password,
    }
  );

  return data;
}
