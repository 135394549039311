import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import CloseIcon from '@/assets/icons/close.svg';
import {
  CustomFieldType,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';
import { InitiativeFieldOption } from '@/features/canvas/types/initiative';

const FieldStyledSelect = ({
  selectedId,
  onChange,
  options,
  onDelete,
  placeholder,
  disabled,
}: {
  onChange: (title: null | string, type: keyof typeof InitiativeFieldTypes) => void;
  options: InitiativeFieldOption[];
  placeholder: string;
  selectedId: null;
  disabled?: boolean;
  onDelete?: (fieldTitle: string, fieldType: keyof typeof CustomFieldType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <SSelect
      value={selectedId === null ? '' : selectedId}
      sx={{ position: 'relative' }}
      SelectDisplayProps={{
        style: {
          backgroundColor: 'none',
          padding: '0.5rem 1rem',
        },
      }}
      disabled={disabled}
      MenuProps={{
        style: {
          left: -6,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {},
      }}
      variant="standard"
      disableUnderline
      displayEmpty
      labelId="new-initiative-label"
      IconComponent={() => <ArrowDownIcon />}
      renderValue={selected => {
        if (!selected) {
          return <div>{placeholder}</div>;
        }
        return options.find(o => o.id === (selected as string))?.title;
      }}
    >
      {options.map(o => {
        const isDeletable =
          onDelete &&
          o.isDeletable &&
          (o.type === CustomFieldType.big || o.type === CustomFieldType.small);

        return (
          <StyledMenuItem key={o.id} value={o.id} onClick={() => onChange(o.title, o.type)}>
            <MenuItemTitle>{o.title}</MenuItemTitle>
            {isDeletable && (
              <StyledSvg
                src={CloseIcon}
                onClick={e => {
                  e.stopPropagation();
                  onDelete(o.title, o.type as keyof typeof CustomFieldType);
                }}
              />
            )}
          </StyledMenuItem>
        );
      })}

      {!options.length && (
        <StyledMenuItem disabled>{t('editor.sidebar.no_options')}</StyledMenuItem>
      )}
    </SSelect>
  );
};

export { FieldStyledSelect };

const SSelect = styled(Select)`
  width: 100%;
  border-radius: 1rem;
  font-size: 1.25rem;
  border: 0;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  svg {
    width: 1rem;
    height: 1rem;
    position: relative;
    right: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  & .MuiInput-input:focus {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

const StyledSvg = styled(SVG)<{ $color?: string }>`
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
`;
const MenuItemTitle = styled.div`
  overflow: hidden;
  flex: 5;
  max-width: 185px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
