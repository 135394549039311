import { darken } from 'polished';
import styled from 'styled-components';

import { TAB_HEIGHT } from '@/features/canvas/constants';

const SwimlaneViewTimePeriod = ({
  title,

  index,
}: {
  index: number;
  title: string;
}) => {
  return (
    <TimePeriod $index={index}>
      <TimePeriodTitle>{title}</TimePeriodTitle>
    </TimePeriod>
  );
};

const TimePeriod = styled.div<{ $index: number }>`
  height: ${TAB_HEIGHT}px;
  background-color: ${({ theme, $index }) =>
    darken($index * 0.04, theme.palette.brand.backgroundDialog)};
  grid-column-start: ${({ $index }) => $index + 2};
  border-radius: 1rem 1rem 0 0;
`;

const TimePeriodTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 16px;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  padding-bottom: 2px;
  text-shadow: 0px 0px 1px ${({ theme }) => theme.palette.brand.textPrimary};
`;

export { SwimlaneViewTimePeriod };
