interface Values {
  sectionId?: string;
  timePeriodId?: string;
}

export const getIntersectionSectionAndTimePeriodId = (intersections: unknown[]) => {
  const sectionIdPartToInclude = '-section-area-shape';
  const timePeriodIdPartToInclude = '-time-period-area-shape';
  const values: Values = {};
  intersections.forEach(intersection => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const groupId = (intersection as any)?.getParent()?.getAttr('id');
    if (groupId.includes(sectionIdPartToInclude)) {
      values.sectionId = groupId.replace(sectionIdPartToInclude, '');
    }
    if (groupId.includes(timePeriodIdPartToInclude)) {
      values.timePeriodId = groupId.replace(timePeriodIdPartToInclude, '');
    }
  });
  return values;
};
