import { transparentize } from 'polished';
import { Card } from 'src/features/ui/components/Card/Card';
import Text from 'src/features/ui/components/Text/Text';
import styled from 'styled-components';

import { theme as mainTheme } from '@/features/ui/theme';

type TextProps = {
  fontSize?: string;
  marginTop?: number;
};

export const HoverLink = styled.a`
  cursor: pointer;

  &:hover {
    color: ${mainTheme().palette.brand.editorTabPrimary};
  }
`;

export const LoginCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 16rem;
  background-color: ${({ theme }) => theme.palette.brand.white};
  max-width: 920px;
  margin: 0 auto;
`;

export const Wrapper = styled.main`
  font-size: 1.6rem;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.brand.backgroundMain};
  height: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  padding: 32px;
`;

export const Logo = styled.img`
  width: 20rem;
`;

export const H2 = styled.h2<TextProps>`
  font-size: ${props => props?.fontSize ?? '2.0rem'};
  margin-top: ${props => props?.marginTop ?? 0}rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  font-weight: 600;
  line-height: 3.3rem;
`;

export const H4 = styled.h4<TextProps>`
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  font-weight: 600px;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

export const ForgotText = styled(Text)`
  font-weight: 600;
  font-size: 1.4rem;
  text-align: left;
  color: ${({ theme }) => transparentize(0.5, theme.palette.brand.secondary)};

  &:hover {
    color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
  }
`;

export const ErrorMessage = styled(Text)`
  font-size: 1.75rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.palette.brand.corePPTAction};
`;
