import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Button, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

import LastSave from '@/assets/icons/bookmark.svg';
import { ReactComponent as EyeFull } from '@/assets/icons/eye-full.svg';
import Play from '@/assets/icons/play.svg';
import Redo from '@/assets/icons/redo.svg';
import Undo from '@/assets/icons/undo.svg';
import { useAccount } from '@/contexts/account-context';
import { updateRoadmapById } from '@/features/canvas/api';
import { EditRoadmapTitleFormContent } from '@/features/canvas/components/EditRoadmapTitleFormContent';
import { PublishDialogForm } from '@/features/canvas/components/PublishDialogForm';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { EditorHeaderPermissionManager } from '@/features/canvas/modules/EditorHeader/EditorHeaderPermissionManager';
import { getRoadmapById, getRoadmapList } from '@/features/roadmaps/api';
import { Dialog } from '@/features/ui/components/Dialog';
import { Form } from '@/features/ui/components/Form';
import { components } from '@/types/api';

type EditRoadmapTitleFormState = {
  title: string;
};

const EditorHeader = () => {
  const { t } = useTranslation();
  const {
    roadmap,
    isSwimlaneView,
    setIsSwimlaneView,
    setIsPreview,
    isPreview,
    isPublicView,
    lastSavedTime,
    updateLastSavedTime,
    roadmapPermissions,
  } = useEditorContext();
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);

  const queryClient = useQueryClient();
  const { undo, redo, canGoBack, canGoForward, isLoading } = useUndoRedo();
  const account = useAccount();

  const isRoadmapOwner = account?.id === roadmap.createdById;

  const isRoadmapEditor = Boolean(
    account?.id &&
      roadmapPermissions.find(p => p.userId === account?.id && p.permissionType === 'roadmap_edit')
  );
  const canUserEditRoadmapTitle = isRoadmapOwner && !isPreview;

  const { mutateAsync: updateRoadmap, isLoading: isUpdatingRoadmap } = useMutation(
    ({
      id,
      newRoadmapData,
    }: {
      id: number;
      newRoadmapData: components['schemas']['RoadmapDetail'];
    }) => updateRoadmapById({ id, newRoadmapData }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([getRoadmapById.name, roadmap.id]);
        await queryClient.invalidateQueries(getRoadmapList.name);
        setIsEditingTitle(false);
        updateLastSavedTime();
      },
    }
  );

  return (
    <Header>
      {!isPublicView && (
        <HeaderSideWrapper>
          <MinorActions>
            <UndoRedo>
              <RedoUndoIconWrapper onClick={() => undo()} isDisabled={!canGoBack || isLoading}>
                {isLoading ? <StyledLoader size="1.5rem" /> : <StyledImage src={Undo} />}
              </RedoUndoIconWrapper>
              <RedoUndoIconWrapper onClick={() => redo()} isDisabled={!canGoForward || isLoading}>
                {isLoading ? <StyledLoader size="1.5rem" /> : <StyledImage src={Redo} />}
              </RedoUndoIconWrapper>
            </UndoRedo>
          </MinorActions>
          <LastSaved>
            <StyledImage src={LastSave} />
            <Text>
              {t('editor.header.last_saved', {
                lastSavedAtTime: dayjs(lastSavedTime).format('DD/MM/YYYY HH:mm'),
              })}
            </Text>
          </LastSaved>
        </HeaderSideWrapper>
      )}
      {!isEditingTitle && (
        <ProjectName onClick={canUserEditRoadmapTitle ? () => setIsEditingTitle(true) : undefined}>
          {roadmap.title || t('editor.header.untitled')}
        </ProjectName>
      )}
      {isEditingTitle && (
        <Form<EditRoadmapTitleFormState>
          defaultValues={{ title: roadmap.title || t('editor.header.untitled') }}
          onSubmit={formData =>
            updateRoadmap({
              id: roadmap.id!,
              newRoadmapData: { ...roadmap, title: formData.title },
            })
          }
        >
          <EditRoadmapTitleFormContent
            isUpdatingRoadmap={isUpdatingRoadmap}
            setIsEditingTitle={setIsEditingTitle}
          />
        </Form>
      )}
      {!isPublicView && (
        <Actions>
          {!isPreview && (isRoadmapOwner || isRoadmapEditor) && <EditorHeaderPermissionManager />}
          <PreviewButton
            type="button"
            startIcon={<StyledEyeIcon />}
            onClick={() => {
              if (isSwimlaneView) {
                setIsSwimlaneView(false);
              }
              setIsPreview(!isPreview);
            }}
            $isPreview={isPreview}
          >
            {t('editor.header.preview')}
          </PreviewButton>
          {!isPreview && (
            <PublishButton
              type="button"
              startIcon={<ButtonIcon src={Play} />}
              onClick={() => setIsPublishDialogOpen(true)}
            >
              {t('editor.header.publish')}
            </PublishButton>
          )}
        </Actions>
      )}
      <Dialog
        onClose={() => setIsPublishDialogOpen(false)}
        open={isPublishDialogOpen}
        title={t('editor.header.publish_dialog.title')}
      >
        <PublishDialogForm />
      </Dialog>
    </Header>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 60px;
  background-color: ${({ theme }) => theme.palette.brand.white};
  padding: 1rem;
`;

const MinorActions = styled.div`
  gap: 2rem;
  display: flex;

  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  gap: 1rem;

  align-items: center;
`;

const UndoRedo = styled.div`
  display: flex;
  gap: 1rem;

  align-items: center;
`;

const LastSaved = styled.div`
  display: flex;
  align-items: center;

  gap: 1rem;
`;

const Text = styled.div`
  font-size: 1.5rem;
`;

const HeaderSideWrapper = styled.div`
  display: flex;

  align-items: center;
  gap: 2rem;
`;

const ProjectName = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
`;

const PreviewButton = styled(Button)<{ $isPreview?: boolean }>`
  border-radius: 2rem;
  padding: 0.25rem 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  background-color: ${({ theme }) => theme.palette.brand.white};
  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.white)};
  }
  ${({ $isPreview, theme }) =>
    $isPreview &&
    css`
      color: ${theme.palette.brand.white};
      background-color: ${darken(0, '#58B4B2')};
      svg {
        path {
          fill: ${theme.palette.brand.white};
        }
      }
      :hover {
        background-color: ${darken(0.1, '#58B4B2')};
      }
    `}
`;
const PublishButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.brand.primary};
  padding: 0.25rem 1.5rem;
  border-radius: 2rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  color: ${({ theme }) => theme.palette.brand.white};
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;

  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.editorTabPrimary)};
  }
`;

const StyledImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const ButtonIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const RedoUndoIconWrapper = styled.div<{ isDisabled: boolean }>`
  background-color: ${({ theme }) => theme.palette.brand.buttonPrimaryHover};
  padding: 1rem;
  border-radius: 1rem;
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
`;

const StyledEyeIcon = styled(EyeFull)`
  width: 1.5rem;
  height: 1.5rem;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { EditorHeader };
