import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

const borderRadius = 1.8;

export const StyledIcon = styled.img`
  height: '2.4rem';
  width: '2.4rem';
`;

export const StyledIconWrapper = styled.div`
  min-width: 5.9rem;
  background-color: ${({ color, theme }) => color ?? theme.palette.brand.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${`${borderRadius}rem`};
  transform: scale(1.03);
  height: 100%;
`;

export const StyledInputField = styled.input`
  border: none;
  padding: 0 2rem;
  width: 100%;
  border-radius: ${`${borderRadius}rem`};
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.brand.textSecondary};

  &:focus {
    outline: none;
    background: ${({ theme }) => theme.palette.brand.sunray};
  }

  &::placeholder {
    font-style: italic;
  }
`;

export const StyledEyeIcon = styled.img`
  height: 2.4rem;
  padding-right: 1.5rem;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledHideButton = styled.button`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledInput = styled.div<{ isError?: boolean }>`
  display: flex;
  height: 6.4rem;
  align-items: center;
  border-radius: ${`${borderRadius + 0.1}rem`};
  border: 2px solid ${({ theme }) => theme.palette.brand.primary};
  background: ${({ theme }) => theme.palette.brand.white};

  &:focus-within {
    background: ${({ theme }) => theme.palette.brand.sunray};
  }

  ${({ isError, theme }) =>
    isError &&
    css`
      border: 2px solid ${theme.palette.brand.negativeStatus};
      background: ${transparentize(0.9, theme.palette.brand.negativeStatus)};
    `}
`;
