import { ReactNode, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { useAccount } from '@/contexts/account-context';
import Header from '@/features/roadmaps/components/Header';
import Sidebar from '@/features/roadmaps/components/Sidebar';
import { Card } from '@/features/ui/components/Card';

export const Page = styled.div`
  background-color: ${({ theme }) => theme.palette.brand.backgroundMain};
  height: 100%;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: row;
`;

const ContentWhiteBoxWrapper = styled(Card)<{
  $fullWidth?: boolean;
}>`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  overflow: auto;
  background-color: ${({ theme }) => theme.palette.brand.white};
  max-width: 1200px;

  margin: 60px 20px 60px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    max-height: calc(100vh - 180px);
    max-height: calc(100dvh - 180px);
    margin: 120px 20px 120px 0;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      max-width: 100%;
    `}
`;

const ContentNoBoxWrapper = styled.div<{
  $fullWidth?: boolean;
}>`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  overflow: auto;
  max-width: 1200px;

  margin: 60px 20px 60px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    max-height: calc(100vh - 180px);
    max-height: calc(100dvh - 180px);
    margin: 120px 20px 120px 0;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      max-width: 100%;
    `}
`;

const ContentGridPlacementWrapper = styled.div<{
  $gridColumnEnd?: number;
  $gridColumnStart?: number;
}>`
  grid-column-start: 2;
  grid-column-end: 4;
  padding: 0rem 2rem 0 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    padding: 0rem 0.5rem 0 0.5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xl}px) {
    grid-column: 1/4;
  }

  ${({ $gridColumnStart, $gridColumnEnd }) =>
    $gridColumnStart &&
    $gridColumnEnd &&
    css`
      grid-column-start: ${$gridColumnStart};
      grid-column-end: ${$gridColumnEnd};
    `}
`;

const PageWithSidebar: React.FC<{
  children: ReactNode;
  contentWhiteBox?: boolean;
  fullWidth?: boolean;
  gridColumnEnd?: number;
  gridColumnStart?: number;
}> = ({ children, gridColumnEnd, gridColumnStart, contentWhiteBox = true, fullWidth = false }) => {
  const account = useAccount();

  const ContentWrapper = useMemo(() => {
    if (contentWhiteBox) return ContentWhiteBoxWrapper;

    return ContentNoBoxWrapper;
  }, [contentWhiteBox]);

  return (
    <Page>
      <Header account={account} />
      <Main>
        <Sidebar />
        <ContentWrapper $fullWidth={fullWidth}>
          <ContentGridPlacementWrapper
            $gridColumnEnd={gridColumnEnd}
            $gridColumnStart={gridColumnStart}
          >
            {children}
          </ContentGridPlacementWrapper>
        </ContentWrapper>
      </Main>
    </Page>
  );
};

export { PageWithSidebar };
