import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useTheme } from '@mui/material';
import CircleIconSrc from 'src/assets/icons/circle.svg';
import CircleCheckIconSrc from 'src/assets/icons/circle-check.svg';
import TriangleIconSrc from 'src/assets/icons/triangle.svg';
import TriangleCheckIconSrc from 'src/assets/icons/triangle-check.svg';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import AddNewCircleSVG from '@/assets/icons/add-new-circle.svg';
import AddNewSquareSVG from '@/assets/icons/add-new-square.svg';
import AddNewTriangleSVG from '@/assets/icons/add-new-triangle.svg';
import SquareIconSrc from '@/assets/icons/square.svg';
import SquareCheckIconSrc from '@/assets/icons/square-check.svg';
import { NewFilterLinkingFilterOption } from '@/features/canvas/types/filter-option';
import { useFormContext } from '@/features/ui/components/Form';

import { FieldLinkingFilterMultiOption } from './FieldLinkingFilterMultiOption';
import { FieldLinkingFilterOption } from './FieldLinkingFilterOption';

const FieldLinkingFilter = ({
  filterOptions,
  setFilterOptions,
  filterSelection,
}: {
  filterOptions: NewFilterLinkingFilterOption[];
  setFilterOptions: (options: NewFilterLinkingFilterOption[]) => void;
  filterSelection?: 'single' | 'multiple';
}) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const [shape, setShape] = useState<'circle' | 'square' | 'triangle'>('circle');

  const getAddIcon = () => {
    let addIcon = AddNewCircleSVG;
    if (shape === 'triangle') {
      addIcon = AddNewTriangleSVG;
    } else if (shape === 'square') {
      addIcon = AddNewSquareSVG;
    }

    return (
      <StyledAddNewSVG src={addIcon} onClick={handleCreate} $isTriangle={shape === 'triangle'} />
    );
  };

  const handleUpdate = (updatedOption: {
    color: string;
    id: string;
    title: string;
    isNotEditable?: boolean;
  }) => {
    const updatedOptions = filterOptions.map(option => {
      if (option.id === updatedOption.id) {
        return {
          ...updatedOption,
        };
      }
      return option;
    });
    setFilterOptions(updatedOptions);
  };

  const handleDelete = (id: string) => {
    const updatedOptions = filterOptions.filter(option => option.id !== id);
    setFilterOptions(updatedOptions);
  };

  const handleCreate = () => {
    const newOption = {
      id: uuidv4(),
      title: t('editor.sidebar.filter.new_option_title'),
      color: theme.palette.brand.corePPTHeadlines,
    };

    setFilterOptions([...filterOptions, newOption]);
  };

  useEffect(() => {
    setValue('filterShape', shape);
  }, [setValue, shape]);

  return (
    <FilterWrapper>
      <FilterTitle>{t('editor.sidebar.data_tab.filter_labels')}</FilterTitle>
      <FilterSubtitle>{t('editor.sidebar.data_tab.filter_subtitle')}</FilterSubtitle>
      {filterSelection === 'single' && (
        <OptionsWrapper>
          {filterOptions?.map(option => {
            return (
              <FieldLinkingFilterOption
                key={option.id}
                color={option.color}
                filterShape={shape}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                id={option.id}
                title={option.title}
                isNotEditable={option.isNotEditable}
              />
            );
          })}
        </OptionsWrapper>
      )}
      {filterSelection === 'multiple' && (
        <OptionsWrapper>
          {filterOptions?.map(option => {
            return (
              <FieldLinkingFilterMultiOption
                key={option.id}
                filterShape={shape}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                id={option.id}
                title={option.title}
                isNotEditable={option.isNotEditable}
              />
            );
          })}
        </OptionsWrapper>
      )}
      {getAddIcon()}
      <ShapeSelectWrapper>
        <AccordionSubContentText>
          {t('editor.sidebar.filter.selection_shape')}
        </AccordionSubContentText>
        <ShapeImagesWrapper>
          {shape === 'circle' ? (
            <StyledShapeSVGCheck src={CircleCheckIconSrc} />
          ) : (
            <StyledShapeSVG src={CircleIconSrc} onClick={() => setShape('circle')} />
          )}
          {shape === 'square' ? (
            <StyledShapeSVGCheck src={SquareCheckIconSrc} />
          ) : (
            <StyledShapeSVG src={SquareIconSrc} onClick={() => setShape('square')} />
          )}
          {shape === 'triangle' ? (
            <StyledShapeSVGCheck src={TriangleCheckIconSrc} />
          ) : (
            <StyledShapeSVG src={TriangleIconSrc} onClick={() => setShape('triangle')} />
          )}
        </ShapeImagesWrapper>
      </ShapeSelectWrapper>
    </FilterWrapper>
  );
};

const FilterWrapper = styled.div`
  margin: 0 0.5rem;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StyledAddNewSVG = styled(SVG)<{ $isTriangle: boolean }>`
  width: 10rem;
  height: 2.5rem;
  cursor: pointer;
  transform: translateX(-1rem);

  ${({ $isTriangle }) =>
    $isTriangle &&
    css`
      .st0 path {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      polygon {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      #Polygon_102 .st2 {
        fill-opacity: 0;
      }

      #Polygon_102 .st1 {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }
    `}

  ${({ $isTriangle }) =>
    !$isTriangle &&
    css`
      path {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      polygon {
        fill: ${({ theme }) => theme.palette.brand.textPrimary}!important;
      }

      circle {
        fill-opacity: 0 !important;
      }
    `}
`;

const ShapeSelectWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ShapeImagesWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`;

const StyledShapeSVG = styled(SVG)`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  margin: 0 0.5rem;
`;

const StyledShapeSVGCheck = styled(SVG)`
  width: 2.8rem;
  height: 2.8rem;
  cursor: pointer;
`;

const AccordionSubContentText = styled.div`
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
`;

const FilterTitle = styled.div`
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;

  color: ${({ theme }) => theme.palette.brand.buttonSecondaryHover};
`;
const FilterSubtitle = styled.div`
  font-size: 1.125rem;
  width: 100%;
  color: ${({ theme }) => theme.palette.brand.buttonSecondaryHover};
`;
export { FieldLinkingFilter };
