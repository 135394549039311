/* eslint-disable typescript-enum/no-enum */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { MAX_UNDO_REDO_STACK_SIZE } from '@/features/canvas/constants';

export enum HistoryActionType {
  AddInitiative = 'AddInitiative',
  AddSection = 'AddSection',
  AddTimePeriod = 'AddTimePeriod',
  AddDependency = 'AddDependency',
  DeleteInitiative = 'DeleteInitiative',
  DeleteSection = 'DeleteSection',
  DeleteTimePeriod = 'DeleteTimePeriod',
  DeleteDependency = 'DeleteDependency',
  MoveSection = 'MoveSection',
  UpdateSection = 'UpdateSection',
  MoveTimePeriod = 'MoveTimePeriod',
  UpdateNode = 'UpdateNode',
}

const TimePeriodActions = [
  HistoryActionType.AddTimePeriod,
  HistoryActionType.DeleteTimePeriod,
  HistoryActionType.MoveTimePeriod,
];

const InitiativeActions = [HistoryActionType.AddInitiative, HistoryActionType.DeleteInitiative];

const SectionActions = [
  HistoryActionType.AddSection,
  HistoryActionType.DeleteSection,
  HistoryActionType.MoveSection,
  HistoryActionType.UpdateSection,
];

const NodeActions = [HistoryActionType.UpdateNode];

type HistoryItem = {
  redo: (resourceIds?: number[]) => void | Promise<void>;
  type: HistoryActionType;
  undo: (resourceIds?: number[]) => void | Promise<void>;
  resourceIds?: number[];
};

export const UndoRedoContext = createContext({
  isLoading: false,
  canGoForward: false,
  canGoBack: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  undo: async (resourceIds?: number[]) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  redo: async (resourceIds?: number[]) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addHistoryItem: (historyItem: HistoryItem) => {},
  updateResourceId: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemType: HistoryActionType,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    oldResourceId: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newResourceId: number,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    otherItemsUpdate?: { newResourceIds: number[]; resourceIds: number[] }
  ) => {},
});

const isMacOs = () => window.navigator.appVersion.indexOf('Mac') !== -1;

export const UndoRedoContextProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [historyItems, setHistoryItems] = useState<HistoryItem[]>([]);
  const [historyIndex, setHistoryIndex] = useState(0);

  const historyItemsRef = useRef<HistoryItem[]>(historyItems);
  const canGoBack = historyIndex > 0 && historyItems.length > 0;
  const canGoForward = historyIndex <= historyItems.length - 1;

  const undo = useCallback(async () => {
    try {
      if (canGoBack && !isLoading) {
        setIsLoading(true);

        const historyItem = historyItems[historyIndex - 1];

        await historyItem.undo?.(historyItem.resourceIds);
        setHistoryIndex(historyIndex - 1);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [canGoBack, historyIndex, historyItems, isLoading]);

  const redo = useCallback(async () => {
    try {
      if (canGoForward && !isLoading) {
        setIsLoading(true);

        const historyItem = historyItems[historyIndex];
        setHistoryIndex(historyIndex + 1);

        await historyItem.redo?.(historyItem.resourceIds);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [canGoForward, historyIndex, historyItems, isLoading]);

  useEffect(() => {
    historyItemsRef.current = historyItems;
  }, [historyItems]);

  const addHistoryItem = (historyItem: HistoryItem) => {
    const newHistoryItems = historyItems.slice(0, historyIndex);
    newHistoryItems.push(historyItem);
    if (newHistoryItems.length > MAX_UNDO_REDO_STACK_SIZE) {
      newHistoryItems.shift();
    }

    setHistoryIndex(newHistoryItems.length);
    setHistoryItems(newHistoryItems);
  };

  const updateResourceId = (
    itemType: HistoryActionType,
    oldResourceId: number,
    newResourceId: number,
    otherItemsUpdate?: { newResourceIds: number[]; resourceIds: number[] }
  ) => {
    const newStack = historyItemsRef.current.map(item => {
      if (
        otherItemsUpdate &&
        item.resourceIds?.every(r => otherItemsUpdate.resourceIds.includes(r)) &&
        item.type === HistoryActionType.UpdateNode
      ) {
        return {
          ...item,
          resourceIds: otherItemsUpdate.newResourceIds,
        };
      }
      if (
        TimePeriodActions.includes(item.type) &&
        TimePeriodActions.includes(itemType) &&
        item.resourceIds?.includes(oldResourceId)
      ) {
        return {
          ...item,
          resourceIds: item.resourceIds.map(id => {
            if (id === oldResourceId) {
              return newResourceId;
            }
            return id;
          }),
        };
      }

      if (
        InitiativeActions.includes(item.type) &&
        InitiativeActions.includes(itemType) &&
        item.resourceIds?.includes(oldResourceId)
      ) {
        return {
          ...item,
          resourceIds: item.resourceIds!.map(id => {
            if (id === oldResourceId) {
              return newResourceId;
            }
            return id;
          }),
        };
      }
      if (
        SectionActions.includes(item.type) &&
        SectionActions.includes(itemType) &&
        item.resourceIds?.includes(oldResourceId)
      ) {
        return {
          ...item,
          resourceIds: item.resourceIds.map(id => {
            if (id === oldResourceId) {
              return newResourceId;
            }
            return id;
          }),
        };
      }
      if (
        NodeActions.includes(item.type) &&
        NodeActions.includes(itemType) &&
        item.resourceIds?.includes(oldResourceId)
      ) {
        return {
          ...item,
          resourceIds: item.resourceIds.map(id => {
            if (id === oldResourceId) {
              return newResourceId;
            }
            return id;
          }),
        };
      }
      return item;
    });

    setHistoryItems(newStack);
  };

  useEffect(() => {
    window.onkeydown = e => {
      if ((!isMacOs() && e.ctrlKey) || e.metaKey) {
        // eslint-disable-next-line default-case
        switch (e.key) {
          case 'z':
            undo();
            return;
          case 'y':
            redo();
        }
      }
    };

    return () => {
      window.onkeydown = null;
    };
  }, [undo, redo]);

  return (
    <UndoRedoContext.Provider
      value={{ undo, redo, addHistoryItem, updateResourceId, canGoForward, canGoBack, isLoading }}
    >
      {children}
    </UndoRedoContext.Provider>
  );
};

export const useUndoRedo = () => {
  return useContext(UndoRedoContext);
};
