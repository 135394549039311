import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { defaultApi } from '@/api';
import routes from '@/constants/routes';
import { authCheck, refreshAccessToken } from '@/features/authentication/api/auth';
import { components } from '@/types/api';

const AccountContext = React.createContext({
  account: {} as components['schemas']['User'] | undefined,
});

export const AccountContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isInterceptorInitialized, setIsInterceptorInitialized] = useState(false);
  const [userCookies, setUserCookies] = useCookies(['user']);
  const [{ refreshToken }, setRefreshTokenCookie] = useCookies(['refreshToken']);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const refreshTokenRef = useRef(refreshToken);
  refreshTokenRef.current = refreshToken;

  const { data: account, isLoading } = useQuery<components['schemas']['User']>(
    authCheck.name,
    authCheck,
    {
      enabled: Boolean(userCookies.user) && isInterceptorInitialized,
      onSuccess: userAccount => {
        if (!userAccount) {
          navigate(routes.login);
        }
      },
      onError: () => {
        navigate(routes.login);
      },
    }
  );

  const { mutateAsync: refreshTokenMutation } = useMutation(refreshAccessToken);

  useEffect(() => {
    const connectivityUpdateInterceptor = defaultApi.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (error?.response?.status === 401) {
          try {
            const { accessToken, refreshToken: newRefreshToken } = await refreshTokenMutation({
              refreshToken: refreshTokenRef.current,
            });
            setRefreshTokenCookie('refreshToken', newRefreshToken);
            setUserCookies('user', accessToken);

            const originalRequest = error.config;
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;

            return defaultApi.request(originalRequest);
          } catch (err) {
            queryClient.setQueryData(authCheck.name, undefined);
            navigate(routes.login);
          }
        }
        return Promise.reject(error);
      }
    );

    setIsInterceptorInitialized(true);

    return () => {
      defaultApi.interceptors.response.eject(connectivityUpdateInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account && pathname === routes.login && !isLoading) {
      navigate(routes.roadmaps);
    }
  }, [account, navigate, pathname, isLoading]);

  if (isLoading || !isInterceptorInitialized) {
    return (
      <LoaderWrapper>
        <StyledLoader />
      </LoaderWrapper>
    );
  }

  return <AccountContext.Provider value={{ account }}>{children}</AccountContext.Provider>;
};

export const useAccount = () => {
  const context = React.useContext(AccountContext);

  if (!context) {
    throw Error('AccountContext hook can be used only inside AccountContextProvider');
  }

  return context.account;
};

const LoaderWrapper = styled.div`
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoader = styled(CircularProgress)`
  color: '#6767e0';
`;
