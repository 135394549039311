export const InitiativeFieldTypes = {
  description: 'description',
  title: 'title',
  budget: 'budget',
  progressBar: 'progressBar',
  date: 'date',
  big: 'big',
  small: 'small',
  placeholder: 'placeholder',
};

export const InitiativeFieldTypesConst = {
  description: 'description',
  title: 'title',
  budget: 'budget',
  progressBar: 'progressBar',
  date: 'date',
  big: 'big',
  small: 'small',
  placeholder: 'placeholder',
} as const;

export const InitiativeFieldTitles = {
  description: 'Description',
  title: 'Title',
  budget: 'Budget',
  progressBar: 'Progress Bar',
  startDate: 'Start Date',
  endDate: 'End Date',
  customSmall: 'Add Small custom field',
  customBig: 'Add Large custom field',
};

export const CustomFieldType = {
  big: 'big',
  small: 'small',
} as const;
