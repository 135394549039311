import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import styled from 'styled-components';

import SearchIconSrc from '@/assets/icons/search.svg';
import routes from '@/constants/routes';
import { createNewRoadmap, deleteRoadmap, getRoadmapList } from '@/features/roadmaps/api';
import { defaultRoadmap } from '@/features/roadmaps/components/Sidebar';
import { ConfirmationDialog } from '@/features/ui/components/ConfirmationDialog';
import Icon from '@/features/ui/components/Icon/Icon';
import { useShowToast } from '@/hooks/useShowToast';

import EmptyProjectItem from '../EmptyProject';
import { ManageRoadmapPermissionsDialog } from '../ManageRoadmapPermissionsDialog';
import ProjectItem from '../ProjectItem';

import * as S from './styles';

const RoadmapsList = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();

  const [isDeleteProjectDialogOpen, setIsDeleteProjectDialogOpen] = useState(false);
  const [selectedRoadMapId, setSelectedRoadMapId] = useState<null | number>(null);
  const [roadmapOwner, setRoadmapOwner] = useState<null | { id: number; userName: string }>(null);
  const [isManageRoadmapPermissionsDialogOpened, setIsManageRoadmapPermissionsDialogOpened] =
    useState(false);

  const { data: roadmaps, isLoading } = useQuery(getRoadmapList.name, getRoadmapList, {
    onError: fetchingError => {
      console.error(fetchingError);
      showToast('error', t('roadmaps.fetch_error'));
    },
  });

  const { mutateAsync: createRoadmapMutation } = useMutation(
    () => createNewRoadmap(defaultRoadmap),

    {
      onSuccess: roadmap => {
        showToast('success', t('roadmaps.create_success'));
        navigate(`${routes.roadmap}/${roadmap.id}`);
      },
      onError: () => {
        showToast('error', t('roadmaps.create_error'));
      },
    }
  );

  const filteredRoadmaps = useMemo(() => {
    return roadmaps
      ?.filter(roadmap => {
        const cleanedRoadmapTitle = roadmap.title
          ? roadmap.title.replace(/\s/g, '').toLowerCase()
          : '';
        const cleanedSearchText = searchText.replace(/\s/g, '').toLowerCase();

        return cleanedRoadmapTitle.includes(cleanedSearchText);
      })
      .sort((a, b) => dayjs(b.updatedAt).valueOf() - dayjs(a.updatedAt).valueOf());
  }, [roadmaps, searchText]);

  const filteredRoadmapOptions = useMemo(
    () =>
      (filteredRoadmaps || []).map(({ title, id, updatedAt }) => ({
        id,
        label: title,
        value: id,
        lastEditedAt: updatedAt,
      })),
    [filteredRoadmaps]
  );

  const { mutateAsync: deleteRoadmapMutation, isLoading: isDeletingRoadmap } = useMutation(
    () => deleteRoadmap(selectedRoadMapId!),
    {
      onSuccess: async () => {
        setSelectedRoadMapId(null);
        setRoadmapOwner(null);
        setIsDeleteProjectDialogOpen(false);
        await queryClient.invalidateQueries(getRoadmapList.name);
      },
    }
  );

  const debouncedSetSearchText = debounce(setSearchText, 500);

  return (
    <>
      <S.SearchBarRoadmapDiv>
        <div>
          <Autocomplete
            freeSolo
            sx={{
              width: 450,
              maxWidth: '100%',
            }}
            popupIcon={null}
            filterOptions={options => options}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            renderInput={params => (
              <StyledSearchTextField
                {...params}
                placeholder={t('roadmaps.search')}
                variant="standard"
                value={searchText}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  sx: {
                    padding: '0 1rem',
                  },
                  startAdornment: (
                    <StartAdornmentIconWrapper>
                      <Icon src={SearchIconSrc} />
                    </StartAdornmentIconWrapper>
                  ),
                }}
              />
            )}
            disableClearable
            options={filteredRoadmapOptions}
            onInputChange={e => {
              const target = e.target as HTMLInputElement;
              if (target.value) {
                debouncedSetSearchText(target.value);
              } else {
                debouncedSetSearchText('');
              }
            }}
            clearOnBlur={false}
            getOptionLabel={option => {
              if (typeof option === 'string') {
                return option;
              }
              return option.label || 'Untitled';
            }}
            renderOption={(props, { label, lastEditedAt }) => {
              return (
                <StyledOption {...props} key={props.id}>
                  <IconWrapper>
                    <Icon src={SearchIconSrc} />
                  </IconWrapper>
                  <OptionContent>
                    <OptionTitle>{label}</OptionTitle>
                    <OptionSubtitle>
                      {t('roadmaps.last_opened', {
                        time: dayjs(lastEditedAt).format('MM/DD/YYYY HH:mm'),
                      })}
                    </OptionSubtitle>
                  </OptionContent>
                </StyledOption>
              );
            }}
            onChange={(_, roadmap) => {
              if (typeof roadmap === 'string') {
                setSearchText(roadmap);
                return;
              }

              setSearchText(roadmap?.label || '');
            }}
          />
          {isLoading && (
            <LoaderWrapper>
              <StyledLoader />
            </LoaderWrapper>
          )}
          {!isLoading && (
            <S.ListContainer>
              <>
                {filteredRoadmaps?.map(roadmap => (
                  <ProjectItem
                    roadmap={roadmap}
                    key={roadmap.id}
                    setIsDeleteProjectDialogOpen={setIsDeleteProjectDialogOpen}
                    setSelectedRoadMapId={setSelectedRoadMapId}
                    setRoadmapOwner={setRoadmapOwner}
                    setIsManageRoadmapPermissionsDialogOpened={
                      setIsManageRoadmapPermissionsDialogOpened
                    }
                  />
                ))}
                <EmptyProjectItem handleNewProject={createRoadmapMutation} />
              </>
            </S.ListContainer>
          )}
        </div>
      </S.SearchBarRoadmapDiv>

      <ManageRoadmapPermissionsDialog
        onClose={() => setIsManageRoadmapPermissionsDialogOpened(false)}
        open={isManageRoadmapPermissionsDialogOpened}
        selectedRoadmapId={selectedRoadMapId!}
        roadmapOwner={roadmapOwner}
      />

      <ConfirmationDialog
        isLoading={isDeletingRoadmap}
        open={isDeleteProjectDialogOpen}
        okButtonText={t('yes_sure')}
        keepMounted={false}
        description={t('roadmaps.delete_dialogue_description')}
        handleOk={() => deleteRoadmapMutation()}
        id="delete-roadmap-confirm-dialog"
        onClose={() => {
          setIsDeleteProjectDialogOpen(false);
          setSelectedRoadMapId(null);
        }}
      />
    </>
  );
};

const StyledOption = styled(MenuItem)`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;

  &&:hover {
    background-color: ${({ theme }) => theme.palette.brand.backgroundInputFocus};
  }
`;
const IconWrapper = styled.div`
  padding: 1rem 2rem 1rem 1rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const OptionContent = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;
const OptionTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;
const OptionSubtitle = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.brand.secondary};
`;

const StartAdornmentIconWrapper = styled.div`
  margin-right: 2rem;

  svg {
    display: flex;
    width: 2rem;
    height: 2rem;
  }
`;

const StyledSearchTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.brand.white};
  border-radius: 2rem;
  font-size: 2rem;
  border: 0;
  font-style: italic;
  margin-bottom: 4rem;
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export default RoadmapsList;
