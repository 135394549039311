import { useTranslation } from 'react-i18next';
import { Engineering } from '@mui/icons-material';
import styled from 'styled-components';

import { theme } from '@/features/ui/theme';

const MaintenanceMode = () => {
  const defaultTheme = theme();
  const { t } = useTranslation();
  return (
    <Maintenance $bgColor={defaultTheme.palette.brand.backgroundMain}>
      <Engineering sx={{ fontSize: '15rem' }} />
      <Heading $color={defaultTheme.palette.brand.textPrimary}>{t('maintenance.title')}</Heading>
      <Description $color={defaultTheme.palette.brand.textSecondary}>
        {t('maintenance.description')}
      </Description>
    </Maintenance>
  );
};

const Maintenance = styled.div<{ $bgColor: string }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  background-color: ${({ $bgColor }) => $bgColor};
  padding: 2rem;
`;

const Heading = styled.h1<{ $color: string }>`
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: bold;
  color: ${({ $color }) => $color};
`;

const Description = styled.p<{ $color: string }>`
  margin-bottom: 2rem;

  font-weight: bold;
  text-align: center;
  font-size: 2rem;
  color: ${({ $color }) => $color};
`;

export { MaintenanceMode };
