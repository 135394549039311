import { useMutation, UseMutationOptions } from 'react-query';
import { defaultApi } from 'src/api';

import { ForgotPasswordFormData } from '../types/User';

const useForgotPasswordMutation = (
  options?: UseMutationOptions<boolean, unknown, ForgotPasswordFormData>
) => {
  return useMutation<boolean, unknown, ForgotPasswordFormData>(async formData => {
    try {
      await defaultApi.post('/reset-password', formData);
      return true;
    } catch {
      return false;
    }
  }, options);
};

export default useForgotPasswordMutation;
