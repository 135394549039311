import { useMutation, useQueryClient } from 'react-query';
import { deleteInitiative, getAllRoadmapNodes } from '@/features/canvas/api';
import { getRoadmapById } from '@/features/roadmaps/api';
import { useShowToast } from '@/hooks/useShowToast';
import { useTranslation } from 'react-i18next';

interface UseInitiativeProps {
  updateLastSavedTime: () => void;
  roadmapId: number;
  setSelectedNodeId: (nodeId: number | null) => void;
  setIsBasicDeleteInitiativeDialogOpen?: (isOpen: boolean) => void;
}

export const useInitiative = ({
  updateLastSavedTime,
  roadmapId,
  setIsBasicDeleteInitiativeDialogOpen,
  setSelectedNodeId,
}: UseInitiativeProps) => {
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const { mutateAsync: deleteInitiativeMutation, isLoading: isDeletingInitiative } = useMutation(
    async (initiativeId: number) => {
      await deleteInitiative({ id: initiativeId });
      updateLastSavedTime();
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([getRoadmapById.name, roadmapId]);
        await queryClient.invalidateQueries([getAllRoadmapNodes.name, roadmapId]);
        setSelectedNodeId(null);
        setIsBasicDeleteInitiativeDialogOpen?.(false);
        showToast('success', t('editor.sidebar.initiative.delete_initiative_success'));
      },
      onError: () => {
        showToast('error', t('editor.sidebar.initiative.delete_initiative_error'));
      },
    }
  );

  return {
    deleteInitiativeMutation,
    isDeletingInitiative,
  };
};
