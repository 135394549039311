import { useCallback } from 'react';
import { OptionsObject, SnackbarOrigin, useSnackbar, VariantType } from 'notistack';

export const useShowToast = (): {
  showToast: (variant: VariantType, message: string, options?: OptionsObject<VariantType>) => void;
} => {
  const { enqueueSnackbar } = useSnackbar();

  const showToast = useCallback(
    (variant: VariantType, message: string, options?: OptionsObject<VariantType>) => {
      const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' };
      enqueueSnackbar(message, { variant, anchorOrigin, ...options });
    },
    [enqueueSnackbar]
  );
  return { showToast };
};
