import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { darken } from 'polished';
import styled from 'styled-components';

import { INPUT_MAX_LENGTH } from '@/constants/common';
import { useAccount } from '@/contexts/account-context';

import useSupportMutation from '../api/hooks/useSupportMutation';
import { SupportForm } from '../types/SupportForm';

import * as S from './styles';

const SupportPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const account = useAccount();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SupportForm>({
    defaultValues: {
      name: `${account!.firstName} ${account!.lastName}`,
      email: account!.email,
    },
  });
  const { mutateAsync } = useSupportMutation();

  const onSubmit = useCallback((data: SupportForm) => mutateAsync(data), [mutateAsync]);

  return (
    <S.Wrapper>
      <S.GridWrapper>
        <S.Title text={t('support.page_title')} color={theme.palette.brand.primary} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.InputDiv>
            <S.InputRow>
              <S.Label text={t('name')} color={theme.palette.brand.primary} />
              <S.NameInput
                {...register('name', { required: true, maxLength: INPUT_MAX_LENGTH })}
                placeholder={t('support.name_placeholder')}
              />

              {errors.name && (
                <S.ErrorMessage
                  text={t('support.name_error')}
                  color={theme.palette.brand.corePPTAction}
                />
              )}
            </S.InputRow>
            <S.InputRow marginLeft="2.4rem">
              <S.Label text={t('email')} color={theme.palette.brand.primary} />
              <S.EmailInput
                {...register('email', {
                  required: true,
                  maxLength: INPUT_MAX_LENGTH,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                })}
                placeholder={t('authentication.email')}
              />

              {errors.email && (
                <S.ErrorMessage
                  text={t('support.email_error')}
                  color={theme.palette.brand.corePPTAction}
                />
              )}
            </S.InputRow>
          </S.InputDiv>
          <S.InputDiv>
            <S.InputRow>
              <S.Label marginTop="3.8rem" text={t('message')} color={theme.palette.brand.primary} />
              <S.TextAreaInput
                {...register('message', { required: true })}
                placeholder={t('support.message_placeholder')}
              />

              {errors.message && (
                <S.ErrorMessage
                  text={t('support.message_error')}
                  color={theme.palette.brand.corePPTAction}
                />
              )}
            </S.InputRow>
          </S.InputDiv>

          <ButtonWrapper>
            <StyledButton variant="contained" type="submit">
              {t('send')}
            </StyledButton>
          </ButtonWrapper>
        </form>
      </S.GridWrapper>
    </S.Wrapper>
  );
};

export default SupportPage;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  border-radius: 4rem;
  padding: 0.2rem 3rem 0.2rem 3rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-weight: 500;
  font-size: 2rem;
  transition: background-color 0.2s ease-in-out;
  text-transform: none;

  background-color: ${({ theme }) => theme.palette.brand.primary};
  color: ${({ theme }) => theme.palette.brand.white};

  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.secondary)};
  }
`;
