import Text from 'src/features/ui/components/Text/Text';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: ${({ theme }) => theme.palette.brand.backgroundHeader};
  padding: 0.75rem 0 0 4rem;
`;

export const LogoWrapper = styled.img`
  max-width: 100%;
  height: 40px;
  cursor: pointer;
`;

export const WelcomeText = styled(Text)`
  padding: 10px 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
