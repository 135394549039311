import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { darken } from 'polished';
import useLogin from 'src/features/authentication/api/useLogin';
import Input from 'src/features/ui/components/Input/Input';
import styled from 'styled-components';

import { UserManagementContainer } from '@/features/ui/components/UserManagementContainer';
import { components } from '@/types/api';

import * as S from './styles';

const FormContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  margin: 2.5rem 0;
`;
const FormItem = styled.div``;

const LoginPage = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<components['schemas']['User']>();
  const { mutateAsync, isLoading } = useLogin();

  const onSubmit = useCallback(
    async (formData: components['schemas']['User']) => {
      await mutateAsync(formData);
    },
    [mutateAsync]
  );

  return (
    <UserManagementContainer title={t('welcome')} description={t('authentication.login_desc')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContent>
          <FormItem>
            <S.H4>{t('email')}</S.H4>
            <Input
              {...register('email', {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
              })}
              icon="person"
              placeholder={t('authentication.email')}
            />
            {errors.email && <S.ErrorMessage text={t('support.email_error')} />}
          </FormItem>
          <FormItem>
            <S.H4>{t('password')}</S.H4>
            <Input
              {...register('password', {
                required: true,
              })}
              icon="key"
              type="password"
              hidable
              placeholder={t('password')}
            />
            {errors.password && <S.ErrorMessage text={t('authentication.password_required')} />}
          </FormItem>
          <S.HoverLink href="forgot-password">
            <S.ForgotText text={t('authentication.password_forgot')} size="small" />
          </S.HoverLink>
        </FormContent>

        <ButtonWrapper>
          <StyledButton variant="contained" type="submit" disabled={isLoading}>
            {t('log_in')}
          </StyledButton>
        </ButtonWrapper>
      </form>
    </UserManagementContainer>
  );
};

export default LoginPage;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  border-radius: 4rem;
  padding: 0.2rem 3rem 0.2rem 3rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-weight: 500;
  font-size: 2rem;
  transition: background-color 0.2s ease-in-out;
  text-transform: none;

  background-color: ${({ theme }) => theme.palette.brand.primary};
  color: ${({ theme }) => theme.palette.brand.white};

  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.secondary)};
  }
`;
