import Button from 'src/features/ui/components/Button/Button';
import { Card } from 'src/features/ui/components/Card/Card';
import Text from 'src/features/ui/components/Text/Text';
import styled from 'styled-components';

type TextProps = {
  fontSize?: string;
  lineHeight?: string;
  margin?: string;
  textAlign?: 'center' | 'left';
};

export const H2 = styled(Text)<TextProps>`
  margin: ${props => props?.margin ?? '0rem'};
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  text-align: center;
  font-weight: 600;
  font-size: ${props => props?.fontSize ?? '2.5rem'};
  line-height: ${props => props?.lineHeight ?? '3.3rem'};
  letter-spacing: 0.4px;
`;

export const H4 = styled(Text)<TextProps>`
  text-align: ${props => props?.textAlign ?? 'left'};
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
  font-size: 2rem;
  font-weight: 600;
`;

export const Wrapper = styled.main`
  font-size: 1.6rem;
  background-color: ${({ theme }) => theme.palette.brand.backgroundMain};
  height: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  text-align: center;
  padding: 32px;
`;

export const Logo = styled.img`
  width: 20rem;
`;

export const ForgotPasswordCard = styled(Card)`
  margin: 0 auto;
  padding: 3rem 16rem;
  max-width: 920px;
  background-color: ${({ theme }) => theme.palette.brand.white};
`;

export const ForgotPasswordButton = styled(Button)`
  font-size: 2rem;
  padding: 0.5rem 3rem;
  text-align: center;
  align-self: center;
`;

export const SendAgainButton = styled(Button)`
  font-size: 2rem;
  padding: 0.5rem 3rem;
  text-align: center;
  align-self: center;
`;

export const CentralizedDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 5rem;
`;

export const ErrorMessage = styled(Text)`
  font-size: 1.75rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.palette.brand.corePPTAction};
`;
