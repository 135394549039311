import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { Divider, useTheme } from '@mui/material';
import styled, { css } from 'styled-components';

import CircleSVG from '@/assets/icons/circle.svg';
import CircleEmptySVG from '@/assets/icons/circle-empty.svg';
import CircleMarkedSVG from '@/assets/icons/circle-marked.svg';
import SquareSVG from '@/assets/icons/square.svg';
import SquareEmptySVG from '@/assets/icons/square-empty.svg';
import SquareMarkedSVG from '@/assets/icons/square-marked.svg';
import TriangleSVG from '@/assets/icons/triangle.svg';
import TriangleEmptySVG from '@/assets/icons/triangle-empty.svg';
import TriangleMarkedSVG from '@/assets/icons/triangle-marked.svg';
import { CustomSwitch } from '@/features/canvas/components/CustomSwitch';
import { FilteredNodes, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { components } from '@/types/api';

const FilterSidebarItem = ({ filter }: { filter: components['schemas']['Filter'] }) => {
  const theme = useTheme();
  const {
    filterNodes,
    filteredNodes,
    setFilteredNodes,
    filters,
    activeFilterId,
    setActiveFilterId,
    optionCheckedId,
    setOptionCheckedId,
    setShowDependencies,
  } = useEditorContext();

  const getFilterIcon = (color: string, isChecked: boolean, selection: 'single' | 'multiple') => {
    let fillColor = color;

    let addIcon = isChecked ? CircleEmptySVG : CircleSVG;
    if (selection === 'single') {
      if (filter.shape === 'triangle') {
        addIcon = isChecked ? TriangleEmptySVG : TriangleSVG;
      } else if (filter.shape === 'square') {
        addIcon = isChecked ? SquareEmptySVG : SquareSVG;
      }
    }

    if (selection === 'multiple') {
      fillColor = theme.palette.brand.textSecondary;
      addIcon = isChecked ? CircleMarkedSVG : CircleEmptySVG;
      if (filter.shape === 'triangle') {
        addIcon = isChecked ? TriangleMarkedSVG : TriangleEmptySVG;
      } else if (filter.shape === 'square') {
        addIcon = isChecked ? SquareMarkedSVG : SquareEmptySVG;
      }
    }

    return (
      <StyledSVG
        src={addIcon}
        $color={color}
        $isSquare={filter.shape === 'square'}
        $isChecked={isChecked}
        $fillColor={fillColor}
        $isMultiFilter={selection === 'multiple'}
      />
    );
  };

  const handleChecked = (option: components['schemas']['FilterOption']) => {
    if (option.id !== optionCheckedId) {
      setShowDependencies(false);
      setOptionCheckedId(option.id!);
      filterNodes({
        filter,
        optionId: option.id!,
        optionColor: option.color,
        multiFilterColor: theme.palette.brand.textSecondary,
      });
      setActiveFilterId(filter.id!);
      return;
    }

    filterNodes({
      filter,
      optionId: undefined,
      optionColor: '',
      multiFilterColor: theme.palette.brand.textSecondary,
    });
    deactivateFilterGroup();
    setActiveFilterId(null);
  };

  const activateFilterGroup = useCallback(
    ({ filterId }: { filterId: number }) => {
      const activeFilter = filters.find(f => Number(f.id) === filterId);
      const activeFilterNodes = activeFilter?.nodes;
      const activeFilterOptions = activeFilter?.options;

      if (!activeFilterNodes) {
        return;
      }

      const optionColorMap = new Map<number, string>();

      activeFilterOptions?.forEach(option => {
        optionColorMap.set(Number(option.id), option.color);
      });

      const newEditedNodes = filteredNodes.map(node => {
        if (activeFilter.selection === 'multiple') {
          const filteredFilterNodes = activeFilterNodes?.filter(n => n.nodeId === node.id);

          if (filteredFilterNodes?.length) {
            return {
              ...node,
              shape: filter.shape,
              color: theme.palette.brand.textSecondary,
            };
          }
          return {
            ...node,
            shape: '',
            color: '',
          };
        }
        const filteredNode = activeFilterNodes?.find(n => n.nodeId === node.id);

        if (filteredNode) {
          return {
            ...node,
            shape: activeFilter?.shape,
            color: optionColorMap.get(Number(filteredNode.optionId)),
          };
        }
        return {
          ...node,
          shape: '',
          color: '',
        };
      });

      setFilteredNodes(newEditedNodes as FilteredNodes[]);
      setOptionCheckedId(null);
    },
    [
      filter.shape,
      filteredNodes,
      filters,
      setFilteredNodes,
      setOptionCheckedId,
      theme.palette.brand.textSecondary,
    ]
  );

  const deactivateFilterGroup = useCallback(() => {
    const newEditedNodes = filteredNodes.map(node => {
      return {
        ...node,
        shape: '',
        color: '',
      };
    });

    setFilteredNodes(newEditedNodes as FilteredNodes[]);
    setOptionCheckedId(null);
  }, [filteredNodes, setFilteredNodes, setOptionCheckedId]);

  return (
    <SidebarFilterItem>
      <FilterItemHeader>
        <FilterItemTitle>{filter.title}</FilterItemTitle>
        <CustomSwitch
          onChange={() => {
            if (activeFilterId === Number(filter.id)) {
              setActiveFilterId(null);
              deactivateFilterGroup();
              return;
            }

            activateFilterGroup({ filterId: Number(filter.id) });
            setActiveFilterId(Number(filter.id));
          }}
          isChecked={activeFilterId === Number(filter.id)}
          value={Number(filter.id)}
        />
      </FilterItemHeader>
      <Divider variant="middle" sx={{ borderColor: theme.palette.brand.backgroundDialog }} />
      <FilterWrapper>
        {filter.options.map(option => {
          return (
            <FilterOptionWrapper key={option.id} onClick={() => handleChecked(option)}>
              {getFilterIcon(
                option.color || theme.palette.brand.textSecondary,
                option.id === optionCheckedId,
                filter.selection!
              )}
              <OptionTitle $isMarked={option.id === optionCheckedId}>{option.title}</OptionTitle>
            </FilterOptionWrapper>
          );
        })}
      </FilterWrapper>
    </SidebarFilterItem>
  );
};

const SidebarFilterItem = styled.div`
  width: 100%;
`;

const FilterItemTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  word-wrap: break-word;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 20px;
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0 1rem 1.5rem;
  flex-direction: column;
`;

const FilterOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  overflow: visible;
`;

const FilterItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem 0.5rem 1.5rem;
`;

const StyledSVG = styled(SVG)<{
  $color: string;
  $fillColor: string;
  $isChecked: boolean;
  $isMultiFilter: boolean;
  $isSquare: boolean;
}>`
  width: 1.5rem;
  height: 1.5rem;
  overflow: visible;

  ${({ $color, $isChecked, $fillColor, $isMultiFilter }) =>
    css`
      ${!$isMultiFilter &&
      css`
        ${!$isChecked &&
        css`
          fill: ${$fillColor};
          & path {
            stroke: ${$fillColor};
            fill: ${$fillColor};
          }
        `}
        ${$isChecked &&
        css`
          & circle:nth-child(2),
          rect:nth-child(2) {
            stroke: ${$color};
            stroke-width: 2;
          }
          & path {
            stroke: ${$fillColor};
          }
        `}
      `}
      ${$isMultiFilter &&
      css`
        & > path {
          fill: ${$fillColor};
          stroke: ${$fillColor};
        }
        & circle:nth-child(3),
        rect:nth-child(3) {
          fill: ${$fillColor};
        }
      `}
    `}
`;

const OptionTitle = styled.div<{ $isMarked: boolean }>`
  font-size: 1.4rem;
  max-width: 190px;
  word-break: initial;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  line-height: 1.7rem;

  ${({ $isMarked }) =>
    $isMarked &&
    css`
      font-weight: bold;
    `}
`;

export { FilterSidebarItem };
