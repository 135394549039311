import { Controller, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, SelectProps } from '@mui/material';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';

import { useFormContext } from '../Form';

type FormSelectInputProps = SelectProps & {
  name: string;
  options: { id: number | string; label: string }[];
  hide?: boolean;
  label?: string;
  maxWidth?: number;
  noMargin?: boolean;
  placeholder?: string;
  required?: boolean;
  rules?: Omit<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

export const FormSelectInput = ({
  label,
  name,
  placeholder,
  required,
  hide,
  options,
  noMargin,
  onChange,
  ...otherProps
}: FormSelectInputProps) => {
  const { control } = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      rules={{
        required: required ? 'validation required' : false,
        ...(otherProps.rules ? otherProps.rules : {}),
      }}
      render={({ field, fieldState: { error }, formState: { isSubmitting } }) => {
        return (
          <FormInputGroup noMargin={noMargin} maxWidth={otherProps.maxWidth}>
            {Boolean(label) && !hide && <FormInputLabel htmlFor={name}>{label}</FormInputLabel>}

            <FormInputWrapper style={hide ? { visibility: 'hidden', height: 0 } : undefined}>
              <StyledSelect
                placeholder={placeholder}
                {...otherProps}
                error={!!error}
                disabled={isSubmitting || otherProps.disabled}
                disableUnderline
                displayEmpty
                IconComponent={() => <StyledArrowDownIcon />}
                renderValue={selected => {
                  if (!selected) {
                    return <div>{placeholder}</div>;
                  }
                  return options.find(o => o.id === (selected as number))?.label;
                }}
                MenuProps={{
                  sx: {
                    maxHeight: '400px',
                  },
                }}
                inputProps={{
                  sx: {
                    padding: '1.5rem 2.5rem 1.5rem 1.5rem',
                  },
                }}
                value={field.value}
                onChange={(e, child) => {
                  onChange?.(e, child);
                  field.onChange(e.target.value);
                }}
              >
                {options.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormInputWrapper>

            {!!error && !isSubmitting && <FormInputError>{error.message}</FormInputError>}
          </FormInputGroup>
        );
      }}
    />
  );
};

const FormInputGroup = styled.div<{ maxWidth?: number; noMargin?: boolean }>`
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin-bottom: 1rem;
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `}
`;

const FormInputWrapper = styled.div`
  margin: 1rem 0.5rem !important;
`;

const FormInputError = styled.div`
  color: red;
`;

const FormInputLabel = styled.label`
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.palette.brand.buttonSecondaryHover};
`;

const StyledSelect = styled(Select)`
  background-color: ${({ theme }) => theme.palette.brand.white};
  border-radius: 1rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};

  & .MuiInput-input:focus {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.palette.brand.white};
  }
`;

const StyledArrowDownIcon = styled(ArrowDownIcon)`
  position: absolute;
  right: 3rem;
  pointer-events: none;
`;
