import { useCallback } from 'react';

import {
  CustomFieldType,
  InitiativeFieldTitles,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useInitialFieldOptions } from '@/features/canvas/hooks/useInitialFieldOptions';
import { InitiativeFieldOption } from '@/features/canvas/types/initiative';
import { components } from '@/types/api';

export const useFieldOptions = () => {
  const { dropdownVisible } = useEditorContext();
  const { selectFieldOptionsInit } = useInitialFieldOptions();

  const getFieldOptions = useCallback(
    (fields: components['schemas']['InitiativeField'][]) => {
      if (!fields) {
        return selectFieldOptionsInit;
      }

      const mappedVisibleInitiativeDropdownFields = dropdownVisible
        .map(f => {
          return {
            id: `${f.title}`,
            title: f.title,
            type: f.type,
            isDeletable: f.type === CustomFieldType.big || f.type === CustomFieldType.small,
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title));

      const visibleInitiativeFields = [
        {
          id: InitiativeFieldTitles.customSmall,
          title: InitiativeFieldTitles.customSmall,
          isDeletable: false,
          type: InitiativeFieldTypes.small,
        },
        {
          id: InitiativeFieldTitles.customBig,
          title: InitiativeFieldTitles.customBig,
          isDeletable: false,
          type: InitiativeFieldTypes.big,
        },
        ...mappedVisibleInitiativeDropdownFields,
      ];

      return visibleInitiativeFields as InitiativeFieldOption[];
    },
    [dropdownVisible, selectFieldOptionsInit]
  );

  const orderInitiativeFields = useCallback(
    (fields: components['schemas']['InitiativeField'][]) => {
      const fieldsWithOrderNumber = fields.map(field => {
        if (field.type === InitiativeFieldTypes.description) {
          return {
            ...field,
            order: 2,
          };
        }
        if (
          field.type === InitiativeFieldTypes.date &&
          field.title === InitiativeFieldTitles.startDate
        ) {
          return {
            ...field,
            order: 3,
          };
        }
        if (
          field.type === InitiativeFieldTypes.date &&
          field.title === InitiativeFieldTitles.endDate
        ) {
          return {
            ...field,
            order: 4,
          };
        }
        if (field.type === InitiativeFieldTypes.budget) {
          return {
            ...field,
            order: 5,
          };
        }
        if (field.type === InitiativeFieldTypes.progressBar) {
          return {
            ...field,
            order: 6,
          };
        }
        return { ...field, order: 7 };
      });

      const sortedReservedFields = fieldsWithOrderNumber.sort((a, b) => a.order! - b.order!);

      const sortedReservedFieldsWithAlphabeticallySortedCustomFields = sortedReservedFields.sort(
        (a, b) => {
          if (a.order === 7 && b.order === 7) {
            return a.title!.localeCompare(b.title!);
          }
          return 0;
        }
      );

      return sortedReservedFieldsWithAlphabeticallySortedCustomFields.map(field => {
        const { order, ...restOfField } = field;
        return restOfField;
      });
    },
    []
  );

  return {
    getFieldOptions,
    orderInitiativeFields,
  };
};
