import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { INPUT_MAX_LENGTH } from 'src/constants/common';
import useCreatePasswordMutation from 'src/features/authentication/api/useCreatePassword';
import { CreatePasswordFormInputs } from 'src/features/authentication/types/User';
import Input from 'src/features/ui/components/Input/Input';
import styled from 'styled-components';

import { UserManagementContainer } from '@/features/ui/components/UserManagementContainer';

import * as S from './styles';

const CreatePassword = () => {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<CreatePasswordFormInputs>();
  const password = watch('password', '');
  const { mutateAsync, isLoading } = useCreatePasswordMutation();

  const onSubmit = useCallback(
    async (formData: CreatePasswordFormInputs) => {
      if (token) {
        await mutateAsync({ email: formData.email, newPassword: formData.password, token });
      }
    },
    [mutateAsync, token]
  );

  return (
    <UserManagementContainer title={t('authentication.setup_password')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContent>
          <FormItem>
            <S.H4 text={t('email')} />
            <Input
              icon="person"
              placeholder={t('authentication.email')}
              {...register('email', {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                maxLength: INPUT_MAX_LENGTH,
              })}
            />
            {errors.email && <S.ErrorMessage text={t('support.email_error')} />}
          </FormItem>
          <FormItem>
            <S.H4 text={t('authentication.new_password')} />
            <Input
              icon="key"
              type="password"
              placeholder={t('new_password')}
              hidable
              {...register('password', {
                required: { value: true, message: t('authentication.password_required') },
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{10,}$/g,
                  message: t('authentication.wrong_password_format'),
                },
                minLength: {
                  value: 10,
                  message: t('authentication.wrong_password_length'),
                },
              })}
            />
            {errors.password?.message && <S.ErrorMessage text={errors.password.message} />}
          </FormItem>
          <FormItem>
            <S.H4 text={t('authentication.re_enter_password')} />
            <Input
              icon="key"
              type="password"
              placeholder={t('new_password_again')}
              hidable
              {...register('newPassword', {
                required: { value: true, message: t('authentication.password_required') },
                validate: {
                  passwordMatch: value => value === password,
                },
              })}
            />
            {errors.newPassword?.message && <S.ErrorMessage text={errors.newPassword?.message} />}
          </FormItem>
        </FormContent>

        <S.CentralizedDiv>
          <S.SubmitButton
            label={t('submit')}
            variant="secondary"
            type="submit"
            disabled={isLoading}
          />
        </S.CentralizedDiv>
      </form>
    </UserManagementContainer>
  );
};

const FormContent = styled.div`
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormItem = styled.div`
  margin: 1rem 0;
`;

export default CreatePassword;
