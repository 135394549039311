import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { darken } from 'polished';
import { INPUT_MAX_LENGTH } from 'src/constants/common';
import useForgotPasswordMutation from 'src/features/authentication/api/useForgotPassword';
import { ForgotPasswordFormData } from 'src/features/authentication/types/User';
import Input from 'src/features/ui/components/Input/Input';
import styled from 'styled-components';

import { UserManagementContainer } from '@/features/ui/components/UserManagementContainer';

import * as S from './styles';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    trigger,
  } = useForm<ForgotPasswordFormData>({ reValidateMode: 'onSubmit' });

  const { mutateAsync, isLoading } = useForgotPasswordMutation();
  const [isCodeRequested, setIsCodeRequested] = useState(false);
  const [isEmailNotFound, setIsEmailNotFound] = useState(false);

  const onSubmit = useCallback(
    async (formData: ForgotPasswordFormData) => {
      try {
        await trigger('email');
        const response = await mutateAsync(formData);
        if (response) {
          setIsCodeRequested(true);
          setIsEmailNotFound(false);
          navigate('/login');
        }
        throw new Error('Email was not found');
      } catch (error) {
        setIsEmailNotFound(true);
      }
    },
    [navigate, mutateAsync, setIsEmailNotFound, setIsCodeRequested, trigger]
  );

  const onSendAgain = useCallback(() => {
    setIsCodeRequested(false);
    setIsEmailNotFound(false);
    clearErrors('email');
    handleSubmit(onSubmit);
  }, [setIsCodeRequested, handleSubmit, onSubmit, clearErrors]);

  return (
    <UserManagementContainer
      title={t('authentication.password_forgot')}
      description={t('authentication.password_forgot_desc')}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContent>
          <FormItem>
            <S.H4 text={t('email')} />
            <Input
              {...register('email', {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                maxLength: INPUT_MAX_LENGTH,
              })}
              icon="person"
              placeholder={t('authentication.email')}
              isError={Boolean(errors.email || isEmailNotFound)}
            />
            {errors.email && !isEmailNotFound && <S.ErrorMessage text={t('support.email_error')} />}
          </FormItem>
        </FormContent>
        <ButtonWrapper>
          <StyledButton variant="contained" type="submit" disabled={isCodeRequested || isLoading}>
            {t('submit')}
          </StyledButton>

          {isCodeRequested && !isLoading && (
            <S.H4
              text={t('authentication.code_sent_message')}
              size="small"
              color={theme.palette.brand.positiveStatus}
              textAlign="center"
            />
          )}
          {!isEmailNotFound && !errors.email && !isLoading && (
            <SendAgainArea>
              <S.H4
                text={t('authentication.send_again_title')}
                size="small"
                color={theme.palette.brand.textSecondary}
                textAlign="center"
              />

              <StyledButton
                variant="contained"
                type="submit"
                disabled={!isCodeRequested || isLoading}
                onClick={onSendAgain}
              >
                {t('authentication.send_again')}
              </StyledButton>
            </SendAgainArea>
          )}
          {isEmailNotFound && (
            <S.ErrorMessage text={t('authentication.password_forgot_email_not_found')} />
          )}
        </ButtonWrapper>
      </form>
    </UserManagementContainer>
  );
};

const FormContent = styled.div`
  margin: 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const FormItem = styled.div`
  margin: 1rem 0;
`;

const SendAgainArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default ForgotPassword;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
`;

const StyledButton = styled(Button)`
  border-radius: 4rem;
  padding: 0.2rem 3rem 0.2rem 3rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-weight: 500;
  font-size: 2rem;
  transition: background-color 0.2s ease-in-out;
  text-transform: none;

  background-color: ${({ theme }) => theme.palette.brand.primary};
  color: ${({ theme }) => theme.palette.brand.white};

  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.secondary)};
  }
`;
