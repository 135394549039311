import React, { ReactNode, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

import BinIconSrc from '@/assets/icons/bin.svg';
import { HideFieldIcon } from '@/features/canvas/components/InitiativesAccordionContent/HideFieldIcon';
import { CustomFieldType } from '@/features/canvas/constants/initiative-field';
import { SmallActionButton } from '@/features/ui/components/SmallActionButton/SmallActionButton';
import { components } from '@/types/api';

const InitiativeCustom = ({
  readOnly,
  field,
  onDeleteField,
  isLoading,
  isInCreationMode = false,
  handleSubmit,
  isInitiativeLockedAndIsNotOwner,
  children,
}: {
  children: ReactNode;
  field: components['schemas']['InitiativeField'];
  handleSubmit: (title: string, type: keyof typeof CustomFieldType) => Promise<void>;
  isInitiativeLockedAndIsNotOwner: boolean;
  isLoading: boolean;
  readOnly: boolean;
  isInCreationMode?: boolean;
  onDeleteField?: (fieldId: number, e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const { t } = useTranslation();

  const [inputTitle, setInputTitle] = useState<string>(field.title || '');

  const handleInputTitleChange = (event: { target: { value: SetStateAction<string> } }) => {
    setInputTitle(event.target.value);
  };

  return (
    <>
      {isInCreationMode && (
        <StyledInput
          type="text"
          value={inputTitle}
          placeholder={t('editor.sidebar.initiative.title_custom_field_placeholder')}
          onChange={handleInputTitleChange}
          readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
          maxLength={32}
          required
        />
      )}
      {children}
      {!readOnly && (
        <ActionWrapper>
          {!isInitiativeLockedAndIsNotOwner && (
            <ButtonWrapper>
              <SmallActionButton
                text={t('editor.sidebar.initiative.save_button_title')}
                isLoading={isLoading}
                disabled={!inputTitle}
                onClick={() => handleSubmit(inputTitle, field.type as keyof typeof CustomFieldType)}
              />
            </ButtonWrapper>
          )}
          {!isInCreationMode && (
            <>
              <HideFieldIcon initiativeField={field} />
              {onDeleteField && !isInitiativeLockedAndIsNotOwner && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={e => onDeleteField(field.id!, e)}
                >
                  <Tooltip title={t('editor.sidebar.initiative.delete_field')} placement="top">
                    <ListStyledImage src={BinIconSrc} />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </ActionWrapper>
      )}
    </>
  );
};

export { InitiativeCustom };

const StyledInput = styled.input`
  outline: none;
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const ActionWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
`;

export const ListStyledImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${({ theme }) => theme.palette.brand.textSecondaryFilter};
  &:hover {
    filter: ${({ theme }) => theme.palette.brand.buttonPrimaryFilterHover};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-self: center;
`;
