import { ChromePicker } from 'react-color';
import Popover from '@mui/material/Popover';

const ColorPicker = ({
  anchorEl,
  handleEdit,
  pickerColor,
  handleClose,
}: {
  anchorEl: SVGElement | null;
  handleClose: () => void;
  handleEdit: (hex: string) => void;
  pickerColor: string;
}) => {
  const handleChangeComplete = (color: { hex: string }) => {
    handleEdit(color.hex);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <div>
        <ChromePicker color={pickerColor} onChangeComplete={handleChangeComplete} />
      </div>
    </Popover>
  );
};

export { ColorPicker };
