import { CSSProperties } from 'react';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

export const SmallActionButton = ({
  onClick,
  disabled,
  isLoading,
  text,
  overrides,
}: {
  onClick: () => void;
  text: string;
  disabled?: boolean;
  isLoading?: boolean;
  overrides?: CSSProperties;
}) => {
  return (
    <CButton
      styleOverrides={overrides}
      disabled={disabled || isLoading}
      type="button"
      onClick={onClick}
    >
      {isLoading && <StyledLoader color="inherit" size={14} />}
      {text}
    </CButton>
  );
};

const CButton = styled.button<{ styleOverrides?: CSSProperties }>`
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${({ styleOverrides }) => ({ ...styleOverrides })}
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;
