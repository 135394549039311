import { ReactNode } from 'react';
import styled from 'styled-components';

import { Logo } from '@/features/ui/components/Logo';

import { UserManagementPanel } from '../UserManagementPanel';

const UserManagementContainer: React.FC<{
  children: ReactNode;
  title: string;
  description?: string;
}> = ({ children, title, description }) => {
  return (
    <MainPanel>
      <PanelContentContainer>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <UserManagementPanel title={title} description={description}>
          {children}
        </UserManagementPanel>
      </PanelContentContainer>
    </MainPanel>
  );
};

const MainPanel = styled.main`
  font-size: 1.6rem;
  background-color: ${({ theme }) => theme.palette.brand.backgroundMain};
  height: 100%;
  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const PanelContentContainer = styled.div`
  width: 60%;
  height: 100%;
  display: grid;
  max-width: 920px;
  grid-template-rows: 1fr 2fr 1fr;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { UserManagementContainer };
