import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { createRoadmapNode, getAllRoadmapNodes } from '@/features/canvas/api';
import { FilteredNodes, Node, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';

interface UseInitiativeDropProps {
  roadmapId: number;
}

export const useInitiativeDrop = ({ roadmapId }: UseInitiativeDropProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();
  const {
    setFilteredNodes,
    filteredNodes,
    generateUniqueIntTemporaryId,
    updateLastSavedTime,
    setSelectedNodeId,
    setIsCreatingNewInitiative,
    temporaryCreatedIds,
  } = useEditorContext();

  const { mutateAsync: createNewRoadmapNode } = useMutation(
    ({ newNode }: { newNode: Node; newNodeTempId: number; newNodesInitiativeTempId: number }) =>
      createRoadmapNode({
        roadmapId,
        newNode,
      }),
    {
      onSuccess: ({ id, initiative }, { newNodeTempId, newNodesInitiativeTempId }) => {
        queryClient.setQueryData([getAllRoadmapNodes.name, roadmapId], (oldNodes?: Node[]) => {
          if (oldNodes) {
            return oldNodes.map(n => {
              if (n.id === newNodeTempId) {
                temporaryCreatedIds.delete(newNodeTempId);
                temporaryCreatedIds.delete(newNodesInitiativeTempId);
                return { ...n, id, initiatives: { ...initiative! } };
              }
              return n;
            });
          }
          return [];
        });
        updateLastSavedTime();
      },
    }
  );

  const onNewInitiativeDrop = useCallback(
    async ({
      title,
      newNodesCanvasXPercentage,
      newNodesCanvasYPercentage,
      timePeriodId,
      sectionId,
    }: {
      newNodesCanvasXPercentage: number;
      newNodesCanvasYPercentage: number;
      sectionId: number;
      timePeriodId: number;
      title: string;
    }) => {
      const newNodeTempId = generateUniqueIntTemporaryId();
      const newInitiativeTempId = generateUniqueIntTemporaryId();

      const newRoadmapNode = {
        id: newNodeTempId,
        title,
        isVisible: true,
        x: newNodesCanvasXPercentage,
        y: newNodesCanvasYPercentage,
        timePeriodId,
        sectionId,
        dependencies: [],
        initiatives: { title },
      };

      queryClient.setQueryData(
        [getAllRoadmapNodes.name, roadmapId],
        (oldNodes?: FilteredNodes[]) => {
          const newRoadmapNodeWithInitiative = {
            ...{ ...newRoadmapNode, shape: 'circle' as const, color: '' },
            initiatives: {
              id: newInitiativeTempId,
              title,
            },
          };
          if (oldNodes) {
            const newNodes: FilteredNodes[] = [...oldNodes, newRoadmapNodeWithInitiative];
            setFilteredNodes(newNodes);
            return newNodes;
          }
          setFilteredNodes([newRoadmapNodeWithInitiative] as unknown as typeof filteredNodes);
          return [newRoadmapNodeWithInitiative];
        }
      );

      try {
        const newlyCreatedNode = await createNewRoadmapNode({
          newNode: newRoadmapNode,
          newNodeTempId,
          newNodesInitiativeTempId: newInitiativeTempId,
        });
        const newInitiative = newlyCreatedNode.initiative!;

        setSelectedNodeId(newlyCreatedNode.id!);
        setIsCreatingNewInitiative(false);
        return {
          initiative: {
            ...newInitiative,
          },
          nodeId: newlyCreatedNode.id!,
        };
      } catch (error) {
        showToast('error', t('editor.canvas.add_initiative_error'));
        await queryClient.invalidateQueries([getAllRoadmapNodes.name, roadmapId]);
      }
    },
    [
      generateUniqueIntTemporaryId,
      queryClient,
      roadmapId,
      setFilteredNodes,
      createNewRoadmapNode,
      setSelectedNodeId,
      setIsCreatingNewInitiative,
      showToast,
      t,
    ]
  );
  return {
    onNewInitiativeDrop,
  };
};
