import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  isOwner?: boolean;
};

const PermissionTag = ({ isOwner }: Props) => {
  const { t } = useTranslation();
  if (isOwner) {
    return <OwnerTag>{t('shared.owner')}</OwnerTag>;
  }

  return null;
};

const OwnerTag = styled.div`
  color: ${({ theme }) => theme.palette.brand.editorTabSecondary};
  font-size: 1.25rem;
  font-style: italic;
  font-family: 'Arial';
`;

export { PermissionTag };
