import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { CircularProgress, DialogContent } from '@mui/material';
import dayjs from 'dayjs';
import styled from 'styled-components';

import {
  CURRENCY_CODE_TO_SYMBOL_MAP,
  DATE_FORMAT,
  SUPPORTED_CURRENCIES,
  SUPPORTED_DATE_FORMATS,
} from '@/constants/common';
import { getInitiativeById } from '@/features/canvas/api';
import { InitiativeDetailField } from '@/features/canvas/components/InitiativeDetailField';
import { InitiativeDetailFilterTag } from '@/features/canvas/components/InitiativeDetailFilterTag';
import {
  INITIATIVE_DETAIL_MAX_SLOTS,
  INITIATIVE_DETAIL_SMALL_FIELDS_EQUAL_TO_ONE_BIG_FIELD,
} from '@/features/canvas/constants';
import {
  InitiativeFieldTitles,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { extractCurrencyAndValue } from '@/features/canvas/utils/extract-currency-and-value';
import { ProgressBar } from '@/features/ui/components/ProgressBar';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

const InitiativeDetailsDialogContent = () => {
  const { filteredNodes, selectedNodeId, filters, isPublicView, popupVisible, isPreview } =
    useEditorContext();
  const { t } = useTranslation();
  const { showToast } = useShowToast();

  const { data: initiative, isLoading: isInitiativeLoading } = useQuery(
    [getInitiativeById.name, selectedNodeId],
    () => {
      const node = filteredNodes.find(n => Number(n.id) === Number(selectedNodeId))!;
      const initiativeId = node.initiatives?.id;
      return getInitiativeById({ id: Number(initiativeId) });
    },
    {
      enabled: Boolean(selectedNodeId && !isPublicView),
    }
  );

  const fields = useMemo(() => {
    if (!initiative?.fields) return [];

    const defaultStart = {
      bigFieldsCounter: 0,
      smallFieldsCounter: 0,
      isStartDatePresent: false,
      isEndDatePresent: false,
      orderMapToId: new Map<number, number>(),
      fields: [] as components['schemas']['InitiativeField'][],
    };

    const dialogLayoutCreator = initiative.fields.reduce((acc, currentField) => {
      const accClone = { ...acc };

      const isVisible = popupVisible.find(
        f => f.title === currentField.title && f.type === currentField.type
      );
      if (!isVisible) {
        return accClone;
      }

      switch (currentField.type) {
        case InitiativeFieldTypes.description:
          accClone.bigFieldsCounter += 1;
          accClone.orderMapToId.set(Number(currentField.id), 1);
          accClone.fields.push(currentField);
          break;

        case InitiativeFieldTypes.date:
          if (currentField.title === InitiativeFieldTitles.startDate) {
            accClone.smallFieldsCounter += 1;
            accClone.isStartDatePresent = true;
            accClone.orderMapToId.set(Number(currentField.id), 2);
          }
          if (currentField.title === InitiativeFieldTitles.endDate) {
            accClone.smallFieldsCounter += 1;
            accClone.isEndDatePresent = true;
            accClone.orderMapToId.set(Number(currentField.id), 3);
          }
          accClone.fields.push(currentField);
          break;
        case InitiativeFieldTypes.budget:
          accClone.smallFieldsCounter += 1;
          accClone.orderMapToId.set(Number(currentField.id), 5);
          accClone.fields.push(currentField);
          break;
        case InitiativeFieldTypes.small:
          accClone.smallFieldsCounter += 1;
          accClone.orderMapToId.set(Number(currentField.id), 5);
          accClone.fields.push(currentField);
          break;
        case InitiativeFieldTypes.big:
          accClone.bigFieldsCounter += 1;
          accClone.orderMapToId.set(Number(currentField.id), 6);
          accClone.fields.push(currentField);
          break;
        case InitiativeFieldTypes.progressBar:
          accClone.orderMapToId.set(Number(currentField.id), 7);
          accClone.fields.push(currentField);
          break;
        case InitiativeFieldTypes.title:
          break;
        default: {
          accClone.smallFieldsCounter += 1;
          accClone.fields.push(currentField);
          break;
        }
      }
      return accClone;
    }, defaultStart);

    if (dialogLayoutCreator.isStartDatePresent && dialogLayoutCreator.isEndDatePresent) {
      let maxSmallFieldsThatFitsSpace = 2;
      dialogLayoutCreator.orderMapToId.forEach((value, key) => {
        if (value === 6 && maxSmallFieldsThatFitsSpace !== 0) {
          dialogLayoutCreator.orderMapToId.set(key, 4);
          maxSmallFieldsThatFitsSpace -= 1;
        }
      });
    }

    if (
      (dialogLayoutCreator.isStartDatePresent && !dialogLayoutCreator.isEndDatePresent) ||
      (!dialogLayoutCreator.isStartDatePresent && dialogLayoutCreator.isEndDatePresent)
    ) {
      let maxSmallFieldsThatFitsSpace = 3;
      dialogLayoutCreator.orderMapToId.forEach((value, key) => {
        if (value === 6 && maxSmallFieldsThatFitsSpace !== 0) {
          dialogLayoutCreator.orderMapToId.set(key, 4);
          maxSmallFieldsThatFitsSpace -= 1;
        }
      });
    }

    if (!dialogLayoutCreator.isStartDatePresent && !dialogLayoutCreator.isEndDatePresent) {
      let maxBigFieldsInLayout = 4;
      dialogLayoutCreator.orderMapToId.forEach((value, key) => {
        if (value === 5 && maxBigFieldsInLayout !== 0) {
          dialogLayoutCreator.orderMapToId.set(key, 4);
          maxBigFieldsInLayout -= 1;
        }
      });
    }

    dialogLayoutCreator.fields.sort(({ id: idA }, { id: idB }) => {
      const orderA = dialogLayoutCreator.orderMapToId.get(Number(idA));
      const orderB = dialogLayoutCreator.orderMapToId.get(Number(idB));

      if (orderA && orderB) {
        return orderA - orderB;
      }
      return 0;
    });

    const isLayoutOverflown =
      dialogLayoutCreator.bigFieldsCounter * INITIATIVE_DETAIL_SMALL_FIELDS_EQUAL_TO_ONE_BIG_FIELD +
        dialogLayoutCreator.smallFieldsCounter >
      INITIATIVE_DETAIL_MAX_SLOTS;

    if (isLayoutOverflown) {
      if (!isPreview) {
        showToast('error', t('editor.initiative_detail.too_many_fields'), {
          preventDuplicate: true,
        });
      }
      let slotCount = 0;
      const newFields: components['schemas']['InitiativeField'][] = [];
      dialogLayoutCreator.fields.forEach(field => {
        if (slotCount < INITIATIVE_DETAIL_MAX_SLOTS) {
          if (
            field.type === InitiativeFieldTypes.description ||
            field.type === InitiativeFieldTypes.big
          ) {
            slotCount += INITIATIVE_DETAIL_SMALL_FIELDS_EQUAL_TO_ONE_BIG_FIELD;
          } else if (field.type !== InitiativeFieldTypes.progressBar) {
            slotCount += 1;
          }
          if (slotCount <= INITIATIVE_DETAIL_MAX_SLOTS) {
            newFields.push(field);
          }
        }
      });

      if (!newFields.find(f => f.type === InitiativeFieldTypes.progressBar)) {
        const progressField = dialogLayoutCreator.fields.find(
          f => f.type === InitiativeFieldTypes.progressBar
        );
        if (progressField) {
          newFields.push(progressField);
        }
      }
      dialogLayoutCreator.fields = newFields;
      return dialogLayoutCreator.fields;
    }

    return dialogLayoutCreator.fields;
  }, [initiative?.fields, isPreview, popupVisible, showToast, t]);

  const filtersToDisplay = useMemo(() => {
    return filters.filter(f => {
      if (!f.isVisible) {
        return false;
      }
      const selectedNodeFilterOptions = f?.nodes?.filter(
        node => Number(node.nodeId) === Number(selectedNodeId)
      );

      if (selectedNodeFilterOptions?.length) {
        const filteredOptions = f.options.filter(o =>
          selectedNodeFilterOptions?.find(
            nodeFilterOption => Number(nodeFilterOption.optionId) === Number(o.id)
          )
        );
        return filteredOptions.length;
      }
      return false;
    });
  }, [filters, selectedNodeId]);

  const isDateIncluded = fields.find(f => f.type === InitiativeFieldTypes.date);

  const getInitiativeFieldComponent = (field: components['schemas']['InitiativeField']) => {
    switch (field.type) {
      case InitiativeFieldTypes.description:
        return (
          <InitiativeDetailField
            key={field.id}
            colSize={2}
            rowSize={2}
            title={field.title}
            value={field.value}
          />
        );
      case InitiativeFieldTypes.date:
        return (
          <InitiativeDetailField
            key={field.id}
            title={field.title}
            value={
              field.value
                ? dayjs(field.value, SUPPORTED_DATE_FORMATS).format(DATE_FORMAT)
                : t('not_specified')
            }
          />
        );
      case InitiativeFieldTypes.progressBar:
        return (
          <InitiativeDetailField
            colSize={4}
            key={field.id}
            title={field.title}
            content={
              <ProgressBarWrapper>
                <ProgressBar value={field.value || '0'} />
              </ProgressBarWrapper>
            }
          />
        );
      case InitiativeFieldTypes.big:
        return (
          <InitiativeDetailField
            colSize={2}
            rowSize={2}
            {...(isDateIncluded && {
              rowEnd: 5,
              colEnd:
                fields.find(f => f.type === InitiativeFieldTypes.big)?.id === field.id ? 3 : 5,
            })}
            key={field.id}
            title={field.title}
            value={field.value}
          />
        );
      case InitiativeFieldTypes.budget: {
        const { currencyId, value } = extractCurrencyAndValue({ budgetWithCurrency: field.value });
        const currencyCode = SUPPORTED_CURRENCIES.find(c => c.id === currencyId)!.currency;
        const currencySymbol =
          CURRENCY_CODE_TO_SYMBOL_MAP[currencyCode as keyof typeof CURRENCY_CODE_TO_SYMBOL_MAP];
        const formattedValue = value ? `${currencySymbol} ${value}` : '';

        return <InitiativeDetailField key={field.id} title={field.title} value={formattedValue} />;
      }
      case InitiativeFieldTypes.placeholder:
        return null;

      default:
        return <InitiativeDetailField key={field.id} title={field.title} value={field.value} />;
    }
  };

  return (
    <StyledDialogContent>
      {isInitiativeLoading && (
        <LoaderWrapper>
          <StyledLoader />
        </LoaderWrapper>
      )}
      <InitiativeDetailsGrid>
        {fields.map(field => getInitiativeFieldComponent(field))}
      </InitiativeDetailsGrid>
      {Boolean(filtersToDisplay.length) && initiative && (
        <InitiativeDetailField
          colSize={4}
          title={t('editor.initiative_detail.filters')}
          key="filter-field"
          content={
            <FiltersWrapper>
              {filtersToDisplay.map(filter => {
                const selectedNodeFilterOptions = filter?.nodes?.filter(
                  node => Number(node.nodeId) === Number(selectedNodeId)
                );
                const filteredOptions = filter.options.filter(o =>
                  selectedNodeFilterOptions?.find(
                    nodeFilterOption => nodeFilterOption.optionId === o.id
                  )
                );

                return (
                  <InitiativeDetailFilterTag
                    key={filter.id}
                    title={`${filter.title}:`}
                    options={filteredOptions}
                    filterShape={filter.shape}
                    filterSelection={filter.selection}
                  />
                );
              })}
            </FiltersWrapper>
          }
        />
      )}
    </StyledDialogContent>
  );
};

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

const InitiativeDetailsGrid = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: min(50px 80px);
  margin-bottom: 2rem;
`;

const ProgressBarWrapper = styled.div`
  width: 100%;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;

const StyledDialogContent = styled(DialogContent)`
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  padding: '0 2rem';
  overflow-y: 'hidden';
`;

export { InitiativeDetailsDialogContent };
