import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import styled from 'styled-components';

import { useAccount } from '@/contexts/account-context';
import { updateAccount } from '@/features/accountSettings/api/account';
import { AccountSettingsFormContent } from '@/features/accountSettings/components';
import { type AccountSettingsFormState } from '@/features/accountSettings/types/accountSettings';
import { authCheck } from '@/features/authentication/api/auth';
import { Form } from '@/features/ui/components/Form';
import { useShowToast } from '@/hooks/useShowToast';
import { PageWithSidebar } from '@/layouts/PageWithSidebar';

const AccountSettings = () => {
  const { t } = useTranslation();
  const account = useAccount();
  const queryClient = useQueryClient();
  const { showToast } = useShowToast();

  const [shouldChangePassword, setShouldChangePassword] = useState(false);
  const { mutateAsync, isLoading } = useMutation(updateAccount, {
    onSuccess: async () => {
      showToast('success', t('account_settings.save_success'));
      await queryClient.invalidateQueries(authCheck.name);
      setShouldChangePassword(false);
    },
    onError: () => {
      showToast('error', t('account_settings.save_error'));
    },
  });

  const onSubmit = useCallback(
    async (formData: Record<string, string>) => {
      const { newPasswordAgain, ...data } = formData;
      await mutateAsync({ formData: data as unknown as AccountSettingsFormState });
    },
    [mutateAsync]
  );

  return (
    <PageWithSidebar>
      <Title>{t('account_settings.title')}</Title>

      <Form<AccountSettingsFormState>
        onSubmit={onSubmit}
        defaultValues={{
          email: account!.email,
          firstName: account!.firstName,
          lastName: account!.lastName,
        }}
      >
        <AccountSettingsFormContent
          isLoading={isLoading}
          shouldChangePassword={shouldChangePassword}
          setShouldChangePassword={setShouldChangePassword}
        />
      </Form>
    </PageWithSidebar>
  );
};

const Title = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.brand.primary};
`;

export { AccountSettings };
