import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getRoadmapsDropdownVisible({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get<components['schemas']['DropdownVisible'][]>(
    `/dropdown-visible/roadmap/${roadmapId}`
  );
  return data;
}

export async function createRoadmapsDropdownVisible({
  roadmapId,
  newDropdownVisible,
}: {
  roadmapId: number;
  newDropdownVisible: components['schemas']['DropdownVisible'];
}) {
  const dropdownVisibleInArray = [newDropdownVisible];
  const { data } = await defaultApi.post<components['schemas']['DropdownVisible']>(
    `/dropdown-visible/${roadmapId}`,
    dropdownVisibleInArray
  );
  return data;
}

export async function deleteRoadmapsDropdownVisible({ roadmapId }: { roadmapId: number }) {
  return await defaultApi.delete(`/dropdown-visible/${roadmapId}`);
}
