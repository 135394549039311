import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import FilterIcon from '@/assets/icons/filters.svg';
import { CustomSwitch } from '@/features/canvas/components/CustomSwitch';
import { FilterSidebarItem } from '@/features/canvas/components/FilterSidebarItem';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

const EditorFilterSidebar = () => {
  const { t } = useTranslation();
  const {
    filters,
    setOptionCheckedId,
    showDependencies,
    setShowDependencies,
    setIsSelectingDependencies,
    optionCheckedId,
    setActiveFilterId,
    isSwimlaneView,
  } = useEditorContext();

  return (
    <Sidebar>
      <SidebarHeader>{t('editor.filter_sidebar.title')}</SidebarHeader>
      <SidebarBody>
        {!!filters?.length &&
          filters.map(filter => {
            return (
              !!filter.options.length &&
              filter.isVisible && <FilterSidebarItem filter={filter} key={filter.id} />
            );
          })}
        {!filters?.length && (
          <EmptyFiltersWrapper>
            <FilterImageWrapper>
              <StyledImage src={FilterIcon} />
            </FilterImageWrapper>
            <EmptyFiltersText>{t('editor.filter_sidebar.add_filters')}</EmptyFiltersText>
          </EmptyFiltersWrapper>
        )}
        {!isSwimlaneView && (
          <DependencyToggle>
            <DependencyTitle>{t('editor.filter_sidebar.dependencies')}</DependencyTitle>
            <CustomSwitch
              isChecked={showDependencies}
              onChange={() => {
                setOptionCheckedId(null);
                setIsSelectingDependencies(false);
                setShowDependencies(!showDependencies);
                if (optionCheckedId) {
                  setActiveFilterId(null);
                }
              }}
            />
          </DependencyToggle>
        )}
      </SidebarBody>
    </Sidebar>
  );
};

const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.palette.brand.white};
  width: 250px;
  flex: 1 0 250px;
  min-height: 0;
  padding: 2rem 1rem;
  border-radius: 1rem;
  overflow-y: auto;
  overflow-x: auto;
`;
const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2.5rem;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  font-weight: 600;
`;
const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const FilterImageWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
`;

const EmptyFiltersText = styled.div`
  font-size: 1.5rem;
  text-align: center;
  max-width: 125px;
`;

const EmptyFiltersWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
`;

const DependencyToggle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem 0.5rem 1.5rem;
`;

const DependencyTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

export { EditorFilterSidebar };
