import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';

const StyledSelect = ({
  selectedId,
  onChange,
  options,
  placeholder,
  children,
}: {
  onChange: (value: null | number) => void;
  options: { id: number; title: string }[];
  placeholder: string;
  selectedId: number | null;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <SSelect
      value={selectedId === null ? '' : selectedId}
      onChange={e => onChange(e.target.value as number)}
      SelectDisplayProps={{
        style: {
          backgroundColor: 'none',
          padding: '0.5rem 1rem',
        },
      }}
      MenuProps={{
        style: {
          left: -6,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        MenuListProps: {},
      }}
      variant="standard"
      disableUnderline
      displayEmpty
      labelId="new-initiative-label"
      IconComponent={() => <ArrowDownIcon />}
      renderValue={selected => {
        if (!selected) {
          return <div>{placeholder}</div>;
        }
        return options.find(o => o.id === (selected as number))?.title;
      }}
    >
      {Boolean(children) && children}
      {!children &&
        options.map(o => (
          <StyledMenuItem key={o.id} value={o.id}>
            {o.title}
          </StyledMenuItem>
        ))}
      {!options.length && (
        <StyledMenuItem disabled>{t('editor.sidebar.no_options')}</StyledMenuItem>
      )}
    </SSelect>
  );
};

export { StyledSelect };

const SSelect = styled(Select)`
  width: 100%;
  border-radius: 1rem;
  font-size: 1.25rem;
  border: 0;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  svg {
    width: 1rem;
    height: 1rem;
    position: relative;
    right: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  & .MuiInput-input:focus {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 1.25rem;
  padding: 0.25rem 1rem;
`;
