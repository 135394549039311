import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialog } from '@/features/ui/components/ConfirmationDialog';

type TimelineEditorInitiativeDeleteDialogsProps = {
  handleThisRoadmapInitiativeDelete: () => Promise<void>;
  isBasicDeleteInitiativeDialogOpen: boolean;
  isLoading: boolean;
  setIsBasicDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
};

const TimelineEditorBasicInitiativeDeleteDialog = ({
  isBasicDeleteInitiativeDialogOpen,
  isLoading,
  setIsBasicDeleteInitiativeDialogOpen,
  handleThisRoadmapInitiativeDelete,
}: TimelineEditorInitiativeDeleteDialogsProps) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      open={isBasicDeleteInitiativeDialogOpen}
      keepMounted={false}
      fullScreen={false}
      disablePortal
      isLoading={isLoading}
      okButtonText={t('yes_sure')}
      description={t('editor.canvas.delete_initiative_dialog_description')}
      handleOk={handleThisRoadmapInitiativeDelete}
      id="delete-initiative-confirm-dialog"
      onClose={() => {
        setIsBasicDeleteInitiativeDialogOpen(false);
      }}
      sx={{
        '&.MuiDialog-root': {
          position: 'absolute',
        },
        '& .MuiBackdrop-root': {
          position: 'absolute',
        },
        '& .MuiDialog-paper': { padding: '3rem', borderRadius: '2rem', maxWidth: '450px' },
      }}
    />
  );
};

export { TimelineEditorBasicInitiativeDeleteDialog };
