import { LinkedColumnType } from '@/features/canvas/types/linkage';
import { transformPercentageToInteger } from '@/features/canvas/utils/transform-percentage-to-integer';

import { parseBudget } from './format-budget';

export const getTransformedLinkedValue = (value: string, type: keyof typeof LinkedColumnType) => {
  let linkedValue = value;
  if (type === LinkedColumnType.progressBar) {
    linkedValue = transformPercentageToInteger(value).toString();
  }
  if (type === LinkedColumnType.budget) {
    if (!value.trim()) return '';

    const budget = parseBudget(value.trim());
    const formattedValue = budget.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    linkedValue = `${budget.currency}${formattedValue}`;
  }
  return linkedValue;
};
