import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as DataIcon } from '@/assets/icons/data.svg';
import { ReactComponent as FiltersIcon } from '@/assets/icons/filters.svg';
import HomeIcon from '@/assets/icons/home.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.svg';
import { ReactComponent as KonexisLogo } from '@/assets/images/KonexisLogo.svg';
import routes from '@/constants/routes';
import { FiltersAccordionContent } from '@/features/canvas/components/FiltersAccordionContent';
import { ImportAccordionContent } from '@/features/canvas/components/ImportAccordionContent';
import { InitiativesAccordionContent } from '@/features/canvas/components/InitiativesAccordionContent';
import { SidebarListItem } from '@/features/canvas/components/SidebarListItem';
import { SIDEBAR_ACCORDION_KEY_MAP } from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

const EditorSidebar = ({
  setIsBasicDeleteInitiativeDialogOpen,
  setIsDualChoiceDeleteInitiativeDialogOpen,
}: {
  setIsBasicDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDualChoiceDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  const { expandedSidebarAccordionKey } = useEditorContext();

  const sidebarItems = [
    {
      id: SIDEBAR_ACCORDION_KEY_MAP.initiatives,
      iconSrc: PlusIcon,
      title: t('editor.sidebar.initiatives'),
      ariaId: 'initiatives',
      children: (
        <InitiativesAccordionContent
          isExpanded={SIDEBAR_ACCORDION_KEY_MAP.initiatives === expandedSidebarAccordionKey}
          setIsBasicDeleteInitiativeDialogOpen={setIsBasicDeleteInitiativeDialogOpen}
          setIsDualChoiceDeleteInitiativeDialogOpen={setIsDualChoiceDeleteInitiativeDialogOpen}
        />
      ),
    },
    {
      id: SIDEBAR_ACCORDION_KEY_MAP.filters,
      iconSrc: FiltersIcon,
      title: t('editor.sidebar.filters'),
      ariaId: 'filters',
      children: (
        <FiltersAccordionContent
          isExpanded={SIDEBAR_ACCORDION_KEY_MAP.filters === expandedSidebarAccordionKey}
        />
      ),
    },
    {
      id: SIDEBAR_ACCORDION_KEY_MAP.import,
      iconSrc: DataIcon,
      title: t('editor.sidebar.import'),
      ariaId: 'import',
      children: (
        <ImportAccordionContent
          isExpanded={SIDEBAR_ACCORDION_KEY_MAP.import === expandedSidebarAccordionKey}
        />
      ),
    },
  ];

  return (
    <Sidebar>
      <SidebarHeader to={routes.roadmaps}>
        <HomeIconWrapper>
          <Home src={HomeIcon} />
        </HomeIconWrapper>
        <LogoWrapper>
          <KonexisLogo />
        </LogoWrapper>
      </SidebarHeader>
      <SidebarBody>
        <SidebarList>
          {sidebarItems.map(item => (
            <SidebarListItem
              key={item.id}
              id={item.id}
              ariaId={item.ariaId}
              title={item.title}
              isExpanded={item.id === expandedSidebarAccordionKey}
              Icon={item.iconSrc}
            >
              {item.children}
            </SidebarListItem>
          ))}
        </SidebarList>
      </SidebarBody>
    </Sidebar>
  );
};

const Sidebar = styled.div`
  background-color: ${({ theme }) => theme.palette.brand.white};
  width: 280px;
  flex: 0 0 280px;
  min-height: 0;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  clip-path: inset(0 -1rem 0 0);

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    width: 200px;
    flex: 0 0 200px;
  }
`;
const SidebarHeader = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 2rem 5rem;
  flex: 0 0 60px;
`;
const SidebarBody = styled.div`
  display: flex;
  height: calc(100vh - 60px - 7rem);
  height: calc(100dvh - 60px - 7rem);
  flex: 1 0 auto;
  flex-basis: 0;
  min-height: 0;
`;

const SidebarList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 100%;
  min-height: 0;
  width: 100%;
`;

const HomeIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0.5rem;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
`;

const Home = styled.img`
  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 5;
  padding-top: 0.5rem;
  svg {
    max-width: 100%;
    height: 55px;
  }
`;
export { EditorSidebar };
