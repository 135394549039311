import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useMutation, useQueryClient } from 'react-query';
import CircleIconSrc from 'src/assets/icons/circle.svg';
import TriangleIconSrc from 'src/assets/icons/triangle.svg';
import styled from 'styled-components';

import RectangleIconSrc from '@/assets/icons/square.svg';
import { editOption, getFiltersByRoadmapId, getOptionsByFilterId } from '@/features/canvas/api';
import { ColorPicker } from '@/features/canvas/components/ColorPicker';
import { FilterOption } from '@/features/canvas/components/FilterOption';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

const FilterSingleOption = ({
  option,
  filterShape,
  filterId,
  roadmapId,
}: {
  filterId: number;
  filterShape: 'circle' | 'square' | 'triangle';
  option: components['schemas']['FilterOption'];
  roadmapId: number;
}) => {
  const [pickerColor, setPickerColor] = useState<string>(option.color);
  const [pickerAnchorEl, setPickerAnchorEl] = useState<SVGElement | null>(null);
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const queryClient = useQueryClient();
  const { setIsSidebarContentLoading, updateLastSavedTime } = useEditorContext();

  const { mutateAsync: updateOption } = useMutation(
    async (optionData: components['schemas']['FilterOption']) => {
      try {
        await editOption(optionData.id!, optionData);
        updateLastSavedTime();
        showToast('success', t('editor.sidebar.filter.edit_option_success'));
      } catch (e) {
        showToast('success', t('editor.sidebar.filter.edit_option_error'));
      }
    }
  );

  const onClickColor = (event: React.MouseEvent<SVGElement>) => {
    setPickerAnchorEl(event.currentTarget);
  };

  const handleCloseColorPicker = async () => {
    setIsSidebarContentLoading(true);
    const updatedOption = { ...option, color: pickerColor };

    setPickerAnchorEl(null);

    await updateOption(updatedOption);
    await queryClient.invalidateQueries([getOptionsByFilterId.name, filterId]);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmapId]);

    setIsSidebarContentLoading(false);
  };

  const handleEditColorPicker = (hex: string) => {
    setPickerColor(hex);
  };

  const getShapeIcon = () => {
    switch (filterShape) {
      case 'triangle':
        return <StyledSVG src={TriangleIconSrc} color={option.color} onClick={onClickColor} />;
      case 'square':
        return <StyledSVG src={RectangleIconSrc} color={option.color} onClick={onClickColor} />;
      default:
        return <StyledSVG src={CircleIconSrc} color={option.color} onClick={onClickColor} />;
    }
  };

  const Icon = getShapeIcon();

  return (
    <>
      <FilterOption
        key={option.id}
        option={option}
        filterId={filterId}
        roadmapId={roadmapId}
        Icon={Icon}
        iconCursor
      />
      <ColorPicker
        anchorEl={pickerAnchorEl}
        handleClose={handleCloseColorPicker}
        handleEdit={handleEditColorPicker}
        pickerColor={pickerColor}
      />
    </>
  );
};

const StyledSVG = styled(SVG)`
  width: 1.4rem;
  height: 1.4rem;
  overflow: visible;
  & path {
    fill: ${({ color }) => color};
    stroke: ${({ color }) => color};
  }
`;

export { FilterSingleOption };
