import styled from 'styled-components';

const StyledInputLabel = styled.label`
  display: inline-block;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
  font-weight: 500;
  font-size: 2rem;
  padding: 0.9rem 0;
`;

export default StyledInputLabel;
