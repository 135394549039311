import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';

import routes from '@/constants/routes';
import { GlobalStyle, theme } from '@/features/ui/theme';
import { AccountSettings } from '@/pages/AccountSettings';
import CreatePassword from '@/pages/CreatePassword/CreatePassword';
import DashboardPage from '@/pages/DashboardPage/DashboardPage';
import ForgotPassword from '@/pages/ForgotPassword/ForgotPassword';
import LoginPage from '@/pages/LoginPage/LoginPage';
import PublicLinks from '@/pages/PublicLinks/PublicLinks';
import { PublicView } from '@/pages/PublicVIew/PublicView';
import RoadmapsHome from '@/pages/RoadmapsHome/RoadmapsHome';
import Support from '@/pages/Support/Support';
import { TimelineEditor } from '@/pages/TimelineEditor/TimelineEditor';
import UiPage from '@/pages/UiPage/UiPage';

const App = () => {
  const customTheme = theme();

  return (
    <ThemeProvider theme={customTheme}>
      <MuiThemeProvider theme={customTheme}>
        <GlobalStyle />
        <Routes>
          <Route index path={routes.home} element={<Navigate to={routes.roadmaps} replace />} />
          <Route path={routes.login} element={<LoginPage />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
          <Route path={routes.createPassword} element={<CreatePassword />} />

          <Route path={routes.dashboardTest} element={<DashboardPage />} />
          <Route path={routes.ui} element={<UiPage />} />

          <Route path={routes.publicLinks} element={<PublicLinks />} />
          <Route path={routes.account} element={<AccountSettings />} />
          <Route path={routes.roadmaps} element={<RoadmapsHome />} />
          <Route path={routes.support} element={<Support />} />
          <Route path={`${routes.roadmap}/:id`} element={<TimelineEditor />} />
          <Route path={`${routes.view}/:roadmapPublicLinkUrl`} element={<PublicView />} />
          <Route path={routes.timelineEditor} element={<TimelineEditor />} />
          <Route path="*" element={<Navigate to={routes.roadmaps} replace />} />
        </Routes>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default App;
