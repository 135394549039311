import axios from 'axios';

const createApi = () => {
  const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  const api = axios.create({
    baseURL:
      process.env.REACT_APP_ENVIRONMENT === 'dev'
        ? 'https://konvergent-api-dev.azurewebsites.net/api'
        : 'https://konvergent-api-prod.azurewebsites.net/api',
    withCredentials: true,
    ...(development
      ? {
          headers: {
            OriginOverride: 'https://ambitious-pebble-087fedc03.3.azurestaticapps.net',
          },
        }
      : {}),
  });

  api.interceptors.request.use(
    ({ ...config }) => {
      if (development) console.log(config.method, config.url);
      return {
        ...config,
      };
    },
    error => Promise.reject(error)
  );

  api.interceptors.response.use(response => {
    if (development && response?.request?.hasError && response.status === 200) {
      const errorString = `special error detected for ${response?.config?.url}, throwing error: ${response?.data}`;
      console.warn(errorString, '_hasError', response.request.hasError);
    }
    return response;
  });

  return api;
};

export const publicApi = createApi();
