import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { IconButton, MenuItem, Select } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import BinIconSrc from '@/assets/icons/bin.svg';
import { SUPPORTED_CURRENCIES } from '@/constants/common';
import { ListStyledImage } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeCustom/InitiativeCustom';
import { HideFieldIcon } from '@/features/canvas/components/InitiativesAccordionContent/HideFieldIcon';
import { extractCurrencyAndValue } from '@/features/canvas/utils/extract-currency-and-value';
import { components } from '@/types/api';

const InitiativeBudget = ({
  field,
  onDeleteField,
  onUpdateField,
  onClick,
  readOnly,
  isInitiativeLockedAndIsNotOwner,
}: {
  field: components['schemas']['InitiativeField'];
  isInitiativeLockedAndIsNotOwner: boolean;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  onDeleteField: (fieldId: number, e: React.MouseEvent<HTMLButtonElement>) => void;
  onUpdateField: (field: components['schemas']['InitiativeField']) => void;
  readOnly: boolean;
}) => {
  const { t } = useTranslation();
  const defaultCurrency = SUPPORTED_CURRENCIES[0].id;
  const [selectedCurrencyId, setSelectedCurrencyId] = useState<number>(defaultCurrency);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const getFullCurrency = (currency: string | undefined, symbol: string | undefined) => {
    return `${currency} (${symbol})`;
  };

  useEffect(() => {
    if (field.value) {
      const { currencyId, value } = extractCurrencyAndValue({ budgetWithCurrency: field.value });

      setSelectedCurrencyId(currencyId);
      setInputValue(value);
    }
  }, [field.value]);

  const handleInputChange = (event: { target: { value: SetStateAction<string> } }) => {
    const regexInputValidation = /^(?=[0-9,.]{0,16}$)(\d+)(((?:,)(?:\d{3})+)*)((?:.{1})(?:\d*))?$/g;
    const { value } = event.target;
    if (value === '') {
      setInputValue('');
      return;
    }
    if (!regexInputValidation.test(value as string)) {
      return;
    }
    setInputValue(value);
  };

  const handleCurrencyOptionChange = (value: number) => {
    setSelectedCurrencyId(value);
    handleBlur({ currencyId: value });
  };

  const handleBlur = ({ currencyId }: { currencyId: number }) => {
    if (readOnly || isInitiativeLockedAndIsNotOwner) return;
    const currencyCode = SUPPORTED_CURRENCIES.find(option => option.id === currencyId)?.currency;

    const updatedField = { ...field, value: `${currencyCode}${inputValue}` };
    onUpdateField(updatedField);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      inputRef.current?.blur();
    }
  };

  return (
    <>
      <FieldWrapper>
        <StyledSelect
          readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
          value={selectedCurrencyId === null ? '' : selectedCurrencyId}
          onChange={e => handleCurrencyOptionChange(e.target.value as number)}
          SelectDisplayProps={{
            style: {
              backgroundColor: 'none',
              padding: '0.5rem 1rem',
            },
          }}
          MenuProps={{
            style: {
              width: '100%',
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            MenuListProps: {},
          }}
          variant="standard"
          disableUnderline
          displayEmpty
          labelId="new-initiative-label"
          IconComponent={() => <ArrowDownIcon />}
          renderValue={selected => {
            const option = SUPPORTED_CURRENCIES.find(currency => currency.id === selected);
            return getFullCurrency(option?.currency, option?.symbol);
          }}
        >
          {SUPPORTED_CURRENCIES.map(option => (
            <StyledMenuItem key={option.id} value={option.id}>
              {getFullCurrency(option.currency, option.symbol)}
            </StyledMenuItem>
          ))}
        </StyledSelect>
        <TitleInput
          getInputRef={inputRef}
          readOnly={readOnly || isInitiativeLockedAndIsNotOwner}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={() => handleBlur({ currencyId: selectedCurrencyId })}
          onKeyDown={handleKeyDown}
          onClick={onClick}
          thousandSeparator
          valueIsNumericString
        />
      </FieldWrapper>
      {!readOnly && (
        <MainActions>
          <HideFieldIcon initiativeField={field} />
          {!isInitiativeLockedAndIsNotOwner && (
            <IconButton aria-label="delete" size="small" onClick={e => onDeleteField(field.id!, e)}>
              <Tooltip title={t('editor.sidebar.initiative.delete_field')} placement="top">
                <ListStyledImage src={BinIconSrc} />
              </Tooltip>
            </IconButton>
          )}
        </MainActions>
      )}
    </>
  );
};

export { InitiativeBudget };

const FieldWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 75%;
  gap: 0.5rem;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 0.4rem 0 0 0.4rem;
  font-size: 1.25rem;
  border: 0;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  svg {
    width: 1rem;
    height: 1rem;
    position: relative;
    right: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  & .MuiInput-input:focus {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 1.25rem;
`;

const TitleInput = styled(NumericFormat)`
  outline: none;
  width: 100%;
  border-radius: 0 0.4rem 0.4rem 0;
  border: 1px solid ${({ theme }) => theme.palette.brand.backgroundDialog};

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const MainActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
