import { Controller, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, InputProps } from '@mui/material';
import Input from '@mui/material/Input';
import styled from 'styled-components';

import { useFormContext } from '@/features/ui/components/Form';

type ErrorInputProps = {
  fontSize?: string;
};

type FormTextInputProps = InputProps & {
  name: string;
  $shouldHideBorder?: boolean;
  defaultValue?: string | number;
  errorInputProps?: ErrorInputProps;
  hide?: boolean;
  label?: string;
  maxWidth?: number;
  placeholder?: string;
  required?: boolean;
  rules?: RegisterOptions;
};

export const PublishDialogFormInput = ({
  label,
  name,
  placeholder,
  required,
  hide,
  defaultValue,
  errorInputProps,
  ...otherProps
}: FormTextInputProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      rules={{
        required: required ? t('form.field_required_validation') : false,
        ...(otherProps.rules ? otherProps.rules : {}),
      }}
      render={({ field, fieldState: { error }, formState: { isSubmitting } }) => {
        return (
          <FormControl sx={{ width: '100%' }}>
            {Boolean(label) && !hide && <FormInputLabel htmlFor={name}>{label}</FormInputLabel>}

            <StyledInput
              sx={{
                '&.MuiInput-root:before': {
                  content: 'unset',
                },
                '&.MuiInput-root:after': {
                  border: 'none',
                },
                '&.Mui-disabled': {
                  content: 'unset',
                },
              }}
              inputProps={{
                style: {
                  padding: '0.25rem 1rem',
                },
              }}
              placeholder={placeholder}
              {...otherProps}
              {...field}
              onChange={v => {
                otherProps.onChange?.(v);
                field.onChange(v);
              }}
              error={!!error}
              value={otherProps.value?.toString() || field.value}
              disabled={otherProps.disabled || isSubmitting}
              onBlur={e => {
                otherProps.onBlur?.(e);
                field.onBlur();
              }}
            />

            {!!error && !isSubmitting && (
              <FormInputError {...errorInputProps}>{error.message}</FormInputError>
            )}
          </FormControl>
        );
      }}
    />
  );
};

const FormInputLabel = styled.label`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

const StyledInput = styled(Input)`
  &.MuiInput-root {
    margin-top: 0.75rem;
  }
  font-size: 1.5rem;
  border-radius: 0.5rem;

  font-style: italic;
  color: ${({ theme }) => theme.palette.brand.textSecondary};
  border: 2px solid ${({ theme }) => theme.palette.brand.backgroundDialog};
`;

const FormInputError = styled.div`
  bottom: -1.5rem;
  position: absolute;
  color: ${({ theme }) => theme.palette.brand.negativeStatus};
`;
