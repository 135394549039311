/* eslint-disable react/no-this-in-sfc */
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import {
  deleteNode,
  getAllRoadmapNodes,
  getInitiativeById,
  getRoadmapNodeById,
} from '@/features/canvas/api';
import { useInitiative } from '@/features/canvas/api/hooks/useInitiative';
import { TimelineEditorBasicInitiativeDeleteDialog } from '@/features/canvas/components/TimelineEditorBasicInitiativeDeleteDialog';
import { TimelineEditorDualChoiceDeleteDialog } from '@/features/canvas/components/TimelineEditorDualChoiceDeleteDialog';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useInitiativeDrop } from '@/features/canvas/hooks/useInitiativeDrop';
import { useShowToast } from '@/hooks/useShowToast';

type TimelineEditorInitiativeDeleteDialogsProps = {
  isBasicDeleteInitiativeDialogOpen: boolean;
  isDualChoiceDeleteInitiativeDialogOpen: boolean;
  setIsBasicDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDualChoiceDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export const TimelineEditorDeleteInitiativeDialogs = ({
  isBasicDeleteInitiativeDialogOpen,
  isDualChoiceDeleteInitiativeDialogOpen,
  setIsBasicDeleteInitiativeDialogOpen,
  setIsDualChoiceDeleteInitiativeDialogOpen,
}: TimelineEditorInitiativeDeleteDialogsProps) => {
  const [isFirstButtonLoading, setIsFirstButtonLoading] = useState(false);
  const [isSecondButtonLoading, setIsSecondButtonLoading] = useState(false);

  const { t } = useTranslation();
  const { updateLastSavedTime, setSelectedNodeId, selectedNodeId, roadmap } = useEditorContext();
  const { deleteInitiativeMutation } = useInitiative({
    roadmapId: roadmap.id!,
    setIsBasicDeleteInitiativeDialogOpen,
    setSelectedNodeId,
    updateLastSavedTime,
  });

  const { showToast } = useShowToast();
  const { mutateAsync: getNodeById } = useMutation(getRoadmapNodeById);
  const { mutateAsync: deleteNodeAndHandleInitiativeDeletion } = useMutation(deleteNode);
  const { addHistoryItem, updateResourceId } = useUndoRedo();
  const { onNewInitiativeDrop } = useInitiativeDrop({ roadmapId: roadmap.id! });
  const queryClient = useQueryClient();
  const handleThisRoadmapInitiativeDelete = useCallback(async () => {
    try {
      setIsFirstButtonLoading(true);
      const node = await getNodeById({ nodeId: selectedNodeId! });
      await deleteNodeAndHandleInitiativeDeletion({ id: node.id! });

      setIsFirstButtonLoading(false);
      addHistoryItem({
        type: HistoryActionType.DeleteInitiative,
        async undo(resourceIds?: number[]) {
          const { initiative: newCreatedInitiative, nodeId } = (await onNewInitiativeDrop({
            newNodesCanvasXPercentage: node.x,
            newNodesCanvasYPercentage: node.y,
            sectionId: node.sectionId!,
            timePeriodId: node.timePeriodId!,
            title: node.initiatives!.title!,
          }))!;

          updateResourceId(
            this.type,
            resourceIds?.[0] || node.initiatives!.id!,
            newCreatedInitiative!.id!,
            {
              newResourceIds: [nodeId, newCreatedInitiative.id!],
              resourceIds: [node.id!, node.initiatives!.id!],
            }
          );

          this.redo = async (redoResourceIds?: number[]) => {
            await deleteNodeAndHandleInitiativeDeletion({
              id: redoResourceIds?.[0] || newCreatedInitiative!.id!,
            });
          };
          this.resourceIds = [newCreatedInitiative!.id!];
        },
        redo: async (resourceIds?: number[]) => {
          await deleteNodeAndHandleInitiativeDeletion({
            id: resourceIds?.[0] || node.initiatives!.id!,
          });
        },
        resourceIds: [node.initiatives!.id!],
      });
      await Promise.all([
        queryClient.invalidateQueries([getInitiativeById.name, node.initiatives.id]),
        queryClient.invalidateQueries([getAllRoadmapNodes.name, roadmap.id]),
      ]);
      setSelectedNodeId(null);
      showToast('success', t('editor.sidebar.initiative.delete_initiative_success'));
    } catch {
      showToast('error', t('editor.sidebar.initiative.delete_initiative_error'));
    } finally {
      setIsFirstButtonLoading(false);
    }
  }, [
    addHistoryItem,
    deleteNodeAndHandleInitiativeDeletion,
    getNodeById,
    onNewInitiativeDrop,
    queryClient,
    roadmap.id,
    selectedNodeId,
    setSelectedNodeId,
    showToast,
    t,
    updateResourceId,
  ]);

  const deleteInitiativeFromAllRoadmaps = useCallback(async () => {
    setIsSecondButtonLoading(true);
    const node = await getNodeById({ nodeId: selectedNodeId! });
    await deleteInitiativeMutation(node.initiatives!.id!);
    setIsDualChoiceDeleteInitiativeDialogOpen(false);
    setIsSecondButtonLoading(false);
  }, [
    deleteInitiativeMutation,
    getNodeById,
    selectedNodeId,
    setIsDualChoiceDeleteInitiativeDialogOpen,
  ]);

  return (
    <>
      <TimelineEditorBasicInitiativeDeleteDialog
        isLoading={isFirstButtonLoading}
        isBasicDeleteInitiativeDialogOpen={isBasicDeleteInitiativeDialogOpen}
        setIsBasicDeleteInitiativeDialogOpen={setIsBasicDeleteInitiativeDialogOpen}
        handleThisRoadmapInitiativeDelete={async () => {
          await handleThisRoadmapInitiativeDelete();
          setIsBasicDeleteInitiativeDialogOpen(false);
        }}
      />
      <TimelineEditorDualChoiceDeleteDialog
        firstChoiceOnClick={async () => {
          await handleThisRoadmapInitiativeDelete();
          setIsDualChoiceDeleteInitiativeDialogOpen(false);
        }}
        firstChoiceIsDisabled={isSecondButtonLoading}
        firstChoiceIsLoading={isFirstButtonLoading}
        isDualChoiceDeleteInitiativeDialogOpen={isDualChoiceDeleteInitiativeDialogOpen}
        onClose={() => setIsDualChoiceDeleteInitiativeDialogOpen(false)}
        secondChoiceIsDisabled={isFirstButtonLoading}
        secondChoiceIsLoading={isSecondButtonLoading}
        secondChoiceOnClick={deleteInitiativeFromAllRoadmaps}
      />
    </>
  );
};
