import { useTranslation } from 'react-i18next';

import { CanvasFormInput } from '@/features/canvas/components/CanvasFormInput';
import { TabEditFormValues } from '@/features/canvas/types/tabEditFormValues';
import { useFormContext } from '@/features/ui/components/Form';

const TabEditFormContent = ({
  onSubmit,
  textColor,
}: {
  onSubmit: (formValues: TabEditFormValues) => Promise<void>;
  textColor: string;
}) => {
  const { t } = useTranslation();
  const { handleSubmit, getValues } = useFormContext();

  return (
    <CanvasFormInput
      name="title"
      rules={{
        required: { value: true, message: t('form.field_required_validation') },
        maxLength: {
          value: 32,
          message: t('form.field_too_long'),
        },
      }}
      onBlur={handleSubmit(async () => {
        const formValues = getValues() as TabEditFormValues;
        await onSubmit(formValues);
      })}
      textColor={textColor}
    />
  );
};

export { TabEditFormContent };
