import { ROADMAP_ITEM_GAP, ROADMAP_ITEM_WIDTH } from 'src/constants/common';
import styled from 'styled-components';

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${ROADMAP_ITEM_WIDTH}px);
  grid-gap: ${ROADMAP_ITEM_GAP}px;
  flex: 1;
`;

export const SearchBarRoadmapDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.brand.backgroundMain};
`;
