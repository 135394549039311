import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function updateRoadmapById({
  id,
  newRoadmapData,
}: {
  id: number;
  newRoadmapData: components['schemas']['RoadmapDetail'];
}) {
  // temporary, BE design
  const newRoadmapDataArray = [newRoadmapData];
  const { data } = await defaultApi.put<components['schemas']['RoadmapDetail']>(
    `/roadmaps/${id}`,
    newRoadmapDataArray
  );
  return data;
}
