import { useState } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, InputProps } from '@mui/material';
import Input from '@mui/material/Input';
import styled, { css } from 'styled-components';

import EyePasswordImgSrc from '@/assets/icons/eye.svg';
import EyePasswordCrossedSrc from '@/assets/icons/eye-crossed.svg';
import KeyImgSrc from '@/assets/icons/key.svg';
import PersonImgSrc from '@/assets/icons/person.svg';
import SearchImgSrc from '@/assets/icons/search.svg';
import { useFormContext } from '@/features/ui/components/Form';

import { FormInputError } from '../FormInputError';
import { Icon } from '../Input/Input';

type ErrorInputProps = {
  fontSize?: string;
};

type FormTextInputProps = InputProps & {
  name: string;
  $shouldHideBorder?: boolean;
  defaultValue?: string | number;
  errorInputProps?: ErrorInputProps;
  hide?: boolean;
  icon?: Icon;
  label?: string;
  maxWidth?: number;
  placeholder?: string;
  required?: boolean;
  rules?: RegisterOptions;
  secondaryLabel?: string;
};

const getIconSrc = (icon: Icon): string | undefined => {
  switch (icon) {
    case 'key':
      return KeyImgSrc;
    case 'search':
      return SearchImgSrc;
    case 'person':
    default:
      return PersonImgSrc;
  }
};

export const FormTextInput = ({
  label,
  name,
  icon,
  placeholder,
  required,
  hide,
  defaultValue,
  secondaryLabel,
  errorInputProps,
  ...otherProps
}: FormTextInputProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const [shouldHidePassword, setShouldHidePassword] = useState(true);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      rules={{
        required: required ? t('form.field_required_validation') : false,
        ...(name === 'email'
          ? {
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                message: t('form.invalid_email_validation'),
              },
            }
          : {}),
        ...(otherProps.rules ? otherProps.rules : {}),
      }}
      render={({ field, fieldState: { error }, formState: { isSubmitting } }) => {
        return (
          <FormControl sx={{ width: '100%', position: 'relative' }}>
            {Boolean(label) && !hide && <FormInputLabel htmlFor={name}>{label}</FormInputLabel>}

            <StyledInput
              sx={{
                '&.MuiInput-root:before': {
                  content: 'unset',
                },
                '&.MuiInput-root:after': {
                  border: 'none',
                },
                '&.Mui-disabled': {
                  content: 'unset',
                },
                '& .MuiInputBase-inputAdornedStart': {
                  marginLeft: '3rem',
                },
                fontSize: '2rem',

                ...(error ? { backgroundColor: '#FFF2F2', borderColor: '#D93839!important' } : {}),
              }}
              placeholder={placeholder}
              {...otherProps}
              {...field}
              onChange={v => {
                otherProps.onChange?.(v);
                field.onChange(v);
              }}
              error={!!error}
              value={otherProps.value?.toString() || field.value}
              disabled={otherProps.disabled || isSubmitting}
              {...(otherProps.type === 'password' &&
                !otherProps.$shouldHideBorder && {
                  endAdornment: (
                    <StyledHideButton
                      type="button"
                      onClick={() => setShouldHidePassword(!shouldHidePassword)}
                    >
                      <StyledEyeIcon
                        src={shouldHidePassword ? EyePasswordImgSrc : EyePasswordCrossedSrc}
                      />
                    </StyledHideButton>
                  ),
                  ...(!shouldHidePassword && { type: 'text' }),
                })}
              {...(!!icon && {
                startAdornment: (
                  <StyledIconWrapper>
                    <StyledIcon src={getIconSrc(icon)} />
                  </StyledIconWrapper>
                ),
              })}
              onBlur={e => {
                otherProps.onBlur?.(e);
                field.onBlur();
              }}
            />

            {Boolean(secondaryLabel) && !hide && <SecondaryLabel>{secondaryLabel}</SecondaryLabel>}

            {!!error && !isSubmitting && (
              <FormInputError {...errorInputProps}>{error.message}</FormInputError>
            )}
          </FormControl>
        );
      }}
    />
  );
};

const borderRadius = 1.8;

const StyledHideButton = styled.button`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledEyeIcon = styled.img`
  height: 2.4rem;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;
const StyledIconWrapper = styled.div`
  background-color: ${({ color, theme }) => color ?? theme.palette.brand.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${`${borderRadius}rem`};
  margin: -2rem;
  height: 6rem;
  width: 6rem;
  min-width: 6rem;
`;
const StyledIcon = styled.img`
  height: '2.4rem';
  width: '2.4rem';
`;
const SecondaryLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.brand.secondary};
  font-style: italic;
`;

const FormInputLabel = styled.label`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.palette.brand.primary};
`;

const StyledInput = styled(Input)<{
  $shouldHideBorder?: boolean;
  readOnly?: boolean;
}>`
  width: 100%;
  border: 2px solid ${({ theme }) => theme.palette.brand.textSecondary};
  padding: 1rem 2rem;
  ${({ $shouldHideBorder }) =>
    $shouldHideBorder &&
    css`
      border: none;
      padding: 0;
    `}
  border-radius: 2rem;
  margin-bottom: 1rem;
  ${({ readOnly }) =>
    !readOnly &&
    css`
      &:focus-within {
        border: 2px solid ${({ theme }) => theme.palette.brand.primary};
        background: ${({ theme }) => theme.palette.brand.sunray};
      }
    `}
`;
