import { useMemo, useState } from 'react';
import { Circle, Group, Image } from 'react-konva';
import { useTheme } from '@mui/material';

import { Tooltip } from '@/features/canvas/components/Tooltip';
import {
  TAB_ACTION_MENU_ICON_SIZE,
  TAB_ACTION_MENU_ICON_SIZE_TABLET,
  TAB_ACTION_MENU_ITEM_MARGIN,
  TAB_ACTION_MENU_ITEM_MARGIN_TABLET,
  TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT,
  TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT_TABLET,
  TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH,
  TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH_TABLET,
} from '@/features/canvas/constants';

type Props = {
  disabled: boolean;
  icon: HTMLImageElement;
  id: string;
  itemIndex: number;
  onClick: () => void;
  text: string;
  isHorizontal?: boolean;
  rotation?: number;
};

const TabActionMenuItem = ({
  icon,
  id,
  text,
  itemIndex,
  rotation,
  onClick,
  disabled,
  isHorizontal = true,
}: Props) => {
  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);

  const isTablet = window.matchMedia('(max-width: 1367px)').matches;

  const iconSize = useMemo(
    () => (isTablet ? TAB_ACTION_MENU_ICON_SIZE_TABLET : TAB_ACTION_MENU_ICON_SIZE),
    [isTablet]
  );

  const itemMargin = useMemo(
    () => (isTablet ? TAB_ACTION_MENU_ITEM_MARGIN_TABLET : TAB_ACTION_MENU_ITEM_MARGIN),
    [isTablet]
  );

  const tooltip = useMemo(
    () => ({
      width: isTablet
        ? TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH_TABLET
        : TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH,
      height: isTablet
        ? TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT_TABLET
        : TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT,
    }),
    [isTablet]
  );

  const imageX = useMemo(
    () =>
      rotation
        ? itemIndex * (iconSize + itemMargin) + iconSize
        : itemIndex * (iconSize + itemMargin),
    [rotation, itemIndex, iconSize, itemMargin]
  );

  const tooltipPosition = useMemo(
    () =>
      isHorizontal
        ? {
            x: itemIndex * (iconSize + itemMargin) - tooltip.width / 2 + iconSize / 2,
            y: iconSize + tooltip.height + 10,
          }
        : {
            x: itemIndex * (iconSize + itemMargin) + tooltip.height / 2 + iconSize / 2,
            y: iconSize * 2,
          },
    [iconSize, isHorizontal, itemIndex, itemMargin, tooltip.height, tooltip.width]
  );

  return (
    <Group
      id={id}
      x={iconSize}
      opacity={disabled ? 0.4 : 1}
      width={iconSize}
      onMouseOver={() => setIsHovered(true)}
      onMouseEnter={e => {
        const shape = e.target;
        const stage = shape.getStage();
        if (stage) {
          const container = stage.container();
          container.style.cursor = 'pointer';

          if (disabled) {
            container.style.cursor = 'not-allowed';
          }
        }
      }}
      onMouseLeave={e => {
        setIsHovered(false);
        const stage = e.target.getStage();
        if (stage) {
          const container = stage.container();
          container.style.cursor = 'default';
        }
      }}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      onTap={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      <Circle
        x={itemIndex * (iconSize + itemMargin) + iconSize / 2}
        y={iconSize}
        radius={iconSize}
        fill={isHovered ? theme.palette.brand.white : theme.palette.brand.buttonPrimaryHover}
      />
      <Image
        image={icon}
        x={imageX}
        y={iconSize / 2}
        width={iconSize}
        height={iconSize}
        rotation={360 - (rotation || 0)}
      />
      <Tooltip
        x={tooltipPosition.x}
        y={tooltipPosition.y}
        height={
          isTablet
            ? TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT_TABLET
            : TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT
        }
        width={
          isTablet ? TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH_TABLET : TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH
        }
        isVisible={isHovered}
        text={text}
        rotation={rotation}
      />
    </Group>
  );
};

export { TabActionMenuItem };
