import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getRoadmapsPopupVisible({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get<components['schemas']['PopupVisible'][]>(
    `/popup-visible/roadmap/${roadmapId}`
  );
  return data;
}

export async function createRoadmapsPopupVisible({
  roadmapId,
  newPopupVisible,
}: {
  roadmapId: number;
  newPopupVisible: components['schemas']['PopupVisible'];
}) {
  const popupVisibleInArray = [newPopupVisible];
  const { data } = await defaultApi.post<components['schemas']['PopupVisible']>(
    `/popup-visible/${roadmapId}`,
    popupVisibleInArray
  );
  return data;
}

export async function deleteRoadmapsPopupVisible({ roadmapId }: { roadmapId: number }) {
  return await defaultApi.delete(`/popup-visible/${roadmapId}`);
}
