import { forwardRef } from 'react';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as LockedIcon } from '@/assets/icons/locked.svg';
import { ReactComponent as UnlockedIcon } from '@/assets/icons/unlocked.svg';

interface Props {
  isHandlingLockingInitiative: boolean;
  isInitiativeLocked: boolean;
}

const LockElement = forwardRef<HTMLDivElement, Props>(
  ({ isHandlingLockingInitiative, isInitiativeLocked, ...other }, ref) => {
    return (
      <div ref={ref} {...other}>
        {isInitiativeLocked && !isHandlingLockingInitiative && <LockedIcon />}
        {!isInitiativeLocked && !isHandlingLockingInitiative && <UnlockedIcon />}
        {isHandlingLockingInitiative && <StyledCircularProgress />}
      </div>
    );
  }
);

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabSecondary};
  max-width: 1.7rem;
  max-height: 1.7rem;
`;

export { LockElement };
