import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function deleteRoadmap(id: number): Promise<void> {
  await defaultApi.delete(`/roadmaps/${id}`);
}

export async function createNewRoadmap(roadmap: components['schemas']['RoadmapDetail']) {
  // temporary
  const roadmapDataInArray = [roadmap];
  return (await defaultApi.post<components['schemas']['Roadmap']>('/roadmaps', roadmapDataInArray))
    .data;
}

export async function getRoadmapById(roadmapId: number) {
  return (await defaultApi.get<components['schemas']['RoadmapDetail']>(`/roadmaps/${roadmapId}`))
    .data;
}

export async function getRoadmapList() {
  const { data } = await defaultApi.get<components['schemas']['Roadmap'][]>('/roadmaps');
  return data;
}

export async function duplicateRoadmap(roadmapId: number) {
  const { data } = await defaultApi.post<components['schemas']['RoadmapDetail']>(
    `/roadmaps/${roadmapId}/duplicate`
  );
  return data;
}
