import SupportPage from 'src/features/support/screens/Support';

import { PageWithSidebar } from '@/layouts/PageWithSidebar';

const Support = () => {
  return (
    <PageWithSidebar>
      <SupportPage />
    </PageWithSidebar>
  );
};

export default Support;
