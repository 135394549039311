import RoadmapsList from 'src/features/roadmaps/components/RoadmapsList';

import { PageWithSidebar } from '@/layouts/PageWithSidebar';

const RoadmapsHome = () => {
  return (
    <PageWithSidebar contentWhiteBox={false} fullWidth gridColumnStart={1} gridColumnEnd={5}>
      <RoadmapsList />
    </PageWithSidebar>
  );
};

export default RoadmapsHome;
