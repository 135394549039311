import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  useTheme,
} from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';

type ConfirmationDialogProps = DialogProps & {
  description: string;
  handleOk: () => void;
  onClose: () => void;
  cancelButtonText?: string;
  isLoading?: boolean;
  okButtonText?: string;
};

const ConfirmationDialog = ({
  open,
  onClose,
  description,
  okButtonText,
  cancelButtonText,
  handleOk,
  isLoading,
  ...other
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      sx={{ '& .MuiDialog-paper': { padding: '3rem', borderRadius: '2rem', maxWidth: '450px' } }}
      {...other}
    >
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'space-center',
          alignItems: 'center',
          fontSize: '1.75rem',
          textAlign: 'center',
          padding: '2rem',
          marginBottom: '1rem',
          fontWeight: '600',
          lineHeight: '2.5rem',
          color: theme.palette.brand.textPrimary,
        }}
      >
        {description}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          fontSize: '2rem',
          padding: '0 2rem',
          gap: '2rem',
        }}
      >
        <StyledSecondaryButton
          onClick={handleOk}
          disabled={isLoading}
          {...(isLoading && {
            startIcon: <StyledLoader color="inherit" size={20} />,
          })}
        >
          {okButtonText || t('confirm')}
        </StyledSecondaryButton>
        <StyledSecondaryButton onClick={() => onClose()}>
          <>{cancelButtonText || t('cancel')}</>
        </StyledSecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

const StyledSecondaryButton = styled(Button)`
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.brand.primary};
  color: ${({ theme }) => theme.palette.brand.white};
  padding: 0 3rem;
  border-radius: 2rem;
  font-size: 1.5rem;
  text-transform: none;
  font-weight: 600;
  gap: 1rem;
  min-width: 100px;
  flex: 1;
  white-space: nowrap;

  & .MuiButton-startIcon {
    margin: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
  }
  &:focus {
    background-color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
    color: ${({ theme }) => theme.palette.brand.white};
  }
  &.Mui-disabled {
    color: ${({ theme }) => transparentize(0.3, theme.palette.brand.white)};
  }
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.white};
`;

export { ConfirmationDialog };
