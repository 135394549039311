import { MouseEvent } from 'react';
import { Button, IconButton, TablePagination } from '@mui/material';
import { t } from 'i18next';
import { darken, transparentize } from 'polished';
import styled from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import { LINKAGE_ROW_SIZE } from '@/features/canvas/constants';

interface TableFooterProps {
  onSubmit: () => void;
  page: number;
  rowsLength: number;
  setPage: (page: number) => void;
}

interface TableFooterActionProps {
  count: number;
  onPageChange: (event: MouseEvent<HTMLButtonElement>, newPage: number) => void;
  onSubmit: () => void;
  page: number;
  rowsPerPage: number;
}

const LinkingTableFooterActions = (props: TableFooterActionProps) => {
  const { count, page, rowsPerPage, onPageChange, onSubmit } = props;

  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <FooterActionsWrapper>
      <FooterTableActions>
        <PageActions>
          <PageArrowIconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            <RotatedArrowIcon rotate={90} />
          </PageArrowIconButton>
          <PageArrowIconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            <RotatedArrowIcon rotate={-90} />
          </PageArrowIconButton>
        </PageActions>
        <PageState>
          {t('page_state', {
            pageFrom: page + 1,
            pageTo: Math.ceil(count / rowsPerPage),
          })}
        </PageState>
        <RowsCount>{t('items_count', { count })}</RowsCount>
      </FooterTableActions>
      <SubmitButton onClick={onSubmit}>{t('editor.sidebar.data_tab.finalize_import')}</SubmitButton>
    </FooterActionsWrapper>
  );
};

const LinkingTableFooter = (props: TableFooterProps) => {
  const { rowsLength, setPage, page, onSubmit } = props;

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  return (
    <StyledTablePagination
      rowsPerPage={LINKAGE_ROW_SIZE}
      count={rowsLength}
      component="div"
      page={page}
      onPageChange={handleChangePage}
      ActionsComponent={passedProps => LinkingTableFooterActions({ ...passedProps, onSubmit })}
      labelDisplayedRows={() => ''}
      rowsPerPageOptions={[]}
      labelRowsPerPage={null}
      onSubmit={onSubmit}
    />
  );
};

const PageState = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => transparentize(0.5, theme.palette.brand.textSecondary)};
`;
const PageActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const FooterTableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
`;

const FooterActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowsCount = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => transparentize(0.5, theme.palette.brand.textSecondary)};
`;

const PageArrowIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  border-radius: 1rem;

  &.Mui-disabled {
    opacity: 0.5;
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
    color: ${({ theme }) => theme.palette.brand.textPrimary};
  }

  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.backgroundDialog)};
  }
`;

const StyledTablePagination = styled(TablePagination)`
  margin-top: 2rem;
  border-bottom: none;
  & .MuiToolbar-root {
    padding: 0;
    margin: 0;
  }
  & .MuiTablePagination-spacer {
    flex: none;
  }
` as typeof TablePagination;

const RotatedArrowIcon = styled(ArrowDownIcon)<{ rotate: number }>`
  transform: rotate(${({ rotate }) => rotate}deg);
  width: 1.5rem;
  height: 1.5rem;
`;

const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.brand.secondary};
  text-transform: none;
  font-size: 1.5rem;
  padding: 0 2rem;
  color: ${({ theme }) => theme.palette.brand.white};
  font-weight: 600;
  border-radius: 2rem;
  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.secondary)};
  }
`;

export { LinkingTableFooter };
