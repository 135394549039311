/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from '@mui/material';
import Papa from 'papaparse';
import { darken, transparentize } from 'polished';
import { ReactComponent as LinkIconSrc } from 'src/assets/icons/link.svg';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { LinkingDialog } from '@/features/canvas/components/ImportAccordionContent/LinkingDialog';
import { useImportInitiativeContext } from '@/features/canvas/contexts/import-initiative-context';
import { InitiativeToBeImported } from '@/features/canvas/types/initiative';
import {
  LinkageColumn,
  LinkageRow,
  LinkedColumn,
  LinkedColumnType,
} from '@/features/canvas/types/linkage';
import { getTransformedLinkedValue } from '@/features/canvas/utils/get-transformed-linked-value';
import { useShowToast } from '@/hooks/useShowToast';

import { ImportList } from '../List';

const CsvLinkage = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const { isInitiativeImportRunning } = useImportInitiativeContext();

  const [isActive, setActive] = useState(false);
  const [isLinkingDialogOpen, setIsLinkingDialogOpen] = useState(false);
  const [linkingColumns, setLinkingColumns] = useState<LinkageColumn[]>([]);
  const [linkingRows, setLinkingRows] = useState<LinkageRow[]>([]);
  const [linkedColumns, setLinkedColumns] = useState<LinkedColumn[]>([]);
  const [filename, setFilename] = useState<string>('');

  const [initiativesToPlaceOnRoadmap, setInitiativesToPlaceOnRoadmap] = useState<
    InitiativeToBeImported[]
  >([]);

  const handleFileInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        setFilename(file.name);
        Papa.parse(file, {
          header: true,
          skipEmptyLines: 'greedy',
          complete: (results: unknown) => {
            const { data, meta } = results as any;
            const columns = meta.fields.map((field: string) => ({
              id: uuidv4(),
              name: field,
            }));
            const rows = data.map((row: any) => {
              const cells = columns.map((column: any) => ({
                id: uuidv4(),
                value: row[column.name],
              }));
              return {
                id: uuidv4(),
                cells,
              };
            });

            setLinkingColumns(columns);
            setLinkingRows(rows);
            showToast('success', t('editor.sidebar.data_tab.import_csv_file_success'));
          },
          error: error => {
            console.error(error);
            showToast('error', t('editor.sidebar.data_tab.import_csv_file_error'));
          },
        });

        setActive(true);
        setIsLinkingDialogOpen(true);

        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      }
    },
    [showToast, t]
  );

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleSubmitLinking = useCallback(() => {
    const titleColumn = linkedColumns.find(col => col.linkedTargetType === LinkedColumnType.title);
    if (!titleColumn) {
      showToast('error', t('editor.sidebar.data_tab.import_initiative_title_missing_error'));
      return;
    }

    const indexOfTitleColumn = linkingColumns.findIndex(
      col => col.id === titleColumn?.importedColumnUuid
    );

    if (indexOfTitleColumn < 0) {
      showToast('error', t('editor.sidebar.data_tab.import_initiative_title_missing_error'));
    }

    const filterLinkedColumns = linkedColumns.filter(
      lc => lc.linkedTargetType === LinkedColumnType.filter
    );
    const fieldLinkedColumns = linkedColumns.filter(
      lc => ![LinkedColumnType.title, LinkedColumnType.filter].includes(lc.linkedTargetType as any)
    );

    const initiativesToCreate = linkingRows.map((row, index) => {
      const filterOptionValues = filterLinkedColumns
        .map(lc => {
          const indexOfLinkedFilterColumn = linkingColumns.findIndex(
            col => col.id === lc.importedColumnUuid
          );

          return {
            id: lc.linkedTargetId,
            value: row.cells[indexOfLinkedFilterColumn].value,
          };
        })
        .filter(f => f.value);

      const fieldValues = fieldLinkedColumns.map(lc => {
        const indexOfLinkedFieldColumn = linkingColumns.findIndex(
          col => col.id === lc.importedColumnUuid
        );
        const value = getTransformedLinkedValue(
          row.cells[indexOfLinkedFieldColumn].value,
          lc.linkedTargetType
        );

        return {
          id: lc.linkedTargetId,
          title: lc.linkedTargetTitle,
          value,
          type: lc.linkedTargetType,
        };
      });

      const initiative: InitiativeToBeImported = {
        id: index,
        title: row.cells[indexOfTitleColumn].value,
        isImportedFromCSV: true,
        fieldValues,
        filterOptionValues,
      };
      return initiative;
    });
    setInitiativesToPlaceOnRoadmap(initiativesToCreate);
    if (!initiativesToCreate.length) {
      setFilename('');
    }
    onCSVLinkageDialogClose();
  }, [linkedColumns, linkingColumns, linkingRows, showToast, t]);

  const handleUpdateList = (id: number, e?: React.DragEvent<HTMLDivElement>) => {
    if (e?.dataTransfer?.dropEffect !== 'none') {
      const newInitiatives = initiativesToPlaceOnRoadmap.filter(i => i.id !== id);
      setInitiativesToPlaceOnRoadmap(newInitiatives);

      if (newInitiatives.length === 0) {
        setActive(false);
        setFilename('');
      }
    }
  };

  const onCSVLinkageDialogClose = () => {
    setIsLinkingDialogOpen(false);
    setActive(false);
    setLinkingColumns([]);
    setLinkingRows([]);
    setLinkedColumns([]);
  };

  return (
    <ContentWrapper>
      {filename && <Filename>{filename}</Filename>}
      {Boolean(initiativesToPlaceOnRoadmap.length) && (
        <ImportList
          title={t('editor.sidebar.data_tab.checklist_title')}
          onUpdateList={handleUpdateList}
          list={initiativesToPlaceOnRoadmap}
          disabled={isInitiativeImportRunning}
        />
      )}
      <Input
        type="file"
        inputRef={fileInputRef}
        hidden
        inputProps={{ accept: 'text/csv' }}
        onChange={handleFileInputChange}
      />
      {!initiativesToPlaceOnRoadmap.length && (
        <ImportButton
          $isActive={isActive}
          type="button"
          startIcon={<LinkIconSrc width="2rem" height="2rem" />}
          onClick={() => handleButtonClick()}
        >
          {t('editor.sidebar.data_tab.import_csv_file')}
        </ImportButton>
      )}
      <LinkingDialog
        open={isLinkingDialogOpen}
        onClose={onCSVLinkageDialogClose}
        columns={linkingColumns}
        rows={linkingRows}
        linkedColumns={linkedColumns}
        setLinkedColumns={setLinkedColumns}
        onSubmit={handleSubmitLinking}
      />
    </ContentWrapper>
  );
};

export { CsvLinkage };

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
`;

const ImportButton = styled(Button)<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 2rem;
  padding: 0.25rem 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: none;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  background-color: ${({ theme }) => theme.palette.brand.white};
  gap: 0 !important;
  justify-content: center !important;
  :hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.white)};
  }
  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      color: ${theme.palette.brand.white};
      background-color: ${theme.palette.brand.editorTabPrimary};
      svg {
        path {
          fill: ${theme.palette.brand.white};
        }
      }
      :hover {
        background-color: ${darken(0.1, theme.palette.brand.editorTabSecondary)};
      }
    `}
`;

const Filename = styled.div`
  font-size: 1rem;
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  color: ${({ theme }) => transparentize(0.3, theme.palette.brand.textPrimary)};
`;
