import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, useTheme } from '@mui/material';
import styled from 'styled-components';

import { InitiativesFilterOptionRow } from '@/features/canvas/components/InitiativesFilterOptionRow';
import { SidebarListSubItem } from '@/features/canvas/components/SidebarListSubItem';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { components } from '@/types/api';

type Props = {
  expandedAccordionKey: number | null;
  isInitiativeLoading: boolean;
  setExpandedAccordionKey: Dispatch<SetStateAction<number | null>>;
  initiative?: components['schemas']['Initiative'];
};

const InitiativeAccordionContentFilters = ({
  expandedAccordionKey,
  initiative,
  isInitiativeLoading,
  setExpandedAccordionKey,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { roadmap, selectedNodeId, filters } = useEditorContext();
  return (
    <>
      {!isInitiativeLoading && !!filters?.length && initiative && (
        <>
          <Box>
            <InitiativeHeaders>{t('editor.filter_sidebar.title')}</InitiativeHeaders>
            <Divider
              variant="fullWidth"
              sx={{ borderColor: theme.palette.brand.backgroundDialog }}
            />
          </Box>
          <FilterWrapper>
            {filters.map(filter => {
              return (
                <SidebarListSubItem
                  key={filter.id}
                  id={filter.id!}
                  ariaId={filter.id!.toString()}
                  title={filter.title}
                  isExpanded={expandedAccordionKey === filter.id}
                  setExpandedAccordionKey={setExpandedAccordionKey}
                >
                  <InitiativesFilterOptionRow
                    filter={filter}
                    selectedNodeId={selectedNodeId}
                    roadmapId={roadmap.id!}
                  />
                </SidebarListSubItem>
              );
            })}
          </FilterWrapper>
        </>
      )}
    </>
  );
};

const FilterWrapper = styled.div`
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
`;
const InitiativeHeaders = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;
export { InitiativeAccordionContentFilters };
