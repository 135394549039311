import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getOptionsByFilterId(filterId: number) {
  const { data } = await defaultApi.get(`/filteroptions/filter/${filterId}`);
  return data;
}

export async function deleteOptionById(id: number) {
  return defaultApi.delete(`/filteroptions/${id}`);
}

export async function editOption(optionId: number, payload: components['schemas']['FilterOption']) {
  return defaultApi.put(`/filteroptions/${optionId}`, [payload]);
}

export async function createFilterOption({
  filterId,
  payload,
}: {
  filterId: number;
  payload: components['schemas']['FilterOption'];
}) {
  const { data } = await defaultApi.post<components['schemas']['FilterOption']>(
    `/filteroptions/${filterId}`,
    [payload]
  );
  return data;
}
