import { FilterFactory } from '@/features/canvas/components/FiltersAccordionContent/FilterFactory';
import { FilterTypes } from '@/features/canvas/types/filter';

export const createDefaultFilterByType = (filterType: keyof typeof FilterTypes) => {
  switch (filterType) {
    case FilterTypes.PRIORITY:
      return FilterFactory.createPriorityFilter().getFilter();
    case FilterTypes.RAG:
      return FilterFactory.createRAGFilter().getFilter();
    case FilterTypes.PROGRESS_STATUS:
      return FilterFactory.createProgressStatusFilter().getFilter();
    case FilterTypes.CUSTOM_MULTI:
      return FilterFactory.createCustomMultiFilter().getFilter();
    default:
      return FilterFactory.createCustomSingleFilter().getFilter();
  }
};
