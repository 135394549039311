import { Fragment, FunctionComponent, ReactNode, SVGProps } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Divider, useTheme } from '@mui/material';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import { SIDEBAR_ACCORDION_KEY_MAP } from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

type Props = {
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  ariaId: string;
  children: ReactNode;
  id: keyof typeof SIDEBAR_ACCORDION_KEY_MAP;
  isExpanded: boolean;
  title: string;
};

const SidebarListItem = ({ isExpanded, Icon, ariaId, id, title, children }: Props) => {
  const theme = useTheme();
  const { setExpandedSidebarAccordionKey } = useEditorContext();
  return (
    <Fragment key={id}>
      <ListItem $isExpanded={isExpanded}>
        <StyledAccordion
          square
          elevation={0}
          expanded={isExpanded}
          onChange={() => {
            setExpandedSidebarAccordionKey(isExpanded ? null : id);
          }}
          disableGutters
        >
          <StyledAccordionSummary
            id={id}
            aria-controls={ariaId}
            expandIcon={<ArrowDownIcon />}
            $isExpanded={isExpanded}
          >
            <AccordionPanel key={id} id={ariaId} $isExpanded={isExpanded}>
              <Icon />
              <ItemTitle>{title}</ItemTitle>
            </AccordionPanel>
          </StyledAccordionSummary>
          <StyledAccordionDetails>{children}</StyledAccordionDetails>
        </StyledAccordion>
      </ListItem>
      {!isExpanded && (
        <Divider variant="middle" sx={{ borderColor: theme.palette.brand.backgroundDialog }} />
      )}
    </Fragment>
  );
};
const StyledAccordion = styled(Accordion)`
  & .MuiButtonBase-root {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    align-items: center;
  }
  & .MuiAccordion-region {
    height: 100%;
  }
  box-shadow: none;
  height: 100%;

  & .MuiAccordionSummary-content {
    margin-top: 12px !important;
    margin-bottom: 6px !important;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)<{ $isExpanded: boolean }>`
  ${({ $isExpanded, theme }) =>
    $isExpanded &&
    css`
      color: ${theme.palette.brand.editorTabSecondary};
      svg {
        path {
          stroke: ${theme.palette.brand.editorTabSecondary};
        }
      }
    `}
`;

const ItemTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;

const ListItem = styled.li<{ $isExpanded: boolean }>`
  flex: 0 0 80px;
  min-height: 0;
  transition: height 500ms ease, flex 500ms ease;

  height: 100% !important;

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      transition: height 500ms ease, flex 500ms ease;

      flex: 1;
    `};
  & .MuiCollapse-root {
    background-color: ${({ theme }) => theme.palette.brand.backgroundMain};
  }

  && > .MuiPaper-root {
    transition: all 1s ease !important;
    transition-duration: 1s;
    display: flex;
    flex-direction: column;
    height: 100% !important;

    > .MuiCollapse-root {
      opacity: 0;
      transition: height 500ms ease, flex 500ms ease, opacity 500ms ease;
      height: 100% !important;

      ${({ $isExpanded }) =>
        $isExpanded &&
        css`
          opacity: 1;
          transition: height 500ms ease, flex 500ms ease, opacity 500ms ease;
        `}

      > .MuiCollapse-wrapper {
        height: 100% !important;
        ${({ $isExpanded }) =>
          $isExpanded &&
          css`
            height: 100% !important;
          `};

        .MuiAccordionDetails-root {
          height: 0;
          transition: height 500ms ease, flex 500ms ease, opacity 500ms ease;

          ${({ $isExpanded }) =>
            $isExpanded &&
            css`
              transition: height 500ms ease, flex 500ms ease, opacity 500ms ease;

              height: 100% !important;
            `};
        }
      }
    }

    > .MuiButtonBase-root {
      flex-shrink: 0;
    }
  }
`;

const AccordionPanel = styled.div<{ $isExpanded: boolean }>`
  ${({ $isExpanded, theme }) =>
    $isExpanded &&
    css`
      color: ${theme.palette.brand.editorTabSecondary};
      svg {
        path {
          fill: ${theme.palette.brand.editorTabSecondary};
        }
      }
    `}

  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
  flex: 1;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`;
export { SidebarListItem };
