import { VerticalSection } from '@/features/canvas/components/VerticalSection';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { getTabSizeProportion } from '@/features/canvas/utils/get-tab-size-proportion';

type Props = {
  canvasHeightToFit: number;
  canvasWidthToFit: number;
};

const VerticalSections = ({ canvasWidthToFit, canvasHeightToFit }: Props) => {
  const { sortedVerticalSections } = useEditorContext();

  const verticalSectionsTabWidths = sortedVerticalSections.map(({ tabWidth }) => tabWidth);

  const verticalSectionTabScaleProportion = getTabSizeProportion({
    sizeToFit: canvasHeightToFit,
    tabSizes: verticalSectionsTabWidths,
  });

  const scaleAdjustedVerticalSectionsTabWidths = verticalSectionsTabWidths.map(
    tabWidth => tabWidth * verticalSectionTabScaleProportion
  );

  return (
    <>
      {sortedVerticalSections.map(({ id, title, order, orientation }, sectionIndex) => (
        <VerticalSection
          key={id}
          sectionId={id!}
          sectionTitle={title || ''}
          sectionOrder={order}
          sectionOrientation={orientation}
          sectionIndex={sectionIndex}
          sectionTabScaleProportion={verticalSectionTabScaleProportion}
          cornerRadius={[10, 10, 0, 0]}
          areaRotation={90}
          rotation={270}
          canvasWidth={canvasWidthToFit}
          scaledVerticalSectionTabWidths={scaleAdjustedVerticalSectionsTabWidths}
        />
      ))}
    </>
  );
};

export { VerticalSections };
