import SVG from 'react-inlinesvg';
import { useTheme } from '@mui/material';
import styled, { css } from 'styled-components';

import CircleSVG from '@/assets/icons/circle.svg';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useImportInitiativeContext } from '@/features/canvas/contexts/import-initiative-context';
import { components } from '@/types/api';

const ImportListItem = ({
  initiative,
  onUpdateList,
}: {
  initiative: components['schemas']['Initiative'];
  onUpdateList: (id: number, e?: React.DragEvent<HTMLDivElement>) => void;
}) => {
  const { setInitiativeToImport } = useImportInitiativeContext();
  const { setShowDependencies, setActiveFilterId } = useEditorContext();
  const theme = useTheme();
  return (
    <ListItemWrapper
      draggable
      onDragStart={() => {
        setInitiativeToImport(initiative);
      }}
      onDragEnd={e => {
        setInitiativeToImport(null);
        setShowDependencies(false);
        setActiveFilterId(null);
        onUpdateList(initiative.id!, e);
      }}
    >
      <ListItem>
        <IconWrapper>
          <StyledSVG src={CircleSVG} $color={theme.palette.brand.textSecondary} />
          <Title id="new-initiative-title">{initiative.title}</Title>
        </IconWrapper>
      </ListItem>
    </ListItemWrapper>
  );
};

export { ImportListItem };

const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-radius: 1rem;
  &:hover {
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  }
`;

const ListItem = styled.div`
  font-size: 1.25rem;
`;

const Title = styled.div``;

const StyledSVG = styled(SVG)<{ $color?: string }>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  ${({ $color }) =>
    $color &&
    css`
      & path {
        stroke: ${$color};
        fill: ${$color};
      }

      & rect {
        stroke: ${$color};
      }
    `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
