interface FilterOption {
  color: string;
  id: number;
  title: string;
}

interface Filter {
  id: number;
  options: FilterOption[];
  shape: 'circle' | 'square' | 'triangle';
  title: string;
  isVisible?: boolean;
  selection?: 'single' | 'multiple';
}

class ConcreteFilter implements Filter {
  static idCounter = -1;

  static usedIds: Set<number> = new Set();

  id: number;

  title: string;

  selection: 'single' | 'multiple';

  shape: 'circle' | 'square' | 'triangle';

  isVisible: boolean;

  options: FilterOption[];

  // eslint-disable-next-line max-params
  constructor(
    title: string,
    selection: 'single' | 'multiple',
    shape: 'square' | 'triangle' | 'circle',
    isVisible: boolean,
    options: FilterOption[]
  ) {
    ConcreteFilter.idCounter -= 1;
    while (ConcreteFilter.usedIds.has(ConcreteFilter.idCounter)) {
      ConcreteFilter.idCounter -= 1;
    }
    this.id = ConcreteFilter.idCounter;
    ConcreteFilter.usedIds.add(this.id);

    this.title = title;
    this.selection = selection;
    this.shape = shape;
    this.isVisible = isVisible;
    this.options = options;
  }

  getFilter(): Filter {
    return {
      id: this.id,
      selection: this.selection,
      title: this.title,
      shape: this.shape,
      isVisible: this.isVisible,
      options: this.options,
    };
  }

  static generateNewId(): number {
    ConcreteFilter.idCounter -= 1;
    while (ConcreteFilter.usedIds.has(ConcreteFilter.idCounter)) {
      ConcreteFilter.idCounter -= 1;
    }
    ConcreteFilter.usedIds.add(ConcreteFilter.idCounter);
    return ConcreteFilter.idCounter;
  }
}

export { ConcreteFilter };
