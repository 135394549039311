export type ValidationResponse = {
  isValid: boolean;
  errorKey?: string;
  value?: string;
};

export const VALIDATION_ERROR_KEY = {
  textTooLong: 'textTooLong',
  textIsEmpty: 'textIsEmpty',
  wrongDateFormat: 'wrongDateFormat',
  invalidNumber: 'invalidNumber',
  invalidProgressValue: 'invalidProgressValue',
  wrongBudgetFormat: 'wrongBudgetFormat',
} as const;
