import { forwardRef, InputHTMLAttributes, useCallback, useState } from 'react';
import EyePasswordImgSrc from 'src/assets/icons/eye-password.svg';
import KeyImgSrc from 'src/assets/icons/key.svg';
import PersonImgSrc from 'src/assets/icons/person.svg';
import SearchImgSrc from 'src/assets/icons/search.svg';

import {
  StyledEyeIcon,
  StyledHideButton,
  StyledIcon,
  StyledIconWrapper,
  StyledInput,
  StyledInputField,
} from './Input.styled';

export type Icon = 'person' | 'key' | 'search';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  hidable?: boolean;
  icon?: Icon;
  isError?: boolean;
};

const getIconSrc = (icon: Icon): string | undefined => {
  switch (icon) {
    case 'key':
      return KeyImgSrc;
    case 'search':
      return SearchImgSrc;
    case 'person':
    default:
      return PersonImgSrc;
  }
};

const Input: React.FC<InputProps> = forwardRef((props, ref) => {
  const { icon, hidable = false, type = 'text', isError, ...rest } = props;
  const [hidden, setHidden] = useState(true);

  return (
    <StyledInput isError={isError}>
      {icon && (
        <StyledIconWrapper>
          <StyledIcon src={getIconSrc(icon)} />
        </StyledIconWrapper>
      )}
      <StyledInputField
        type={hidden ? type : 'text'}
        ref={ref as React.Ref<HTMLInputElement>}
        {...rest}
      />
      {hidable && (
        <StyledHideButton type="button" onClick={useCallback(() => setHidden(!hidden), [hidden])}>
          <StyledEyeIcon src={EyePasswordImgSrc} />
        </StyledHideButton>
      )}
    </StyledInput>
  );
});

export default Input;
