import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox } from '@mui/material';
import { ReactComponent as TriangleIconSrc } from 'src/assets/icons/triangle.svg';
import { ReactComponent as TriangleCheckIconSrc } from 'src/assets/icons/triangle-check.svg';
import styled, { css } from 'styled-components';

import CircleEmptySVG from '@/assets/icons/circle-empty.svg';
import CircleMarkedSVG from '@/assets/icons/circle-marked.svg';
import SquareEmptySVG from '@/assets/icons/square-empty.svg';
import SquareMarkedSVG from '@/assets/icons/square-marked.svg';
import TriangleEmptySVG from '@/assets/icons/triangle-empty.svg';
import TriangleMarkedSVG from '@/assets/icons/triangle-marked.svg';
import {
  createFilterNode,
  deleteNodeById,
  getFiltersByRoadmapId,
  getNodesByFilterId,
} from '@/features/canvas/api';
import { FilteredNodes, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

const getFilterIcon = (color: string, filterShape: string, optionChecked: boolean) => {
  let addIcon = optionChecked ? CircleMarkedSVG : CircleEmptySVG;

  if (filterShape === 'triangle') {
    addIcon = optionChecked ? TriangleMarkedSVG : TriangleEmptySVG;
  } else if (filterShape === 'square') {
    addIcon = optionChecked ? SquareMarkedSVG : SquareEmptySVG;
  }

  return (
    <StyledSVG
      src={addIcon}
      $color={color}
      $isChecked={optionChecked}
      $isSquare={filterShape === 'square'}
    />
  );
};

const InitiativesFilterOptionRow = ({
  filter,
  selectedNodeId,
  roadmapId,
}: {
  filter: components['schemas']['Filter'];
  roadmapId: number;
  selectedNodeId: number | null;
}) => {
  const queryClient = useQueryClient();

  const {
    setIsSidebarContentLoading,
    filteredNodes,
    activeFilterId,
    setFilteredNodes,
    updateLastSavedTime,
  } = useEditorContext();
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const { data: nodes } = useQuery(
    [getNodesByFilterId.name, filter.id],
    () => getNodesByFilterId(filter.id!),
    {
      enabled: Boolean(filter.id),
    }
  );

  const { mutateAsync: deleteNode } = useMutation(async (nodeId: number) => {
    try {
      await deleteNodeById(nodeId);
      updateLastSavedTime();
      showToast('success', t('editor.sidebar.initiative.edit_initiative_success'));
    } catch (e) {
      showToast('error', t('editor.sidebar.initiative.edit_initiative_error'));
    }
  });

  const { mutateAsync: createNode } = useMutation(
    async (params: { filterId: number; node: components['schemas']['FilterNode'] }) => {
      try {
        await createFilterNode({ filterId: params.filterId, payload: params.node });
        updateLastSavedTime();
        showToast('success', t('editor.sidebar.initiative.edit_initiative_success'));
      } catch (e) {
        showToast('error', t('editor.sidebar.initiative.edit_initiative_error'));
      }
    }
  );

  const setOptionAsChecked = async (
    filterToUpdated: components['schemas']['Filter'],
    option: components['schemas']['FilterOption']
  ) => {
    setIsSidebarContentLoading(true);
    const nodesToUpdate = nodes?.filter(n => n.nodeId === selectedNodeId) || [];
    const node = {
      id: selectedNodeId || undefined,
      nodeId: selectedNodeId!,
      optionId: option.id!,
    };

    if (
      (filterToUpdated.selection !== 'single' || nodesToUpdate.length) &&
      filterToUpdated.selection !== 'multiple'
    ) {
      const nodeToEdit = nodes?.find(n => n.nodeId === selectedNodeId);

      if (nodeToEdit) {
        nodeToEdit.optionId = option.id!;
        await deleteNode(nodeToEdit.id!);
      } else {
        setIsSidebarContentLoading(false);
        return;
      }
    }

    await createNode({
      filterId: filterToUpdated.id!,
      node: { ...node },
    });
    await queryClient.invalidateQueries([getNodesByFilterId.name, filter.id]);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmapId]);

    const newNodes = filteredNodes.map(currentNode => {
      if (activeFilterId === filter.id) {
        if (currentNode.id === selectedNodeId) {
          return {
            ...currentNode,
            shape: filter.shape,
            color: option.color,
          };
        }
      }

      return currentNode;
    });

    setFilteredNodes(newNodes as FilteredNodes[]);
    setIsSidebarContentLoading(false);
  };

  const setOptionAsUnchecked = async (nodeId: number) => {
    setIsSidebarContentLoading(true);
    await deleteNode(nodeId);
    await queryClient.invalidateQueries([getNodesByFilterId.name, filter.id]);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmapId]);

    const newNodes = filteredNodes.map(currentNode => {
      if (currentNode.id === selectedNodeId) {
        return {
          ...currentNode,
          shape: '',
          color: '',
        };
      }

      return currentNode;
    });

    setFilteredNodes(newNodes as FilteredNodes[]);

    setIsSidebarContentLoading(false);
  };

  const getCheckedIcon = () => {
    if (filter.shape === 'square') {
      return <CheckBoxIcon />;
    }
    if (filter.shape === 'triangle') {
      return <StyledTriangleCheckIcon />;
    }
    if (filter.shape === 'circle') {
      return <CheckCircleIcon />;
    }
  };

  const getNotCheckedIcon = () => {
    if (filter.shape === 'square') {
      return <CheckBoxOutlineBlankIcon />;
    }
    if (filter.shape === 'triangle') {
      return <StyledTriangleEmptyIcon />;
    }
    if (filter.shape === 'circle') {
      return <RadioButtonUncheckedIcon />;
    }
  };

  return (
    <FilterOptionRow $isMultiFilter={filter.selection === 'multiple'}>
      {filter.options.map(option => {
        const optionNode = nodes?.find(
          node => node.optionId === option.id && node.nodeId === selectedNodeId
        );
        return (
          <FilterOptionWrapper
            key={option.id}
            onClick={() =>
              optionNode ? setOptionAsUnchecked(optionNode.id!) : setOptionAsChecked(filter, option)
            }
          >
            {filter.selection === 'multiple' && (
              <StyledCheckbox
                checked={!!optionNode}
                icon={getNotCheckedIcon()}
                checkedIcon={getCheckedIcon()}
              />
            )}

            {filter.selection === 'single' && (
              <>{getFilterIcon(option.color, filter.shape, !!optionNode)}</>
            )}
            <FilterOptionText>{option.title}</FilterOptionText>
          </FilterOptionWrapper>
        );
      })}
    </FilterOptionRow>
  );
};

const FilterOptionRow = styled.div<{ $isMultiFilter: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isMultiFilter }) =>
    !$isMultiFilter &&
    css`
      gap: 1rem;
    `}
`;

const FilterOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const FilterOptionText = styled.div`
  font-size: 1.3rem;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0;
  color: ${({ theme }) => theme.palette.brand.textSecondary};

  &.Mui-checked {
    color: ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const StyledTriangleCheckIcon = styled(TriangleCheckIconSrc)`
  width: 3rem;
  height: 3rem;
  margin: -0.25rem -0.5rem -0.5rem;
  path {
    fill: ${({ theme }) => theme.palette.brand.textSecondary};
    stroke: ${({ theme }) => theme.palette.brand.textSecondary};
  }
  polygon {
    fill: ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const StyledTriangleEmptyIcon = styled(TriangleIconSrc)`
  width: 2rem;
  height: 2rem;
  path {
    stroke: ${({ theme }) => theme.palette.brand.textSecondary};
  }
`;

const StyledSVG = styled(SVG)<{
  $color: string;
  $isChecked: boolean;
  $isSquare: boolean;
}>`
  width: 1.5rem;
  height: 1.5rem;
  overflow: visible;

  ${({ $color, $isChecked, theme }) =>
    css`
      ${$isChecked &&
      css`
        & circle,
        rect,
        g > path {
          stroke: ${$color};
        }
        & circle:nth-child(3),
        rect:nth-child(3) {
          stroke: ${theme.palette.brand.textPrimary};
          stroke-width: 2;

          fill: ${theme.palette.brand.textPrimary};
        }
      `}

      ${!$isChecked &&
      css`
        & circle,
        rect,
        g > path {
          stroke: ${$color};
        }
        & circle:nth-child(3),
        rect:nth-child(3) {
          stroke: none;
          fill: none;
        }
      `}
    `}
`;

export { InitiativesFilterOptionRow };
