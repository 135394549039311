import 'styled-components';

import { createTheme, Shadows, Theme } from '@mui/material/styles';

import { ResourceContract } from '@/features/canvas/api/theme';

import { BRAND_COLORS, BRAND_SHADOWS } from './variables';

declare module '@mui/material/styles' {
  interface Palette {
    brand: Record<keyof typeof BRAND_COLORS, string>;
  }

  interface PaletteOptions {
    brand?: Record<keyof typeof BRAND_COLORS, string>;
  }
}

const originalTheme = createTheme();

let defaultTheme = createTheme({
  palette: {
    brand: {
      ...BRAND_COLORS,
    },
    text: {
      primary: BRAND_COLORS.textPrimary,
    },
  },
  shadows: [
    ...BRAND_SHADOWS,
    ...originalTheme.shadows.slice(0, originalTheme.shadows.length - BRAND_SHADOWS.length),
  ] as Shadows,
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 20,
  },
});

export const theme = (resources?: ResourceContract) => {
  if (resources) {
    defaultTheme = createTheme({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        brand: {
          ...defaultTheme.palette.brand,
          editorTabPrimary:
            resources.palette_primary1 || defaultTheme.palette.brand.corePPTHeadlines,
          editorTabSecondary: resources.palette_secondary1 || defaultTheme.palette.brand.secondary,
          progressBar: resources.palette_primary2 || defaultTheme.palette.brand.progressBar,
          sunray: resources.palette_secondary2 || defaultTheme.palette.brand.sunray,
        },
      },
    });
  }

  return defaultTheme;
};

declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends Theme {}
}
