import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function createRoadmapPublicLink({
  roadmapId,
  newLink,
}: {
  roadmapId: number;
  newLink: components['schemas']['PublicLinkRequest'];
}) {
  const { data } = await defaultApi.post<components['schemas']['PublicLink']>(
    `/public-links/${roadmapId}`,
    [newLink]
  );
  return data;
}
