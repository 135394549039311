import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import {
  getAllRoadmapNodes,
  getInitiativeById,
  getRoadmapsDropdownVisible,
  getRoadmapsPopupVisible,
  getRoadmapsRoadmapVisible,
  importNewRoadmapInitiativeNode,
} from '@/features/canvas/api';
import { FilteredNodes, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useImportInitiativeContext } from '@/features/canvas/contexts/import-initiative-context';
import { useCSVImportedInitiativeDrop } from '@/features/canvas/hooks/useCSVImportedInitiative';
import { FilterOptionValue } from '@/features/canvas/types/filter-option';
import { InitiativeFieldValues, InitiativeToBeImported } from '@/features/canvas/types/initiative';
import { useShowToast } from '@/hooks/useShowToast';

interface UseImportedInitiativeDropProps {
  roadmapId: number;
}

export const useImportedInitiativeDrop = ({ roadmapId }: UseImportedInitiativeDropProps) => {
  const { t } = useTranslation();
  const {
    updateLastSavedTime,
    filteredNodes,
    setFilteredNodes,
    generateUniqueIntTemporaryId,
    setSelectedNodeId,
    temporaryCreatedIds,
  } = useEditorContext();
  const { showToast } = useShowToast();
  const queryClient = useQueryClient();

  const { setIsInitiativeImportRunning, selectedRoadmapId } = useImportInitiativeContext();

  const { mutateAsync: importNewRoadmapInitiativeNodeMutation } = useMutation(
    importNewRoadmapInitiativeNode
  );

  const { onCSVImportedInitiativeDrop } = useCSVImportedInitiativeDrop({ roadmapId });

  const onImportInitiativeDrop = useCallback(
    async ({
      initiative,
      newNodesCanvasXPercentage,
      newNodesCanvasYPercentage,
      sectionId,
      timePeriodId,
      newInitiativeFieldValues,
      newInitiativeFilterValues,
    }: {
      initiative: InitiativeToBeImported;
      newNodesCanvasXPercentage: number;
      newNodesCanvasYPercentage: number;
      sectionId: number;
      timePeriodId: number;
      newInitiativeFieldValues?: InitiativeFieldValues[];
      newInitiativeFilterValues?: FilterOptionValue[];
    }) => {
      setIsInitiativeImportRunning(true);
      try {
        const newNodeTempId = generateUniqueIntTemporaryId();
        const newInitiativeTempId = generateUniqueIntTemporaryId();

        const newRoadmapNode = {
          id: newNodeTempId,
          title: initiative?.title,
          isVisible: true,
          x: newNodesCanvasXPercentage,
          y: newNodesCanvasYPercentage,
          timePeriodId,
          sectionId,
          initiatives: {
            title: initiative?.title,
          },
        };

        const newNodesQueryData = queryClient.setQueryData(
          [getAllRoadmapNodes.name, roadmapId],
          (oldNodes?: FilteredNodes[]) => {
            const newRoadmapNodeWithInitiative = {
              ...{ ...newRoadmapNode, shape: 'circle' as const, color: '' },
              initiatives: {
                id: newInitiativeTempId,
                title: initiative?.title,
              },
            };
            if (oldNodes) {
              const newNodes: FilteredNodes[] = [...oldNodes, newRoadmapNodeWithInitiative];
              setFilteredNodes(newNodes);
              return newNodes;
            }

            setFilteredNodes([newRoadmapNodeWithInitiative] as unknown as typeof filteredNodes);
            return [newRoadmapNodeWithInitiative];
          }
        );

        if (initiative.isImportedFromCSV) {
          await onCSVImportedInitiativeDrop({
            initiative,
            newRoadmapNode,
            newInitiativeFieldValues,
            newInitiativeFilterValues,
          });
        } else {
          const newlyCreatedNode = await importNewRoadmapInitiativeNodeMutation({
            roadmapId,
            initiativeId: initiative.id!,
            nodeInfo: {
              x: newNodesCanvasXPercentage,
              y: newNodesCanvasYPercentage,
              sectionId,
              timePeriodId,
              foreignRoadmapId: selectedRoadmapId!,
            },
          });

          queryClient.setQueryData(
            [getAllRoadmapNodes.name, roadmapId],
            newNodesQueryData.map(n => {
              if (n.id === newNodeTempId) {
                temporaryCreatedIds.delete(newNodeTempId);
                temporaryCreatedIds.delete(newInitiativeTempId);
                return {
                  ...newlyCreatedNode,
                };
              }
              return n;
            })
          );

          await Promise.all([
            queryClient.invalidateQueries([
              getInitiativeById.name,
              newlyCreatedNode.initiatives.id,
            ]),
            queryClient.invalidateQueries([getRoadmapsDropdownVisible.name, selectedRoadmapId]),
            queryClient.invalidateQueries([getRoadmapsRoadmapVisible.name, selectedRoadmapId]),
            queryClient.invalidateQueries([getRoadmapsPopupVisible.name, selectedRoadmapId]),
          ]);
          updateLastSavedTime();
          setSelectedNodeId(newlyCreatedNode.id!);
        }

        await Promise.all([
          queryClient.invalidateQueries([getAllRoadmapNodes.name, roadmapId]),
          queryClient.invalidateQueries([getRoadmapsDropdownVisible.name, roadmapId]),
          queryClient.invalidateQueries([getRoadmapsRoadmapVisible.name, roadmapId]),
          queryClient.invalidateQueries([getRoadmapsPopupVisible.name, roadmapId]),
        ]);
        updateLastSavedTime();
      } catch (error) {
        showToast('error', t('editor.canvas.add_initiative_error'));
        await queryClient.invalidateQueries([getAllRoadmapNodes.name, roadmapId]);
      }
      setIsInitiativeImportRunning(false);
    },
    [
      generateUniqueIntTemporaryId,
      importNewRoadmapInitiativeNodeMutation,
      onCSVImportedInitiativeDrop,
      queryClient,
      roadmapId,
      selectedRoadmapId,
      setFilteredNodes,
      setIsInitiativeImportRunning,
      setSelectedNodeId,
      showToast,
      t,
      temporaryCreatedIds,
      updateLastSavedTime,
    ]
  );

  return {
    onImportInitiativeDrop,
  };
};
