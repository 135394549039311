import { Group, Image, Rect } from 'react-konva';
import { useImage } from 'react-konva-utils';
import { useTheme } from '@mui/material';

import SwimlaneMode from '@/assets/icons/active-swimlane-mode.svg';
import CanvasMode from '@/assets/icons/canvas-mode.svg';
import {
  CANVAS_MODE_BUTTON_HEIGHT,
  CANVAS_MODE_BUTTON_WIDTH,
  CANVAS_MODE_BUTTON_X_OFFSET,
  CANVAS_MODE_BUTTON_Y_OFFSET,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

const CanvasViewModeButton = () => {
  const theme = useTheme();
  const { isSwimlaneView, setIsSwimlaneView } = useEditorContext();

  const [canvasMode] = useImage(CanvasMode);
  const [swimlaneView] = useImage(SwimlaneMode);
  return (
    <Group
      width={CANVAS_MODE_BUTTON_WIDTH}
      height={CANVAS_MODE_BUTTON_HEIGHT}
      onMouseEnter={e => {
        const stage = e.target.getStage();
        if (stage) {
          const container = stage.container();
          container.style.cursor = 'pointer';
        }
      }}
      onMouseLeave={e => {
        const stage = e.target.getStage();
        if (stage) {
          const container = stage.container();
          container.style.cursor = 'default';
        }
      }}
      onClick={() => {
        setIsSwimlaneView(!isSwimlaneView);
      }}
      onTap={() => {
        setIsSwimlaneView(!isSwimlaneView);
      }}
    >
      <Rect
        cornerRadius={[5, 5, 5, 5]}
        fill={theme.palette.brand.white}
        width={CANVAS_MODE_BUTTON_WIDTH}
        height={CANVAS_MODE_BUTTON_HEIGHT}
        shadowColor={
          isSwimlaneView ? theme.palette.brand.backgroundIcon : theme.palette.brand.black
        }
        shadowBlur={5}
        shadowOpacity={0.3}
      />
      <Image
        image={isSwimlaneView ? swimlaneView : canvasMode}
        x={CANVAS_MODE_BUTTON_X_OFFSET}
        y={CANVAS_MODE_BUTTON_Y_OFFSET}
        width={CANVAS_MODE_BUTTON_WIDTH - 2 * CANVAS_MODE_BUTTON_X_OFFSET}
        height={CANVAS_MODE_BUTTON_HEIGHT - 2 * CANVAS_MODE_BUTTON_Y_OFFSET}
      />
    </Group>
  );
};

export { CanvasViewModeButton };
