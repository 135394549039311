import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

import { ReactComponent as PlusCircle } from '@/assets/icons/plus-circle.svg';
import { useAccount } from '@/contexts/account-context';
import { getInitiativeById } from '@/features/canvas/api';
import { InitiativeAccordionContentDeleteFieldDialogs } from '@/features/canvas/components/InitiativesAccordionContent/InitiativeAccordionContentDeleteFieldDialogs';
import { InitiativeAccordionContentDependencies } from '@/features/canvas/components/InitiativesAccordionContent/InitiativeAccordionContentDependencies';
import { InitiativeAccordionContentFilters } from '@/features/canvas/components/InitiativesAccordionContent/InitiativeAccordionContentFilters';
import { InitiativeAccordionContentInitiativeInfo } from '@/features/canvas/components/InitiativesAccordionContent/InitiativeAccordionContentInitiativeInfo';
import { InitiativeAccordionContentInitiativeFieldList } from '@/features/canvas/components/InitiativesAccordionContent/InitiativeAccordionContentInitiativeInfo/InitiativeAccordionContentInitiativeFieldList';
import { InitiativeAccordionContentNewInitiative } from '@/features/canvas/components/InitiativesAccordionContent/InitiativeAccordionContentNewInitiative';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

const InitiativesAccordionContent = ({
  isExpanded,
  setIsBasicDeleteInitiativeDialogOpen,
  setIsDualChoiceDeleteInitiativeDialogOpen,
}: {
  isExpanded: boolean;
  setIsBasicDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDualChoiceDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const account = useAccount();
  const {
    roadmap,
    filteredNodes,
    selectedNodeId,
    isCreatingNewInitiative,
    setIsCreatingNewInitiative,
    roadmapPermissions,
  } = useEditorContext();

  const [isDeletingFromDropdown, setIsDeletingFromDropdown] = useState(false);

  const [selectedCustomFieldId, setSelectedCustomFieldId] = useState<null | number>(null);

  const [isEditingInitiative, setIsEditingInitiative] = useState(false);

  const [expandedAccordionKey, setExpandedAccordionKey] = useState<null | number>(null);
  const [selectedFieldId, setSelectedFieldId] = useState<null | number>(null);
  const [isDeleteFieldDialogOpen, setIsDeleteFieldDialogOpen] = useState(false);
  const [isDeleteCustomFieldDialogOpen, setIsDeleteCustomFieldDialogOpen] = useState(false);

  const { data: initiative, isLoading: isInitiativeLoading } = useQuery(
    [getInitiativeById.name, selectedNodeId],
    () => {
      const node = filteredNodes.find(n => n.id === selectedNodeId)!;
      const initiativeId = node.initiatives?.id;

      if (!initiativeId) return;
      return getInitiativeById({ id: initiativeId });
    },
    {
      cacheTime: 0,
      enabled: Boolean(selectedNodeId),
    }
  );

  const isRoadmapOwner = Boolean(account?.id === roadmap.createdById);

  const isRoadmapEditor = Boolean(
    account?.id &&
      roadmapPermissions.find(p => p.userId === account?.id && p.permissionType === 'roadmap_edit')
  );

  return (
    <ContentWrapper $isExpanded={isExpanded}>
      {!isCreatingNewInitiative && (
        <StyledButton
          fullWidth
          variant="text"
          sx={{
            gap: '0 !important',
            justifyContent: 'flex-start !important',
          }}
          startIcon={<PlusCircle />}
          onClick={() => {
            setIsCreatingNewInitiative(true);
            setIsEditingInitiative(true);
          }}
        >
          <ButtonText>{t('editor.sidebar.add_initiative')}</ButtonText>
        </StyledButton>
      )}
      {isCreatingNewInitiative && (
        <InitiativeAccordionContentNewInitiative
          isEditingInitiative={isEditingInitiative}
          setIsEditingInitiative={setIsEditingInitiative}
        />
      )}
      <InitiativeAccordionContentInitiativeInfo isInitiativeLoading={isInitiativeLoading} />
      <InitiativeAccordionContentInitiativeFieldList
        expandedAccordionKey={expandedAccordionKey}
        initiative={initiative}
        isInitiativeLoading={isInitiativeLoading}
        isRoadmapOwner={isRoadmapOwner}
        isRoadmapEditor={isRoadmapEditor}
        setExpandedAccordionKey={setExpandedAccordionKey}
        setIsDeleteCustomFieldDialogOpen={setIsDeleteCustomFieldDialogOpen}
        setIsDeleteFieldDialogOpen={setIsDeleteFieldDialogOpen}
        setSelectedFieldId={setSelectedFieldId}
        setSelectedCustomFieldId={setSelectedCustomFieldId}
        setIsDeletingFromDropdown={setIsDeletingFromDropdown}
        setIsBasicDeleteInitiativeDialogOpen={setIsBasicDeleteInitiativeDialogOpen}
        setIsDualChoiceDeleteInitiativeDialogOpen={setIsDualChoiceDeleteInitiativeDialogOpen}
      />
      <InitiativeAccordionContentFilters
        expandedAccordionKey={expandedAccordionKey}
        initiative={initiative}
        isInitiativeLoading={isInitiativeLoading}
        setExpandedAccordionKey={setExpandedAccordionKey}
      />
      {selectedNodeId && !isInitiativeLoading && (isRoadmapOwner || isRoadmapEditor) && (
        <InitiativeAccordionContentDependencies />
      )}
      <InitiativeAccordionContentDeleteFieldDialogs
        isDeleteFieldDialogOpen={isDeleteFieldDialogOpen}
        isDeletingFromDropdown={isDeletingFromDropdown}
        customFieldId={selectedCustomFieldId}
        isDeleteCustomFieldDialogOpen={isDeleteCustomFieldDialogOpen}
        onDualChoiceClose={() => {
          setIsDeleteCustomFieldDialogOpen(false);
          setSelectedCustomFieldId(null);
        }}
        onFieldDeleteClose={() => {
          setIsDeletingFromDropdown(false);
          setIsDeleteFieldDialogOpen(false);
        }}
        selectedFieldId={selectedFieldId}
        initiative={initiative!}
      />
    </ContentWrapper>
  );
};

const StyledButton = styled(Button)`
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  font-size: 1.25rem;
  &:hover {
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  }
`;

const ButtonText = styled.div`
  display: flex;
  align-items: center;
`;
const ContentWrapper = styled.div<{ $isExpanded: boolean }>`
  padding: 1.5rem 1rem;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      overflow-y: auto;
      height: 100%;
      animation: hide-scroll 0.3s backwards;
    `}
  @keyframes hide-scroll {
    from,
    to {
      overflow: hidden;
    }
  }
`;

export { InitiativesAccordionContent };
