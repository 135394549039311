import styled, { DefaultTheme } from 'styled-components';

export type Variant = 'primary' | 'secondary' | 'tertiary' | 'action';

export type StyledButtonProps = {
  variant?: Variant;
};

const getBackgroundColor = (theme: DefaultTheme, variant?: Variant) => {
  switch (variant) {
    case 'primary':
      return theme.palette.brand.white;
    case 'secondary':
      return theme.palette.brand.secondary;
    case 'tertiary':
      return theme.palette.brand.tertiary;
    case 'action':
      return theme.palette.brand.primary;
    default:
      return theme.palette.brand.white;
  }
};

const getHoverColor = (theme: DefaultTheme, variant?: Variant) => {
  switch (variant) {
    case 'primary':
      return theme.palette.brand.buttonPrimaryHover;
    case 'secondary':
      return theme.palette.brand.buttonSecondaryHover;
    case 'tertiary':
      return theme.palette.brand.buttonTertiaryHover;
    case 'action':
      return theme.palette.brand.secondary;
    default:
      return theme.palette.brand.buttonPrimaryHover;
  }
};

const getTextColor = (theme: DefaultTheme, variant?: Variant) => {
  switch (variant) {
    case 'primary':
      return theme.palette.brand.textPrimary;
    case 'secondary':
    case 'tertiary':
    case 'action':
      return theme.palette.brand.white;
    default:
      return theme.palette.brand.textPrimary;
  }
};

const StyledButton = styled.button<StyledButtonProps>`
  border-radius: 4rem;
  padding: 0.5rem 1.4rem 0.8rem;
  box-shadow: ${({ theme }) => theme.shadows[0]};
  font-weight: 500;
  font-size: 2rem;
  transition: background-color 0.2s ease-in-out;
  display: inline-flex;
  width: fit-content;

  svg {
    display: inline;
    margin: auto;
    margin-right: 1rem;
  }

  span {
    margin: auto;
  }

  background-color: ${({ theme, variant }) => getBackgroundColor(theme, variant)};
  color: ${({ theme, variant }) => getTextColor(theme, variant)};

  &:hover {
    background-color: ${({ theme, variant }) => getHoverColor(theme, variant)};
  }
`;

export default StyledButton;
