import { HorizontalSection } from '@/features/canvas/components/HorizontalSection';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { getTabSizeProportion } from '@/features/canvas/utils/get-tab-size-proportion';

type Props = {
  canvasHeightToFit: number;
  canvasWidthToFit: number;
};

const HorizontalSections = ({ canvasWidthToFit, canvasHeightToFit }: Props) => {
  const { sortedHorizontalSections } = useEditorContext();

  const horizontalSectionsTabWidths = sortedHorizontalSections.map(({ tabWidth }) => tabWidth);
  const horizontalSectionsTabScaleProportion = getTabSizeProportion({
    sizeToFit: canvasWidthToFit,
    tabSizes: horizontalSectionsTabWidths,
  });
  const scaleAdjustedHorizontalSectionsTabWidths = horizontalSectionsTabWidths.map(
    tabWidth => tabWidth * horizontalSectionsTabScaleProportion
  );

  return (
    <>
      {sortedHorizontalSections.map(({ id, title, order, orientation }, index) => (
        <HorizontalSection
          key={id}
          sectionId={id!}
          sectionTitle={title || ''}
          sectionOrder={order}
          sectionOrientation={orientation}
          cornerRadius={[0, 0, 10, 10]}
          canvasWidth={canvasWidthToFit}
          canvasHeight={canvasHeightToFit}
          sectionIndex={index}
          horizontalSectionTabScaleProportion={horizontalSectionsTabScaleProportion}
          scaledHorizontalSectionTabWidths={scaleAdjustedHorizontalSectionsTabWidths}
        />
      ))}
    </>
  );
};

export { HorizontalSections };
