import { ConcreteFilter } from '@/features/canvas/components/FiltersAccordionContent/ConcreteFilter';
import { FilterTypes } from '@/features/canvas/types/filter';
import { generateTemporaryNumberId } from '@/features/canvas/utils/generate-temporary-number-id';

class FilterFactory {
  private static tempIds: Set<number> = new Set();

  static createCustomSingleFilter(): ConcreteFilter {
    return new ConcreteFilter(FilterTypes.CUSTOM_SINGLE, 'single', 'circle', true, []);
  }

  static createCustomMultiFilter(): ConcreteFilter {
    return new ConcreteFilter(FilterTypes.CUSTOM_MULTI, 'multiple', 'circle', true, []);
  }

  static createPriorityFilter(): ConcreteFilter {
    return new ConcreteFilter(FilterTypes.PRIORITY, 'single', 'circle', true, [
      { id: FilterFactory.generateId(), title: 'High', color: '#FF4747' },
      { id: FilterFactory.generateId(), title: 'Medium', color: '#FFB300' },
      { id: FilterFactory.generateId(), title: 'Low', color: '#2FA100' },
    ]);
  }

  static createRAGFilter(): ConcreteFilter {
    return new ConcreteFilter(FilterTypes.RAG, 'single', 'circle', true, [
      { id: FilterFactory.generateId(), title: 'Red', color: '#FF4747' },
      { id: FilterFactory.generateId(), title: 'Amber', color: '#FFB300' },
      { id: FilterFactory.generateId(), title: 'Green', color: '#2FA100' },
    ]);
  }

  static createProgressStatusFilter(): ConcreteFilter {
    return new ConcreteFilter(FilterTypes.PROGRESS_STATUS, 'single', 'circle', true, [
      { id: FilterFactory.generateId(), title: 'Not Started', color: '#FFA800' },
      { id: FilterFactory.generateId(), title: 'In Progress', color: '#1075D2' },
      { id: FilterFactory.generateId(), title: 'Paused', color: '#707070' },
      { id: FilterFactory.generateId(), title: 'Completed', color: '#2FA100' },
    ]);
  }

  private static generateId(): number {
    const newId = generateTemporaryNumberId({ createdIds: FilterFactory.tempIds });
    FilterFactory.tempIds.add(newId);
    return newId;
  }
}

export { FilterFactory };
