const routes = {
  createPassword: '/create-new-password/:token',
  home: '/',
  dashboardTest: '/dashboard-test',
  forgotPassword: '/forgot-password',
  login: '/login',
  roadmaps: '/roadmaps',
  support: '/support',
  publicLinks: '/public-links',
  ui: '/ui',
  canvas: '/canvas',
  account: '/account',
  roadmap: '/roadmap',
  view: '/view',
  timelineEditor: '/timeline-editor',
};

const maintenanceRoutes = {
  maintenance: '/maintenance',
};
export default routes;
export { maintenanceRoutes };
