import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InitiativeFieldTitles,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';
import { InitiativeFieldOption } from '@/features/canvas/types/initiative';

export const useInitialFieldOptions = () => {
  const { t } = useTranslation();

  const selectFieldOptionsInit = useMemo(
    () => [
      {
        id: InitiativeFieldTitles.budget,
        title: t('editor.sidebar.initiative.field_option_budget'),
        type: InitiativeFieldTypes.budget,
      },
      {
        id: InitiativeFieldTitles.progressBar,
        title: t('editor.sidebar.initiative.field_option_progressBar'),
        type: InitiativeFieldTypes.progressBar,
      },
      {
        id: InitiativeFieldTitles.startDate,
        title: t('editor.sidebar.initiative.field_option_startDate'),
        type: InitiativeFieldTypes.date,
      },
      {
        id: InitiativeFieldTitles.endDate,
        title: t('editor.sidebar.initiative.field_option_endDate'),
        type: InitiativeFieldTypes.date,
      },
      {
        id: InitiativeFieldTitles.customSmall,
        title: t('editor.sidebar.initiative.field_option_small_custom'),
        type: InitiativeFieldTypes.small,
      },
      {
        id: InitiativeFieldTitles.customBig,
        title: t('editor.sidebar.initiative.field_option_big_custom'),
        type: InitiativeFieldTypes.big,
      },
    ],
    [t]
  ) as InitiativeFieldOption[];

  return { selectFieldOptionsInit };
};
