import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getRoadmapsRoadmapVisible({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get<components['schemas']['RoadmapVisible'][]>(
    `/roadmap-visible/roadmap/${roadmapId}`
  );
  return data;
}

export async function createRoadmapsRoadmapVisible({
  roadmapId,
  newRoadmapVisible,
}: {
  roadmapId: number;
  newRoadmapVisible: components['schemas']['RoadmapVisible'];
}) {
  const roadmapVisibleInArray = [newRoadmapVisible];
  const { data } = await defaultApi.post<components['schemas']['RoadmapVisible']>(
    `/roadmap-visible/${roadmapId}`,
    roadmapVisibleInArray
  );
  return data;
}

export async function deleteRoadmapsRoadmapVisible({ roadmapId }: { roadmapId: number }) {
  return await defaultApi.delete(`/roadmap-visible/${roadmapId}`);
}
