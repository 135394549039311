import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function getRoadmapNodeById({ nodeId }: { nodeId: number }) {
  const { data } = await defaultApi.get<components['schemas']['Node']>(`/nodes/${nodeId}`);
  return data;
}

export async function updateRoadmapNodeById({
  nodeId,
  nodeBody,
}: {
  nodeId: number;
  nodeBody: components['schemas']['Node'];
}) {
  const nodeInArray = [nodeBody];

  const { data } = await defaultApi.put<components['schemas']['Node']>(
    `/nodes/${nodeId}`,
    nodeInArray
  );
  return data;
}

export async function createRoadmapNode({
  roadmapId,
  newNode,
}: {
  roadmapId: number;
  newNode: components['schemas']['Node'];
}) {
  const nodeInArray = [newNode];
  const { data } = await defaultApi.post<components['schemas']['Node']>(
    `/nodes/${roadmapId}`,
    nodeInArray
  );
  return data;
}
export async function deleteNode({ id }: { id: number }) {
  await defaultApi.delete(`/nodes/${id}`);
}

export async function getAllRoadmapNodes({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get<components['schemas']['Node'][]>(
    `/nodes/roadmap/${roadmapId}`
  );
  return data;
}

export async function importNewRoadmapInitiativeNode({
  roadmapId,
  initiativeId,
  nodeInfo,
}: {
  roadmapId: number;
  initiativeId: number;
  nodeInfo: {
    x: number;
    y: number;
    sectionId: number;
    timePeriodId: number;
    foreignRoadmapId: number;
  };
}) {
  const { data } = await defaultApi.post<components['schemas']['Node']>(
    `/nodes/${roadmapId}/initiative/${initiativeId}`,
    [nodeInfo]
  );
  return data;
}

export async function updateNodesSectionAndTimePeriod({
  roadmapId,
  nodes,
}: {
  roadmapId: number;
  nodes: components['schemas']['NodeUpdate'][];
}) {
  const { data } = await defaultApi.put<components['schemas']['NodeUpdate'][]>(
    `/nodes/${roadmapId}/update`,
    nodes
  );
  return data;
}

export async function csvImportNewNode({
  roadmapId,
  csvNode,
}: {
  roadmapId: number;
  csvNode: components['schemas']['NodeImportCSV'];
}) {
  const { data } = await defaultApi.post<components['schemas']['NodeImportCSV']>(
    `/nodes/import/${roadmapId}`,
    [csvNode]
  );
  return data;
}

export async function handleNodeVisibility({
  nodeId,
  isTimelineVisible,
}: {
  nodeId: number;
  isTimelineVisible: boolean;
}) {
  const { data } = await defaultApi.put<{ nodeId: number; isTimelineVisible: boolean }>(
    `/nodes/${nodeId}/timeline/hide`,
    {
      isTimelineVisible,
    }
  );
  return data;
}
