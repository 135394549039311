import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { darken, transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { Form } from '@/features/ui/components/Form';
import { FormTextInput } from '@/features/ui/components/FormTextInput';

type Props = {
  isEditingInitiative: boolean;
  setIsEditingInitiative: (isEditingInitiative: boolean) => void;
};

interface NewInitiativeFormState {
  title: string;
}

const InitiativeAccordionContentNewInitiative = ({
  isEditingInitiative,
  setIsEditingInitiative,
}: Props) => {
  const [newInitiativeTitle, setNewInitiativeTitle] = useState('');
  const newInitiativeRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  return (
    <NewInitiative
      $isEditingInitiative={isEditingInitiative}
      draggable={!isEditingInitiative}
      ref={newInitiativeRef}
    >
      <NewInitiativeContent>
        {isEditingInitiative && (
          <Form<NewInitiativeFormState>
            onSubmit={formData => {
              setNewInitiativeTitle(formData.title);
              setIsEditingInitiative(false);
            }}
          >
            <EditingMainContentWrapper>
              <NewInitiativeDot />
              <InputWrapper>
                <NewInitiativeTitleInput
                  name="title"
                  fullWidth
                  inputProps={{
                    'aria-label': t('editor.sidebar.add_initiative_title'),
                    maxLength: 32,
                  }}
                  placeholder={t('editor.sidebar.add_initiative_title')}
                  errorInputProps={{
                    fontSize: '1rem',
                  }}
                  required
                />
              </InputWrapper>
            </EditingMainContentWrapper>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledSaveButton
                sx={{ minWidth: 'fit-content', marginTop: '0.25rem' }}
                variant="text"
                type="submit"
              >
                {t('save')}
              </StyledSaveButton>
            </Box>
          </Form>
        )}
        {!isEditingInitiative && (
          <>
            <EditingMainContentWrapper>
              <NewInitiativeDot />
              <NewInitiativeTitle id="new-initiative-title">
                {newInitiativeTitle}
              </NewInitiativeTitle>
            </EditingMainContentWrapper>
            <NewInitiativeDescription>
              {t('editor.sidebar.drag_initiative')}
            </NewInitiativeDescription>
          </>
        )}
      </NewInitiativeContent>
    </NewInitiative>
  );
};
const NewInitiativeContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
`;

const NewInitiativeTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

const NewInitiativeDescription = styled.div`
  margin-left: 3rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.brand.secondary};
`;

const EditingMainContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.25rem;
`;

const InputWrapper = styled.div`
  flex: 1;
`;

const StyledSaveButton = styled(Button)`
  border-radius: 1rem;
  font-size: 1rem;
  padding: 0 1rem;
  font-size: 1rem;
  padding: 0 1rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.palette.brand.backgroundDialog)};
  }
`;

const NewInitiativeTitleInput = styled(FormTextInput)`
  height: 2.5rem;
  border: 1px solid ${({ theme }) => transparentize(0.7, theme.palette.brand.sunray)};
  font-size: 1.5rem;
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  background-color: ${({ theme }) => theme.palette.brand.white};
  margin: 0;
`;

const NewInitiativeDot = styled.div`
  background-color: ${({ theme }) => theme.palette.brand.editorTabSecondary};
  margin-top: 2px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  flex: 0 0 2rem;
`;

const NewInitiative = styled.div<{ $isEditingInitiative: boolean }>`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  ${({ $isEditingInitiative, theme }) =>
    !$isEditingInitiative &&
    css`
      cursor: grab;
      &:hover {
        background-color: ${theme.palette.brand.backgroundDialog};
      }
    `}
  padding: 1rem;
  border-radius: 1rem;
`;

export { InitiativeAccordionContentNewInitiative };
