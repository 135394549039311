import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

import { SwimlaneViewButton } from '@/features/canvas/components/SwimlaneViewButton';
import { SwimlaneViewSection } from '@/features/canvas/components/SwimlaneViewSection';
import { SwimlaneViewTimePeriod } from '@/features/canvas/components/SwimlaneViewTimePeriod';
import { TAB_HEIGHT } from '@/features/canvas/constants';
import { InitiativeFieldTypes } from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { SectionSwimlaneNode } from '@/features/canvas/types/swimlane-node';

const SwimlaneViewGrid = () => {
  const {
    sortedTimePeriods,
    sortedHorizontalSections,
    sortedVerticalSections,
    filteredNodes,
    filters,
    activeFilterId,
    optionCheckedId,
  } = useEditorContext();

  const [areNodesDistributed, setAreNodesDistributed] = useState(false);

  const [timePeriodDistributedNodesMap, setTimePeriodDistributedNodesMap] = useState<
    Map<number, SectionSwimlaneNode | undefined>
  >(new Map<number, SectionSwimlaneNode | undefined>());

  useEffect(() => {
    const newTimePeriodDistributedNodes = sortedTimePeriods.reduce((map, timePeriod) => {
      map.set(timePeriod.id!, undefined);
      return map;
    }, new Map<number, SectionSwimlaneNode | undefined>());

    filteredNodes
      .filter(n => Boolean(n.isVisible))
      .forEach(node => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const timePeriodId = node.timePeriodId! || node.timeperiodId!;
        const sectionId = node.sectionId!;

        const nodeHasActiveFilter = filters
          ?.find(f => f.id === activeFilterId)
          ?.nodes?.find(n => n.nodeId === node.id);

        const isNodeOptionChecked = nodeHasActiveFilter?.optionId === optionCheckedId;

        const progressValue =
          node.initiatives?.progressField ||
          node.initiatives.fields?.find(f => f.type === InitiativeFieldTypes.progressBar)?.value ||
          '0';

        const augmentedNode = {
          nodeId: node.id!,
          color: node.color,
          progress: Number(progressValue),
          title: node.initiatives?.title,
          isNodeOptionChecked,
          nodeHasActiveFilter: Boolean(nodeHasActiveFilter),
        };

        const timePeriodNodes = newTimePeriodDistributedNodes.get(timePeriodId);

        if (timePeriodNodes) {
          const sectionNodes = timePeriodNodes[sectionId];
          if (sectionNodes) {
            sectionNodes.push(augmentedNode);
          } else {
            timePeriodNodes[sectionId] = [augmentedNode];
          }
        }
        if (!timePeriodNodes) {
          newTimePeriodDistributedNodes.set(timePeriodId, { [sectionId]: [augmentedNode] });
        }
      });
    setTimePeriodDistributedNodesMap(newTimePeriodDistributedNodes);
    setAreNodesDistributed(true);
  }, [filteredNodes, filters, optionCheckedId, activeFilterId, sortedTimePeriods]);

  if (!areNodesDistributed) {
    return (
      <LoaderWrapper>
        <StyledLoader />
      </LoaderWrapper>
    );
  }

  return (
    <div style={{ height: 'calc(100% - 60px)' }}>
      <TimePeriodContainer>
        <Grid $columns={sortedTimePeriods.map(stp => stp.tabWidth)}>
          <SwimlaneViewButton />
          {sortedTimePeriods.map((tp, index) => (
            <SwimlaneViewTimePeriod key={tp.id} index={index} title={tp.title} />
          ))}
        </Grid>
      </TimePeriodContainer>
      <Grid $columns={sortedTimePeriods.map(stp => stp.tabWidth)}>
        {[...sortedVerticalSections, ...sortedHorizontalSections].map((s, index) => (
          <SwimlaneViewSection
            isLast={index === [...sortedHorizontalSections, ...sortedVerticalSections].length - 1}
            isOdd={index % 2 === 0}
            sectionId={s.id!}
            key={s.id}
            title={s.title}
            timePeriodDistributedNodesMap={timePeriodDistributedNodesMap}
          />
        ))}
      </Grid>
    </div>
  );
};

const TimePeriodContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.palette.brand.backgroundMain};
`;

const Grid = styled.div<{ $columns: number[] }>`
  display: grid;
  overflow-y: auto;
  height: 100%;
  grid-template-columns: ${TAB_HEIGHT}px ${({ $columns }) => $columns.map(c => `${c}fr`).join(' ')};
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { SwimlaneViewGrid };
