import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';

import EyePasswordImgSrc from '@/assets/icons/eye.svg';
import EyePasswordCrossedSrc from '@/assets/icons/eye-crossed.svg';
import {
  createRoadmapsPopupVisible,
  deleteRoadmapsPopupVisible,
  getRoadmapsPopupVisible,
} from '@/features/canvas/api';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { components } from '@/types/api';

const HideFieldIcon = ({
  initiativeField,
}: {
  initiativeField: components['schemas']['InitiativeField'];
}) => {
  const { popupVisible, roadmap } = useEditorContext();
  const [visible, setVisible] = useState<boolean>();

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    const isFieldVisible = popupVisible.some(
      f => f.title === initiativeField.title && f.type === initiativeField.type
    );
    setVisible(isFieldVisible);
  }, [initiativeField.title, initiativeField.type, popupVisible]);

  const handleVisibility = async () => {
    const field = popupVisible.find(
      f => f.title === initiativeField.title && f.type === initiativeField.type
    );
    const isFieldVisible = !!field;

    if (isFieldVisible) {
      await deleteRoadmapsPopupVisible({ roadmapId: field.id! });
    } else {
      await createRoadmapsPopupVisible({
        roadmapId: roadmap.id!,
        newPopupVisible: {
          id: initiativeField.id,
          title: initiativeField.title!,
          type: initiativeField.type,
        },
      });
    }

    await queryClient.invalidateQueries([getRoadmapsPopupVisible.name, roadmap.id]);
    setVisible(!visible);
  };

  return (
    <IconButton aria-label="toggle-hide" size="small" onClick={handleVisibility}>
      <Tooltip
        title={
          visible
            ? t('editor.sidebar.initiative.hide_field')
            : t('editor.sidebar.initiative.show_field')
        }
        placement="top"
      >
        <StyledImage src={visible ? EyePasswordImgSrc : EyePasswordCrossedSrc} />
      </Tooltip>
    </IconButton>
  );
};

export { HideFieldIcon };

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${({ theme }) => theme.palette.brand.textSecondaryFilter};
  &:hover {
    filter: ${({ theme }) => theme.palette.brand.buttonPrimaryFilterHover};
  }
`;
