import { useMemo } from 'react';

import { InitiativeFieldTypes } from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useInitialFieldOptions } from '@/features/canvas/hooks/useInitialFieldOptions';

export const useReservedProperty = () => {
  const { roadmapVisible, dropdownVisible } = useEditorContext();
  const { selectFieldOptionsInit } = useInitialFieldOptions();

  const reservedProperties = useMemo(() => {
    const roadmapVisibilityValidationProperties = roadmapVisible.map(rv => ({
      title: rv.title,
      type: rv.type,
    }));
    const dropdownVisibilityValidationProperties = dropdownVisible.map(dv => ({
      title: dv.title,
      type: dv.type,
    }));
    const reservedFieldsValidationProperties = selectFieldOptionsInit.map(sfo => ({
      title: sfo.title,
      type: sfo.type,
    }));

    const reservedValidationProperties = [
      ...reservedFieldsValidationProperties,
      ...roadmapVisibilityValidationProperties,
      ...dropdownVisibilityValidationProperties,
    ];

    const filteredProperties = reservedValidationProperties.filter(
      (property, index, array) =>
        !array.slice(0, index).some(p => p.title === property.title && p.type === property.type)
    );
    return filteredProperties as { title: string; type: keyof typeof InitiativeFieldTypes }[];
  }, [roadmapVisible, dropdownVisible, selectFieldOptionsInit]);

  return {
    reservedProperties,
  };
};
