import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useLogout from 'src/features/authentication/api/useLogout';
import Button from 'src/features/ui/components/Button/Button';

const DashboardPage = () => {
  const { t } = useTranslation();
  const { mutateAsync: onLogout } = useLogout();
  const navigate = useNavigate();

  const navigateToUi = useCallback(() => {
    navigate('/ui');
  }, [navigate]);

  const navigatePublicLinks = useCallback(() => {
    navigate('/public-links');
  }, [navigate]);

  const navigateToRoadmaps = useCallback(() => {
    navigate('/roadmaps');
  }, [navigate]);

  const navigateToSupport = useCallback(() => {
    navigate('/support');
  }, [navigate]);

  return (
    <main>
      <h1>{t('dashboard')}</h1>

      <Button label={t('roadmaps.title')} variant="primary" onClick={navigateToRoadmaps} />
      <Button label={t('support.title')} variant="primary" onClick={navigateToSupport} />
      <Button label={t('public_links.title')} variant="primary" onClick={navigatePublicLinks} />
      <Button label={t('ui.library')} variant="primary" onClick={navigateToUi} />
      <Button label={t('logout')} variant="primary" onClick={() => onLogout()} />
    </main>
  );
};

export default DashboardPage;
