import styled from 'styled-components';

export type Size = 'small' | 'medium' | 'large';

export type StyledTextProps = {
  color?: string;
  size?: Size;
};

const getSize = (size?: Size) => {
  switch (size) {
    case 'small':
      return '1.4rem';
    case 'medium':
      return '2rem';
    case 'large':
      return '2.5rem';
    default:
      return '1.4rem';
  }
};

const getWeight = (size?: Size) => {
  switch (size) {
    case 'small':
      return 400;
    case 'medium':
      return 600;
    case 'large':
      return 600;
    default:
      return 400;
  }
};

const StyledText = styled.p<StyledTextProps>`
  font-size: ${({ size }) => getSize(size)};
  font-weight: ${({ size }) => getWeight(size)};
  color: ${({ color, theme }) => color ?? theme.palette.brand.textPrimary};
`;

export default StyledText;
