export const isRouteActive = ({
  currentRoute,
  targetRoute,
  shouldBeExact = false,
}: {
  currentRoute: string;
  targetRoute: string;
  shouldBeExact?: boolean;
}) => {
  if (shouldBeExact) {
    return currentRoute === targetRoute;
  }
  return currentRoute.startsWith(targetRoute);
};
