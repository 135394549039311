/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Image, Rect } from 'react-konva';
import { useImage } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { KonvaEventObject } from 'konva/lib/Node';
import { Vector2d } from 'konva/lib/types';
import { useTheme } from 'styled-components';

import TickIcon from '@/assets/icons/tick.svg';
import {
  createDependency,
  deleteDependency,
  getAllRoadmapNodes,
  getInitiativeById,
  updateRoadmapNodeById,
} from '@/features/canvas/api';
import { NodeDependencies } from '@/features/canvas/components/NodeDependencies/NodeDependencies';
import { NodeShape } from '@/features/canvas/components/NodeShape';
import {
  HORIZONTAL_OFFSET,
  NODE_RADIUS,
  SIDEBAR_ACCORDION_KEY_MAP,
  VERTICAL_OFFSET,
} from '@/features/canvas/constants';
import { FilteredNodes, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { calculateAbsolutePositionOnCanvasFromPercentageToPx } from '@/features/canvas/utils/calculate-absolute-position-on-canvas-in-px';
import { getIntersectionSectionAndTimePeriodId } from '@/features/canvas/utils/get-intersection-section-and-time-period-id';
import { useShowToast } from '@/hooks/useShowToast';

const CanvasNodes = ({
  canvasWidthToFit,
  canvasHeightToFit,
}: {
  canvasHeightToFit: number;
  canvasWidthToFit: number;
}) => {
  const {
    roadmap,
    setSelectedNodeId,
    isPreview,
    filteredNodes,
    activeFilterId,
    filters,
    optionCheckedId,
    selectedNodeId,
    setExpandedSidebarAccordionKey,
    setIsInitiativeDetailDialogOpen,
    isPublicView,
    setFilteredNodes,
    canvasAreaRef,
    stageRef,
    canvasAreaOffset,
    showDependencies,
    isSelectingDependencies,
    setIsSelectingDependencies,
    filteredNodesRef,
    updateLastSavedTime,
  } = useEditorContext();
  const { addHistoryItem, updateResourceId } = useUndoRedo();
  const [tick] = useImage(TickIcon);

  const queryClient = useQueryClient();
  const theme = useTheme();
  const { t } = useTranslation();
  const { showToast } = useShowToast();

  const isView = isPreview || isPublicView;

  const { mutateAsync: createNodeDependency } = useMutation(createDependency, {
    onSuccess: ({ masterNodeId, slaveNodeId }) => {
      const newFilteredNodes = filteredNodesRef.current.map(n => {
        if (n.id === slaveNodeId) {
          return {
            ...n,
            dependencies: [
              ...(n.dependencies || []),
              {
                masterNodeId,
                slaveNodeId,
              },
            ],
          };
        }
        return n;
      });

      updateLastSavedTime();
      setFilteredNodes(newFilteredNodes as FilteredNodes[]);
    },
    onError: () => {
      showToast('error', t('editor.canvas.dependency_creation_error'));
    },
  });

  const { mutateAsync: deleteNodeDependency } = useMutation(deleteDependency, {
    onSuccess: (_, { slaveNodeId, masterNodeId }) => {
      const newFilteredNodes = filteredNodesRef.current.map(n => {
        if (n.id === slaveNodeId) {
          return {
            ...n,
            dependencies: n.dependencies?.filter(
              d => d.masterNodeId !== masterNodeId || d.slaveNodeId !== slaveNodeId
            ),
          };
        }
        return n;
      });
      setFilteredNodes(newFilteredNodes as FilteredNodes[]);
    },
    onError: () => {
      showToast('error', t('editor.canvas.dependency_deletion_error'));
    },
  });

  const { mutateAsync: updateNodeById } = useMutation(updateRoadmapNodeById, {
    onMutate: ({ nodeId, nodeBody }) => {
      queryClient.setQueryData([getAllRoadmapNodes.name, Number(roadmap.id)], () => {
        updateLastSavedTime();
        const updatedNodes = filteredNodes.map(n => {
          if (n.id === nodeId) {
            return {
              ...n,
              ...nodeBody,
            };
          }
          return n;
        });

        return updatedNodes;
      });
    },
    onSuccess: () => updateLastSavedTime(),
  });

  const openNodeDialog = useCallback(
    ({ id }: { id: number }) => {
      setSelectedNodeId(id);
      setIsInitiativeDetailDialogOpen(true);
      if (isPublicView) {
        queryClient.setQueryData([getInitiativeById.name, id], () => {
          const initiative = filteredNodes.find(n => n.id === id)?.initiatives;
          return initiative;
        });
      }
    },
    [filteredNodes, isPublicView, queryClient, setIsInitiativeDetailDialogOpen, setSelectedNodeId]
  );

  const onClick = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      const id = e.target.parent?.id();
      const node = filteredNodes.find(n => n.id === Number(id))!;
      if (!node.initiatives) return;

      setSelectedNodeId(node.id === selectedNodeId ? null : node.id!);
      setExpandedSidebarAccordionKey(SIDEBAR_ACCORDION_KEY_MAP.initiatives);
      setIsSelectingDependencies(false);
      if (isView) {
        openNodeDialog({ id: node.id! });
      }
    },

    [
      filteredNodes,
      isView,
      openNodeDialog,
      selectedNodeId,
      setExpandedSidebarAccordionKey,
      setIsSelectingDependencies,
      setSelectedNodeId,
    ]
  );

  const onDependencyClick = useCallback(
    async ({
      evt,
      slaveNodeId,
      dependencyExist,
    }: {
      dependencyExist: boolean;
      evt: KonvaEventObject<MouseEvent>;
      slaveNodeId?: number;
    }) => {
      if (!selectedNodeId) return;

      const masterNodeId = Number(evt.target.parent?.id());

      if (!dependencyExist) {
        const newlyCreatedDependency = await createNodeDependency({
          masterNodeId,
          slaveNodeId: selectedNodeId,
        });

        addHistoryItem({
          type: HistoryActionType.AddDependency,
          undo: async (resourceIds?: number[]) => {
            await deleteNodeDependency({
              masterNodeId: resourceIds?.[1] || newlyCreatedDependency.masterNodeId!,
              slaveNodeId: resourceIds?.[0]! || newlyCreatedDependency.slaveNodeId,
            });
          },
          async redo(redoResourceIds?: number[]) {
            const redoCreatedDependency = await createNodeDependency({
              masterNodeId: redoResourceIds?.[1] || masterNodeId,
              slaveNodeId: redoResourceIds?.[0] || slaveNodeId || selectedNodeId,
            });
            updateResourceId(
              this.type,
              redoResourceIds?.[1] || newlyCreatedDependency.masterNodeId!,
              redoCreatedDependency.slaveNodeId!
            );
            this.undo = async (resourceIds?: number[]) => {
              await deleteNodeDependency({
                masterNodeId: resourceIds?.[1] || redoCreatedDependency.masterNodeId!,
                slaveNodeId: resourceIds?.[0]!,
              });
            };
            this.resourceIds = [
              redoResourceIds?.[0] || slaveNodeId || selectedNodeId,
              redoResourceIds?.[1] || newlyCreatedDependency.masterNodeId!,
            ];
          },
          resourceIds: [slaveNodeId || selectedNodeId, newlyCreatedDependency.masterNodeId!],
        });

        return;
      }
      await deleteNodeDependency({ masterNodeId, slaveNodeId: slaveNodeId || selectedNodeId });
      addHistoryItem({
        type: HistoryActionType.DeleteDependency,
        async undo(resourceIds?: number[]) {
          const createdDependency = await createNodeDependency({
            masterNodeId: resourceIds?.[1] || masterNodeId,
            slaveNodeId: resourceIds?.[0] || slaveNodeId || selectedNodeId,
          });
          this.redo = async (redoResourceIds?: number[]) => {
            await deleteNodeDependency({
              masterNodeId: redoResourceIds?.[1] || createdDependency.masterNodeId!,
              slaveNodeId: redoResourceIds?.[0] || createdDependency.slaveNodeId,
            });
          };
          this.resourceIds = [
            resourceIds?.[0] || slaveNodeId || selectedNodeId,
            resourceIds?.[1] || createdDependency.masterNodeId!,
          ];
        },
        redo: async (resourceIds?: number[]) => {
          await deleteNodeDependency({
            masterNodeId: resourceIds?.[1] || masterNodeId,
            slaveNodeId: resourceIds?.[0] || slaveNodeId || selectedNodeId,
          });
        },
        resourceIds: [slaveNodeId || selectedNodeId, masterNodeId],
      });
    },
    [selectedNodeId, deleteNodeDependency, addHistoryItem, createNodeDependency, updateResourceId]
  );

  const updateNodeValue = useCallback(
    ({
      sectionId,
      timePeriodId,
      nodeId,
      position,
    }: {
      nodeId: number;
      position: Vector2d;
      sectionId: number;
      timePeriodId: number;
    }) => {
      const newlyUpdatedNodes = filteredNodes.map(node => {
        if (node.id === nodeId) {
          return {
            ...node,
            x: position.x,
            y: position.y,
            sectionId,
            timePeriodId,
          };
        }
        return node;
      });

      setFilteredNodes(newlyUpdatedNodes as FilteredNodes[]);
    },
    [filteredNodes, setFilteredNodes]
  );

  const updateNode = useCallback(
    async (e: KonvaEventObject<DragEvent>) => {
      const canvasDropArea = canvasAreaRef?.current;
      const id = Number(e.target.id());
      const targetPosition = e.target.position();
      const foundNode = filteredNodes.find(n => n.id === id)!;

      const { color, shape, ...node } = foundNode;

      const intersections = stageRef.current?.getAllIntersections(targetPosition);

      if (
        !targetPosition ||
        !canvasDropArea ||
        !intersections?.length ||
        intersections.length < 2
      ) {
        return showToast('error', t('editor.canvas.initiative_position_error'));
      }

      const offset = canvasDropArea.getAbsolutePosition();

      const { timePeriodId, sectionId } = getIntersectionSectionAndTimePeriodId(intersections);

      if (!timePeriodId || !sectionId) {
        return showToast('error', t('editor.canvas.initiative_position_error'));
      }

      const newInitiativeCanvasXPercentage = Number(
        (((targetPosition.x - offset.x - NODE_RADIUS) / canvasDropArea.width()) * 100).toFixed(2)
      );

      const newInitiativeCanvasYPercentage = Number(
        (((targetPosition.y - offset.y - NODE_RADIUS) / canvasDropArea.height()) * 100).toFixed(2)
      );

      updateNodeValue({
        nodeId: node.id!,
        sectionId: Number(sectionId),
        timePeriodId: Number(timePeriodId),
        position: { x: newInitiativeCanvasXPercentage, y: newInitiativeCanvasYPercentage },
      });

      const undoNode = JSON.parse(JSON.stringify(node)) as typeof node;

      addHistoryItem({
        type: HistoryActionType.UpdateNode,
        redo: (resourceIds?: number[]) => {
          updateNodeValue({
            nodeId: resourceIds?.[0] || undoNode.id!,
            sectionId: Number(sectionId),
            timePeriodId: Number(timePeriodId),
            position: { x: newInitiativeCanvasXPercentage, y: newInitiativeCanvasYPercentage },
          });

          updateNodeById({
            nodeBody: {
              ...node,
              x: newInitiativeCanvasXPercentage,
              y: newInitiativeCanvasYPercentage,
              sectionId: Number(sectionId),
              timePeriodId: Number(timePeriodId),
              id: resourceIds?.[0] || node.id!,
              initiatives: node.initiatives
                ? {
                    ...node.initiatives,
                    id: resourceIds?.[1],
                  }
                : {
                    title: node.title,
                  },
            },
            nodeId: resourceIds?.[0] || node.id!,
          });
        },
        undo: (resourceIds?: number[]) => {
          updateNodeValue({
            nodeId: resourceIds?.[0] || undoNode.id!,
            sectionId: Number(sectionId),
            timePeriodId: Number(timePeriodId),
            position: { x: undoNode.x, y: undoNode.y },
          });

          updateNodeById({
            nodeBody: {
              ...undoNode,
              sectionId: Number(sectionId),
              timePeriodId: Number(timePeriodId),
              id: resourceIds?.[0] || node.id!,
              initiatives: undoNode.initiatives
                ? {
                    ...undoNode.initiatives,
                    id: resourceIds?.[1] || undoNode.initiatives.id!,
                  }
                : {
                    title: undoNode.title,
                  },
            },
            nodeId: resourceIds?.[0] || node.id!,
          });
        },
        resourceIds: [node.id!, node.initiatives?.id].filter(Boolean) as number[],
      });

      await updateNodeById({
        nodeBody: {
          ...node,
          x: newInitiativeCanvasXPercentage,
          y: newInitiativeCanvasYPercentage,
          sectionId: Number(sectionId),
          timePeriodId: Number(timePeriodId),
          id: node.id!,
        },
        nodeId: node.id!,
      });
    },
    [
      addHistoryItem,
      canvasAreaRef,
      filteredNodes,
      showToast,
      stageRef,
      t,
      updateNodeById,
      updateNodeValue,
    ]
  );

  const checkBoundaries = (e: KonvaEventObject<DragEvent>) => {
    const canvasDropArea = canvasAreaRef?.current;
    const position = canvasDropArea?.getRelativePointerPosition();
    if (!canvasDropArea || !position) return;

    const { x, y } = position;

    let newX = x;
    let newY = y;

    // for some reason intersections are not found when x or y is max value (width or height)
    //  - 1 is to avoid this for now
    if (newX - NODE_RADIUS < 0) {
      newX = 0 + NODE_RADIUS;
    }
    if (newX + NODE_RADIUS > canvasDropArea.width()) {
      newX = canvasDropArea.width() - NODE_RADIUS - 1;
    }

    if (newY - NODE_RADIUS < 0) {
      newY = 0 + NODE_RADIUS;
    }
    if (newY + NODE_RADIUS > canvasDropArea.height()) {
      newY = canvasDropArea.height() - NODE_RADIUS - 1;
    }

    const layerOffset = canvasDropArea.getAbsolutePosition();
    e.target.setAbsolutePosition({ x: newX + layerOffset.x, y: newY + layerOffset.y });
  };

  const selectedNode = filteredNodes.find(n => n.id === selectedNodeId);

  const allNodeIdsInDependencyRelations = filteredNodes.reduce((acc, node) => {
    const nodeIds = [...acc];
    const slaveNodeIds = node.dependencies?.map(dep => dep.slaveNodeId) || [];
    const masterNodeIds = node.dependencies?.map(dep => dep.masterNodeId) || [];

    return [...nodeIds, ...slaveNodeIds, ...masterNodeIds];
  }, [] as number[]);

  return (
    <Group key="nodes" offset={{ x: VERTICAL_OFFSET, y: HORIZONTAL_OFFSET }}>
      {filteredNodes.map(node => {
        const activeFilter = filters?.find(f => f.id === activeFilterId);

        const nodeActiveSingleSelectFilter = activeFilter?.nodes?.find(n => n.nodeId === node.id);

        const nodeActiveMultiSelectFilter = activeFilter?.nodes?.filter(n => n.nodeId === node.id);

        const doesNodeHaveActiveFilter =
          activeFilter?.selection === 'multiple'
            ? Boolean(nodeActiveMultiSelectFilter?.length)
            : Boolean(nodeActiveSingleSelectFilter);

        const isNodesOptionChecked =
          activeFilter?.selection === 'multiple'
            ? nodeActiveMultiSelectFilter?.some(f => f.optionId === optionCheckedId)
            : nodeActiveSingleSelectFilter?.optionId === optionCheckedId;

        const hasNoDependencyAndIsNotDependency =
          !allNodeIdsInDependencyRelations.includes(node.id!) && !node.dependencies?.length;

        const isNotInFilter = Boolean(
          (doesNodeHaveActiveFilter && optionCheckedId && !isNodesOptionChecked) ||
            (!doesNodeHaveActiveFilter && activeFilterId)
        );

        let color = theme.palette.brand.textSecondary;
        let opacity = 1;
        if (activeFilterId) {
          color = node.color || theme.palette.brand.textSecondaryFiltered;
        }

        if (isNotInFilter || (showDependencies && hasNoDependencyAndIsNotDependency)) {
          color = theme.palette.brand.textSecondaryFiltered;
          opacity = 0.3;
        }

        if (activeFilterId && showDependencies && !hasNoDependencyAndIsNotDependency) {
          color = node.color || theme.palette.brand.textSecondary;
          opacity = 1;
        }
        if (!activeFilterId && showDependencies && !hasNoDependencyAndIsNotDependency) {
          color = theme.palette.brand.textSecondary;
          opacity = 1;
        }

        const { x, y } = calculateAbsolutePositionOnCanvasFromPercentageToPx({
          height: canvasHeightToFit,
          width: canvasWidthToFit,
          xPercentage: node.x,
          yPercentage: node.y,
          offsetX: canvasAreaOffset.x,
          offsetY: canvasAreaOffset.y,
          additionX: NODE_RADIUS,
          additionY: NODE_RADIUS,
        });

        const isMasterOfSelectedNodeDependency = selectedNode?.dependencies?.some(
          d => d.masterNodeId === node.id
        );

        return (
          <Fragment key={`container-node-group-${node.id}`}>
            {node.isVisible && (
              <Group key={`node-group-${node.id}`}>
                <NodeShape
                  onClick={onClick}
                  onDblClick={() => openNodeDialog({ id: node.id! })}
                  active={Number(selectedNodeId) === Number(node.id) && !isPreview}
                  onDragEnd={updateNode}
                  onDragMove={checkBoundaries}
                  id={node.id!}
                  key={node.id!.toString()}
                  title={
                    node.initiatives?.title || `${node.title || t('untitled')} - Missing Initiative`
                  }
                  x={x}
                  y={y}
                  fill={color}
                  shape={node.shape}
                  draggable={!isPreview}
                  opacity={opacity}
                />
                {(isSelectingDependencies || showDependencies) && (
                  <Group>
                    {selectedNodeId !== node.id && isSelectingDependencies && (
                      <Group
                        key={`${node.id}-checkbox`}
                        id={node.id!.toString()}
                        onMouseEnter={e => {
                          const stage = e.target.getStage();
                          if (stage) {
                            const container = stage.container();
                            container.style.cursor = 'pointer';
                          }
                        }}
                        onMouseLeave={e => {
                          const stage = e.target.getStage();
                          if (stage) {
                            const container = stage.container();
                            container.style.cursor = 'default';
                          }
                        }}
                        onClick={async e => {
                          const masterNodeId = Number(e.target.parent?.id());
                          const dependencyExist = Boolean(
                            selectedNode?.dependencies?.some(
                              d =>
                                d.masterNodeId === masterNodeId && d.slaveNodeId === selectedNodeId
                            )
                          );
                          await onDependencyClick({
                            evt: e,
                            dependencyExist,
                            slaveNodeId: selectedNodeId!,
                          });
                        }}
                      >
                        <Rect
                          x={x - NODE_RADIUS * 3 - 5}
                          y={y - NODE_RADIUS}
                          width={NODE_RADIUS * 2}
                          height={NODE_RADIUS * 2}
                          strokeWidth={1}
                          stroke={theme.palette.brand.textPrimary}
                        />
                        {isMasterOfSelectedNodeDependency && (
                          <Image
                            image={tick}
                            x={x - NODE_RADIUS * 3 - 2}
                            y={y - NODE_RADIUS / 2 - 2}
                            width={NODE_RADIUS * 2 - 5}
                            height={NODE_RADIUS * 2 - 5}
                          />
                        )}
                      </Group>
                    )}
                    {((selectedNodeId === node.id && isSelectingDependencies) ||
                      (!isSelectingDependencies && showDependencies)) && (
                      <NodeDependencies
                        key={`${node.id}-dependencies`}
                        nodeDependencies={node.dependencies || []}
                        nodeId={node.id!}
                        nodePosition={{ x, y }}
                        canvasHeightToFit={canvasHeightToFit}
                        canvasWidthToFit={canvasWidthToFit}
                        offset={canvasAreaOffset}
                      />
                    )}
                  </Group>
                )}
              </Group>
            )}
          </Fragment>
        );
      })}
    </Group>
  );
};

export { CanvasNodes };
