import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import BinIconSrc from 'src/assets/icons/bin.svg';
import styled, { css } from 'styled-components';

import ArrowDownIconSrc from '@/assets/icons/arrow-down.svg';
import EyePasswordImgSrc from '@/assets/icons/eye.svg';
import EyePasswordCrossedSrc from '@/assets/icons/eye-crossed.svg';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

type Props = {
  ariaId: string;
  children: ReactNode;
  id: number;
  isExpanded: boolean;
  setExpandedAccordionKey: Dispatch<SetStateAction<number | null>>;
  title: string;
  isVisible?: boolean;
  onDeleteClick?: (filterId: number, e: React.MouseEvent<HTMLImageElement>) => void;
  onShowClick?: (filterId: number, e: React.MouseEvent<HTMLImageElement>, value: boolean) => void;
};

const SidebarListSubItem = ({
  isExpanded,
  isVisible,
  ariaId,
  id,
  setExpandedAccordionKey,
  onDeleteClick,
  onShowClick,
  children,
  title,
}: Props) => {
  const { t } = useTranslation();
  const { isSidebarContentLoading } = useEditorContext();

  return (
    <StyledList key={id} $isExpanded={isExpanded}>
      <ListItem>
        <StyledAccordion
          expanded={isExpanded}
          onChange={() => {
            setExpandedAccordionKey(isExpanded ? null : id);
          }}
          disableGutters
          $isExpanded={isExpanded}
        >
          <StyledAccordionSummary
            id={id.toString()}
            aria-controls={ariaId}
            expandIcon={<ArrowDownIcon src={ArrowDownIconSrc} />}
          >
            <AccordionPanel key={id} id={ariaId} $isExpanded={isExpanded}>
              <ItemTitle>{title}</ItemTitle>
              <IconsWrapper>
                {!!onShowClick && (
                  <Tooltip title={t('editor.sidebar.filter.show_filter_tooltip')} placement="top">
                    <IconWrapper>
                      <StyledImage
                        src={isVisible ? EyePasswordImgSrc : EyePasswordCrossedSrc}
                        onClick={e => onShowClick(id, e, !isVisible)}
                      />
                    </IconWrapper>
                  </Tooltip>
                )}
                {!!onDeleteClick && (
                  <Tooltip title={t('editor.sidebar.filter.delete_filter_tooltip')} placement="top">
                    <IconWrapper>
                      <StyledImage src={BinIconSrc} onClick={e => onDeleteClick(id, e)} />
                    </IconWrapper>
                  </Tooltip>
                )}
              </IconsWrapper>
            </AccordionPanel>
          </StyledAccordionSummary>
          {isSidebarContentLoading && (
            <LoaderWrapper>
              <StyledLoader />
            </LoaderWrapper>
          )}
          {!isSidebarContentLoading && <StyledAccordionDetails>{children}</StyledAccordionDetails>}
        </StyledAccordion>
      </ListItem>
    </StyledList>
  );
};
const StyledAccordion = styled(Accordion)<{ $isExpanded?: boolean }>`
  background-color: ${({ theme }) => theme.palette.brand.backgroundMain};

  & .MuiButtonBase-root {
    min-height: 28px;
    gap: 1rem !important;
    transition: background-color 0.5s;
    ${({ theme, $isExpanded }) =>
      $isExpanded &&
      css`
        transition-delay: 0s;
        background-color: ${theme.palette.brand.white};
      `}
  }

  & .MuiAccordionSummary-content {
    margin: 12px 0;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;

  & .MuiAccordionSummary-content {
    min-width: 0;
  }
`;

const ListItem = styled.li`
  && .MuiCollapse-root {
    background-color: ${({ theme }) => theme.palette.brand.white};
    padding: 0;
  }
`;

const AccordionPanel = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  height: 100%;
  padding: 1rem 0;
`;

const ArrowDownIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const ItemTitle = styled.div`
  font-size: 1.4rem;
  max-width: 172px;
  word-wrap: break-word;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 18px;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const IconsWrapper = styled(IconWrapper)`
  min-width: 38px;
`;

const StyledList = styled.ul<{ $isExpanded?: boolean }>`
  transition: padding 0.5s ease, margin 0.5s ease, background-color 0.5s;
  padding: 0 1rem;
  ${({ $isExpanded, theme }) =>
    $isExpanded &&
    css`
      background: ${theme.palette.brand.white};
      padding: 0px 2.5rem;
      margin: 0px -1.5rem;
      transition-delay: 0s;
    `}
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: ${({ theme }) => theme.palette.brand.textSecondaryFilter};
  &:hover {
    filter: ${({ theme }) => theme.palette.brand.buttonPrimaryFilterHover};
  }
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { SidebarListSubItem };
