import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Box } from '@mui/material';
import styled from 'styled-components';

import { useAccount } from '@/contexts/account-context';
import {
  getAllRoadmapNodes,
  getInitiativeById,
  getRoadmapsDropdownVisible,
  getRoadmapsPopupVisible,
  getRoadmapsRoadmapVisible,
  updateInitiativeFieldById,
} from '@/features/canvas/api';
import { InitiativeBigCustom } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeBigCustom';
import { InitiativeBudget } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeBudget';
import { InitiativeDate } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeDate';
import { InitiativeDescription } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeDescription';
import { InitiativeProgressBar } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeProgressBar';
import { InitiativeSmallCustom } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeSmallCustom';
import { InitiativeTitle } from '@/features/canvas/components/InitiativesAccordionContent/Fields/InitiativeTitle';
import { SidebarListSubItem } from '@/features/canvas/components/SidebarListSubItem';
import {
  CustomFieldType,
  InitiativeFieldTitles,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useFieldOptions } from '@/features/canvas/hooks/useFieldOptions';
import { useInitiativeFields } from '@/features/canvas/hooks/useInitiativeFields';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

import { InitiativeAccordionContentInitiativeFieldListDropdown } from './InitiativeAccordionContentInitiativeFieldListDropdown';

type Props = {
  expandedAccordionKey: number | null;
  isInitiativeLoading: boolean;
  isRoadmapEditor: boolean;
  isRoadmapOwner: boolean;
  setExpandedAccordionKey: Dispatch<SetStateAction<number | null>>;
  setIsBasicDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteCustomFieldDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteFieldDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeletingFromDropdown: Dispatch<SetStateAction<boolean>>;
  setIsDualChoiceDeleteInitiativeDialogOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedCustomFieldId: Dispatch<SetStateAction<number | null>>;
  setSelectedFieldId: Dispatch<SetStateAction<number | null>>;
  initiative?: components['schemas']['Initiative'] | null;
};

const InitiativeAccordionContentInitiativeFieldList = ({
  isInitiativeLoading,
  initiative,
  setIsBasicDeleteInitiativeDialogOpen,
  setIsDeletingFromDropdown,
  setIsDualChoiceDeleteInitiativeDialogOpen,
  setSelectedCustomFieldId,
  setSelectedFieldId,
  setIsDeleteFieldDialogOpen,
  setIsDeleteCustomFieldDialogOpen,
  setExpandedAccordionKey,
  isRoadmapEditor,
  isRoadmapOwner,
  expandedAccordionKey,
}: Props) => {
  const { t } = useTranslation();

  const { showToast } = useShowToast();
  const account = useAccount();
  const queryClient = useQueryClient();
  const { orderInitiativeFields } = useFieldOptions();

  const {
    roadmap,
    selectedNodeId,
    roadmapVisible,
    dropdownVisible,
    updateLastSavedTime,
    setIsInitiativeDetailDialogOpen,
  } = useEditorContext();

  const [isCreatingCustomField, setIsCreatingCustomField] = useState(false);
  const [customFieldTypeInCreation, setCustomFieldTypeInCreation] = useState<
    null | keyof typeof CustomFieldType
  >(null);

  const { createField, isCreatingField } = useInitiativeFields({
    initiativeId: initiative?.id,
  });

  const isInitiativeOwner = initiative?.creatorUserId === account?.id;

  const isInitiativeLocked = Boolean(initiative?.isLocked);

  const isInitiativeLockedAndIsNotOwner = isInitiativeLocked && !isInitiativeOwner;
  const isNotRoadmapOwnerNorEditor = !isRoadmapOwner && !isRoadmapEditor;

  const { mutateAsync: updateField, isLoading: isUpdatingField } = useMutation(
    async (values: { field: components['schemas']['InitiativeField'] }) => {
      try {
        const { field } = values;
        await updateInitiativeFieldById({
          fieldId: field.id!,
          initiativeId: initiative!.id!,
          payload: field,
        });

        updateLastSavedTime();
        showToast('success', t('editor.sidebar.initiative.edit_field_success'), {
          preventDuplicate: true,
        });
      } catch (e) {
        showToast('error', t('editor.sidebar.initiative.edit_field_error'));
      }
    }
  );
  const handleUpdate = async (field: components['schemas']['InitiativeField']) => {
    await updateField({ field });
    await Promise.all([
      queryClient.invalidateQueries([getInitiativeById.name, selectedNodeId]),
      queryClient.invalidateQueries([getRoadmapsPopupVisible.name, roadmap.id]),
      queryClient.invalidateQueries([getRoadmapsRoadmapVisible.name, roadmap.id]),
      queryClient.invalidateQueries([getRoadmapsDropdownVisible.name, roadmap.id]),
    ]);
  };
  const onDeleteClick = (
    fieldId: number,
    e: React.MouseEvent<HTMLButtonElement>,
    setDialogOpen: Dispatch<SetStateAction<boolean>>
  ) => {
    e.stopPropagation();
    setSelectedFieldId(fieldId);
    setDialogOpen(true);
  };

  const onFieldSingleOptionDeleteClick = (
    fieldId: number,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    onDeleteClick(fieldId, e, setIsDeleteFieldDialogOpen);
  };

  const onCustomFieldMultiOptionDeleteClick = (
    fieldId: number,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    onDeleteClick(fieldId, e, setIsDeleteCustomFieldDialogOpen);
    setSelectedCustomFieldId(fieldId);
  };

  const handleCustomFieldUpdate = async (field: components['schemas']['InitiativeField']) => {
    const dropdownField = dropdownVisible.find(
      f => f.title === field.title && f.type === field.type
    );
    await createField({
      dropdownField,
      field,
    });
    setIsCreatingCustomField(false);
    await queryClient.invalidateQueries([getInitiativeById.name, selectedNodeId]);
  };

  const getInitiativeFieldComponent = (field: components['schemas']['InitiativeField']) => {
    const isReadonly = isNotRoadmapOwnerNorEditor;
    switch (field.type) {
      case InitiativeFieldTypes.description:
        return (
          <InitiativeDescription
            readOnly={isReadonly || isInitiativeLockedAndIsNotOwner}
            field={field}
            onUpdateField={handleUpdate}
            onClick={() => setIsInitiativeDetailDialogOpen(true)}
          />
        );
      case InitiativeFieldTypes.budget:
        return (
          <InitiativeBudget
            readOnly={isReadonly}
            field={field}
            onDeleteField={onFieldSingleOptionDeleteClick}
            onUpdateField={handleUpdate}
            onClick={() => setIsInitiativeDetailDialogOpen(true)}
            isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
          />
        );
      case InitiativeFieldTypes.progressBar:
        return (
          <InitiativeProgressBar
            readOnly={isReadonly}
            field={field}
            onDeleteField={onFieldSingleOptionDeleteClick}
            onUpdateField={async newField => {
              await handleUpdate(newField);
              await queryClient.invalidateQueries([getAllRoadmapNodes.name, roadmap.id]);
            }}
            onClick={() => setIsInitiativeDetailDialogOpen(true)}
            isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
          />
        );
      case InitiativeFieldTypes.date:
        return (
          <InitiativeDate
            readOnly={isReadonly}
            field={field}
            onDeleteField={onFieldSingleOptionDeleteClick}
            onUpdateField={handleUpdate}
            onClick={() => setIsInitiativeDetailDialogOpen(true)}
            isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
          />
        );
      case InitiativeFieldTypes.big:
        return (
          <InitiativeBigCustom
            readOnly={isReadonly}
            field={field}
            onDeleteField={onCustomFieldMultiOptionDeleteClick}
            onUpdateField={handleUpdate}
            onClick={() => setIsInitiativeDetailDialogOpen(true)}
            isLoading={isUpdatingField}
            isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
          />
        );
      case InitiativeFieldTypes.small:
        return (
          <InitiativeSmallCustom
            readOnly={isReadonly}
            field={field}
            onDeleteField={onCustomFieldMultiOptionDeleteClick}
            onUpdateField={handleUpdate}
            onClick={() => setIsInitiativeDetailDialogOpen(true)}
            isLoading={isUpdatingField}
            isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
          />
        );
      default:
        return (
          <InitiativeSmallCustom
            readOnly={isReadonly}
            field={field}
            onDeleteField={onFieldSingleOptionDeleteClick}
            onUpdateField={handleUpdate}
            onClick={() => setIsInitiativeDetailDialogOpen(true)}
            isLoading={isUpdatingField}
            isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
          />
        );
    }
  };

  const memoedInitiativeFields = useMemo(() => {
    let initiativeFields: {
      type: string;
      id?: number;
      isVisible?: number;
      name?: string;
      title?: string;
      value?: string;
    }[] = [];

    if (initiative?.fields) {
      initiativeFields = initiative.fields.filter(field =>
        roadmapVisible.some(
          roadmapField => roadmapField.title === field.title && roadmapField.type === field.type
        )
      );
    }
    return orderInitiativeFields(initiativeFields);
  }, [initiative?.fields, orderInitiativeFields, roadmapVisible]);

  return (
    <CustomFieldsWrapper>
      {!isInitiativeLoading && initiative && (
        <>
          <InitiativeTitle
            key={initiative.id}
            isRoadmapEditor={isRoadmapEditor}
            isRoadmapOwner={isRoadmapOwner}
            initiative={initiative}
            setIsBasicDeleteInitiativeDialogOpen={setIsBasicDeleteInitiativeDialogOpen}
            setIsDualChoiceDeleteInitiativeDialogOpen={setIsDualChoiceDeleteInitiativeDialogOpen}
            isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
            isInitiativeOwner={isInitiativeOwner}
          />
          {memoedInitiativeFields.map(field => {
            return (
              <SidebarListSubItem
                key={field.id}
                id={field.id!}
                ariaId={field.id!.toString()}
                title={field.title!}
                isExpanded={expandedAccordionKey === field.id}
                setExpandedAccordionKey={setExpandedAccordionKey}
              >
                <Box>{getInitiativeFieldComponent(field)}</Box>
              </SidebarListSubItem>
            );
          })}
        </>
      )}

      {isCreatingCustomField && customFieldTypeInCreation === CustomFieldType.small && (
        <SidebarListSubItem
          key="temporary-small-custom-field"
          id={0}
          ariaId="temporary-small-custom-field"
          title={InitiativeFieldTitles.customSmall}
          isExpanded={expandedAccordionKey === 0}
          setExpandedAccordionKey={setExpandedAccordionKey}
        >
          <Box>
            <InitiativeSmallCustom
              readOnly={!isRoadmapEditor && !isRoadmapOwner}
              field={{
                type: InitiativeFieldTypes.small,
                title: '',
                value: '',
              }}
              onUpdateField={handleCustomFieldUpdate}
              onClick={() => setIsInitiativeDetailDialogOpen(true)}
              isLoading={isCreatingField}
              isInCreationMode
              isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
            />
          </Box>
        </SidebarListSubItem>
      )}
      {isCreatingCustomField && customFieldTypeInCreation === CustomFieldType.big && (
        <SidebarListSubItem
          key="temporary-large-custom-field"
          id={0}
          ariaId="temporary-large-custom-field"
          title={InitiativeFieldTitles.customBig}
          isExpanded={expandedAccordionKey === 0}
          setExpandedAccordionKey={setExpandedAccordionKey}
        >
          <Box>
            <InitiativeBigCustom
              readOnly={!isRoadmapEditor && !isRoadmapOwner}
              field={{
                type: InitiativeFieldTypes.big,
                title: '',
                value: '',
              }}
              onUpdateField={handleCustomFieldUpdate}
              onClick={() => setIsInitiativeDetailDialogOpen(true)}
              isLoading={isCreatingField}
              isInCreationMode
              isInitiativeLockedAndIsNotOwner={isInitiativeLockedAndIsNotOwner}
            />
          </Box>
        </SidebarListSubItem>
      )}
      {selectedNodeId && !isInitiativeLoading && !isNotRoadmapOwnerNorEditor && (
        <InitiativeAccordionContentInitiativeFieldListDropdown
          setIsDeleteFieldDialogOpen={setIsDeleteFieldDialogOpen}
          setIsDeletingFromDropdown={setIsDeletingFromDropdown}
          setSelectedFieldId={setSelectedFieldId}
          setCustomFieldTypeInCreation={setCustomFieldTypeInCreation}
          setExpandedAccordionKey={setExpandedAccordionKey}
          setIsCreatingCustomField={setIsCreatingCustomField}
          initiativeFields={initiative?.fields}
          memoedInitiativeFields={memoedInitiativeFields}
          disabled={isInitiativeLockedAndIsNotOwner}
        />
      )}
    </CustomFieldsWrapper>
  );
};

const CustomFieldsWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
`;

export { InitiativeAccordionContentInitiativeFieldList };
