import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Rect, Text } from 'react-konva';
import { Html, Portal } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';

import { getRoadmapSections, updateSection } from '@/features/canvas/api';
import { HorizontalSectionActionMenu } from '@/features/canvas/components/HorizontalSectionActionMenu';
import { TabEditFormContent } from '@/features/canvas/components/TabEditFormContent';
import {
  SIZE_HANDLER_WIDTH,
  TAB_ACTION_MENU_END_OFFSET,
  TAB_ACTION_MENU_HEIGHT,
  TAB_ACTION_MENU_WIDTH,
  TAB_HEIGHT,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { HorizontalSection } from '@/features/canvas/types/section';
import { TabEditFormValues } from '@/features/canvas/types/tabEditFormValues';
import { checkXTabBoundaries } from '@/features/canvas/utils/check-x-tab-boundaries';
import { Form } from '@/features/ui/components/Form';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  cornerRadius: number[];
  fill: string;
  horizontalSectionTabWidth: number;
  onDragEnd: () => void;
  sectionId: number;
  sectionIndex: number;
  sectionOrder: number;
  sectionOrientation: components['schemas']['RoadmapDetail']['sections'][0]['orientation'];
  sectionTitle: string;
  shouldDisableActionMenuBecauseOfUsedTemporaryId: boolean;
  successor: { id: number | undefined; width: number };
  textColor: string;
  y: number;
  children?: ReactNode;
  x?: number;
};

const HorizontalSectionTabShape = ({
  x = 0,
  y,
  horizontalSectionTabWidth,
  fill,
  sectionId,
  sectionOrder,
  sectionOrientation,
  sectionTitle,
  cornerRadius,
  shouldDisableActionMenuBecauseOfUsedTemporaryId,
  textColor,
  children,
  successor,
  sectionIndex,
  onDragEnd,
}: Props) => {
  const {
    isPreview,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    sortedHorizontalSections,
    temporaryCreatedIds,
    roadmap,
    updateLastSavedTime,
  } = useEditorContext();
  const [isHoveringTab, setIsHoveringTab] = useState(false);
  const [isHoveringAction, setIsHoveringAction] = useState(false);
  const theme = useTheme();
  const { showToast } = useShowToast();
  const { t } = useTranslation();
  const { addHistoryItem } = useUndoRedo();

  const actionMenuX =
    x -
    (horizontalSectionTabWidth > TAB_ACTION_MENU_WIDTH + TAB_ACTION_MENU_END_OFFSET
      ? TAB_ACTION_MENU_END_OFFSET
      : 0);
  const actionMenuY = y + TAB_HEIGHT - TAB_ACTION_MENU_HEIGHT / 2;

  const draggableRectXPosition = x + horizontalSectionTabWidth - SIZE_HANDLER_WIDTH / 2;
  const draggableRectYPosition = y;

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: updateSectionMutation } = useMutation(
    ({ sectionData }: { sectionData: HorizontalSection }) =>
      updateSection({ sectionId: Number(sectionData.id), newSectionData: sectionData }),
    {
      onMutate: ({ sectionData }) => {
        queryClient.setQueryData(
          [getRoadmapSections.name, roadmap.id],
          (oldSections?: components['schemas']['Section'][]) => {
            setIsEditingTitle(false);
            updateLastSavedTime();
            if (oldSections) {
              const indexToReplace = oldSections.findIndex(s => Number(s.id) === Number(sectionId));
              const sectionsToUpdate = [...oldSections];
              sectionsToUpdate[indexToReplace] = sectionData;
              return sectionsToUpdate;
            }
            return [];
          }
        );
      },
      onSuccess: () => updateLastSavedTime(),
      onError: async () => {
        showToast('error', t('editor.canvas.edit_section_error'));
        await queryClient.invalidateQueries([getRoadmapSections.name, roadmap.id]);
        setIsEditingTitle(false);
      },
    }
  );

  const submitNewSections = useCallback(
    async (formData: TabEditFormValues) => {
      const sectionToReplace = sortedHorizontalSections[sectionIndex];

      await updateSectionMutation({
        sectionData: {
          ...sectionToReplace,
          roadmap_id: Number(roadmap.id),
          title: formData.title,
        },
      });

      addHistoryItem({
        type: HistoryActionType.UpdateSection,
        undo: async (resourceIds?: number[]) => {
          await updateSectionMutation({
            sectionData: {
              ...sectionToReplace,
              id: resourceIds?.[0] || sectionToReplace.id,
              roadmap_id: Number(roadmap.id),
            },
          });
        },
        redo: async (resourceIds?: number[]) => {
          await updateSectionMutation({
            sectionData: {
              ...sectionToReplace,
              id: resourceIds?.[0] || sectionToReplace.id,
              roadmap_id: Number(roadmap.id),
              title: formData.title,
            },
          });
        },
        resourceIds: [sectionToReplace.id!],
      });
    },
    [roadmap.id, sectionIndex, sortedHorizontalSections, updateSectionMutation, addHistoryItem]
  );

  const hasSuccessorWithValidId = Boolean(
    successor.id && successor.id > 0 && !temporaryCreatedIds.has(successor.id)
  );

  const shouldShowResizeHandler =
    !shouldDisableActionMenuBecauseOfUsedTemporaryId &&
    Boolean(successor.width) &&
    !isPreview &&
    hasSuccessorWithValidId;

  return (
    <>
      <Group id={sectionId.toString()} key={sectionId} x={x} y={y}>
        <Group
          id={`tab-${sectionId}`}
          key={`tab-${sectionId}`}
          onMouseEnter={() => setIsHoveringTab(true)}
          onMouseOver={() => setIsHoveringTab(true)}
          onMouseOut={() => setIsHoveringTab(false)}
          onTap={() => setIsHoveringTab(!isHoveringTab)}
        >
          <Rect
            width={horizontalSectionTabWidth}
            height={TAB_HEIGHT}
            fill={fill}
            cornerRadius={cornerRadius}
          />
          {isEditingTitle && !isPreview && (
            <Html
              divProps={{
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: `${horizontalSectionTabWidth}px`,
                  height: `${TAB_HEIGHT / 3}px`,
                  top: `${TAB_HEIGHT / 2 - TAB_HEIGHT / 3 / 2}px`,
                  left: '0px',
                },
              }}
            >
              <Form
                defaultValues={{ title: sectionTitle }}
                onSubmit={formData => submitNewSections(formData as TabEditFormValues)}
              >
                <TabEditFormContent
                  key="horizontalSections"
                  textColor={theme.palette.brand.white}
                  onSubmit={submitNewSections}
                />
              </Form>
            </Html>
          )}
          {!isEditingTitle && (
            <Text
              fill={textColor}
              text={sectionTitle}
              align="center"
              verticalAlign="middle"
              width={horizontalSectionTabWidth}
              height={TAB_HEIGHT}
              fontStyle="900"
              fontSize={16}
              fontFamily="Open Sans"
              letterSpacing={0.15}
              shadowColor={textColor}
              shadowBlur={1}
            />
          )}
          {!isPreview && (
            <HorizontalSectionActionMenu
              sectionIndex={sectionIndex}
              sectionOrder={sectionOrder}
              sectionOrientation={sectionOrientation}
              horizontalSectionTabWidth={horizontalSectionTabWidth}
              sectionId={sectionId}
              x={actionMenuX}
              y={actionMenuY}
              isHoverActive={isHoveringTab || isHoveringAction}
              setIsEditingTitle={setIsEditingTitle}
              setIsHoveringAction={setIsHoveringAction}
              shouldDisableActionMenuBecauseOfUsedTemporaryId={
                shouldDisableActionMenuBecauseOfUsedTemporaryId
              }
            />
          )}
        </Group>
        {children}
      </Group>

      {shouldShowResizeHandler && (
        <Portal selector=".top-layer" enabled>
          <Rect
            width={SIZE_HANDLER_WIDTH}
            height={TAB_HEIGHT}
            x={draggableRectXPosition}
            y={draggableRectYPosition}
            draggable
            onMouseEnter={e => {
              const container = e.target.getStage()!.container();
              container.style.cursor = 'col-resize';
            }}
            onMouseLeave={e => {
              const stage = e.target.getStage();
              if (stage) {
                const container = stage.container();
                container.style.cursor = 'default';
              }
            }}
            onDragStart={() => {
              setIsDraggingTab(true);
            }}
            onDragMove={evt => {
              const rect = evt.target;
              const currentX = rect.x();
              const deltaX = currentX - draggableRectXPosition;
              setDraggedTabPositionChange(deltaX);
            }}
            onDragEnd={onDragEnd}
            dragBoundFunc={pos =>
              checkXTabBoundaries({
                rectXPosition: draggableRectXPosition,
                posX: pos.x,
                tabWidth: horizontalSectionTabWidth,
                neighborTabWidth: successor.width,
                posY: draggableRectYPosition,
              })
            }
          />
        </Portal>
      )}
    </>
  );
};

export { HorizontalSectionTabShape };
