import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

import { ReactComponent as PencilIconSrc } from '@/assets/icons/pencil.svg';
import { editPublicLink, getPublicLinks } from '@/features/publicLinks/api';
import { useFormContext } from '@/features/ui/components/Form';
import { FormTextInput } from '@/features/ui/components/FormTextInput';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  isPasswordEditable: boolean;
  publicLink: components['schemas']['PublicLink'];
  setIsPasswordEditable: Dispatch<SetStateAction<boolean>>;
};

const EditLinkPasswordFormContent = ({
  publicLink,
  setIsPasswordEditable,
  isPasswordEditable,
}: Props) => {
  const { t } = useTranslation();
  const { showToast } = useShowToast();
  const queryClient = useQueryClient();

  const { mutateAsync: updatePublicLink } = useMutation(editPublicLink, {
    onSuccess: async () => {
      setIsPasswordEditable(false);
      showToast('success', t('public_links.edit_success'));
      await queryClient.invalidateQueries(getPublicLinks.name);
    },
    onError: () => {
      showToast('error', t('public_links.edit_error'));
    },
  });

  const { getValues } = useFormContext();
  return (
    <ActionInput
      onBlur={async () => {
        const formValues = getValues();

        if (!formValues.password || formValues.password.length > 16) {
          return;
        }

        const { ...otherValues } = publicLink;
        await updatePublicLink({ ...otherValues, ...formValues });
      }}
    >
      {isPasswordEditable && (
        <FormTextInput
          name="password"
          hide
          size="small"
          type="text"
          rules={{
            required: { value: true, message: t('form.field_required_validation') },
            maxLength: {
              value: 16,
              message: t('authentication.wrong_password_length', { maxCharCount: 16 }),
            },
          }}
        />
      )}
      <Actions>
        <Tooltip title={t('public_links.tooltip.edit_password')} placement="top">
          <IconWrapper
            onClick={() => {
              setIsPasswordEditable(!isPasswordEditable);
            }}
          >
            <PencilIconSrc />
          </IconWrapper>
        </Tooltip>
      </Actions>
    </ActionInput>
  );
};

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  height: 2.5rem;

  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
    path,
    rect {
      fill: ${({ theme }) => theme.palette.brand.primary};
    }
  }

  &:hover {
    svg {
      path,
      rect {
        fill: ${({ theme }) => theme.palette.brand.editorTabPrimary};
      }
    }
  }
`;

const ActionInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export { EditLinkPasswordFormContent };
