export const calculateAbsolutePositionOnCanvasFromPercentageToPx = ({
  xPercentage,
  yPercentage,
  offsetX,
  offsetY,
  width,
  height,
  additionX,
  additionY,
}: {
  height: number;
  width: number;
  xPercentage: number;
  yPercentage: number;
  additionX?: number;
  additionY?: number;
  offsetX?: number;
  offsetY?: number;
}) => {
  const x = (xPercentage / 100) * width + (offsetX || 0) + (additionX || 0);
  const y = (yPercentage / 100) * height + (offsetY || 0) + (additionY || 0);

  return { x, y };
};
