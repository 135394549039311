import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Box } from '@mui/material';

import { getInitiativeById } from '@/features/canvas/api';
import { FieldFactory } from '@/features/canvas/components/InitiativesAccordionContent/Fields/FieldFactory';
import { FieldStyledSelect } from '@/features/canvas/components/StyledSelect';
import {
  CustomFieldType,
  InitiativeFieldTitles,
  InitiativeFieldTypes,
} from '@/features/canvas/constants/initiative-field';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useFieldOptions } from '@/features/canvas/hooks/useFieldOptions';
import { useInitialFieldOptions } from '@/features/canvas/hooks/useInitialFieldOptions';
import { useInitiativeFields } from '@/features/canvas/hooks/useInitiativeFields';
import { InitiativeFieldOption } from '@/features/canvas/types/initiative';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  disabled: boolean;
  memoedInitiativeFields: {
    type: string;
    id?: number | undefined;
    title?: string | undefined;
    value?: string | undefined;
  }[];
  setCustomFieldTypeInCreation: Dispatch<SetStateAction<null | keyof typeof CustomFieldType>>;
  setExpandedAccordionKey: Dispatch<SetStateAction<number | null>>;
  setIsCreatingCustomField: Dispatch<SetStateAction<boolean>>;
  setIsDeleteFieldDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeletingFromDropdown: Dispatch<SetStateAction<boolean>>;
  setSelectedFieldId: Dispatch<SetStateAction<number | null>>;
  initiativeFields?: components['schemas']['InitiativeField'][];
  initiativeId?: number;
};

const InitiativeAccordionContentInitiativeFieldListDropdown = ({
  setIsDeletingFromDropdown,
  setIsDeleteFieldDialogOpen,
  setSelectedFieldId,
  initiativeFields,
  setCustomFieldTypeInCreation,
  setExpandedAccordionKey,
  setIsCreatingCustomField,
  initiativeId,
  memoedInitiativeFields,
  disabled,
}: Props) => {
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { selectedNodeId, dropdownVisible } = useEditorContext();

  const { selectFieldOptionsInit } = useInitialFieldOptions();
  const { createField } = useInitiativeFields({
    initiativeId,
  });
  const [initiativeFieldOptions, setInitiativeFieldOptions] =
    useState<InitiativeFieldOption[]>(selectFieldOptionsInit);

  const { getFieldOptions } = useFieldOptions();

  useEffect(() => {
    const filterSelectOption = getFieldOptions(memoedInitiativeFields);
    setInitiativeFieldOptions(filterSelectOption);
  }, [getFieldOptions, memoedInitiativeFields]);

  const onCreateFieldFromDropdown = async (
    fieldTitle: string | null,
    fieldType: keyof typeof InitiativeFieldTypes
  ) => {
    const dropdownField = dropdownVisible.find(f => f.title === fieldTitle && f.type === fieldType);
    switch (fieldTitle) {
      case InitiativeFieldTitles.title:
        await createField({
          dropdownField,
          field: FieldFactory.createTitleField().getField(),
        });
        break;
      case InitiativeFieldTitles.description:
        await createField({
          dropdownField,
          field: FieldFactory.createDescriptionField().getField(),
        });
        break;
      case InitiativeFieldTitles.startDate:
        await createField({
          dropdownField,
          field: FieldFactory.createStartDateField().getField(),
        });
        break;
      case InitiativeFieldTitles.endDate:
        await createField({
          dropdownField,
          field: FieldFactory.createEndDateField().getField(),
        });
        break;
      case InitiativeFieldTitles.progressBar:
        await createField({
          dropdownField,
          field: FieldFactory.createProgressBarField().getField(),
        });
        break;
      case InitiativeFieldTitles.budget:
        await createField({
          dropdownField,
          field: FieldFactory.createBudgetField().getField(),
        });
        break;
      case InitiativeFieldTitles.customSmall:
        setIsCreatingCustomField(true);
        setCustomFieldTypeInCreation(CustomFieldType.small);
        setExpandedAccordionKey(0);
        break;
      case InitiativeFieldTitles.customBig:
        setIsCreatingCustomField(true);
        setCustomFieldTypeInCreation(CustomFieldType.big);
        setExpandedAccordionKey(0);
        break;
      default:
        {
          const field = FieldFactory.createSmallCustomField().getField();
          if (dropdownField) {
            field.title = dropdownField.title;
            field.type = dropdownField.type;
          }

          await createField({
            dropdownField,
            field,
          });
        }
        break;
    }

    await queryClient.invalidateQueries([getInitiativeById.name, selectedNodeId]);
  };

  const onDeleteFieldOption = (fieldTitle: string, fieldType: keyof typeof CustomFieldType) => {
    const field = initiativeFields?.find(f => f.title === fieldTitle && f.type === fieldType);
    if (!field?.id) {
      return showToast('error', t('editor.sidebar.initiative.delete_field_error'));
    }
    setSelectedFieldId(Number(field.id));
    setIsDeletingFromDropdown(true);
    setIsDeleteFieldDialogOpen(true);
  };

  return (
    <Box sx={{ padding: '1rem 0', maxWidth: '100%', minWidth: 120 }}>
      <FieldStyledSelect
        placeholder={t('editor.sidebar.initiative.select_initiative_field_placeholder')}
        selectedId={null}
        options={initiativeFieldOptions}
        onChange={onCreateFieldFromDropdown}
        onDelete={onDeleteFieldOption}
        disabled={disabled}
      />
    </Box>
  );
};

export { InitiativeAccordionContentInitiativeFieldListDropdown };
