/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  Autocomplete,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Popper,
  TextField,
  useTheme,
} from '@mui/material';
import { debounce } from 'lodash';
import { ReactComponent as BinIcon } from 'src/assets/icons/bin.svg';
import styled from 'styled-components';

import {
  createRoadmapPermission,
  getRoadmapPermissionList,
  removeRoadmapPermission,
} from '@/api/shared/roadmap';
import { getUserList } from '@/api/shared/users';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import { useAccount } from '@/contexts/account-context';
import { PermissionTag } from '@/features/ui/components/PermissionTag';
import { useShowToast } from '@/hooks/useShowToast';

const RoadmapPermissions = ({
  roadmapId,
  maxHeight = 'unset',
  roadmapPermissions,
  roadmapOwner,
}: {
  roadmapId: number;
  roadmapOwner: { id: number; userName: string } | null;
  roadmapPermissions: any[];
  maxHeight?: number | string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { showToast } = useShowToast();
  const [searchText, setSearchText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const account = useAccount();
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const { data: allUsers, isLoading: isLoadingUsers } = useQuery(getUserList.name, getUserList);

  const { mutateAsync: createRoadmapPermissionMutation, isLoading: isCreatingPermission } =
    useMutation(createRoadmapPermission, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([getRoadmapPermissionList.name, roadmapId]);
        showToast('success', t('shared.permission_created_success'));
      },
      onError: () => {
        showToast('error', t('shared.permission_created_error'));
      },
    });

  const { mutateAsync: removeRoadmapPermissionMutation, isLoading: isDeletingPermission } =
    useMutation(removeRoadmapPermission, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([getRoadmapPermissionList.name, roadmapId]);
        showToast('success', t('shared.permission_removed_success'));
      },
      onError: () => {
        showToast('error', t('shared.permission_removed_error'));
      },
    });

  const permissionsOptions = useMemo(() => {
    if (!allUsers || !roadmapPermissions) return [];
    const filteredUsers = allUsers?.filter(user => {
      return (
        !roadmapPermissions.some(roadmapPermission => roadmapPermission.userId === user.id) &&
        user.id !== roadmapOwner?.id
      );
    });

    const searchFilteredUsers = filteredUsers?.filter(user => {
      const isIncludesInName = `${user.firstName} ${user.lastName}`
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const isIncludedInEmail = user.email.toLowerCase().includes(searchText.toLowerCase());
      return isIncludesInName || isIncludedInEmail;
    });
    return searchFilteredUsers;
  }, [allUsers, roadmapOwner?.id, roadmapPermissions, searchText]);

  const debouncedSetSearchText = debounce(setSearchText, 500);

  const handleOnOptionClick = async ({ userId }: { userId: number }) => {
    if (isCreatingPermission) return;
    setSearchText('');
    setIsOpen(false);

    await createRoadmapPermissionMutation({
      roadmapId,
      userId,
      permissionType: 'roadmap_edit',
    });
  };

  const handleDeletePermissions = async ({ userId }: { userId: number }) => {
    if (isDeletingPermission) return;
    await removeRoadmapPermissionMutation({
      roadmapId,
      userId,
    });
  };

  return (
    <Permissions maxHeight={maxHeight}>
      <UserPermissions>
        <Label>{t('shared.roadmap_permissions')}</Label>
        <Divider
          variant="fullWidth"
          sx={{ borderColor: theme.palette.brand.backgroundDialog, marginBottom: '1rem' }}
        />

        <UserList>
          {[...roadmapPermissions]
            .sort(a => (a.permissionType === 'roadmap_owner' ? -1 : 1))
            .map(user => {
              const name = `${user.userName} ${user.userLastname}`;
              const isCurrentUser = user.userId === account?.id;
              return (
                <User key={user.userId}>
                  <Name>{name}</Name>
                  {user.permissionType === 'roadmap_owner' && <PermissionTag isOwner />}
                  {user.permissionType !== 'roadmap_owner' && !isCurrentUser && (
                    <IconButton
                      onClick={() => handleDeletePermissions({ userId: user.userId })}
                      sx={{
                        padding: '0.5rem',
                        '& svg': {
                          path: {
                            stroke: theme.palette.brand.textSecondary,
                          },
                          overflow: 'visible',
                        },
                      }}
                    >
                      <BinIcon />
                    </IconButton>
                  )}
                </User>
              );
            })}
        </UserList>
      </UserPermissions>

      <UserManagement>
        <Autocomplete
          open={isOpen}
          freeSolo
          ref={autocompleteRef}
          loading={isLoadingUsers}
          forcePopupIcon
          isOptionEqualToValue={() => false}
          disabled={isCreatingPermission}
          slotProps={{
            popper: {
              sx: {
                zIndex: 2000,
              },
            },
          }}
          PopperComponent={({ ...props }) => (
            <Popper
              {...props}
              container={autocompleteRef.current}
              modifiers={[
                {
                  name: 'flip',
                  options: {
                    fallbackPlacements: [],
                  },
                },
              ]}
              popperOptions={{
                placement: 'bottom',
              }}
            />
          )}
          PaperComponent={({ children }) => <Paper elevation={4}>{children}</Paper>}
          renderInput={params => (
            <StyledSearchTextField
              {...params}
              size="small"
              placeholder={t('shared.add_editor')}
              variant="standard"
              onClick={() => {
                setSearchText('');
                setIsOpen(!isOpen);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: <DropdownArrow />,
                disableUnderline: true,
                sx: {
                  padding: '0 1rem',
                  borderRadius: '2rem',
                },
              }}
              sx={{
                borderRadius: '2rem',
                '& .MuiAutocomplete-inputRoot': {
                  paddingRight: '2rem !important',
                },
              }}
            />
          )}
          onBlur={() => {
            setIsOpen(false);
            setSearchText('');
          }}
          disableClearable
          clearOnBlur
          blurOnSelect
          options={permissionsOptions}
          onInputChange={e => {
            if (!(e?.target as HTMLInputElement | undefined)?.value)
              return debouncedSetSearchText('');
            setIsOpen(true);
            const target = e?.target as HTMLInputElement;
            if (typeof target.value !== 'string') {
              debouncedSetSearchText('');
              return;
            }
            debouncedSetSearchText(target.value);
          }}
          getOptionLabel={option => {
            if (typeof option === 'string') {
              return option;
            }
            const name = `${option.firstName} ${option.lastName}`;
            return name;
          }}
          renderOption={(props, { firstName, lastName, id }) => {
            const name = `${firstName} ${lastName}`;
            return (
              <StyledOption
                {...props}
                disabled={isCreatingPermission}
                key={props.id}
                onClick={() =>
                  handleOnOptionClick({
                    userId: id!,
                  })
                }
              >
                {name}
              </StyledOption>
            );
          }}
        />
      </UserManagement>
    </Permissions>
  );
};

const Permissions = styled.div<{ maxHeight: number | string }>`
  border-radius: 2rem;
  overflow: auto;
  padding: 1.5rem;
  max-height: ${({ maxHeight }) => (typeof maxHeight === 'number' ? `${maxHeight}px` : maxHeight)};
`;

const Label = styled.div`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
  font-weight: 1000;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;
const StyledOption = styled(MenuItem)`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

const UserManagement = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const UserList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 0.5rem;
`;

const UserPermissions = styled.div`
  margin-bottom: 2rem;
`;

const User = styled.div`
  display: grid;
  grid-template-columns: 12fr 1fr;
  align-items: center;
  justify-items: end;
  grid-gap: 0 0.5rem;
  padding-right: 2rem;
`;
const Name = styled.div`
  font-size: 1.5rem;
  justify-self: start;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

const StyledSearchTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  font-size: 2rem;
`;

const DropdownArrow = styled(ArrowDownIcon)`
  cursor: pointer;
`;

export { RoadmapPermissions };
