import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%; /* 1rem = 10px */
  }
  
  body * {
    font-family: 'Open Sans';
    
  }
  body { 
    background-color: ${theme().palette.brand.backgroundMain};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

export { GlobalStyle };
