import { TimelineEditor as Editor } from '@/features/canvas/components/TimelineEditor';
import { EditorContextProvider } from '@/features/canvas/contexts/editor-context';
import { ImportInitiativeContextProvider } from '@/features/canvas/contexts/import-initiative-context';
import { UndoRedoContextProvider } from '@/features/canvas/contexts/undo-redo-context';

const TimelineEditor = ({ isPreview }: { isPreview?: boolean }) => {
  return (
    <EditorContextProvider isPublicView={isPreview}>
      <ImportInitiativeContextProvider>
        <UndoRedoContextProvider>
          <Editor />
        </UndoRedoContextProvider>
      </ImportInitiativeContextProvider>
    </EditorContextProvider>
  );
};

export { TimelineEditor };
