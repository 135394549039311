import 'numeral/locales/en-gb';

import numeral from 'numeral';

import {
  CURRENCY_CODE,
  CURRENCY_SYMBOL_TO_CODE_MAP,
  SUPPORTED_CURRENCIES,
} from '@/constants/common';

const budgetRegexFormats = [
  /^([$€£]|USD|AUD|EUR|CAD|GBP)?\s*([\d,.]+)\s*([MK]?)$/i,
  /^([\d,.]+)\s*([MK]?)\s*([$€£]|USD|AUD|EUR|CAD|GBP)?$/i,
];

numeral.locale('en-gb');

export const parseBudget = (
  budget: string
): { currency: keyof typeof CURRENCY_CODE; value: number } => {
  let parsedBudget = {
    currency: CURRENCY_CODE.GBP as keyof typeof CURRENCY_CODE,
    value: 0,
  };

  if (budget.match(budgetRegexFormats[0])) {
    const match = budget.match(budgetRegexFormats[0])!;

    const isCurrencySymbol = match[1] && SUPPORTED_CURRENCIES.map(c => c.symbol).includes(match[1]);
    const currency = (
      isCurrencySymbol
        ? CURRENCY_SYMBOL_TO_CODE_MAP[match[1] as keyof typeof CURRENCY_SYMBOL_TO_CODE_MAP]
        : match[1] || CURRENCY_CODE.GBP
    ) as keyof typeof CURRENCY_CODE;

    const valueString = match[2];
    const value = Number(numeral(valueString).value());

    const shortcut = match[3];
    parsedBudget = parseBudgetWithShortcut({
      currency,
      shortCut: shortcut,
      value,
    });
  } else if (budget.match(budgetRegexFormats[1])) {
    const match = budget.match(budgetRegexFormats[1])!;

    const isCurrencySymbol = match[3] && SUPPORTED_CURRENCIES.map(c => c.symbol).includes(match[3]);
    const currency = (
      isCurrencySymbol
        ? CURRENCY_SYMBOL_TO_CODE_MAP[match[3] as keyof typeof CURRENCY_SYMBOL_TO_CODE_MAP]
        : match[3] || CURRENCY_CODE.GBP
    ) as keyof typeof CURRENCY_CODE;

    const shortcut = match[2];
    const valueString = match[1];

    const value = Number(numeral(valueString).value());
    parsedBudget = parseBudgetWithShortcut({
      currency,
      shortCut: shortcut,
      value,
    });
  }
  return parsedBudget;
};

const parseBudgetWithShortcut = ({
  shortCut,
  currency,
  value,
}: {
  currency: keyof typeof CURRENCY_CODE;
  shortCut: string;
  value: number;
}) => {
  if (shortCut === 'K') {
    return { currency, value: value * 1000 };
  }
  if (shortCut === 'M') {
    return { currency, value: value * 1000000 };
  }
  return { currency, value };
};
