export const STAGE_OFFSET = 40;
export const MIN_CANVAS_WIDTH = 756;
export const HORIZONTAL_OFFSET = 50;
export const VERTICAL_OFFSET = 50;
export const TAB_HEIGHT = 50;
export const MIN_TAB_WIDTH = 60;
export const MIN_TAB_AREA_HEIGHT = 50;
export const SIZE_HANDLER_WIDTH = 20;
export const VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT = 20;

export const TAB_ACTION_MENU_WIDTH = 75;
export const TAB_ACTION_MENU_HEIGHT = 20;
export const TAB_ACTION_MENU_END_OFFSET = 15;

export const TAB_ACTION_MENU_ICON_SIZE = 10;
export const TAB_ACTION_MENU_ITEM_MARGIN = 12;
export const TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT = 15;
export const TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH = 50;

export const TAB_ACTION_MENU_ICON_SIZE_TABLET = 17;
export const TAB_ACTION_MENU_ITEM_MARGIN_TABLET = 19;
export const TAB_ACTION_MENU_ITEM_TOOLTIP_HEIGHT_TABLET = 22;
export const TAB_ACTION_MENU_ITEM_TOOLTIP_WIDTH_TABLET = 50;

export const DEFAULT_NEW_TAB_WIDTH = 150;
export const DEFAULT_NEW_TAB_HEIGHT_INCREMENT = 50;

export const MAX_TIME_PERIODS = 4;
export const MIN_TIME_PERIODS = 2;

export const MAX_VERTICAL_SECTIONS = 3;
export const MIN_VERTICAL_SECTIONS = 1;
export const MAX_HORIZONTAL_SECTIONS = 5;
export const MIN_HORIZONTAL_SECTIONS = 1;

export const CANVAS_MODE_BUTTON_WIDTH = 40;
export const CANVAS_MODE_BUTTON_HEIGHT = 35;
export const CANVAS_MODE_BUTTON_X_OFFSET = 5;
export const CANVAS_MODE_BUTTON_Y_OFFSET = 5;

export const CANVAS_HOME_BUTTON_WIDTH = 40;
export const CANVAS_HOME_BUTTON_HEIGHT = 35;
export const CANVAS_HOME_BUTTON_X_OFFSET = 5;
export const CANVAS_HOME_BUTTON_Y_OFFSET = 5;
export const CANVAS_HOME_BG_X_OFFSET = 0;
export const CANVAS_HOME_BG_Y_OFFSET = 63;

export const INITIATIVE_DETAIL_MAX_SLOTS = 16;
export const INITIATIVE_DETAIL_SMALL_FIELDS_EQUAL_TO_ONE_BIG_FIELD = 4;

export const INITIATIVE_DETAIL_FILTER_OPTION_TITLE_MAX_LENGTH = 16;

export const CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH = 32;
export const CUSTOM_FIELD_MAX_MULTI_LINE_LENGTH = 350;

export const SIDEBAR_ACCORDION_KEY_MAP = {
  initiatives: 'initiatives',
  filters: 'filters',
  import: 'import',
} as const;

export const PROGRESS_BAR_INCREMENT = 5;
export const PROGRESS_BAR_MAX = 100;
export const PROGRESS_BAR_MIN = 0;

export const AREA_SHAPE_CLIP_OFFSET = 10;

export const NODE_RADIUS = 10;

export const MAX_UNDO_REDO_STACK_SIZE = 3;

export const LINKAGE_ROW_SIZE = 5;
export const LINKAGE_VISIBLE_COLUMNS = 3;

export const ARROW_DASH_LENGTH = 5;
export const ARROW_DASH_APART = 3;
export const ARROW_RADIUS = 12;
export const ARROW_DASH = [ARROW_DASH_LENGTH, ARROW_DASH_APART];
