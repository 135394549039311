import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Circle, Group, Line, Rect, Text } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Text as TextType } from 'konva/lib/shapes/Text';

import { NODE_RADIUS } from '@/features/canvas/constants';

type NodeShapeProps = {
  id: number;
  shape: 'circle' | 'square' | 'triangle';
  title: string;
  x: number;
  y: number;
  active?: boolean;
  draggable?: boolean;
  fill?: string;
  onClick?: (evt: KonvaEventObject<MouseEvent>) => void;
  onDblClick?: (evt: KonvaEventObject<MouseEvent>) => void;
  onDragEnd?: (evt: KonvaEventObject<DragEvent>) => void;
  onDragMove?: (evt: KonvaEventObject<DragEvent>) => void;
  opacity?: number;
};

const NodeShape = React.memo(
  ({
    id,
    title,
    x,
    y,
    fill,
    active = false,
    onDragMove,
    onDragEnd,
    onClick,
    onDblClick,
    draggable,
    shape,
    opacity,
  }: NodeShapeProps) => {
    const [augmentedTitle, setAugmentedTitle] = useState('');
    const [textWidth, setTextWidth] = useState(0);
    const [isTextMultiline, setIsTextMultiline] = useState(false);
    const onMouseEnter = useCallback(
      (e: KonvaEventObject<MouseEvent>) => {
        const container = e.target?.getStage()?.container();
        if (!container || !draggable) return;
        container.style.cursor = 'pointer';
      },
      [draggable]
    );

    const onMouseLeave = useCallback(
      (e: KonvaEventObject<MouseEvent>) => {
        const container = e.target?.getStage()?.container();
        if (!container || !draggable) return;
        container.style.cursor = 'default';
      },
      [draggable]
    );

    const getShape = () => {
      const trianglePoints = [0, 20, 10, 0, 20, 20];

      switch (shape) {
        case 'triangle':
          return (
            <Line x={-NODE_RADIUS} y={-NODE_RADIUS} points={trianglePoints} closed fill={fill} />
          );
        case 'square':
          return (
            <Rect
              x={-NODE_RADIUS}
              y={-NODE_RADIUS}
              width={NODE_RADIUS * 2}
              height={NODE_RADIUS * 2}
              fill={fill}
            />
          );
        default:
          return (
            <Circle
              x={0}
              y={0}
              width={NODE_RADIUS * 2}
              height={NODE_RADIUS * 2}
              fill={fill}
              radius={NODE_RADIUS}
            />
          );
      }
    };

    useEffect(() => {
      if (title !== augmentedTitle) {
        setAugmentedTitle(title.replace(/\s+/g, ' '));
      }
    }, [title, augmentedTitle, setAugmentedTitle]);

    useLayoutEffect(() => {
      const boldText = new TextType({
        text: augmentedTitle.slice(0, 20),
        fontSize: 16,
        fontStyle: 'bold',
      });

      const normalText = new TextType({
        text: augmentedTitle.slice(0, 20),
        fontSize: 16,
        fontStyle: 'normal',
      });

      const boldTextWidth = boldText.width();
      const normalTextWidth = normalText.width();

      const width = active ? boldTextWidth : normalTextWidth;

      const numberOfCharacters = augmentedTitle.length;
      if (numberOfCharacters > 20) {
        setIsTextMultiline(true);
      } else {
        setIsTextMultiline(false);
      }
      if (width !== textWidth) {
        setTextWidth(width);
      }
      boldText.destroy();
      normalText.destroy();
    }, [active, textWidth, augmentedTitle]);

    return (
      <Group
        id={id.toString()}
        x={x}
        y={y}
        draggable={draggable}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        fill={fill}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onDblClick={onDblClick}
        onTap={onClick}
        onDblTap={onDblClick}
      >
        {getShape()}
        <Text
          width={textWidth}
          wrap="word"
          fontSize={16}
          fontStyle={active ? 'bold' : 'normal'}
          x={16}
          y={isTextMultiline ? -NODE_RADIUS : -6}
          text={augmentedTitle}
          opacity={opacity}
        />
      </Group>
    );
  }
);
export { NodeShape };
