import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { SwimlaneViewSectionGridCell } from '@/features/canvas/components/SwimlaneViewSectionGridCell';
import { TAB_HEIGHT } from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { SwimlaneNode } from '@/features/canvas/types/swimlane-node';

type Props = {
  isLast: boolean;
  isOdd: boolean;
  sectionId: number;
  timePeriodDistributedNodesMap: Map<number, Record<number, SwimlaneNode[]> | undefined>;
  title?: string;
};

const SwimlaneViewSection = ({
  title,
  sectionId,
  isLast,
  timePeriodDistributedNodesMap,
  isOdd,
}: Props) => {
  const { sortedTimePeriods } = useEditorContext();
  const childRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (childRef.current && parentRef.current) {
      const childWidth = childRef.current.getBoundingClientRect().height;

      parentRef.current.style.minHeight = `${childWidth}px`;
    }
  });

  return (
    <>
      <SectionHead $isOdd={isOdd} ref={parentRef}>
        <SectionTitle ref={childRef}>{title}</SectionTitle>
      </SectionHead>
      {sortedTimePeriods.map(tp => {
        return (
          <SwimlaneViewSectionGridCell
            isLast={isLast}
            isSectionOdd={isOdd}
            key={tp.id}
            nodes={timePeriodDistributedNodesMap.get(tp.id!)?.[sectionId]}
          />
        );
      })}
    </>
  );
};

const SectionHead = styled.div<{ $isOdd: boolean }>`
  position: relative;
  min-height: ${TAB_HEIGHT}px;
  width: ${TAB_HEIGHT}px;
  background-color: ${({ theme, $isOdd }) =>
    $isOdd ? theme.palette.brand.editorTabPrimary : theme.palette.brand.editorTabSecondary};
  border-radius: 1rem 0 0 1rem;
`;

const SectionTitle = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  color: ${({ theme }) => theme.palette.brand.white};
  font-weight: 900;
  font-size: 16px;
  text-wrap: nowrap;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  padding: 17px;
  white-space: nowrap;
  text-shadow: 0px 0px 1px ${({ theme }) => theme.palette.brand.white};
`;

export { SwimlaneViewSection };
