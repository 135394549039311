import { Controller, RegisterOptions } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, InputProps } from '@mui/material';
import Input from '@mui/material/Input';
import styled from 'styled-components';

import { useFormContext } from '@/features/ui/components/Form';
import { FormInputError } from '@/features/ui/components/FormInputError';

type ErrorInputProps = {
  fontSize?: string;
  padding?: string | number;
};

type FormTextInputProps = InputProps & {
  name: string;
  defaultValue?: string | number;
  errorInputProps?: ErrorInputProps;
  hide?: boolean;
  label?: string;
  maxWidth?: number;
  placeholder?: string;
  required?: boolean;
  rules?: RegisterOptions;
};

export const FormFieldLinkingTextInput = ({
  label,
  name,
  placeholder,
  required,
  hide,
  defaultValue,
  errorInputProps,
  ...otherProps
}: FormTextInputProps) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ''}
      name={name}
      rules={{
        required: required ? t('form.field_required_validation') : false,

        ...(otherProps.rules ? otherProps.rules : {}),
      }}
      render={({ field, fieldState: { error }, formState: { isSubmitting } }) => {
        return (
          <FormControl sx={{ width: '100%', position: 'relative' }}>
            {Boolean(label) && !hide && <FormInputLabel htmlFor={name}>{label}</FormInputLabel>}

            <FormInputWrapper style={hide ? { visibility: 'hidden', height: 0 } : undefined}>
              <StyledInput
                sx={{
                  '&.MuiInput-root:before': {
                    content: 'unset',
                  },
                  '&.MuiInput-root:after': {
                    border: 'none',
                  },
                  '&.Mui-disabled': {
                    content: 'unset',
                  },
                  '& .MuiInputBase-inputAdornedStart': {
                    marginLeft: '3rem',
                  },
                  ...(error
                    ? { backgroundColor: '#FFF2F2', borderColor: '#D93839!important' }
                    : {}),
                }}
                placeholder={placeholder}
                {...otherProps}
                {...field}
                error={!!error}
                value={field.value}
                onChange={v => {
                  otherProps.onChange?.(v);
                  field.onChange(v);
                }}
                disabled={otherProps.disabled || isSubmitting}
                onBlur={e => {
                  otherProps.onBlur?.(e);
                  field.onBlur();
                }}
                inputProps={{
                  sx: {
                    padding: '1.5rem 2.5rem 1.5rem 1.5rem',
                  },
                }}
              />
            </FormInputWrapper>

            {!!error && !isSubmitting && (
              <FormInputError fontSize="1.25rem" padding="0 1rem" {...errorInputProps}>
                {error.message}
              </FormInputError>
            )}
          </FormControl>
        );
      }}
    />
  );
};

const FormInputLabel = styled.label`
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.palette.brand.buttonSecondaryHover};
`;

const FormInputWrapper = styled.div`
  margin: 1rem 0.5rem !important;
`;

const StyledInput = styled(Input)`
  border-radius: 1rem;

  background-color: rgb(255, 255, 255);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0.2rem 0.75rem 0px;
`;
