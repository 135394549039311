import { defaultApi } from '@/api';
import { components } from '@/types/api';

export async function createNewTimePeriod({
  newTimePeriod,
}: {
  newTimePeriod: components['schemas']['TimePeriod'];
}) {
  // temp
  const newTimePeriodInArray = [newTimePeriod];
  const { data } = await defaultApi.post<components['schemas']['TimePeriod']>(
    '/time-periods',
    newTimePeriodInArray
  );
  return data;
}

export async function deleteTimePeriod({ timePeriodId }: { timePeriodId: number }) {
  await defaultApi.delete(`/time-periods/${timePeriodId}`);
}

export async function updateTimePeriod({
  timePeriodId,
  newTimePeriodData,
}: {
  timePeriodId: number;
  newTimePeriodData: components['schemas']['TimePeriod'];
}) {
  const newTimePeriodDataInArray = [newTimePeriodData];
  await defaultApi.put(`/time-periods/${timePeriodId}`, newTimePeriodDataInArray);
}

export async function getRoadmapTimePeriods({ roadmapId }: { roadmapId: number }) {
  const { data } = await defaultApi.get<components['schemas']['TimePeriod'][]>(
    `/time-periods/roadmap/${roadmapId}`
  );
  return data;
}
