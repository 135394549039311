import { Group, Line } from 'react-konva';
import { useTheme } from '@mui/material';
import { transparentize } from 'polished';

const TimePeriodTabAreaShape = ({
  points,
  startX,
  startY,
  timePeriodId,
  height,
  isLast,
  isFirst,
}: {
  height: number;
  isFirst: boolean;
  isLast: boolean;
  points: number[];
  startX: number;
  startY: number;
  timePeriodId: number;
}) => {
  const theme = useTheme();

  return (
    <Group id={`${timePeriodId}-time-period-area-shape`}>
      {!isLast && (
        <Line
          points={points}
          strokeWidth={2}
          stroke={transparentize(0.5, theme.palette.brand.textSecondary)}
          dash={[3, 6]}
          sceneFunc={(ctx, shape) => {
            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(points[4], points[5] - points[5] / 3);
            ctx.bezierCurveTo(
              points[4],
              points[5] - points[5] / 4,
              points[4],
              points[5],
              points[4] + points[5] / 5,
              points[5]
            );
            ctx.lineTo(points[6], points[7]);
            ctx.fillStrokeShape(shape);
          }}
        />
      )}
      <Line
        points={points}
        sceneFunc={(ctx, shape) => {
          ctx.beginPath();
          ctx.moveTo(points[0], startY);
          if (!isFirst) {
            ctx.lineTo(points[0], points[7] + height - (points[7] + height) / 3);
            ctx.bezierCurveTo(
              points[0],
              points[7] + height - (points[7] + height) / 4,
              points[0],
              points[7] + height,
              points[0] + (points[7] + height) / 5,
              points[7] + height
            );
            ctx.lineTo(points[6], points[7] + height);
          }
          if (isFirst) {
            ctx.lineTo(points[0], points[7] + height);
            ctx.lineTo(points[6], points[7] + height);
          }
          ctx.lineTo(points[6], points[7]);

          ctx.lineTo(points[4] + points[5] / 5, points[5]);
          ctx.bezierCurveTo(
            points[4],
            points[5],
            points[4],
            points[5] - points[5] / 4,

            points[4],
            points[5] - points[5] / 3
          );

          ctx.lineTo(startX, startY);
          ctx.closePath();
          ctx.fillStrokeShape(shape);
        }}
      />
    </Group>
  );
};

export { TimePeriodTabAreaShape };
