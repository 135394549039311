import React from 'react';
import { Group, Layer, Rect, Stage } from 'react-konva';
import { useTheme } from '@mui/material';

import { CanvasNodes } from '@/features/canvas/components/CanvasNodes';
import { CanvasViewHomeButton } from '@/features/canvas/components/CanvasViewHomeButton';
import { CanvasViewModeButton } from '@/features/canvas/components/CanvasViewModeButton';
import { HorizontalSections } from '@/features/canvas/components/HorizontalSections';
import { TimePeriods } from '@/features/canvas/components/TimePeriods';
import { VerticalSections } from '@/features/canvas/components/VerticalSections';
import { HORIZONTAL_OFFSET, STAGE_OFFSET, VERTICAL_OFFSET } from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

import { useNodeFocusOut } from './useNodeFocusOut';
import { useOnDrop } from './useOnDrop';

// Canvas is build on top of Konva library (https://konvajs.org/)
// For react integration react-konva is used (https://github.com/konvajs/react-konva)

const Canvas = () => {
  const theme = useTheme();
  const {
    isPreview,
    stageRef,
    canvasAreaRef,
    heightToFitContent,
    widthToFitContent,
    width,
    height,
  } = useEditorContext();

  useOnDrop();
  const { onNodeFocusOut } = useNodeFocusOut();

  return (
    <Stage width={width} height={height} ref={stageRef}>
      <Layer
        name="main-layer"
        offset={{ x: -STAGE_OFFSET, y: -STAGE_OFFSET }}
        onClick={onNodeFocusOut}
      >
        <Group
          ref={canvasAreaRef}
          x={HORIZONTAL_OFFSET}
          y={VERTICAL_OFFSET}
          width={widthToFitContent}
          height={heightToFitContent}
        >
          <Rect
            width={widthToFitContent}
            height={heightToFitContent}
            fill={theme.palette.brand.white}
          />
        </Group>
        {isPreview && <CanvasViewModeButton />}
        {isPreview && <CanvasViewHomeButton canvasHeightToFit={heightToFitContent} />}

        <Group id="sections">
          <VerticalSections
            canvasHeightToFit={heightToFitContent}
            canvasWidthToFit={widthToFitContent}
          />
          <HorizontalSections
            canvasHeightToFit={heightToFitContent}
            canvasWidthToFit={widthToFitContent}
          />
        </Group>

        <TimePeriods canvasHeightToFit={heightToFitContent} canvasWidthToFit={widthToFitContent} />

        <CanvasNodes canvasWidthToFit={widthToFitContent} canvasHeightToFit={heightToFitContent} />
      </Layer>

      <Layer name="top-layer" offset={{ x: -STAGE_OFFSET, y: -STAGE_OFFSET }} />
    </Stage>
  );
};

export { Canvas };
