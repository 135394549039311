export const TESCO_LOGO = 'https://1000logos.net/wp-content/uploads/2023/04/Tesco-logo.png';
export const INPUT_MAX_LENGTH = 64;

export const ROADMAP_ITEM_WIDTH = 400;
export const ROADMAP_ITEM_HEIGHT = 220;
export const ROADMAP_ITEM_GAP = 20;
export const DATE_FORMAT = 'DD/MM/YYYY';

export const SUPPORTED_DATE_FORMATS = [
  'DD/MM/YYYY',
  'D/M/YYYY',
  'DD-MM-YYYY',
  'D-M-YYYY',
  'DD.MM.YYYY',
  'D.M.YYYY',
];

export const SUPPORTED_CURRENCIES = [
  {
    id: 1,
    currency: 'GBP',
    symbol: '£',
  },
  {
    id: 2,
    currency: 'EUR',
    symbol: '€',
  },
  {
    id: 3,
    currency: 'USD',
    symbol: '$',
  },
  {
    id: 4,
    currency: 'AUD',
    symbol: '$',
  },
  {
    id: 5,
    currency: 'CAD',
    symbol: '$',
  },
];

export const CURRENCY_SYMBOL_TO_CODE_MAP = {
  '£': 'GBP',
  $: 'USD',
  '€': 'EUR',
  CA$: 'CAD',
  AU$: 'AUD',
} as const;

export const CURRENCY_CODE_TO_SYMBOL_MAP = {
  GBP: '£',
  USD: '$',
  EUR: '€',
  AUD: '$',
  CAD: '$',
} as const;

export const CURRENCY_CODE = {
  GBP: 'GBP',
  USD: 'USD',
  EUR: 'EUR',
  AUD: 'AUD',
  CAD: 'CAD',
} as const;

export const ServiceStatusCodes = {
  OK: 200,
  MAINTENANCE: 503,
  GATEWAY_TIMEOUT: 504,
} as const;
