import { HTMLAttributes } from 'react';

import StyledCard from './Card.styled';

export type CardProps = HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
};

const Card: React.FC<CardProps> = props => {
  const { children, ...rest } = props;
  return <StyledCard {...rest}>{children}</StyledCard>;
};
export { Card };
