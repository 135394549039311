import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Rect } from 'react-konva';
import { Portal } from 'react-konva-utils';
import { useMutation, useQueryClient } from 'react-query';
import { useTheme } from '@mui/material';
import { darken } from 'polished';

import { getRoadmapTimePeriods, updateTimePeriod } from '@/features/canvas/api';
import { TimePeriodTabAreaShape } from '@/features/canvas/components/TimePeriodTabAreaShape';
import { TimePeriodTabShape } from '@/features/canvas/components/TimePeriodTabShape';
import {
  HORIZONTAL_OFFSET,
  MIN_TAB_AREA_HEIGHT,
  STAGE_OFFSET,
  TAB_HEIGHT,
  VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT,
} from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { HistoryActionType, useUndoRedo } from '@/features/canvas/contexts/undo-redo-context';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

type Props = {
  canvasHeightToFit: number;
  canvasWidthToFit: number;
  isLast: boolean;
  scaleAdjustedTimePeriodTabAreaHeights: number[];
  scaleAdjustedTimePeriodTabWidths: number[];
  timePeriodId: number;
  timePeriodIndex: number;
  timePeriodOrder: number;
  timePeriodTabHeightScaleProportion: number;
  timePeriodTabWidthScaleProportion: number;
  timePeriodTitle?: string;
};

const TimePeriod = ({
  canvasWidthToFit,
  timePeriodId,
  timePeriodIndex,
  timePeriodTitle,
  timePeriodOrder,
  isLast,
  canvasHeightToFit,
  timePeriodTabWidthScaleProportion,
  timePeriodTabHeightScaleProportion,
  scaleAdjustedTimePeriodTabWidths,
  scaleAdjustedTimePeriodTabAreaHeights,
}: Props) => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const {
    draggedTabPositionChange,
    sortedTimePeriods,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    roadmap,
    isPreview,
    setShouldRecalculateNodePositions,
    temporaryCreatedIds,
    updateLastSavedTime,
  } = useEditorContext();
  const { addHistoryItem } = useUndoRedo();
  const { showToast } = useShowToast();
  const { t } = useTranslation();

  const { mutateAsync: updateTimePeriodMutation } = useMutation(
    ({ newTimePeriodData }: { newTimePeriodData: components['schemas']['TimePeriod'] }) =>
      updateTimePeriod({ timePeriodId: newTimePeriodData.id!, newTimePeriodData }),
    { onSuccess: () => updateLastSavedTime() }
  );

  const calculatedRectXPosition = scaleAdjustedTimePeriodTabWidths.reduce(
    (xPosition, tabWidthValue, currentIndex) => {
      if (currentIndex >= timePeriodIndex) {
        return xPosition;
      }
      return xPosition + tabWidthValue;
    },
    HORIZONTAL_OFFSET
  );

  const calculatedTabAreaYPosition = scaleAdjustedTimePeriodTabAreaHeights.reduce(
    (yPosition, tabAreaHeight, currentIndex) => {
      if (currentIndex <= timePeriodIndex) {
        return yPosition - tabAreaHeight;
      }
      return yPosition;
    },
    canvasHeightToFit
  );

  const onTabResizeDragEndHandler = useCallback(async () => {
    try {
      const newScaledTimePeriodWidths = scaleAdjustedTimePeriodTabWidths.map(
        (width, currentIndex) => {
          if (timePeriodIndex === currentIndex) {
            return width + draggedTabPositionChange;
          }
          if (timePeriodIndex + 1 === currentIndex) {
            return width - draggedTabPositionChange;
          }
          return width;
        }
      );

      const timePeriodsToUpdate = [
        sortedTimePeriods[timePeriodIndex],
        sortedTimePeriods[timePeriodIndex + 1],
      ].map(p => ({ ...p, roadmap_id: Number(roadmap.id) }));

      const newTimePeriodsToUpdate = timePeriodsToUpdate.map((tp, index) => ({
        ...tp,
        tabWidth: Math.round(
          newScaledTimePeriodWidths[timePeriodIndex + index] / timePeriodTabWidthScaleProportion
        ),
      }));

      queryClient.setQueryData(
        [getRoadmapTimePeriods.name, roadmap.id],
        (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
          const newTimePeriods = (oldTimePeriods || []).map(tp => {
            if (tp.id === newTimePeriodsToUpdate[0].id) {
              return {
                ...newTimePeriodsToUpdate[0],
              };
            }
            if (tp.id === newTimePeriodsToUpdate[1].id) {
              return {
                ...newTimePeriodsToUpdate[1],
              };
            }
            return tp;
          });

          return newTimePeriods;
        }
      );

      const timePeriodUpdatePromises = newTimePeriodsToUpdate.map(tp =>
        updateTimePeriodMutation({ newTimePeriodData: tp })
      );

      await Promise.all(timePeriodUpdatePromises);
      setIsDraggingTab(false);
      setDraggedTabPositionChange(0);
      setShouldRecalculateNodePositions(true);

      addHistoryItem({
        type: HistoryActionType.MoveTimePeriod,
        resourceIds: [
          sortedTimePeriods[timePeriodIndex].id!,
          sortedTimePeriods[timePeriodIndex + 1].id!,
        ],
        undo: async (resourceIds?: number[]) => {
          const timePeriodsWithUpdatedResourceIds = timePeriodsToUpdate.map(tp => {
            if (resourceIds && tp.id === newTimePeriodsToUpdate[0].id! && resourceIds[0]) {
              return { ...tp, id: resourceIds[0] };
            }
            if (resourceIds && tp.id === newTimePeriodsToUpdate[1].id! && resourceIds[1]) {
              return { ...tp, id: resourceIds[1] };
            }
            return tp;
          });

          queryClient.setQueryData(
            [getRoadmapTimePeriods.name, roadmap.id],
            (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
              const newTimePeriods = (oldTimePeriods || []).map(tp => {
                if (tp.id === timePeriodsWithUpdatedResourceIds[0].id) {
                  return {
                    ...timePeriodsWithUpdatedResourceIds[0],
                  };
                }
                if (tp.id === timePeriodsWithUpdatedResourceIds[1].id) {
                  return {
                    ...timePeriodsWithUpdatedResourceIds[1],
                  };
                }
                return tp;
              });

              return newTimePeriods;
            }
          );

          await Promise.all(
            timePeriodsWithUpdatedResourceIds.map(tp =>
              updateTimePeriodMutation({ newTimePeriodData: tp })
            )
          );
          setShouldRecalculateNodePositions(true);
        },
        redo: async (resourceIds?: number[]) => {
          const timePeriodsWithUpdatedResourceId = timePeriodsToUpdate.map(tp => {
            if (resourceIds && tp.id === newTimePeriodsToUpdate[0].id! && resourceIds[0]) {
              return { ...tp, id: resourceIds[0] };
            }
            if (resourceIds && tp.id === newTimePeriodsToUpdate[1].id! && resourceIds[1]) {
              return { ...tp, id: resourceIds[1] };
            }
            return tp;
          });

          queryClient.setQueryData(
            [getRoadmapTimePeriods.name, roadmap.id],
            (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
              const newTimePeriods = (oldTimePeriods || []).map(tp => {
                if (tp.id === timePeriodsWithUpdatedResourceId[0].id) {
                  return {
                    ...newTimePeriodsToUpdate[0],
                  };
                }
                if (tp.id === timePeriodsWithUpdatedResourceId[1].id) {
                  return {
                    ...newTimePeriodsToUpdate[1],
                  };
                }
                return tp;
              });

              return newTimePeriods;
            }
          );

          const newTimePeriodUpdatePromises = timePeriodsWithUpdatedResourceId.map(tp =>
            updateTimePeriodMutation({ newTimePeriodData: tp })
          );

          await Promise.all(newTimePeriodUpdatePromises);
          setShouldRecalculateNodePositions(true);
        },
      });
    } catch (e) {
      showToast('error', t('editor.canvas.edit_time_period_error'));
      await queryClient.invalidateQueries([getRoadmapTimePeriods.name, roadmap.id]);
    }
  }, [
    scaleAdjustedTimePeriodTabWidths,
    sortedTimePeriods,
    timePeriodIndex,
    queryClient,
    roadmap.id,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    setShouldRecalculateNodePositions,
    addHistoryItem,
    draggedTabPositionChange,
    timePeriodTabWidthScaleProportion,
    updateTimePeriodMutation,
    showToast,
    t,
  ]);

  const onTabAreaResizeDragEndHandler = useCallback(async () => {
    try {
      const newScaledTimePeriodHeights = scaleAdjustedTimePeriodTabAreaHeights.map(
        (height, currentIndex) => {
          if (timePeriodIndex === currentIndex) {
            return height - draggedTabPositionChange;
          }
          if (timePeriodIndex + 1 === currentIndex) {
            return height + draggedTabPositionChange;
          }
          return height;
        }
      );

      const timePeriodsToUpdate = [
        sortedTimePeriods[timePeriodIndex],
        sortedTimePeriods[timePeriodIndex + 1],
      ].map(p => ({ ...p, roadmap_id: Number(roadmap.id) }));

      const newTimePeriodsToUpdate = timePeriodsToUpdate.map((tp, index) => ({
        ...tp,
        areaHeight: Math.round(
          newScaledTimePeriodHeights[timePeriodIndex + index] / timePeriodTabHeightScaleProportion
        ),
      }));

      queryClient.setQueryData(
        [getRoadmapTimePeriods.name, roadmap.id],
        (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
          const newTimePeriods = (oldTimePeriods || []).map(tp => {
            if (tp.id === newTimePeriodsToUpdate[0].id) {
              return {
                ...newTimePeriodsToUpdate[0],
              };
            }
            if (tp.id === newTimePeriodsToUpdate[1].id) {
              return {
                ...newTimePeriodsToUpdate[1],
              };
            }
            return tp;
          });

          return newTimePeriods;
        }
      );

      const timePeriodUpdatePromises = newTimePeriodsToUpdate.map(tp =>
        updateTimePeriodMutation({ newTimePeriodData: tp })
      );

      await Promise.all(timePeriodUpdatePromises);
      setIsDraggingTab(false);
      setDraggedTabPositionChange(0);
      setShouldRecalculateNodePositions(true);

      addHistoryItem({
        type: HistoryActionType.MoveTimePeriod,
        undo: async () => {
          queryClient.setQueryData(
            [getRoadmapTimePeriods.name, roadmap.id],
            (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
              const newTimePeriods = (oldTimePeriods || []).map(tp => {
                if (tp.id === timePeriodsToUpdate[0].id) {
                  return {
                    ...timePeriodsToUpdate[0],
                  };
                }
                if (tp.id === timePeriodsToUpdate[1].id) {
                  return {
                    ...timePeriodsToUpdate[1],
                  };
                }
                return tp;
              });

              return newTimePeriods;
            }
          );

          const newTimePeriodUpdatePromises = timePeriodsToUpdate.map(tp =>
            updateTimePeriodMutation({ newTimePeriodData: tp })
          );

          await Promise.all(newTimePeriodUpdatePromises);
        },
        redo: async () => {
          queryClient.setQueryData(
            [getRoadmapTimePeriods.name, roadmap.id],
            (oldTimePeriods?: components['schemas']['TimePeriod'][]) => {
              const newTimePeriods = (oldTimePeriods || []).map(tp => {
                if (tp.id === newTimePeriodsToUpdate[0].id) {
                  return {
                    ...newTimePeriodsToUpdate[0],
                  };
                }
                if (tp.id === newTimePeriodsToUpdate[1].id) {
                  return {
                    ...newTimePeriodsToUpdate[1],
                  };
                }
                return tp;
              });

              return newTimePeriods;
            }
          );

          const newTimePeriodUpdatePromises = newTimePeriodsToUpdate.map(tp =>
            updateTimePeriodMutation({ newTimePeriodData: tp })
          );

          await Promise.all(newTimePeriodUpdatePromises);
          setShouldRecalculateNodePositions(true);
        },
      });
    } catch (e) {
      showToast('error', t('editor.canvas.edit_time_period_error'));
      await queryClient.invalidateQueries([getRoadmapTimePeriods.name, roadmap.id]);
    }
  }, [
    scaleAdjustedTimePeriodTabAreaHeights,
    sortedTimePeriods,
    timePeriodIndex,
    queryClient,
    roadmap.id,
    setIsDraggingTab,
    setDraggedTabPositionChange,
    setShouldRecalculateNodePositions,
    addHistoryItem,
    draggedTabPositionChange,
    timePeriodTabHeightScaleProportion,
    updateTimePeriodMutation,
    showToast,
    t,
  ]);

  const tabAreaHeightHandlerPosition = {
    x:
      calculatedRectXPosition +
      scaleAdjustedTimePeriodTabWidths[timePeriodIndex] +
      calculatedTabAreaYPosition / 5,

    y: TAB_HEIGHT + calculatedTabAreaYPosition - VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT / 2,
  };

  const successorIdWithScaledWidth =
    timePeriodIndex < scaleAdjustedTimePeriodTabWidths.length - 1
      ? {
          successorId: sortedTimePeriods[timePeriodIndex + 1].id,
          successorWidth: scaleAdjustedTimePeriodTabWidths[timePeriodIndex + 1],
        }
      : {
          successorId: undefined,
          successorWidth: 0,
        };

  const tabAreaShapePoints = useMemo(() => {
    return [
      0,
      TAB_HEIGHT,
      scaleAdjustedTimePeriodTabWidths[timePeriodIndex],
      TAB_HEIGHT,
      scaleAdjustedTimePeriodTabWidths[timePeriodIndex],
      TAB_HEIGHT + calculatedTabAreaYPosition,
      canvasWidthToFit - calculatedRectXPosition + HORIZONTAL_OFFSET,
      TAB_HEIGHT + calculatedTabAreaYPosition,
      canvasWidthToFit - calculatedRectXPosition + HORIZONTAL_OFFSET,
      canvasHeightToFit - calculatedTabAreaYPosition,
    ];
  }, [
    calculatedRectXPosition,
    calculatedTabAreaYPosition,
    canvasHeightToFit,
    canvasWidthToFit,
    scaleAdjustedTimePeriodTabWidths,
    timePeriodIndex,
  ]);

  const hasSuccessorWithValidId = Boolean(
    successorIdWithScaledWidth.successorId &&
      successorIdWithScaledWidth.successorId > 0 &&
      !temporaryCreatedIds.has(successorIdWithScaledWidth.successorId)
  );

  const shouldDisableActionMenuBecauseOfUsedTemporaryId =
    timePeriodId < 0 || temporaryCreatedIds.has(timePeriodId);

  const shouldShowResizeHandler =
    !shouldDisableActionMenuBecauseOfUsedTemporaryId &&
    !isPreview &&
    !isLast &&
    hasSuccessorWithValidId;

  return (
    <TimePeriodTabShape
      key={timePeriodId}
      x={calculatedRectXPosition}
      timePeriodId={timePeriodId}
      timePeriodIndex={timePeriodIndex}
      timePeriodTitle={timePeriodTitle || ''}
      timePeriodTabWidth={scaleAdjustedTimePeriodTabWidths[timePeriodIndex]}
      timePeriodTabHeight={scaleAdjustedTimePeriodTabAreaHeights[timePeriodIndex]}
      timePeriodOrder={timePeriodOrder}
      cornerRadius={[10, 10, 0, 0]}
      successorWidth={successorIdWithScaledWidth.successorWidth}
      textColor={theme.palette.brand.textPrimary}
      fill={darken(timePeriodIndex * 0.04, theme.palette.brand.backgroundDialog)}
      onDragEnd={onTabResizeDragEndHandler}
      handlerHeight={calculatedTabAreaYPosition}
      shouldShowResizeHandler={shouldShowResizeHandler}
      shouldDisableActionMenuBecauseOfUsedTemporaryId={
        shouldDisableActionMenuBecauseOfUsedTemporaryId
      }
    >
      <>
        <TimePeriodTabAreaShape
          isLast={isLast}
          height={scaleAdjustedTimePeriodTabAreaHeights[timePeriodIndex]}
          timePeriodId={timePeriodId}
          startX={scaleAdjustedTimePeriodTabWidths[timePeriodIndex]}
          startY={TAB_HEIGHT}
          points={tabAreaShapePoints}
          isFirst={timePeriodIndex === 0}
        />

        {shouldShowResizeHandler && (
          <Portal selector=".top-layer" enabled>
            <Rect
              width={
                canvasWidthToFit -
                calculatedRectXPosition -
                scaleAdjustedTimePeriodTabWidths[timePeriodIndex] -
                calculatedTabAreaYPosition / 5 +
                HORIZONTAL_OFFSET
              }
              height={VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT}
              x={tabAreaHeightHandlerPosition.x}
              y={tabAreaHeightHandlerPosition.y}
              draggable
              onMouseEnter={e => {
                const container = e.target.getStage()!.container();
                container.style.cursor = 'row-resize';
              }}
              onMouseLeave={e => {
                const stage = e.target.getStage();
                if (stage) {
                  const container = stage.container();
                  container.style.cursor = 'default';
                }
              }}
              onDragStart={() => {
                setIsDraggingTab(true);
              }}
              onDragMove={evt => {
                const rect = evt.target;
                const currentY = rect.y();
                const deltaY = currentY - tabAreaHeightHandlerPosition.y;
                setDraggedTabPositionChange(deltaY);
              }}
              dragBoundFunc={pos =>
                checkBoundaries({
                  rectXPosition: tabAreaHeightHandlerPosition.x,
                  rectYPosition: tabAreaHeightHandlerPosition.y,
                  posX: pos.x,
                  posY: pos.y,
                  tabHeight: scaleAdjustedTimePeriodTabAreaHeights[timePeriodIndex],
                  neighborTabHeight: scaleAdjustedTimePeriodTabAreaHeights[timePeriodIndex + 1],
                })
              }
              onDragEnd={onTabAreaResizeDragEndHandler}
            />
          </Portal>
        )}
      </>
    </TimePeriodTabShape>
  );
};

const checkBoundaries = ({
  rectXPosition,
  rectYPosition,
  posY,
  tabHeight,
  neighborTabHeight,
}: {
  neighborTabHeight: number;
  posX: number;
  posY: number;
  rectXPosition: number;
  rectYPosition: number;
  tabHeight: number;
}) => {
  let newY = posY;

  if (
    newY <
    rectYPosition -
      neighborTabHeight +
      MIN_TAB_AREA_HEIGHT +
      VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT / 2 +
      STAGE_OFFSET
  ) {
    newY =
      rectYPosition -
      neighborTabHeight +
      MIN_TAB_AREA_HEIGHT +
      VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT / 2 +
      STAGE_OFFSET;
  } else if (
    newY >
    rectYPosition +
      tabHeight -
      MIN_TAB_AREA_HEIGHT -
      VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT / 2 +
      STAGE_OFFSET
  ) {
    newY =
      rectYPosition +
      tabHeight -
      MIN_TAB_AREA_HEIGHT -
      VERTICAL_TAB_AREA_HEIGHT_HANDLER_HEIGHT / 2 +
      STAGE_OFFSET;
  }

  return { x: rectXPosition + STAGE_OFFSET, y: newY };
};

export { TimePeriod };
