/* eslint-disable react/jsx-no-literals */
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { transparentize } from 'polished';

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  const { value } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  );
};

const ProgressBar = ({ value }: { value: string }) => {
  const numberValue = parseInt(value);

  const theme = useTheme();
  return (
    <Box sx={{ width: '100%', height: '20px' }}>
      <LinearProgressWithLabel
        color="warning"
        value={numberValue}
        sx={{
          '&.MuiLinearProgress-root': {
            height: '20px',
            borderRadius: '2rem',
            backgroundColor: theme.palette.brand.white,
            boxShadow: `inset 0px 0px 6px -1px ${transparentize(0.5, theme.palette.brand.black)}`,
          },
          '& .MuiLinearProgress-bar': {
            backgroundColor: theme.palette.brand.progressBar,
          },
        }}
      />
    </Box>
  );
};

export { ProgressBar };
