import { publicApi } from '@/api';

// 200 OK - BE is ready
// 503 Service Unavailable - Maintenance
// 504 Gateway Timeout - BE is not running at all

export async function getServiceStatus() {
  const { status } = await publicApi.get('/service/status');
  return status;
}
