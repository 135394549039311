import { CircularProgress } from '@mui/material';
import styled, { css } from 'styled-components';

import { Linkage } from '@/features/canvas/components/ImportAccordionContent/Linkage';
import { useImportInitiativeContext } from '@/features/canvas/contexts/import-initiative-context';

import { CsvLinkage } from './CsvLinkage';

const ImportAccordionContent = ({ isExpanded }: { isExpanded: boolean }) => {
  const { isInitiativeImportRunning } = useImportInitiativeContext();
  return (
    <ContentWrapper $isExpanded={isExpanded}>
      {isInitiativeImportRunning && (
        <LoaderWrapper>
          <StyledLoader />
        </LoaderWrapper>
      )}
      <CsvLinkage />
      <Linkage />
    </ContentWrapper>
  );
};

export { ImportAccordionContent };

const ContentWrapper = styled.div<{ $isExpanded: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 4rem 1rem 1.5rem;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      overflow-y: auto;
      height: 100%;
      animation: hide-scroll 0.3s backwards;
    `}
  @keyframes hide-scroll {
    from,
    to {
      overflow: hidden;
    }
  }
`;
const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;
