import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, CircularProgress, Divider, IconButton, MenuItem, useTheme } from '@mui/material';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { ReactComponent as EyeCrossed } from '@/assets/icons/eye-crossed.svg';
import { handleNodeVisibility } from '@/features/canvas/api';
import { StyledSelect } from '@/features/canvas/components/StyledSelect';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { useShowToast } from '@/hooks/useShowToast';

type Props = {
  isInitiativeLoading: boolean;
};

const InitiativeAccordionContentInitiativeInfo = ({ isInitiativeLoading }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { showToast } = useShowToast();
  const {
    filteredNodes,
    setFilteredNodes,
    selectedNodeId,
    setSelectedNodeId,
    setIsInitiativeDetailDialogOpen,
  } = useEditorContext();

  const sortedFilteredNodesByTitle = useMemo(
    () =>
      filteredNodes
        .map(n => ({
          ...n,
          id: n.id!,
          title: n.initiatives?.title || n.title || t('untitled'),
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [filteredNodes, t]
  );

  const {
    mutateAsync: exposeNodesInitiative,
    isLoading: isExposingNodesInitiative,
    variables: exposeVariables,
  } = useMutation(
    ({ nodeId, isTimelineVisible }: { isTimelineVisible: boolean; nodeId: number }) =>
      handleNodeVisibility({ nodeId, isTimelineVisible }),
    {
      onSuccess: (_, { nodeId, isTimelineVisible }) => {
        const updatedFilteredNodes = filteredNodes.map(fNode => {
          if (nodeId === fNode.id) {
            return { ...fNode, isVisible: isTimelineVisible };
          }
          return fNode;
        });
        setFilteredNodes(updatedFilteredNodes);
        showToast('success', t('editor.sidebar.initiative.initiative_reveal_success'));
      },
      onError: () => {
        showToast('error', t('editor.sidebar.initiative.edit_field_error'));
      },
    }
  );

  return (
    <div>
      <InitiativeHeaders>{t('editor.sidebar.initiative_info')}</InitiativeHeaders>
      <Divider variant="fullWidth" sx={{ borderColor: theme.palette.brand.backgroundDialog }} />
      <Box sx={{ minWidth: 120, marginTop: '1rem' }}>
        <StyledSelect
          placeholder={t('editor.sidebar.select_initiative_placeholder')}
          selectedId={selectedNodeId}
          options={sortedFilteredNodesByTitle.map(n => ({ id: n.id, title: n.title }))}
          onChange={value => {
            setSelectedNodeId(value);
            setIsInitiativeDetailDialogOpen(true);
          }}
        >
          {sortedFilteredNodesByTitle.map(n => {
            const isNotSameId = exposeVariables?.nodeId !== n.id;
            const showWhileUpdating = Boolean(isExposingNodesInitiative && isNotSameId);

            return (
              <StyledMenuItem key={n.id} value={n.id} $isVisible={Boolean(n.isVisible)}>
                {n.title}
                {!n.isVisible && (
                  <IconButton
                    onClick={async e => {
                      e.stopPropagation();
                      await exposeNodesInitiative({
                        nodeId: n.id,
                        isTimelineVisible: true,
                      });
                    }}
                    sx={{
                      padding: '0.25rem',
                      height: '1.5rem',
                    }}
                  >
                    {isExposingNodesInitiative && exposeVariables?.nodeId === n.id && (
                      <StyledInitiativeSelectVisibleLoader size="1.5rem" />
                    )}
                    {(!isExposingNodesInitiative || showWhileUpdating) && <StyledEye />}
                  </IconButton>
                )}
              </StyledMenuItem>
            );
          })}
        </StyledSelect>

        {isInitiativeLoading && (
          <LoaderWrapper>
            <StyledLoader />
          </LoaderWrapper>
        )}
      </Box>
    </div>
  );
};

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

const StyledInitiativeSelectVisibleLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

const StyledMenuItem = styled(MenuItem)<{ $isVisible: boolean }>`
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;

  ${({ $isVisible, theme }) =>
    !$isVisible &&
    css`
      color: ${transparentize(0.8, theme.palette.brand.textPrimary)};
    `}
`;

const StyledEye = styled(EyeCrossed)`
  width: 100%;
  height: 100%;
`;

const InitiativeHeaders = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

export { InitiativeAccordionContentInitiativeInfo };
