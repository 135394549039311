import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  createFilterNode,
  createFilterOption,
  csvImportNewNode,
  getFiltersByRoadmapId,
} from '@/features/canvas/api';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { FilterOptionValue } from '@/features/canvas/types/filter-option';
import { InitiativeFieldValues, InitiativeToBeImported } from '@/features/canvas/types/initiative';
import { components } from '@/types/api';

interface UseImportedInitiativeDropProps {
  roadmapId: number;
}

export const useCSVImportedInitiativeDrop = ({ roadmapId }: UseImportedInitiativeDropProps) => {
  const { updateLastSavedTime, filters } = useEditorContext();
  const queryClient = useQueryClient();

  const { mutateAsync: createFilterOptionMutation } = useMutation(
    ({ filterId, payload }: { filterId: number; payload: components['schemas']['FilterOption'] }) =>
      createFilterOption({ filterId, payload }),
    { onSuccess: () => updateLastSavedTime() }
  );
  const { mutateAsync: createFilterNodeMutation } = useMutation(
    ({ filterId, payload }: { filterId: number; payload: components['schemas']['FilterNode'] }) =>
      createFilterNode({ filterId, payload }),
    { onSuccess: () => updateLastSavedTime() }
  );

  const { mutateAsync: csvImportNewNodeMutation } = useMutation(
    ({ csvNode }: { csvNode: components['schemas']['NodeImportCSV'] }) =>
      csvImportNewNode({ roadmapId, csvNode }),
    {
      onSuccess: () => updateLastSavedTime(),
    }
  );

  const createFilterOptions = useCallback(
    async ({
      filter,
      newlyCreatedNode,
      filterItemToImport,
    }: {
      filter: components['schemas']['Filter'];
      filterItemToImport: FilterOptionValue;
      newlyCreatedNode: components['schemas']['NodeImportCSV'];
    }) => {
      const matchingOption = filter.options.find(fo => fo.title === filterItemToImport.value);

      if (!matchingOption) {
        const newOption = await createFilterOptionMutation({
          filterId: filter.id!,
          payload: { color: '', title: filterItemToImport.value },
        });
        const newFilterNode = await createFilterNodeMutation({
          filterId: filter.id!,
          payload: { nodeId: newlyCreatedNode.id!, optionId: newOption.id! },
        });
        return newFilterNode;
      }
      const newFilterNode = await createFilterNodeMutation({
        filterId: filter.id!,
        payload: { nodeId: newlyCreatedNode.id!, optionId: matchingOption.id! },
      });

      return newFilterNode;
    },
    [createFilterNodeMutation, createFilterOptionMutation]
  );

  const onCSVImportedInitiativeDrop = useCallback(
    async ({
      initiative,
      newInitiativeFieldValues,
      newInitiativeFilterValues,
      newRoadmapNode,
    }: {
      initiative: InitiativeToBeImported;
      newRoadmapNode: components['schemas']['NodeImportCSV'];
      newInitiativeFieldValues?: InitiativeFieldValues[];
      newInitiativeFilterValues?: FilterOptionValue[];
    }) => {
      const { id, ...nodeWithoutId } = newRoadmapNode;

      const fields = [...(newInitiativeFieldValues || [])].map(fv => {
        const { id: fieldId, ...fieldWithoutId } = fv;
        return fieldWithoutId;
      });

      const fieldsWithoutDuplicates = fields.reduce((accumulator, currentValue) => {
        const { title, type, value } = currentValue;
        const existingItem = accumulator.find(item => item.title === title && item.type === type);
        if (existingItem) {
          if (value !== undefined) {
            existingItem.value = value;
          }
        } else {
          accumulator.push(currentValue);
        }
        return accumulator;
      }, [] as typeof fields);

      const newlyCreatedNode = await csvImportNewNodeMutation({
        csvNode: {
          ...nodeWithoutId,
          initiatives: {
            title: initiative.title,
            fields: fieldsWithoutDuplicates,
          },
        },
      });

      if (newInitiativeFilterValues?.length) {
        const newFilterOptionsAndNodesPromises = newInitiativeFilterValues.map(async ifv => {
          const filter = filters.find(f => f.id === Number(ifv.id))!;
          if (filter.selection === 'multiple') {
            const splitItems = ifv.value.split(',').map(i => i.trim());
            const splitOptionsPromises = splitItems.map(async splitItem => {
              const filterNode = await createFilterOptions({
                filter,
                newlyCreatedNode,
                filterItemToImport: { ...ifv, value: splitItem },
              });
              return filterNode;
            });
            await Promise.all(splitOptionsPromises);
          } else {
            const filterNode = await createFilterOptions({
              filter,
              newlyCreatedNode,
              filterItemToImport: ifv,
            });
            return filterNode;
          }
        });
        await Promise.all(newFilterOptionsAndNodesPromises);
        await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmapId]);
      }
    },
    [createFilterOptions, csvImportNewNodeMutation, filters, queryClient, roadmapId]
  );

  return {
    onCSVImportedInitiativeDrop,
  };
};
