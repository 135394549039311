import { createContext, useContext } from 'react';
import {
  FieldValues,
  Mode,
  SubmitHandler,
  useForm,
  UseFormReturn,
  ValidationMode,
} from 'react-hook-form';

export type FormProps<TFormValues extends FieldValues> = {
  children: React.ReactNode;
  className?: string;
  defaultValues?: Record<string, TFormValues[keyof TFormValues]>;
  mode?: keyof ValidationMode;
  onSubmit?: SubmitHandler<Record<string, TFormValues[keyof TFormValues]>>;
  reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>;
};

export const FormContext = createContext<null | UseFormReturn>(null);

export const Form = <TFormValues extends FieldValues = FieldValues>({
  children,
  onSubmit,
  defaultValues,
  mode = 'onChange',
  reValidateMode = 'onChange',
  className,
}: FormProps<TFormValues>) => {
  const formProps = useForm({
    defaultValues,
    mode,
    reValidateMode,
  });

  return (
    <FormContext.Provider value={formProps}>
      <form className={className} onSubmit={formProps.handleSubmit(onSubmit ?? (() => {}))}>
        {children}
      </form>
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext) as UseFormReturn;
