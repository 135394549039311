/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { transparentize } from 'polished';
import CircleIconSrc from 'src/assets/icons/circle.svg';
import TriangleIconSrc from 'src/assets/icons/triangle.svg';
import styled, { css } from 'styled-components';

import RectangleIconSrc from '@/assets/icons/square.svg';
import { ColorPicker } from '@/features/canvas/components/ColorPicker';
import { CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH } from '@/features/canvas/constants';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';

const FieldLinkingFilterOption = ({
  id,
  title,
  filterShape,
  handleUpdate,
  handleDelete,
  color,
  isNotEditable,
}: {
  color: string;
  filterShape: 'circle' | 'square' | 'triangle';
  handleDelete: (id: string) => void;
  handleUpdate: ({
    id,
    color,
    title,
    isNotEditable,
  }: {
    color: string;
    id: string;
    title: string;
    isNotEditable?: boolean;
  }) => void;
  id: string;
  title: string;
  isNotEditable?: boolean;
}) => {
  const [pickerColor, setPickerColor] = useState<string>(color);
  const [pickerAnchorEl, setPickerAnchorEl] = useState<SVGElement | null>(null);
  const { setIsSidebarContentLoading } = useEditorContext();

  const [inputValue, setInputValue] = useState<string>(title);
  const [editMode, setEditMode] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickColor = (event: React.MouseEvent<SVGElement>) => {
    setPickerAnchorEl(event.currentTarget);
  };

  const handleCloseColorPicker = () => {
    setIsSidebarContentLoading(true);
    setPickerAnchorEl(null);
    handleUpdate({ id, color: pickerColor, title, isNotEditable });
    setIsSidebarContentLoading(false);
  };

  const handleEditColorPicker = (hex: string) => {
    setPickerColor(hex);
  };

  const getShapeIcon = () => {
    switch (filterShape) {
      case 'triangle':
        return <StyledSVG src={TriangleIconSrc} color={color} onClick={onClickColor} />;
      case 'square':
        return <StyledSVG src={RectangleIconSrc} color={color} onClick={onClickColor} />;
      default:
        return <StyledSVG src={CircleIconSrc} color={color} onClick={onClickColor} />;
    }
  };

  const Icon = getShapeIcon();

  const handleFocus = () => {
    setEditMode(true);
  };

  const handleBlur = () => {
    inputRef.current?.blur();
    setEditMode(false);
    if (inputValue === title) {
      return;
    }
    const updatedOption = { id, color, title: inputValue };
    handleUpdate({ ...updatedOption });
  };

  const handleInputChange = (event: { target: { value: SetStateAction<string> } }) => {
    if (event.target.value.length > CUSTOM_FIELD_MAX_SINGLE_LINE_LENGTH) {
      return;
    }
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <>
      <ColorPicker
        anchorEl={pickerAnchorEl}
        handleClose={handleCloseColorPicker}
        handleEdit={handleEditColorPicker}
        pickerColor={pickerColor}
      />
      <OptionWrapper $isNotEditable={isNotEditable}>
        <StartOptionWrapper $pointerCursor>
          {Icon}
          <TitleInput
            type="text"
            ref={inputRef}
            value={inputValue}
            readOnly={isNotEditable}
            {...(!isNotEditable && {
              $editMode: editMode,
              onChange: handleInputChange,
              onBlur: handleBlur,
              onKeyDown: handleKeyDown,
              onFocus: handleFocus,
            })}
          />
        </StartOptionWrapper>
        {!isNotEditable && (
          <DeleteWrapper onClick={() => handleDelete(id)}>
            <StyledDelete />
          </DeleteWrapper>
        )}
      </OptionWrapper>
    </>
  );
};
const StartOptionWrapper = styled.div<{ $pointerCursor: boolean }>`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  ${({ $pointerCursor }) =>
    $pointerCursor &&
    css`
      cursor: pointer;
    `}
`;

const StyledSVG = styled(SVG)`
  width: 1.4rem;
  height: 1.4rem;
  overflow: visible;
  & path {
    fill: ${({ color }) => color};
    stroke: ${({ color }) => color};
  }
`;

const OptionWrapper = styled.div<{ $isNotEditable?: boolean }>`
  height: 25px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  border-radius: 1rem;
  padding: 0 0.5rem;
  ${({ $isNotEditable }) =>
    $isNotEditable &&
    css`
      background-color: ${({ theme }) => transparentize(0.5, theme.palette.brand.backgroundDialog)};
    `}
`;

const TitleInput = styled.input<{ $editMode?: boolean }>`
  width: 100%;
  border: none;
  &:focus-visible {
    outline: none;
  }

  ${({ theme }) =>
    css`
      background-color: ${theme.palette.brand.backgroundDialog};
    `}

  ${({ $editMode }) =>
    $editMode &&
    css`
      outline: none;
    `}
  
  ${({ readOnly }) =>
    readOnly &&
    css`
      background-color: unset;
    `}
`;

const DeleteWrapper = styled.div`
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledDelete = styled.div`
  width: 8px;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.brand.textSecondary};
`;

export { FieldLinkingFilterOption };
