import { useMutation, UseMutationOptions } from 'react-query';
import i18n from 'i18next';
import { defaultApi } from 'src/api';
import { SupportForm } from 'src/features/support/types/SupportForm';
import { useShowToast } from '@/hooks/useShowToast';
import { useTranslation } from 'react-i18next';

const useSupportMutation = (options?: UseMutationOptions<void, unknown, SupportForm>) => {
  const { showToast } = useShowToast();
  const { t } = useTranslation();
  return useMutation<void, unknown, SupportForm>(async formData => {
    try {
      const response = await defaultApi.post('/send-support-request', formData);
      showToast('success', t('support.send_success'));
      return response.data;
    } catch (error) {
      showToast('error', t('support.send_error'));
      throw new Error(i18n.t('error', { message: error }));
    }
  }, options);
};

export default useSupportMutation;
