import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { CircularProgress } from '@mui/material';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { updateRoadmapById } from '@/features/canvas/api';
import { useEditorContext } from '@/features/canvas/contexts/editor-context';
import { getRoadmapById } from '@/features/roadmaps/api';
import { useFormContext } from '@/features/ui/components/Form';
import { FormTextInput } from '@/features/ui/components/FormTextInput';
import { components } from '@/types/api';

const EditRoadmapTitleFormContent = ({
  isUpdatingRoadmap,
  setIsEditingTitle,
}: {
  isUpdatingRoadmap: boolean;
  setIsEditingTitle: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { getValues } = useFormContext();
  const { roadmap, updateLastSavedTime } = useEditorContext();
  const { mutateAsync: updateRoadmap, isLoading } = useMutation(
    ({
      id,
      newRoadmapData,
    }: {
      id: number;
      newRoadmapData: components['schemas']['RoadmapDetail'];
    }) => updateRoadmapById({ id, newRoadmapData }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([getRoadmapById.name, roadmap.id]);
        setIsEditingTitle(false);
        updateLastSavedTime();
      },
    }
  );

  return (
    <StyledInput
      name="title"
      disabled={isUpdatingRoadmap || isLoading}
      autoFocus
      inputProps={{
        'aria-label': t('editor.header.title'),
        maxLength: 32,
        sx: { textAlign: 'center', padding: 0, height: 'auto' },
      }}
      onBlur={async () => {
        const formData = getValues();
        await updateRoadmap({
          id: roadmap.id!,
          newRoadmapData: { ...roadmap, title: formData.title },
        });
      }}
      {...((isUpdatingRoadmap || isLoading) && {
        endAdornment: <StyledLoader size={20} />,
      })}
    />
  );
};

const StyledInput = styled(FormTextInput).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    defaultValidatorFn(prop) || prop === 'inputProps',
})`
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 0.5rem;
  padding: 0;
  border: 1px solid ${({ theme }) => transparentize(0.7, theme.palette.brand.corePPTCopy)};
  &:focus-within {
    border: 1px solid ${({ theme }) => transparentize(0.7, theme.palette.brand.corePPTCopy)};
    background: ${({ theme }) => theme.palette.brand.white};
  }
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

export { EditRoadmapTitleFormContent };
