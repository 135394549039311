/* eslint-disable max-lines */
/* eslint-disable max-statements */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Box, CircularProgress, Divider, MenuItem, Select, useTheme } from '@mui/material';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';
import {
  createRoadmapFilter,
  deleteFilterById,
  editFilter,
  getFiltersByRoadmapId,
} from '@/features/canvas/api';
import { FilterFactory } from '@/features/canvas/components/FiltersAccordionContent/FilterFactory/FilterFactory';
import { FiltersAccordionSubContent } from '@/features/canvas/components/FiltersAccordionSubContent';
import { SidebarListSubItem } from '@/features/canvas/components/SidebarListSubItem';
import { FilteredNodes, useEditorContext } from '@/features/canvas/contexts/editor-context';
import { FilterTypes } from '@/features/canvas/types/filter';
import { ConfirmationDialog } from '@/features/ui/components/ConfirmationDialog';
import { useShowToast } from '@/hooks/useShowToast';
import { components } from '@/types/api';

const FiltersAccordionContent = ({ isExpanded }: { isExpanded: boolean }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { filters, roadmap, filteredNodes, setFilteredNodes, activeFilterId, updateLastSavedTime } =
    useEditorContext();
  const { showToast } = useShowToast();
  const queryClient = useQueryClient();

  const selectOptionsInit = useMemo(
    () => [
      { id: FilterTypes.PRIORITY, title: t('editor.sidebar.filter.priority_select_option') },
      { id: FilterTypes.RAG, title: t('editor.sidebar.filter.rag_select_option') },
      {
        id: FilterTypes.PROGRESS_STATUS,
        title: t('editor.sidebar.filter.progress_status_select_option'),
      },
      {
        id: FilterTypes.CUSTOM_SINGLE,
        title: t('editor.sidebar.filter.custom_single_select_option'),
      },
      {
        id: FilterTypes.CUSTOM_MULTI,
        title: t('editor.sidebar.filter.custom_multi_select_option'),
      },
    ],
    [t]
  );

  const [selectedFilterOptionId, setSelectedFilterOptionId] = useState<string | null>(null);
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<components['schemas']['Filter'] | undefined>(
    undefined
  );
  const [isDeleteOptionDialogOpen, setIsDeleteOptionDialogOpen] = useState(false);
  const [expandedAccordionKey, setExpandedAccordionKey] = useState<null | number>(null);
  const [selectOptions, setSelectOptions] =
    useState<{ id: string; title: string }[]>(selectOptionsInit);

  const getFilterOptions = useCallback(
    (roadmapFilters: components['schemas']['Filter'][]) => {
      if (!roadmapFilters) {
        return selectOptionsInit;
      }
      return selectOptionsInit.filter(
        selectOption =>
          !roadmapFilters.some(
            f =>
              f.title === selectOption.id &&
              f.title !== FilterTypes.CUSTOM_MULTI &&
              f.title !== FilterTypes.CUSTOM_SINGLE
          )
      );
    },
    [selectOptionsInit]
  );

  useEffect(() => {
    const filterSelectOption = getFilterOptions(filters);
    setSelectOptions(filterSelectOption);
  }, [filters, getFilterOptions]);

  const { mutateAsync: deleteFilter } = useMutation(async (id: number) => {
    try {
      await deleteFilterById(id);
      updateLastSavedTime();
      showToast('success', t('editor.sidebar.filter.delete_filter_success'));
    } catch (e) {
      showToast('error', t('editor.sidebar.filter.delete_filter_error'));
    }
  });

  const { mutateAsync: createFilter } = useMutation(
    async (filter: components['schemas']['Filter']) => {
      try {
        await createRoadmapFilter({ roadmapId: roadmap.id!, payload: filter });
        updateLastSavedTime();
        showToast('success', t('editor.sidebar.filter.create_filter_success'));
      } catch (e) {
        showToast('error', t('editor.sidebar.filter.create_filter_error'));
      }
    }
  );

  const { mutateAsync: updateFilter } = useMutation(
    async (filter: components['schemas']['Filter']) => {
      try {
        await editFilter(filter.id!, filter);
        updateLastSavedTime();
        showToast('success', t('editor.sidebar.filter.edit_filter_success'));
      } catch (e) {
        showToast('error', t('editor.sidebar.filter.edit_filter_error'));
      }
    }
  );

  const removeSelectOption = (filterType: string) => {
    const newFilterOptions = selectOptions.filter(selectOption => selectOption.id !== filterType);
    setSelectOptions(newFilterOptions);
  };

  const onChangeSelect = async (filterType: string) => {
    setIsFilterLoading(true);

    switch (filterType) {
      case FilterTypes.PRIORITY:
        await createFilter(FilterFactory.createPriorityFilter().getFilter());
        break;
      case FilterTypes.RAG:
        await createFilter(FilterFactory.createRAGFilter().getFilter());
        break;
      case FilterTypes.PROGRESS_STATUS:
        await createFilter(FilterFactory.createProgressStatusFilter().getFilter());
        break;
      case FilterTypes.CUSTOM_MULTI:
        await createFilter(FilterFactory.createCustomMultiFilter().getFilter());
        break;
      default:
        await createFilter(FilterFactory.createCustomSingleFilter().getFilter());
        break;
    }

    if (filterType !== FilterTypes.CUSTOM_SINGLE && filterType !== FilterTypes.CUSTOM_MULTI) {
      removeSelectOption(filterType);
    }

    setSelectedFilterOptionId(null);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmap.id]);

    setIsFilterLoading(false);
  };

  const handleDelete = async (filter: components['schemas']['Filter']) => {
    setIsFilterLoading(true);

    const filterOption = selectOptionsInit.find(selectOption => selectOption.id === filter.title);
    if (filterOption) {
      setSelectOptions([...selectOptions, filterOption]);
    }
    setIsDeleteOptionDialogOpen(false);
    await deleteFilter(filter.id!);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmap.id]);
    setIsFilterLoading(false);
  };

  const onDeleteClick = (filterId: number, e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    const filter = filters?.find(f => f.id === filterId);
    setSelectedFilter(filter);
    setIsDeleteOptionDialogOpen(true);
  };

  const onShowClick = async (
    filterId: number,
    e: React.MouseEvent<HTMLImageElement>,
    value: boolean
  ) => {
    e.stopPropagation();
    const filter = filters?.find(f => f.id === filterId);
    const filterToUpdate = { ...filter, isVisible: value };
    await handleEdit(filterToUpdate as components['schemas']['Filter']);
  };

  const handleEdit = async (filter: components['schemas']['Filter']) => {
    await updateFilter(filter);
    await queryClient.invalidateQueries([getFiltersByRoadmapId.name, roadmap.id]);

    const newNodes = filteredNodes.map(currentNode => {
      if (filter.id === activeFilterId) {
        const isFilteredNode = filter.nodes?.some(n => n.nodeId === currentNode.id);
        if (isFilteredNode) {
          return {
            ...currentNode,
            shape: filter.shape,
            color: filter.options[0].color,
          };
        }
      }

      return currentNode;
    });

    setFilteredNodes(newNodes as FilteredNodes[]);
  };

  return (
    <ContentWrapper $isExpanded={isExpanded}>
      <Box>
        <StyledSelect
          value={selectedFilterOptionId === null ? '' : selectedFilterOptionId}
          onChange={e => onChangeSelect(e.target.value as string)}
          SelectDisplayProps={{
            style: {
              backgroundColor: 'none',
              padding: '0.5rem 1rem',
            },
          }}
          MenuProps={{
            style: {
              width: '100%',
              marginLeft: '-0.5rem',
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            MenuListProps: {},
          }}
          variant="standard"
          disableUnderline
          displayEmpty
          labelId="new-filter-label"
          IconComponent={() => <ArrowDownIcon />}
          renderValue={selected => {
            if (!selected) {
              return <div>{t('editor.sidebar.filter.select_placeholder')}</div>;
            }
            return selectOptions.find(o => o.id === selected)?.title;
          }}
        >
          {selectOptions.map(o => (
            <StyledMenuItem key={o.id} value={o.id}>
              {o.title}
            </StyledMenuItem>
          ))}
        </StyledSelect>
        <SelectedFiltersTitle>
          {t('editor.sidebar.filter.selected_filters_section')}
        </SelectedFiltersTitle>
        <Divider variant="fullWidth" sx={{ borderColor: theme.palette.brand.backgroundDialog }} />
      </Box>
      {!isFilterLoading && (
        <div>
          {filters?.map(filter => {
            return (
              <SidebarListSubItem
                key={filter.id}
                id={filter.id!}
                ariaId={filter.id!.toString()}
                title={filter.title}
                isExpanded={expandedAccordionKey === filter.id!}
                setExpandedAccordionKey={setExpandedAccordionKey}
                onDeleteClick={onDeleteClick}
                onShowClick={onShowClick}
                isVisible={!!filter.isVisible}
              >
                <FiltersAccordionSubContent
                  filter={filter}
                  handleEdit={handleEdit}
                  roadmapId={roadmap.id!}
                />
              </SidebarListSubItem>
            );
          })}
        </div>
      )}
      {isFilterLoading && (
        <LoaderWrapper>
          <StyledLoader />
        </LoaderWrapper>
      )}
      <ConfirmationDialog
        open={isDeleteOptionDialogOpen}
        okButtonText={t('yes_sure')}
        keepMounted={false}
        description={t('editor.sidebar.filter.delete_dialog_description')}
        handleOk={() => handleDelete(selectedFilter!)}
        id="delete-option-confirm-dialog"
        onClose={() => {
          setIsDeleteOptionDialogOpen(false);
          setSelectedFilter(undefined);
        }}
      />
    </ContentWrapper>
  );
};

const SelectedFiltersTitle = styled.div`
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.palette.brand.textPrimary};
`;

const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 1rem;
  border: 0;
  background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  font-size: 1.3rem;
  svg {
    width: 1rem;
    height: 1rem;
    position: relative;
    right: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }
  & .MuiInput-input:focus {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.palette.brand.backgroundDialog};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 1.3rem;
`;

const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

const StyledLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.brand.editorTabPrimary};
`;

const ContentWrapper = styled.div<{ $isExpanded: boolean }>`
  padding: 1.5rem 1rem;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      overflow-y: auto;
      height: 100%;
      animation: hide-scroll 0.3s backwards;
    `}
  @keyframes hide-scroll {
    from,
    to {
      overflow: hidden;
    }
  }
`;

export { FiltersAccordionContent };
